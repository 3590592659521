package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary
import com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay
import com.ustadmobile.lib.db.entities.StatementEntity
import com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class StatementDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StatementDao,
) : StatementDao() {
  override suspend fun insertListAsync(entityList: List<StatementEntity>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.statementUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(60)
        it.statementUid = _newPk
        _generatedPks += _newPk
      }
      it.statementLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  override fun getOneStatement(): Flow<StatementEntity?> = _dao.getOneStatement()

  override fun findByStatementId(id: String): StatementEntity? = throw
      IllegalStateException("findByStatementId: synchronous db access is NOT possible on Javascript!")

  override fun findByStatementIdList(id: List<String>): List<StatementEntity> = throw
      IllegalStateException("findByStatementIdList: synchronous db access is NOT possible on Javascript!")

  override suspend fun getResults(query: DoorQuery): List<StatementReportData> =
      _dao.getResults(query)

  override fun getListResults(query: DoorQuery):
      PagingSource<Int, StatementEntityWithDisplayDetails> = _dao.getListResults(query)

  override fun getPerson(): Person? = throw
      IllegalStateException("getPerson: synchronous db access is NOT possible on Javascript!")

  override fun getXLangMap(): XLangMapEntry? = throw
      IllegalStateException("getXLangMap: synchronous db access is NOT possible on Javascript!")

  override fun updateProgress(
    uid: Long,
    progress: Int,
    updateTime: Long,
  ): Unit = throw
      IllegalStateException("updateProgress: synchronous db access is NOT possible on Javascript!")

  override fun findPersonsWithContentEntryAttempts(
    contentEntryUid: Long,
    accountPersonUid: Long,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, PersonWithAttemptsSummary> =
      _dao.findPersonsWithContentEntryAttempts(contentEntryUid, accountPersonUid, searchText,
      sortOrder)

  override suspend fun getBestScoreForContentForPerson(contentEntryUid: Long,
      accountPersonUid: Long): ContentEntryStatementScoreProgress? =
      _dao.getBestScoreForContentForPerson(contentEntryUid, accountPersonUid)

  override suspend fun findNextStudentNotMarkedForAssignment(assignmentUid: Long,
      currentStudentUid: Long): Long = _dao.findNextStudentNotMarkedForAssignment(assignmentUid,
      currentStudentUid)

  override suspend fun findSubmittedStatementFromStudent(studentUid: Long,
      assignmentObjectUid: Long): StatementEntity? =
      _dao.findSubmittedStatementFromStudent(studentUid, assignmentObjectUid)

  override fun findScoreStatementForStudent(studentUid: Long): StatementEntity? = throw
      IllegalStateException("findScoreStatementForStudent: synchronous db access is NOT possible on Javascript!")

  override fun findSessionsForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
  ): PagingSource<Int, PersonWithSessionsDisplay> = _dao.findSessionsForPerson(contentEntryUid,
      accountPersonUid, personUid)

  override fun findSessionDetailForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
    contextRegistration: String,
  ): PagingSource<Int, StatementWithSessionDetailDisplay> =
      _dao.findSessionDetailForPerson(contentEntryUid, accountPersonUid, personUid,
      contextRegistration)

  override suspend fun calculateScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? = _dao.calculateScoreForSession(contextRegistration)

  override suspend fun findCompletedScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? = _dao.findCompletedScoreForSession(contextRegistration)

  override suspend fun findLatestRegistrationStatement(accountPersonUid: Long, entryUid: Long):
      String? = _dao.findLatestRegistrationStatement(accountPersonUid, entryUid)

  public override fun insert(entity: StatementEntity): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: StatementEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.statementUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(60)
      entity.statementUid = _newPk
    }
    entity.statementLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.statementUid
  }

  public override fun insertList(entityList: List<StatementEntity>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: StatementEntity): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
