package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.XLangMapEntry
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class XLangMapEntryDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: XLangMapEntryDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : XLangMapEntryDao() {
  override suspend fun getValuesWithListOfId(ids: List<Int>): List<XLangMapEntry> {
    val _result = _dao.getValuesWithListOfId(ids)
    return _result
  }

  override fun getXLangMapFromVerb(verbUid: Long, langMapUid: Long): XLangMapEntry? {
    val _result = _dao.getXLangMapFromVerb(verbUid, langMapUid)
    return _result
  }

  override fun getXLangMapFromObject(objectUid: Long, langMapUid: Long): XLangMapEntry? {
    val _result = _dao.getXLangMapFromObject(objectUid, langMapUid)
    return _result
  }

  public override fun insert(entity: XLangMapEntry): Long {
    val _result = _repo.withRepoChangeMonitor("XLangMapEntry") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: XLangMapEntry): Long {
    val _result = _repo.withRepoChangeMonitorAsync("XLangMapEntry") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<XLangMapEntry>) {
    _repo.withRepoChangeMonitor("XLangMapEntry") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: XLangMapEntry) {
    _repo.withRepoChangeMonitor("XLangMapEntry") {
      _dao.update(entity)
    }
  }
}
