package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.LocallyAvailableContainer
import kotlin.Boolean
import kotlin.String
import kotlin.collections.List

public class LocallyAvailableContainerDao_JdbcImpl(
  public val _db: RoomDatabase,
) : LocallyAvailableContainerDao() {
  public val _insertAdapterLocallyAvailableContainer_upsert:
      EntityInsertionAdapter<LocallyAvailableContainer> = object :
      EntityInsertionAdapter<LocallyAvailableContainer>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO LocallyAvailableContainer (laContainerUid) VALUES(?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: LocallyAvailableContainer) {
      stmt.setLong(1, entity.laContainerUid)
    }
  }

  override suspend fun insertList(locallyAvailableContainers: List<LocallyAvailableContainer>) {
    _insertAdapterLocallyAvailableContainer_upsert.insertListAsync(locallyAvailableContainers)
  }

  override suspend fun deleteList(locallyAvailableContainers: List<LocallyAvailableContainer>) {
    var _numChanges = 0
    _db.prepareAndUseStatementAsync("DELETE FROM LocallyAvailableContainer WHERE laContainerUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in locallyAvailableContainers) {
        _stmt.setLong(1, _entity.laContainerUid)
        _numChanges += _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  override fun deleteAll() {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "DELETE FROM LocallyAvailableContainer",
      readOnly = false,)
    ) { _stmt -> 
      _stmt.executeUpdate()
    }
  }
}
