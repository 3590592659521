package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.StateEntity
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StateDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StateDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StateDao() {
  override fun findByStateId(
    id: String?,
    agentUid: Long,
    activityId: String?,
    registration: String?,
  ): StateEntity? {
    val _result = _dao.findByStateId(id, agentUid, activityId, registration)
    return _result
  }

  override fun findStateIdByAgentAndActivity(
    agentUid: Long,
    activityId: String,
    registration: String,
    since: String,
  ): List<StateEntity> {
    val _result = _dao.findStateIdByAgentAndActivity(agentUid, activityId, registration, since)
    return _result
  }

  override fun updateStateToInActive(
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitor("StateEntity") {
      _dao.updateStateToInActive(agentUid, activityId, registration, isActive, updateTime)
    }
  }

  override fun setStateInActive(
    stateId: String,
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitor("StateEntity") {
      _dao.setStateInActive(stateId, agentUid, activityId, registration, isActive, updateTime)
    }
  }

  public override fun insert(entity: StateEntity): Long {
    val _result = _repo.withRepoChangeMonitor("StateEntity") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: StateEntity): Long {
    val _result = _repo.withRepoChangeMonitorAsync("StateEntity") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<StateEntity>) {
    _repo.withRepoChangeMonitor("StateEntity") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: StateEntity) {
    _repo.withRepoChangeMonitor("StateEntity") {
      _dao.update(entity)
    }
  }
}
