package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContentJob
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public actual abstract class ContentJobDao {
  public actual abstract suspend fun insertAsync(contentJob: ContentJob): Long

  public actual abstract fun findByUid(cjUid: Long): ContentJob?

  public actual abstract suspend fun findByUidAsync(cjUid: Long): ContentJob?

  public actual abstract fun findLiveDataByUid(cjUid: Long): Flow<ContentJob?>

  public actual abstract suspend fun updateDestinationDir(cjUid: Long, toUri: String)

  public actual abstract fun findMeteredAllowedLiveData(contentJobId: Long): Flow<Boolean>

  public actual abstract suspend fun isConnectivityAcceptableForJob(jobId: Long): Boolean
}
