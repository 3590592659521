package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class XLangMapEntryDao_JdbcImpl(
  public val _db: RoomDatabase,
) : XLangMapEntryDao() {
  public val _insertAdapterXLangMapEntry_: EntityInsertionAdapter<XLangMapEntry> = object :
      EntityInsertionAdapter<XLangMapEntry>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO XLangMapEntry (statementLangMapUid, verbLangMapUid, objectLangMapUid, languageLangMapUid, languageVariantLangMapUid, valueLangMap, statementLangMapMasterCsn, statementLangMapLocalCsn, statementLangMapLcb, statementLangMapLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: XLangMapEntry) {
      if(entity.statementLangMapUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.statementLangMapUid)
      }
      stmt.setLong(2, entity.verbLangMapUid)
      stmt.setLong(3, entity.objectLangMapUid)
      stmt.setLong(4, entity.languageLangMapUid)
      stmt.setLong(5, entity.languageVariantLangMapUid)
      stmt.setString(6, entity.valueLangMap)
      stmt.setInt(7, entity.statementLangMapMasterCsn)
      stmt.setInt(8, entity.statementLangMapLocalCsn)
      stmt.setInt(9, entity.statementLangMapLcb)
      stmt.setLong(10, entity.statementLangMapLct)
    }
  }

  public override fun insert(entity: XLangMapEntry): Long {
    val _retVal = _insertAdapterXLangMapEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: XLangMapEntry): Long {
    val _retVal = _insertAdapterXLangMapEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<XLangMapEntry>) {
    _insertAdapterXLangMapEntry_.insertList(entityList)
  }

  public override fun update(entity: XLangMapEntry) {
    val _sql =
        "UPDATE XLangMapEntry SET verbLangMapUid = ?, objectLangMapUid = ?, languageLangMapUid = ?, languageVariantLangMapUid = ?, valueLangMap = ?, statementLangMapMasterCsn = ?, statementLangMapLocalCsn = ?, statementLangMapLcb = ?, statementLangMapLct = ? WHERE statementLangMapUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.verbLangMapUid)
      _stmt.setLong(2, entity.objectLangMapUid)
      _stmt.setLong(3, entity.languageLangMapUid)
      _stmt.setLong(4, entity.languageVariantLangMapUid)
      _stmt.setString(5, entity.valueLangMap)
      _stmt.setInt(6, entity.statementLangMapMasterCsn)
      _stmt.setInt(7, entity.statementLangMapLocalCsn)
      _stmt.setInt(8, entity.statementLangMapLcb)
      _stmt.setLong(9, entity.statementLangMapLct)
      _stmt.setLong(10, entity.statementLangMapUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun getValuesWithListOfId(ids: List<Int>): List<XLangMapEntry> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM XLangMapEntry WHERE objectLangMapUid IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("INTEGER",
        ids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_statementLangMapUid = _result.getLong("statementLangMapUid")
        val _tmp_verbLangMapUid = _result.getLong("verbLangMapUid")
        val _tmp_objectLangMapUid = _result.getLong("objectLangMapUid")
        val _tmp_languageLangMapUid = _result.getLong("languageLangMapUid")
        val _tmp_languageVariantLangMapUid = _result.getLong("languageVariantLangMapUid")
        val _tmp_valueLangMap = _result.getString("valueLangMap")
        val _tmp_statementLangMapMasterCsn = _result.getInt("statementLangMapMasterCsn")
        val _tmp_statementLangMapLocalCsn = _result.getInt("statementLangMapLocalCsn")
        val _tmp_statementLangMapLcb = _result.getInt("statementLangMapLcb")
        val _tmp_statementLangMapLct = _result.getLong("statementLangMapLct")
        XLangMapEntry().apply {
          this.statementLangMapUid = _tmp_statementLangMapUid
          this.verbLangMapUid = _tmp_verbLangMapUid
          this.objectLangMapUid = _tmp_objectLangMapUid
          this.languageLangMapUid = _tmp_languageLangMapUid
          this.languageVariantLangMapUid = _tmp_languageVariantLangMapUid
          this.valueLangMap = _tmp_valueLangMap
          this.statementLangMapMasterCsn = _tmp_statementLangMapMasterCsn
          this.statementLangMapLocalCsn = _tmp_statementLangMapLocalCsn
          this.statementLangMapLcb = _tmp_statementLangMapLcb
          this.statementLangMapLct = _tmp_statementLangMapLct
        }
      }
    }
  }

  override fun getXLangMapFromVerb(verbUid: Long, langMapUid: Long): XLangMapEntry? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |SELECT * FROM XLangMapEntry WHERE 
    |            verbLangMapUid = CAST(? AS BIGINT) AND languageLangMapUid = CAST(? AS BIGINT) LIMIT 1
    """.trimMargin(),
    postgreSql = """
    |SELECT * FROM XLangMapEntry WHERE 
    |            verbLangMapUid = ? AND languageLangMapUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,verbUid)
    _stmt.setLong(2,langMapUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_statementLangMapUid = _result.getLong("statementLangMapUid")
        val _tmp_verbLangMapUid = _result.getLong("verbLangMapUid")
        val _tmp_objectLangMapUid = _result.getLong("objectLangMapUid")
        val _tmp_languageLangMapUid = _result.getLong("languageLangMapUid")
        val _tmp_languageVariantLangMapUid = _result.getLong("languageVariantLangMapUid")
        val _tmp_valueLangMap = _result.getString("valueLangMap")
        val _tmp_statementLangMapMasterCsn = _result.getInt("statementLangMapMasterCsn")
        val _tmp_statementLangMapLocalCsn = _result.getInt("statementLangMapLocalCsn")
        val _tmp_statementLangMapLcb = _result.getInt("statementLangMapLcb")
        val _tmp_statementLangMapLct = _result.getLong("statementLangMapLct")
        XLangMapEntry().apply {
          this.statementLangMapUid = _tmp_statementLangMapUid
          this.verbLangMapUid = _tmp_verbLangMapUid
          this.objectLangMapUid = _tmp_objectLangMapUid
          this.languageLangMapUid = _tmp_languageLangMapUid
          this.languageVariantLangMapUid = _tmp_languageVariantLangMapUid
          this.valueLangMap = _tmp_valueLangMap
          this.statementLangMapMasterCsn = _tmp_statementLangMapMasterCsn
          this.statementLangMapLocalCsn = _tmp_statementLangMapLocalCsn
          this.statementLangMapLcb = _tmp_statementLangMapLcb
          this.statementLangMapLct = _tmp_statementLangMapLct
        }
      }
    }
  }

  override fun getXLangMapFromObject(objectUid: Long, langMapUid: Long): XLangMapEntry? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |SELECT * FROM XLangMapEntry WHERE 
    |            objectLangMapUid = CAST(? AS BIGINT) AND languageLangMapUid = CAST(? AS BIGINT) LIMIT 1
    """.trimMargin(),
    postgreSql = """
    |SELECT * FROM XLangMapEntry WHERE 
    |            objectLangMapUid = ? AND languageLangMapUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,objectUid)
    _stmt.setLong(2,langMapUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_statementLangMapUid = _result.getLong("statementLangMapUid")
        val _tmp_verbLangMapUid = _result.getLong("verbLangMapUid")
        val _tmp_objectLangMapUid = _result.getLong("objectLangMapUid")
        val _tmp_languageLangMapUid = _result.getLong("languageLangMapUid")
        val _tmp_languageVariantLangMapUid = _result.getLong("languageVariantLangMapUid")
        val _tmp_valueLangMap = _result.getString("valueLangMap")
        val _tmp_statementLangMapMasterCsn = _result.getInt("statementLangMapMasterCsn")
        val _tmp_statementLangMapLocalCsn = _result.getInt("statementLangMapLocalCsn")
        val _tmp_statementLangMapLcb = _result.getInt("statementLangMapLcb")
        val _tmp_statementLangMapLct = _result.getLong("statementLangMapLct")
        XLangMapEntry().apply {
          this.statementLangMapUid = _tmp_statementLangMapUid
          this.verbLangMapUid = _tmp_verbLangMapUid
          this.objectLangMapUid = _tmp_objectLangMapUid
          this.languageLangMapUid = _tmp_languageLangMapUid
          this.languageVariantLangMapUid = _tmp_languageVariantLangMapUid
          this.valueLangMap = _tmp_valueLangMap
          this.statementLangMapMasterCsn = _tmp_statementLangMapMasterCsn
          this.statementLangMapLocalCsn = _tmp_statementLangMapLocalCsn
          this.statementLangMapLcb = _tmp_statementLangMapLcb
          this.statementLangMapLct = _tmp_statementLangMapLct
        }
      }
    }
  }
}
