package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Container
import kotlin.collections.List

public class ContainerDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContainerDao() {
  override suspend fun deleteListAsync(list: List<Container>) {
    var _numChanges = 0
    _db.prepareAndUseStatementAsync("DELETE FROM Container WHERE containerUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in list) {
        _stmt.setLong(1, _entity.containerUid)
        _numChanges += _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  override suspend fun findAllBatch(): List<Container> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT Container.*
    |          FROM Container
    |         LIMIT 100 
    |    
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_containerUid = _result.getLong("containerUid")
        val _tmp_cntLocalCsn = _result.getLong("cntLocalCsn")
        val _tmp_cntMasterCsn = _result.getLong("cntMasterCsn")
        val _tmp_cntLastModBy = _result.getInt("cntLastModBy")
        val _tmp_cntLct = _result.getLong("cntLct")
        val _tmp_fileSize = _result.getLong("fileSize")
        val _tmp_containerContentEntryUid = _result.getLong("containerContentEntryUid")
        val _tmp_cntLastModified = _result.getLong("cntLastModified")
        val _tmp_mimeType = _result.getString("mimeType")
        val _tmp_remarks = _result.getString("remarks")
        val _tmp_mobileOptimized = _result.getBoolean("mobileOptimized")
        val _tmp_cntNumEntries = _result.getInt("cntNumEntries")
        Container().apply {
          this.containerUid = _tmp_containerUid
          this.cntLocalCsn = _tmp_cntLocalCsn
          this.cntMasterCsn = _tmp_cntMasterCsn
          this.cntLastModBy = _tmp_cntLastModBy
          this.cntLct = _tmp_cntLct
          this.fileSize = _tmp_fileSize
          this.containerContentEntryUid = _tmp_containerContentEntryUid
          this.cntLastModified = _tmp_cntLastModified
          this.mimeType = _tmp_mimeType
          this.remarks = _tmp_remarks
          this.mobileOptimized = _tmp_mobileOptimized
          this.cntNumEntries = _tmp_cntNumEntries
        }
      }
    }
  }
}
