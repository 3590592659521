package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.XObjectEntity
import kotlin.Long
import kotlin.String

public actual abstract class XObjectDao : BaseDao<XObjectEntity> {
  public actual abstract fun findByObjectId(id: String?): XObjectEntity?

  public actual abstract fun findByXobjectUid(xObjectUid: Long): XObjectEntity?
}
