package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class SchoolDao_JdbcImpl(
  public val _db: RoomDatabase,
) : SchoolDao() {
  public val _insertAdapterSchool_: EntityInsertionAdapter<School> = object :
      EntityInsertionAdapter<School>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO School (schoolUid, schoolName, schoolDesc, schoolAddress, schoolActive, schoolPhoneNumber, schoolGender, schoolHolidayCalendarUid, schoolFeatures, schoolLocationLong, schoolLocationLatt, schoolEmailAddress, schoolTeachersPersonGroupUid, schoolStudentsPersonGroupUid, schoolPendingStudentsPersonGroupUid, schoolCode, schoolMasterChangeSeqNum, schoolLocalChangeSeqNum, schoolLastChangedBy, schoolLct, schoolTimeZone) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: School) {
      if(entity.schoolUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.schoolUid)
      }
      stmt.setString(2, entity.schoolName)
      stmt.setString(3, entity.schoolDesc)
      stmt.setString(4, entity.schoolAddress)
      stmt.setBoolean(5, entity.schoolActive)
      stmt.setString(6, entity.schoolPhoneNumber)
      stmt.setInt(7, entity.schoolGender)
      stmt.setLong(8, entity.schoolHolidayCalendarUid)
      stmt.setLong(9, entity.schoolFeatures)
      stmt.setDouble(10, entity.schoolLocationLong)
      stmt.setDouble(11, entity.schoolLocationLatt)
      stmt.setString(12, entity.schoolEmailAddress)
      stmt.setLong(13, entity.schoolTeachersPersonGroupUid)
      stmt.setLong(14, entity.schoolStudentsPersonGroupUid)
      stmt.setLong(15, entity.schoolPendingStudentsPersonGroupUid)
      stmt.setString(16, entity.schoolCode)
      stmt.setLong(17, entity.schoolMasterChangeSeqNum)
      stmt.setLong(18, entity.schoolLocalChangeSeqNum)
      stmt.setInt(19, entity.schoolLastChangedBy)
      stmt.setLong(20, entity.schoolLct)
      stmt.setString(21, entity.schoolTimeZone)
    }
  }

  public override fun insert(entity: School): Long {
    val _retVal = _insertAdapterSchool_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: School): Long {
    val _retVal = _insertAdapterSchool_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<School>) {
    _insertAdapterSchool_.insertList(entityList)
  }

  override suspend fun updateAsync(entity: School): Int {
    var _result = 0
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.schoolName)
      _stmt.setString(2, entity.schoolDesc)
      _stmt.setString(3, entity.schoolAddress)
      _stmt.setBoolean(4, entity.schoolActive)
      _stmt.setString(5, entity.schoolPhoneNumber)
      _stmt.setInt(6, entity.schoolGender)
      _stmt.setLong(7, entity.schoolHolidayCalendarUid)
      _stmt.setLong(8, entity.schoolFeatures)
      _stmt.setDouble(9, entity.schoolLocationLong)
      _stmt.setDouble(10, entity.schoolLocationLatt)
      _stmt.setString(11, entity.schoolEmailAddress)
      _stmt.setLong(12, entity.schoolTeachersPersonGroupUid)
      _stmt.setLong(13, entity.schoolStudentsPersonGroupUid)
      _stmt.setLong(14, entity.schoolPendingStudentsPersonGroupUid)
      _stmt.setString(15, entity.schoolCode)
      _stmt.setLong(16, entity.schoolMasterChangeSeqNum)
      _stmt.setLong(17, entity.schoolLocalChangeSeqNum)
      _stmt.setInt(18, entity.schoolLastChangedBy)
      _stmt.setLong(19, entity.schoolLct)
      _stmt.setString(20, entity.schoolTimeZone)
      _stmt.setLong(21, entity.schoolUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun update(entity: School) {
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.schoolName)
      _stmt.setString(2, entity.schoolDesc)
      _stmt.setString(3, entity.schoolAddress)
      _stmt.setBoolean(4, entity.schoolActive)
      _stmt.setString(5, entity.schoolPhoneNumber)
      _stmt.setInt(6, entity.schoolGender)
      _stmt.setLong(7, entity.schoolHolidayCalendarUid)
      _stmt.setLong(8, entity.schoolFeatures)
      _stmt.setDouble(9, entity.schoolLocationLong)
      _stmt.setDouble(10, entity.schoolLocationLatt)
      _stmt.setString(11, entity.schoolEmailAddress)
      _stmt.setLong(12, entity.schoolTeachersPersonGroupUid)
      _stmt.setLong(13, entity.schoolStudentsPersonGroupUid)
      _stmt.setLong(14, entity.schoolPendingStudentsPersonGroupUid)
      _stmt.setString(15, entity.schoolCode)
      _stmt.setLong(16, entity.schoolMasterChangeSeqNum)
      _stmt.setLong(17, entity.schoolLocalChangeSeqNum)
      _stmt.setInt(18, entity.schoolLastChangedBy)
      _stmt.setLong(19, entity.schoolLct)
      _stmt.setString(20, entity.schoolTimeZone)
      _stmt.setLong(21, entity.schoolUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun findByUidAsync(schoolUid: Long): School? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT * FROM School WHERE schoolUid = CAST(? AS BIGINT) AND CAST(schoolActive AS INTEGER) = 1",
    postgreSql = """
    |SELECT * FROM School WHERE schoolUid = ? AND CAST(schoolActive AS INTEGER) = 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,schoolUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        School().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
        }
      }
    }
  }

  override suspend fun findByUidWithHolidayCalendarAsync(uid: Long): SchoolWithHolidayCalendar? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |SELECT School.*, HolidayCalendar.* FROM School 
    |            LEFT JOIN HolidayCalendar ON School.schoolHolidayCalendarUid = HolidayCalendar.umCalendarUid
    |            WHERE School.schoolUid = CAST(? AS BIGINT)
    """.trimMargin(),
    postgreSql = """
    |SELECT School.*, HolidayCalendar.* FROM School 
    |            LEFT JOIN HolidayCalendar ON School.schoolHolidayCalendarUid = HolidayCalendar.umCalendarUid
    |            WHERE School.schoolUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        var _tmp_HolidayCalendar_nullCount = 0
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarName = _result.getString("umCalendarName")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarCategory = _result.getInt("umCalendarCategory")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarActive = _result.getBoolean("umCalendarActive")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarMasterChangeSeqNum = _result.getLong("umCalendarMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLocalChangeSeqNum = _result.getLong("umCalendarLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLastChangedBy = _result.getInt("umCalendarLastChangedBy")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_umCalendarLct = _result.getLong("umCalendarLct")
        if(_result.wasNull()) _tmp_HolidayCalendar_nullCount++
        val _tmp_HolidayCalendar_isAllNull = _tmp_HolidayCalendar_nullCount == 8
        SchoolWithHolidayCalendar().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
          if(!_tmp_HolidayCalendar_isAllNull) {
            this.holidayCalendar = HolidayCalendar().apply {
              this.umCalendarUid = _tmp_umCalendarUid
              this.umCalendarName = _tmp_umCalendarName
              this.umCalendarCategory = _tmp_umCalendarCategory
              this.umCalendarActive = _tmp_umCalendarActive
              this.umCalendarMasterChangeSeqNum = _tmp_umCalendarMasterChangeSeqNum
              this.umCalendarLocalChangeSeqNum = _tmp_umCalendarLocalChangeSeqNum
              this.umCalendarLastChangedBy = _tmp_umCalendarLastChangedBy
              this.umCalendarLct = _tmp_umCalendarLct
            }
          }
        }
      }
    }
  }

  override suspend fun findBySchoolCode(code: String): School? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM School WHERE schoolCode = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,code)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        School().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
        }
      }
    }
  }

  override suspend fun findBySchoolCodeFromWeb(code: String): School? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM School WHERE schoolCode = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,code)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolUid = _result.getLong("schoolUid")
        val _tmp_schoolName = _result.getString("schoolName")
        val _tmp_schoolDesc = _result.getString("schoolDesc")
        val _tmp_schoolAddress = _result.getString("schoolAddress")
        val _tmp_schoolActive = _result.getBoolean("schoolActive")
        val _tmp_schoolPhoneNumber = _result.getString("schoolPhoneNumber")
        val _tmp_schoolGender = _result.getInt("schoolGender")
        val _tmp_schoolHolidayCalendarUid = _result.getLong("schoolHolidayCalendarUid")
        val _tmp_schoolFeatures = _result.getLong("schoolFeatures")
        val _tmp_schoolLocationLong = _result.getDouble("schoolLocationLong")
        val _tmp_schoolLocationLatt = _result.getDouble("schoolLocationLatt")
        val _tmp_schoolEmailAddress = _result.getString("schoolEmailAddress")
        val _tmp_schoolTeachersPersonGroupUid = _result.getLong("schoolTeachersPersonGroupUid")
        val _tmp_schoolStudentsPersonGroupUid = _result.getLong("schoolStudentsPersonGroupUid")
        val _tmp_schoolPendingStudentsPersonGroupUid =
            _result.getLong("schoolPendingStudentsPersonGroupUid")
        val _tmp_schoolCode = _result.getString("schoolCode")
        val _tmp_schoolMasterChangeSeqNum = _result.getLong("schoolMasterChangeSeqNum")
        val _tmp_schoolLocalChangeSeqNum = _result.getLong("schoolLocalChangeSeqNum")
        val _tmp_schoolLastChangedBy = _result.getInt("schoolLastChangedBy")
        val _tmp_schoolLct = _result.getLong("schoolLct")
        val _tmp_schoolTimeZone = _result.getString("schoolTimeZone")
        School().apply {
          this.schoolUid = _tmp_schoolUid
          this.schoolName = _tmp_schoolName
          this.schoolDesc = _tmp_schoolDesc
          this.schoolAddress = _tmp_schoolAddress
          this.schoolActive = _tmp_schoolActive
          this.schoolPhoneNumber = _tmp_schoolPhoneNumber
          this.schoolGender = _tmp_schoolGender
          this.schoolHolidayCalendarUid = _tmp_schoolHolidayCalendarUid
          this.schoolFeatures = _tmp_schoolFeatures
          this.schoolLocationLong = _tmp_schoolLocationLong
          this.schoolLocationLatt = _tmp_schoolLocationLatt
          this.schoolEmailAddress = _tmp_schoolEmailAddress
          this.schoolTeachersPersonGroupUid = _tmp_schoolTeachersPersonGroupUid
          this.schoolStudentsPersonGroupUid = _tmp_schoolStudentsPersonGroupUid
          this.schoolPendingStudentsPersonGroupUid = _tmp_schoolPendingStudentsPersonGroupUid
          this.schoolCode = _tmp_schoolCode
          this.schoolMasterChangeSeqNum = _tmp_schoolMasterChangeSeqNum
          this.schoolLocalChangeSeqNum = _tmp_schoolLocalChangeSeqNum
          this.schoolLastChangedBy = _tmp_schoolLastChangedBy
          this.schoolLct = _tmp_schoolLct
          this.schoolTimeZone = _tmp_schoolTimeZone
        }
      }
    }
  }
}
