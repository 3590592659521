package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.Person
import kotlin.Unit

public actual fun Person.shallowCopy(block: Person.() -> Unit): Person = Person().also {
  it.personUid = this.personUid
  it.username = this.username
  it.firstNames = this.firstNames
  it.lastName = this.lastName
  it.emailAddr = this.emailAddr
  it.phoneNum = this.phoneNum
  it.gender = this.gender
  it.active = this.active
  it.admin = this.admin
  it.personNotes = this.personNotes
  it.fatherName = this.fatherName
  it.fatherNumber = this.fatherNumber
  it.motherName = this.motherName
  it.motherNum = this.motherNum
  it.dateOfBirth = this.dateOfBirth
  it.personAddress = this.personAddress
  it.personOrgId = this.personOrgId
  it.personGroupUid = this.personGroupUid
  it.personMasterChangeSeqNum = this.personMasterChangeSeqNum
  it.personLocalChangeSeqNum = this.personLocalChangeSeqNum
  it.personLastChangedBy = this.personLastChangedBy
  it.personLct = this.personLct
  it.personCountry = this.personCountry
  it.personType = this.personType
  block(it)
}
