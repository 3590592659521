package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.MessageRead
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class MessageReadDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: MessageReadDao,
) : MessageReadDao() {
  public override fun insert(entity: MessageRead): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: MessageRead): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.messageReadUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(129)
      entity.messageReadUid = _newPk
    }
    entity.messageReadLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.messageReadUid
  }

  public override fun insertList(entityList: List<MessageRead>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: MessageRead): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
