package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class VerbDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: VerbDao,
) : VerbDao() {
  override fun findByUrl(urlId: String?): VerbEntity? = throw
      IllegalStateException("findByUrl: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUidList(uidList: List<Long>): List<Long> = _dao.findByUidList(uidList)

  override suspend fun replaceList(entityList: List<VerbEntity>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.verbUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(62)
        it.verbUid = _newPk
        _generatedPks += _newPk
      }
      it.verbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceList(entityList)
  }

  override fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay> = throw
      IllegalStateException("findAllVerbsAscList: synchronous db access is NOT possible on Javascript!")

  override fun findAllVerbsAsc(uidList: List<Long>): PagingSource<Int, VerbDisplay> =
      _dao.findAllVerbsAsc(uidList)

  override fun findAllVerbsDesc(uidList: List<Long>): PagingSource<Int, VerbDisplay> =
      _dao.findAllVerbsDesc(uidList)

  public override fun insert(entity: VerbEntity): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: VerbEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.verbUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(62)
      entity.verbUid = _newPk
    }
    entity.verbLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.verbUid
  }

  public override fun insertList(entityList: List<VerbEntity>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: VerbEntity): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
