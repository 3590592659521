package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.AgentEntity
import kotlin.String

public actual abstract class AgentDao : BaseDao<AgentEntity> {
  public actual abstract fun getAgentByAnyId(
    openId: String?,
    mbox: String?,
    account: String?,
    homepage: String?,
    sha1: String?,
  ): AgentEntity?

  public actual abstract suspend fun getAgentFromPersonUsername(endpoint: String, username: String):
      AgentEntity?
}
