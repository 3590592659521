package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.XObjectEntity
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class XObjectDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: XObjectDao,
) : XObjectDao() {
  override fun findByObjectId(id: String?): XObjectEntity? = throw
      IllegalStateException("findByObjectId: synchronous db access is NOT possible on Javascript!")

  override fun findByXobjectUid(xObjectUid: Long): XObjectEntity? = throw
      IllegalStateException("findByXobjectUid: synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: XObjectEntity): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: XObjectEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.xObjectUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(64)
      entity.xObjectUid = _newPk
    }
    entity.xObjectLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.xObjectUid
  }

  public override fun insertList(entityList: List<XObjectEntity>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: XObjectEntity): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
