package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.CourseBlock
import kotlin.Unit

public actual fun CourseBlock.shallowCopy(block: CourseBlock.() -> Unit): CourseBlock =
    CourseBlock().also {
  it.cbUid = this.cbUid
  it.cbType = this.cbType
  it.cbIndentLevel = this.cbIndentLevel
  it.cbModuleParentBlockUid = this.cbModuleParentBlockUid
  it.cbTitle = this.cbTitle
  it.cbDescription = this.cbDescription
  it.cbCompletionCriteria = this.cbCompletionCriteria
  it.cbHideUntilDate = this.cbHideUntilDate
  it.cbDeadlineDate = this.cbDeadlineDate
  it.cbLateSubmissionPenalty = this.cbLateSubmissionPenalty
  it.cbGracePeriodDate = this.cbGracePeriodDate
  it.cbMaxPoints = this.cbMaxPoints
  it.cbMinPoints = this.cbMinPoints
  it.cbIndex = this.cbIndex
  it.cbClazzUid = this.cbClazzUid
  it.cbActive = this.cbActive
  it.cbHidden = this.cbHidden
  it.cbEntityUid = this.cbEntityUid
  it.cbLct = this.cbLct
  block(it)
}
