package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary
import com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay
import com.ustadmobile.lib.db.entities.StatementEntity
import com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay
import com.ustadmobile.lib.db.entities.XLangMapEntry
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StatementDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StatementDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StatementDao() {
  override suspend fun insertListAsync(entityList: List<StatementEntity>) {
    _repo.withRepoChangeMonitorAsync("StatementEntity") {
      _dao.insertListAsync(entityList)
    }
  }

  override fun getOneStatement(): Flow<StatementEntity?> {
    val _result = _dao.getOneStatement()
    return _result
  }

  override fun findByStatementId(id: String): StatementEntity? {
    val _result = _dao.findByStatementId(id)
    return _result
  }

  override fun findByStatementIdList(id: List<String>): List<StatementEntity> {
    val _result = _dao.findByStatementIdList(id)
    return _result
  }

  override suspend fun getResults(query: DoorQuery): List<StatementReportData> {
    val _result = _dao.getResults(query)
    return _result
  }

  override fun getListResults(query: DoorQuery):
      PagingSource<Int, StatementEntityWithDisplayDetails> {
    val _result = _dao.getListResults(query)
    return _result
  }

  override fun getPerson(): Person? {
    val _result = _dao.getPerson()
    return _result
  }

  override fun getXLangMap(): XLangMapEntry? {
    val _result = _dao.getXLangMap()
    return _result
  }

  override fun updateProgress(
    uid: Long,
    progress: Int,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitor("StatementEntity") {
      _dao.updateProgress(uid, progress, updateTime)
    }
  }

  override fun findPersonsWithContentEntryAttempts(
    contentEntryUid: Long,
    accountPersonUid: Long,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, PersonWithAttemptsSummary> {
    val _result = _dao.findPersonsWithContentEntryAttempts(contentEntryUid, accountPersonUid,
        searchText, sortOrder)
    return _result
  }

  override suspend fun getBestScoreForContentForPerson(contentEntryUid: Long,
      accountPersonUid: Long): ContentEntryStatementScoreProgress? {
    val _result = _dao.getBestScoreForContentForPerson(contentEntryUid, accountPersonUid)
    return _result
  }

  override suspend fun findNextStudentNotMarkedForAssignment(assignmentUid: Long,
      currentStudentUid: Long): Long {
    val _result = _dao.findNextStudentNotMarkedForAssignment(assignmentUid, currentStudentUid)
    return _result
  }

  override suspend fun findSubmittedStatementFromStudent(studentUid: Long,
      assignmentObjectUid: Long): StatementEntity? {
    val _result = _dao.findSubmittedStatementFromStudent(studentUid, assignmentObjectUid)
    return _result
  }

  override fun findScoreStatementForStudent(studentUid: Long): StatementEntity? {
    val _result = _dao.findScoreStatementForStudent(studentUid)
    return _result
  }

  override fun findSessionsForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
  ): PagingSource<Int, PersonWithSessionsDisplay> {
    val _result = _dao.findSessionsForPerson(contentEntryUid, accountPersonUid, personUid)
    return _result
  }

  override fun findSessionDetailForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
    contextRegistration: String,
  ): PagingSource<Int, StatementWithSessionDetailDisplay> {
    val _result = _dao.findSessionDetailForPerson(contentEntryUid, accountPersonUid, personUid,
        contextRegistration)
    return _result
  }

  override suspend fun calculateScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? {
    val _result = _dao.calculateScoreForSession(contextRegistration)
    return _result
  }

  override suspend fun findCompletedScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? {
    val _result = _dao.findCompletedScoreForSession(contextRegistration)
    return _result
  }

  override suspend fun findLatestRegistrationStatement(accountPersonUid: Long, entryUid: Long):
      String? {
    val _result = _dao.findLatestRegistrationStatement(accountPersonUid, entryUid)
    return _result
  }

  public override fun insert(entity: StatementEntity): Long {
    val _result = _repo.withRepoChangeMonitor("StatementEntity") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: StatementEntity): Long {
    val _result = _repo.withRepoChangeMonitorAsync("StatementEntity") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<StatementEntity>) {
    _repo.withRepoChangeMonitor("StatementEntity") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: StatementEntity) {
    _repo.withRepoChangeMonitor("StatementEntity") {
      _dao.update(entity)
    }
  }
}
