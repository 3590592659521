package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.MessageRead
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class MessageReadDao_JdbcImpl(
  public val _db: RoomDatabase,
) : MessageReadDao() {
  public val _insertAdapterMessageRead_: EntityInsertionAdapter<MessageRead> = object :
      EntityInsertionAdapter<MessageRead>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO MessageRead (messageReadUid, messageReadPersonUid, messageReadMessageUid, messageReadEntityUid, messageReadLct) VALUES(?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: MessageRead) {
      if(entity.messageReadUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.messageReadUid)
      }
      stmt.setLong(2, entity.messageReadPersonUid)
      stmt.setLong(3, entity.messageReadMessageUid)
      stmt.setLong(4, entity.messageReadEntityUid)
      stmt.setLong(5, entity.messageReadLct)
    }
  }

  public override fun insert(entity: MessageRead): Long {
    val _retVal = _insertAdapterMessageRead_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: MessageRead): Long {
    val _retVal = _insertAdapterMessageRead_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<MessageRead>) {
    _insertAdapterMessageRead_.insertList(entityList)
  }

  public override fun update(entity: MessageRead) {
    val _sql =
        "UPDATE MessageRead SET messageReadPersonUid = ?, messageReadMessageUid = ?, messageReadEntityUid = ?, messageReadLct = ? WHERE messageReadUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.messageReadPersonUid)
      _stmt.setLong(2, entity.messageReadMessageUid)
      _stmt.setLong(3, entity.messageReadEntityUid)
      _stmt.setLong(4, entity.messageReadLct)
      _stmt.setLong(5, entity.messageReadUid)
      _stmt.executeUpdate()
    }
  }
}
