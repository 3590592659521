package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.composites.ClazzAndDetailPermissions
import com.ustadmobile.lib.db.composites.ClazzNameAndTerminology
import com.ustadmobile.lib.db.entities.Clazz
import com.ustadmobile.lib.db.entities.ClazzWithDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndSchoolAndTerminology
import com.ustadmobile.lib.db.entities.ClazzWithListDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithSchool
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ClazzDao : BaseDao<Clazz> {
  public actual abstract fun findByUid(uid: Long): Clazz?

  public actual abstract fun findByUidLive(uid: Long): Flow<Clazz?>

  public actual abstract suspend fun findByClazzCode(code: String): Clazz?

  public actual abstract suspend fun findByClazzCodeFromWeb(code: String): Clazz?

  public actual abstract fun findAllLive(): Flow<List<Clazz>>

  public actual abstract fun findAll(): List<Clazz>

  public actual abstract suspend fun findByUidAsync(clazzUid: Long): Clazz?

  public actual abstract fun findByUidAsFlow(uid: Long): Flow<Clazz?>

  public actual abstract suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      ClazzWithHolidayCalendarAndSchoolAndTerminology?

  public actual abstract suspend fun updateAsync(entity: Clazz): Int

  public actual abstract fun findClazzesWithPermission(
    searchQuery: String,
    accountPersonUid: Long,
    excludeSelectedClazzList: List<Long>,
    sortOrder: Int,
    filter: Int,
    currentTime: Long,
    permission: Long,
  ): PagingSource<Int, ClazzWithListDisplayDetails>

  public actual abstract suspend fun getClassNamesFromListOfIds(ids: List<Long>): List<UidAndLabel>

  public actual abstract fun findByClazzName(name: String): List<Clazz>

  public actual abstract suspend fun updateClazzAttendanceAverageAsync(clazzUid: Long,
      timeChanged: Long)

  public actual abstract fun clazzAndDetailPermissionsAsFlow(accountPersonUid: Long,
      clazzUid: Long): Flow<ClazzAndDetailPermissions?>

  public actual abstract fun getClazzWithDisplayDetails(clazzUid: Long, currentTime: Long):
      Flow<ClazzWithDisplayDetails?>

  public actual abstract fun findClazzesWithEffectiveHolidayCalendarAndFilter(filterUid: Long):
      List<ClazzWithHolidayCalendarAndSchoolAndTerminology>

  public actual abstract suspend fun getClazzWithSchool(clazzUid: Long): ClazzWithSchool?

  public actual abstract fun getTitleByUidAsFlow(clazzUid: Long): Flow<String?>

  public actual abstract fun getClazzNameAndTerminologyAsFlow(clazzUid: Long):
      Flow<ClazzNameAndTerminology?>

  public actual abstract suspend fun getClazzTimeZoneByClazzUidAsync(clazzUid: Long): String?

  public actual abstract suspend fun getCoursesByName(names: List<String>): List<Clazz>
}
