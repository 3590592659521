package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.NetworkNode
import kotlin.Array
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class NetworkNodeDao {
  public actual abstract fun all(): List<NetworkNode>

  public actual abstract fun findNodeByIpAddress(ipAddress: String): NetworkNode?

  public actual abstract fun findNodeById(nodeId: Long): NetworkNode?

  public actual abstract fun findNodeByIpOrWifiDirectMacAddress(ipAddress: String,
      wifiDirectMacAddress: String): NetworkNode?

  public actual abstract fun findNodeByBluetoothAddress(bluetoothAddress: String): NetworkNode?

  public actual abstract fun replace(node: NetworkNode): Long

  public actual abstract suspend fun insertAsync(node: NetworkNode): Long

  public actual abstract fun insertList(nodeList: List<NetworkNode>): Array<Long>

  public actual abstract fun update(node: NetworkNode)

  public actual abstract fun deleteByBluetoothAddress(bluetoothAddress: String)

  public actual abstract suspend fun deleteAllAsync()

  public actual abstract suspend fun updateRetryCountAsync(nodeId: Long)

  public actual abstract fun findAllActiveNodes(lastUpdatedTime: Long, maxNumFailure: Int):
      List<NetworkNode>

  public actual abstract suspend fun updateLastSeenAsync(bluetoothAddress: String,
      lastUpdateTimeStamp: Long): Int

  public actual abstract fun deleteOldAndBadNode(minLastSeenTimestamp: Long,
      maxFailuresInPeriod: Int)

  public actual abstract fun updateNetworkNodeGroupSsid(
    nodeId: Long,
    groupSsid: String,
    endpointUrl: String,
  )

  public actual abstract fun getEndpointUrlByGroupSsid(ssid: String): String?
}
