package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import kotlin.Int
import kotlin.Long
import kotlin.String

public actual abstract class SchoolDao : BaseDao<School> {
  public actual abstract suspend fun findByUidAsync(schoolUid: Long): School?

  public actual abstract suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      SchoolWithHolidayCalendar?

  public actual abstract suspend fun findBySchoolCode(code: String): School?

  public actual abstract suspend fun findBySchoolCodeFromWeb(code: String): School?

  public actual abstract suspend fun updateAsync(entity: School): Int
}
