package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.AgentDao
import com.ustadmobile.core.db.dao.CacheLockJoinDao
import com.ustadmobile.core.db.dao.ChatDao
import com.ustadmobile.core.db.dao.ChatMemberDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.ConnectivityStatusDao
import com.ustadmobile.core.db.dao.ContainerDao
import com.ustadmobile.core.db.dao.ContainerETagDao
import com.ustadmobile.core.db.dao.ContainerEntryDao
import com.ustadmobile.core.db.dao.ContainerEntryFileDao
import com.ustadmobile.core.db.dao.ContainerImportJobDao
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryImportJobDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryVersionDao
import com.ustadmobile.core.db.dao.ContentJobDao
import com.ustadmobile.core.db.dao.ContextXObjectStatementJoinDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockPictureDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CoursePermissionDao
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.DeletedItemDao
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.EnrolmentRequestDao
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ExternalAppPermissionDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.LocallyAvailableContainerDao
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.MessageReadDao
import com.ustadmobile.core.db.dao.NetworkNodeDao
import com.ustadmobile.core.db.dao.OfflineItemDao
import com.ustadmobile.core.db.dao.OutgoingReplicationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.SchoolDao
import com.ustadmobile.core.db.dao.SchoolMemberDao
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.ScrapeQueueItemDao
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.StateContentDao
import com.ustadmobile.core.db.dao.StateDao
import com.ustadmobile.core.db.dao.StatementDao
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.SystemPermissionDao
import com.ustadmobile.core.db.dao.TransferJobDao
import com.ustadmobile.core.db.dao.TransferJobErrorDao
import com.ustadmobile.core.db.dao.TransferJobItemDao
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.VerbDao
import com.ustadmobile.core.db.dao.XLangMapEntryDao
import com.ustadmobile.core.db.dao.XObjectDao
import com.ustadmobile.door.room.RoomDatabase

public actual abstract class UmAppDatabase : RoomDatabase() {
  public actual abstract val networkNodeDao: NetworkNodeDao

  public actual abstract val personDao: PersonDao

  public actual abstract val clazzDao: ClazzDao

  public actual abstract val courseBlockDao: CourseBlockDao

  public actual abstract val courseTerminologyDao: CourseTerminologyDao

  public actual abstract val courseGroupSetDao: CourseGroupSetDao

  public actual abstract val courseGroupMemberDao: CourseGroupMemberDao

  public actual abstract val clazzEnrolmentDao: ClazzEnrolmentDao

  public actual abstract val leavingReasonDao: LeavingReasonDao

  public actual abstract val contentEntryDao: ContentEntryDao

  public actual abstract val contentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao

  public actual abstract val contentEntryParentChildJoinDao: ContentEntryParentChildJoinDao

  public actual abstract val contentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao

  public actual abstract val contentCategorySchemaDao: ContentCategorySchemaDao

  public actual abstract val contentCategoryDao: ContentCategoryDao

  public actual abstract val languageDao: LanguageDao

  public actual abstract val languageVariantDao: LanguageVariantDao

  public actual abstract val scrapeQueueItemDao: ScrapeQueueItemDao

  public actual abstract val personAuthDao: PersonAuthDao

  public actual abstract val personGroupDao: PersonGroupDao

  public actual abstract val personGroupMemberDao: PersonGroupMemberDao

  public actual abstract val personPictureDao: PersonPictureDao

  public actual abstract val connectivityStatusDao: ConnectivityStatusDao

  public actual abstract val containerDao: ContainerDao

  public actual abstract val containerEntryDao: ContainerEntryDao

  public actual abstract val containerEntryFileDao: ContainerEntryFileDao

  public actual abstract val containerETagDao: ContainerETagDao

  public actual abstract val verbDao: VerbDao

  public actual abstract val xObjectDao: XObjectDao

  public actual abstract val reportDao: ReportDao

  public actual abstract val containerImportJobDao: ContainerImportJobDao

  public actual abstract val statementDao: StatementDao

  public actual abstract val contextXObjectStatementJoinDao: ContextXObjectStatementJoinDao

  public actual abstract val stateDao: StateDao

  public actual abstract val stateContentDao: StateContentDao

  public actual abstract val agentDao: AgentDao

  public actual abstract val clazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao

  public actual abstract val clazzLogDao: ClazzLogDao

  public actual abstract val scheduleDao: ScheduleDao

  public actual abstract val holidayCalendarDao: HolidayCalendarDao

  public actual abstract val holidayDao: HolidayDao

  public actual abstract val schoolDao: SchoolDao

  public actual abstract val xLangMapEntryDao: XLangMapEntryDao

  public actual abstract val locallyAvailableContainerDao: LocallyAvailableContainerDao

  public actual abstract val schoolMemberDao: SchoolMemberDao

  public actual abstract val clazzAssignmentDao: ClazzAssignmentDao

  public actual abstract val courseAssignmentSubmissionDao: CourseAssignmentSubmissionDao

  public actual abstract val courseAssignmentSubmissionFileDao: CourseAssignmentSubmissionFileDao

  public actual abstract val courseAssignmentMarkDao: CourseAssignmentMarkDao

  public actual abstract val commentsDao: CommentsDao

  public actual abstract val syncNodeDao: SyncNodeDao

  public actual abstract val siteDao: SiteDao

  public actual abstract val siteTermsDao: SiteTermsDao

  public actual abstract val personParentJoinDao: PersonParentJoinDao

  public actual abstract val scopedGrantDao: ScopedGrantDao

  public actual abstract val errorReportDao: ErrorReportDao

  public actual abstract val personAuth2Dao: PersonAuth2Dao

  public actual abstract val userSessionDao: UserSessionDao

  public actual abstract val contentEntryImportJobDao: ContentEntryImportJobDao

  public actual abstract val contentJobDao: ContentJobDao

  public actual abstract val coursePictureDao: CoursePictureDao

  public actual abstract val contentEntryPictureDao: ContentEntryPictureDao

  public actual abstract val chatDao: ChatDao

  public actual abstract val chatMemberDao: ChatMemberDao

  public actual abstract val messageDao: MessageDao

  public actual abstract val messageReadDao: MessageReadDao

  public actual abstract val peerReviewerAllocationDao: PeerReviewerAllocationDao

  public actual abstract val discussionPostDao: DiscussionPostDao

  public actual abstract val externalAppPermissionDao: ExternalAppPermissionDao

  public actual abstract val contentEntryVersionDao: ContentEntryVersionDao

  public actual abstract val outgoingReplicationDao: OutgoingReplicationDao

  public actual abstract val transferJobDao: TransferJobDao

  public actual abstract val transferJobItemDao: TransferJobItemDao

  public actual abstract val cacheLockJoinDao: CacheLockJoinDao

  public actual abstract val offlineItemDao: OfflineItemDao

  public actual abstract val deletedItemDao: DeletedItemDao

  public actual abstract val enrolmentRequestDao: EnrolmentRequestDao

  public actual abstract val coursePermissionDao: CoursePermissionDao

  public actual abstract val systemPermissionDao: SystemPermissionDao

  public actual abstract val courseBlockPictureDao: CourseBlockPictureDao

  public actual abstract val contentEntryPicture2Dao: ContentEntryPicture2Dao

  public actual abstract val transferJobErrorDao: TransferJobErrorDao
}
