package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContainerImportJob
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ContainerImportJobDao : BaseDao<ContainerImportJob> {
  public actual abstract fun findBySessionId(sessionId: String): ContainerImportJob?

  public actual abstract fun findByUid(uploadId: Long): ContainerImportJob?

  public actual abstract fun findJobs(): Flow<List<ContainerImportJob>>

  public actual abstract suspend fun setStatusToQueueAsync(uploadJobId: Long)

  public actual abstract fun updateProgress(
    progress: Long,
    contentLength: Long,
    uploadJobId: Long,
  )

  public actual abstract fun updateStatus(status: Int, uploadJobId: Long)

  public actual abstract fun updateImportComplete(
    importCompleted: Boolean,
    containerUid: Long,
    importJobUid: Long,
  )

  public actual abstract suspend fun getTitleOfEntry(importJobUid: Long): String?

  public actual abstract fun getImportJobLiveData(importJobUid: Long): Flow<ContainerImportJob?>

  public actual abstract suspend fun updateSessionId(importJobUid: Long, sessionId: String)
}
