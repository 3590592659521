package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.AgentEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class AgentDao_JdbcImpl(
  public val _db: RoomDatabase,
) : AgentDao() {
  public val _insertAdapterAgentEntity_: EntityInsertionAdapter<AgentEntity> = object :
      EntityInsertionAdapter<AgentEntity>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO AgentEntity (agentUid, agentMbox, agentMbox_sha1sum, agentOpenid, agentAccountName, agentHomePage, agentPersonUid, statementMasterChangeSeqNum, statementLocalChangeSeqNum, statementLastChangedBy, agentLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: AgentEntity) {
      if(entity.agentUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.agentUid)
      }
      stmt.setString(2, entity.agentMbox)
      stmt.setString(3, entity.agentMbox_sha1sum)
      stmt.setString(4, entity.agentOpenid)
      stmt.setString(5, entity.agentAccountName)
      stmt.setString(6, entity.agentHomePage)
      stmt.setLong(7, entity.agentPersonUid)
      stmt.setLong(8, entity.statementMasterChangeSeqNum)
      stmt.setLong(9, entity.statementLocalChangeSeqNum)
      stmt.setInt(10, entity.statementLastChangedBy)
      stmt.setLong(11, entity.agentLct)
    }
  }

  public override fun insert(entity: AgentEntity): Long {
    val _retVal = _insertAdapterAgentEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: AgentEntity): Long {
    val _retVal = _insertAdapterAgentEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<AgentEntity>) {
    _insertAdapterAgentEntity_.insertList(entityList)
  }

  public override fun update(entity: AgentEntity) {
    val _sql =
        "UPDATE AgentEntity SET agentMbox = ?, agentMbox_sha1sum = ?, agentOpenid = ?, agentAccountName = ?, agentHomePage = ?, agentPersonUid = ?, statementMasterChangeSeqNum = ?, statementLocalChangeSeqNum = ?, statementLastChangedBy = ?, agentLct = ? WHERE agentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.agentMbox)
      _stmt.setString(2, entity.agentMbox_sha1sum)
      _stmt.setString(3, entity.agentOpenid)
      _stmt.setString(4, entity.agentAccountName)
      _stmt.setString(5, entity.agentHomePage)
      _stmt.setLong(6, entity.agentPersonUid)
      _stmt.setLong(7, entity.statementMasterChangeSeqNum)
      _stmt.setLong(8, entity.statementLocalChangeSeqNum)
      _stmt.setInt(9, entity.statementLastChangedBy)
      _stmt.setLong(10, entity.agentLct)
      _stmt.setLong(11, entity.agentUid)
      _stmt.executeUpdate()
    }
  }

  override fun getAgentByAnyId(
    openId: String?,
    mbox: String?,
    account: String?,
    homepage: String?,
    sha1: String?,
  ): AgentEntity? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM AgentEntity WHERE agentOpenId = ? OR agentMbox = ? OR agentMbox_sha1sum = ? OR (agentAccountName = ? AND agentHomePage = ?)",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,openId)
    _stmt.setString(2,mbox)
    _stmt.setString(3,sha1)
    _stmt.setString(4,account)
    _stmt.setString(5,homepage)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_agentMbox = _result.getString("agentMbox")
        val _tmp_agentMbox_sha1sum = _result.getString("agentMbox_sha1sum")
        val _tmp_agentOpenid = _result.getString("agentOpenid")
        val _tmp_agentAccountName = _result.getString("agentAccountName")
        val _tmp_agentHomePage = _result.getString("agentHomePage")
        val _tmp_agentPersonUid = _result.getLong("agentPersonUid")
        val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
        val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
        val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
        val _tmp_agentLct = _result.getLong("agentLct")
        AgentEntity().apply {
          this.agentUid = _tmp_agentUid
          this.agentMbox = _tmp_agentMbox
          this.agentMbox_sha1sum = _tmp_agentMbox_sha1sum
          this.agentOpenid = _tmp_agentOpenid
          this.agentAccountName = _tmp_agentAccountName
          this.agentHomePage = _tmp_agentHomePage
          this.agentPersonUid = _tmp_agentPersonUid
          this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
          this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
          this.statementLastChangedBy = _tmp_statementLastChangedBy
          this.agentLct = _tmp_agentLct
        }
      }
    }
  }

  override suspend fun getAgentFromPersonUsername(endpoint: String, username: String): AgentEntity?
      = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT *
    |          FROM AgentEntity
    |         WHERE agentAccountName = ? 
    |           AND agentHomePage = ?
    |    
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,username)
    _stmt.setString(2,endpoint)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_agentMbox = _result.getString("agentMbox")
        val _tmp_agentMbox_sha1sum = _result.getString("agentMbox_sha1sum")
        val _tmp_agentOpenid = _result.getString("agentOpenid")
        val _tmp_agentAccountName = _result.getString("agentAccountName")
        val _tmp_agentHomePage = _result.getString("agentHomePage")
        val _tmp_agentPersonUid = _result.getLong("agentPersonUid")
        val _tmp_statementMasterChangeSeqNum = _result.getLong("statementMasterChangeSeqNum")
        val _tmp_statementLocalChangeSeqNum = _result.getLong("statementLocalChangeSeqNum")
        val _tmp_statementLastChangedBy = _result.getInt("statementLastChangedBy")
        val _tmp_agentLct = _result.getLong("agentLct")
        AgentEntity().apply {
          this.agentUid = _tmp_agentUid
          this.agentMbox = _tmp_agentMbox
          this.agentMbox_sha1sum = _tmp_agentMbox_sha1sum
          this.agentOpenid = _tmp_agentOpenid
          this.agentAccountName = _tmp_agentAccountName
          this.agentHomePage = _tmp_agentHomePage
          this.agentPersonUid = _tmp_agentPersonUid
          this.statementMasterChangeSeqNum = _tmp_statementMasterChangeSeqNum
          this.statementLocalChangeSeqNum = _tmp_statementLocalChangeSeqNum
          this.statementLastChangedBy = _tmp_statementLastChangedBy
          this.agentLct = _tmp_agentLct
        }
      }
    }
  }
}
