package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.ClazzEnrolmentAndPerson
import com.ustadmobile.lib.db.composites.ClazzEnrolmentAndPersonDetailDetails
import com.ustadmobile.lib.db.composites.CourseNameAndPersonName
import com.ustadmobile.lib.db.composites.PersonAndClazzMemberListDetails
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzEnrolmentDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzEnrolmentDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzEnrolmentDao() {
  override fun insertListAsync(entityList: List<ClazzEnrolment>) {
    _repo.withRepoChangeMonitor("ClazzEnrolment") {
      _dao.insertListAsync(entityList)
    }
  }

  override fun findAllEnrolmentsByPersonAndClazzUid(personUid: Long, clazzUid: Long):
      Flow<List<ClazzEnrolmentWithLeavingReason>> {
    val _result = _dao.findAllEnrolmentsByPersonAndClazzUid(personUid, clazzUid)
    return _result
  }

  override suspend fun findEnrolmentWithLeavingReason(enrolmentUid: Long):
      ClazzEnrolmentWithLeavingReason? {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "ClazzEnrolmentDao/findEnrolmentWithLeavingReason") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzEnrolmentDao/findEnrolmentWithLeavingReason")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("enrolmentUid", _repo.config.json.encodeToString(Long.serializer(), enrolmentUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findEnrolmentWithLeavingReason(enrolmentUid)
    return _result
  }

  override suspend fun updateDateLeftByUid(
    clazzEnrolmentUid: Long,
    endDate: Long,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ClazzEnrolment") {
      _dao.updateDateLeftByUid(clazzEnrolmentUid, endDate, updateTime)
    }
  }

  override suspend fun updateAsync(entity: ClazzEnrolment): Int {
    val _result = _repo.withRepoChangeMonitorAsync("ClazzEnrolment") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  override fun findAllClazzesByPersonWithClazz(accountPersonUid: Long, otherPersonUid: Long):
      Flow<List<ClazzEnrolmentAndPersonDetailDetails>> {
    val _result = _dao.findAllClazzesByPersonWithClazz(accountPersonUid, otherPersonUid)
    return _result
  }

  override fun findAllByPersonUid(personUid: Long): Flow<List<ClazzEnrolment>> {
    val _result = _dao.findAllByPersonUid(personUid)
    return _result
  }

  override suspend fun findAllClazzesByPersonWithClazzAsListAsync(personUid: Long):
      List<ClazzEnrolmentWithClazz> {
    val _result = _dao.findAllClazzesByPersonWithClazzAsListAsync(personUid)
    return _result
  }

  override suspend fun getAllClazzEnrolledAtTimeAsync(
    clazzUid: Long,
    date: Long,
    roleFilter: Int,
    personUidFilter: Long,
  ): List<ClazzEnrolmentWithPerson> {
    val _result = _dao.getAllClazzEnrolledAtTimeAsync(clazzUid, date, roleFilter, personUidFilter)
    return _result
  }

  override suspend fun getAllEnrolmentsAtTimeByClazzAndPerson(
    clazzUid: Long,
    accountPersonUid: Long,
    time: Long,
  ): List<ClazzEnrolment> {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "ClazzEnrolmentDao/getAllEnrolmentsAtTimeByClazzAndPerson") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzEnrolmentDao/getAllEnrolmentsAtTimeByClazzAndPerson")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
        parameter("time", _repo.config.json.encodeToString(Long.serializer(), time))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getAllEnrolmentsAtTimeByClazzAndPerson(clazzUid, accountPersonUid, time)
    return _result
  }

  override suspend fun findByUid(uid: Long): ClazzEnrolment? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  override fun findByUidLive(uid: Long): Flow<ClazzEnrolment?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  override fun findByClazzUidAndRole(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long,
    permission: Long,
  ): PagingSource<Int, PersonAndClazzMemberListDetails> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "ClazzEnrolmentDao/findByClazzUidAndRole",
    dbPagingSource = _dao.findByClazzUidAndRole(clazzUid, roleId, sortOrder, searchText, filter,
        accountPersonUid, currentTime, permission),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath = "ClazzEnrolmentDao/findByClazzUidAndRole") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "ClazzEnrolmentDao/findByClazzUidAndRole")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("roleId", _repo.config.json.encodeToString(Int.serializer(), roleId))
          parameter("sortOrder", _repo.config.json.encodeToString(Int.serializer(), sortOrder))
          parameter("searchText", _repo.config.json.encodeToString(String.serializer().nullable,
              searchText))
          parameter("filter", _repo.config.json.encodeToString(Int.serializer(), filter))
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("currentTime", _repo.config.json.encodeToString(Long.serializer(), currentTime))
          parameter("permission", _repo.config.json.encodeToString(Long.serializer(), permission))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun findEnrolmentsAndPersonByClazzUidWithPermissionCheck(clazzUid: Long,
      accountPersonUid: Long): List<ClazzEnrolmentAndPerson> {
    val _result = _dao.findEnrolmentsAndPersonByClazzUidWithPermissionCheck(clazzUid,
        accountPersonUid)
    return _result
  }

  override suspend fun findByAccountPersonUidAndClazzUid(accountPersonUid: Long, clazzUid: Long):
      List<ClazzEnrolment> {
    val _result = _dao.findByAccountPersonUidAndClazzUid(accountPersonUid, clazzUid)
    return _result
  }

  override suspend fun findEnrolmentsByClazzUidAndRole(
    clazzUid: Long,
    accountPersonUid: Long,
    roleId: Int,
    permission: Long,
  ): List<ClazzEnrolment> {
    val _result = _dao.findEnrolmentsByClazzUidAndRole(clazzUid, accountPersonUid, roleId,
        permission)
    return _result
  }

  override suspend fun findAllEnrolmentsByClazzUidAndRole(clazzUid: Long, roleId: Int):
      List<ClazzEnrolment> {
    val _result = _dao.findAllEnrolmentsByClazzUidAndRole(clazzUid, roleId)
    return _result
  }

  override fun updateClazzEnrolmentActiveForClazzEnrolment(
    clazzEnrolmentUid: Long,
    enrolled: Boolean,
    timeChanged: Long,
  ): Int {
    val _result = _repo.withRepoChangeMonitor("ClazzEnrolment") {
      _dao.updateClazzEnrolmentActiveForClazzEnrolment(clazzEnrolmentUid, enrolled, timeChanged)
    }
    return _result
  }

  override suspend fun updateClazzEnrolmentRole(
    personUid: Long,
    clazzUid: Long,
    newRole: Int,
    oldRole: Int,
    updateTime: Long,
  ): Int {
    val _result = _repo.withRepoChangeMonitorAsync("ClazzEnrolment") {
      _dao.updateClazzEnrolmentRole(personUid, clazzUid, newRole, oldRole, updateTime)
    }
    return _result
  }

  override suspend fun getClazzNameAndPersonName(personUid: Long, clazzUid: Long):
      CourseNameAndPersonName? {
    val _result = _dao.getClazzNameAndPersonName(personUid, clazzUid)
    return _result
  }

  override suspend
      fun findClazzEnrolmentEntitiesForPersonViewPermissionCheck(accountPersonUid: Long,
      otherPersonUid: Long): List<ClazzEnrolment> {
    val _result = _dao.findClazzEnrolmentEntitiesForPersonViewPermissionCheck(accountPersonUid,
        otherPersonUid)
    return _result
  }

  public override fun insert(entity: ClazzEnrolment): Long {
    val _result = _repo.withRepoChangeMonitor("ClazzEnrolment") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzEnrolment): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ClazzEnrolment") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ClazzEnrolment>) {
    _repo.withRepoChangeMonitor("ClazzEnrolment") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: ClazzEnrolment) {
    _repo.withRepoChangeMonitor("ClazzEnrolment") {
      _dao.update(entity)
    }
  }
}
