package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.paging.DoorLimitOffsetPagingSource
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ScheduleDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ScheduleDao() {
  public val _insertAdapterSchedule_: EntityInsertionAdapter<Schedule> = object :
      EntityInsertionAdapter<Schedule>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO Schedule (scheduleUid, sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Schedule) {
      if(entity.scheduleUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.scheduleUid)
      }
      stmt.setLong(2, entity.sceduleStartTime)
      stmt.setLong(3, entity.scheduleEndTime)
      stmt.setInt(4, entity.scheduleDay)
      stmt.setInt(5, entity.scheduleMonth)
      stmt.setInt(6, entity.scheduleFrequency)
      stmt.setLong(7, entity.umCalendarUid)
      stmt.setLong(8, entity.scheduleClazzUid)
      stmt.setLong(9, entity.scheduleMasterChangeSeqNum)
      stmt.setLong(10, entity.scheduleLocalChangeSeqNum)
      stmt.setInt(11, entity.scheduleLastChangedBy)
      stmt.setLong(12, entity.scheduleLastChangedTime)
      stmt.setBoolean(13, entity.scheduleActive)
    }
  }

  public val _insertAdapterSchedule_upsert: EntityInsertionAdapter<Schedule> = object :
      EntityInsertionAdapter<Schedule>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO Schedule (scheduleUid, sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Schedule) {
      if(entity.scheduleUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.scheduleUid)
      }
      stmt.setLong(2, entity.sceduleStartTime)
      stmt.setLong(3, entity.scheduleEndTime)
      stmt.setInt(4, entity.scheduleDay)
      stmt.setInt(5, entity.scheduleMonth)
      stmt.setInt(6, entity.scheduleFrequency)
      stmt.setLong(7, entity.umCalendarUid)
      stmt.setLong(8, entity.scheduleClazzUid)
      stmt.setLong(9, entity.scheduleMasterChangeSeqNum)
      stmt.setLong(10, entity.scheduleLocalChangeSeqNum)
      stmt.setInt(11, entity.scheduleLastChangedBy)
      stmt.setLong(12, entity.scheduleLastChangedTime)
      stmt.setBoolean(13, entity.scheduleActive)
    }
  }

  override fun insert(entity: Schedule): Long {
    val _retVal = _insertAdapterSchedule_.insertAndReturnId(entity)
    return _retVal
  }

  override suspend fun upsertListAsync(entities: List<Schedule>) {
    _insertAdapterSchedule_upsert.insertListAsync(entities)
  }

  public override suspend fun insertAsync(entity: Schedule): Long {
    val _retVal = _insertAdapterSchedule_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Schedule>) {
    _insertAdapterSchedule_.insertList(entityList)
  }

  override suspend fun updateAsync(entity: Schedule): Int {
    var _result = 0
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sceduleStartTime)
      _stmt.setLong(2, entity.scheduleEndTime)
      _stmt.setInt(3, entity.scheduleDay)
      _stmt.setInt(4, entity.scheduleMonth)
      _stmt.setInt(5, entity.scheduleFrequency)
      _stmt.setLong(6, entity.umCalendarUid)
      _stmt.setLong(7, entity.scheduleClazzUid)
      _stmt.setLong(8, entity.scheduleMasterChangeSeqNum)
      _stmt.setLong(9, entity.scheduleLocalChangeSeqNum)
      _stmt.setInt(10, entity.scheduleLastChangedBy)
      _stmt.setLong(11, entity.scheduleLastChangedTime)
      _stmt.setBoolean(12, entity.scheduleActive)
      _stmt.setLong(13, entity.scheduleUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun update(entity: Schedule) {
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sceduleStartTime)
      _stmt.setLong(2, entity.scheduleEndTime)
      _stmt.setInt(3, entity.scheduleDay)
      _stmt.setInt(4, entity.scheduleMonth)
      _stmt.setInt(5, entity.scheduleFrequency)
      _stmt.setLong(6, entity.umCalendarUid)
      _stmt.setLong(7, entity.scheduleClazzUid)
      _stmt.setLong(8, entity.scheduleMasterChangeSeqNum)
      _stmt.setLong(9, entity.scheduleLocalChangeSeqNum)
      _stmt.setInt(10, entity.scheduleLastChangedBy)
      _stmt.setLong(11, entity.scheduleLastChangedTime)
      _stmt.setBoolean(12, entity.scheduleActive)
      _stmt.setLong(13, entity.scheduleUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE Schedule 
      |           SET scheduleActive = ?,
      |               scheduleLastChangedTime = CAST(? AS BIGINT)
      |         WHERE scheduleUid = CAST(? AS BIGINT)
      |            
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE Schedule 
      |           SET scheduleActive = ?,
      |               scheduleLastChangedTime = ?
      |         WHERE scheduleUid = ?
      |            
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,active)
      _stmt.setLong(2,changeTime)
      _stmt.setLong(3,scheduleUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override fun findByUid(uid: Long): Schedule? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM Schedule WHERE scheduleUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }

  override suspend fun findByUidAsync(uid: Long): Schedule? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM Schedule WHERE scheduleUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }

  override fun findAllSchedulesByClazzUid(clazzUid: Long): PagingSource<Int, Schedule> = object :
      DoorLimitOffsetPagingSource<Schedule>(db = _db
  , tableNames = arrayOf("Schedule")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<Schedule> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "SELECT * FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = CAST(? AS BIGINT) AND CAST(scheduleActive AS INTEGER) = 1 ) AS _PagingData LIMIT ? OFFSET ?",
      postgreSql = """
      |SELECT * FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.setInt(2,_limit)
      _stmt.setInt(3,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_scheduleUid = _result.getLong("scheduleUid")
          val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
          val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
          val _tmp_scheduleDay = _result.getInt("scheduleDay")
          val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
          val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
          val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
          val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
          val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
          val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
          val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
          val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
          val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
          Schedule().apply {
            this.scheduleUid = _tmp_scheduleUid
            this.sceduleStartTime = _tmp_sceduleStartTime
            this.scheduleEndTime = _tmp_scheduleEndTime
            this.scheduleDay = _tmp_scheduleDay
            this.scheduleMonth = _tmp_scheduleMonth
            this.scheduleFrequency = _tmp_scheduleFrequency
            this.umCalendarUid = _tmp_umCalendarUid
            this.scheduleClazzUid = _tmp_scheduleClazzUid
            this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
            this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
            this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
            this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
            this.scheduleActive = _tmp_scheduleActive
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "SELECT COUNT(*) FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = CAST(? AS BIGINT) AND CAST(scheduleActive AS INTEGER) = 1 ) AS _PagingCount",
      postgreSql = """
      |SELECT COUNT(*) FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM Schedule WHERE scheduleClazzUid = CAST(? AS BIGINT) AND CAST(scheduleActive AS INTEGER) = 1",
    postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }

  override fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long): Flow<List<Schedule>> =
      _db.doorFlow(arrayOf("Schedule")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "SELECT * FROM Schedule WHERE scheduleClazzUid = CAST(? AS BIGINT) AND CAST(scheduleActive AS INTEGER) = 1",
      postgreSql = """
      |SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,clazzUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_scheduleUid = _result.getLong("scheduleUid")
          val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
          val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
          val _tmp_scheduleDay = _result.getInt("scheduleDay")
          val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
          val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
          val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
          val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
          val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
          val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
          val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
          val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
          val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
          Schedule().apply {
            this.scheduleUid = _tmp_scheduleUid
            this.sceduleStartTime = _tmp_sceduleStartTime
            this.scheduleEndTime = _tmp_scheduleEndTime
            this.scheduleDay = _tmp_scheduleDay
            this.scheduleMonth = _tmp_scheduleMonth
            this.scheduleFrequency = _tmp_scheduleFrequency
            this.umCalendarUid = _tmp_umCalendarUid
            this.scheduleClazzUid = _tmp_scheduleClazzUid
            this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
            this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
            this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
            this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
            this.scheduleActive = _tmp_scheduleActive
          }
        }
      }
    }
  }

  override suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT * FROM Schedule WHERE scheduleClazzUid = CAST(? AS BIGINT) AND CAST(scheduleActive AS INTEGER) = 1 ",
    postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_scheduleUid = _result.getLong("scheduleUid")
        val _tmp_sceduleStartTime = _result.getLong("sceduleStartTime")
        val _tmp_scheduleEndTime = _result.getLong("scheduleEndTime")
        val _tmp_scheduleDay = _result.getInt("scheduleDay")
        val _tmp_scheduleMonth = _result.getInt("scheduleMonth")
        val _tmp_scheduleFrequency = _result.getInt("scheduleFrequency")
        val _tmp_umCalendarUid = _result.getLong("umCalendarUid")
        val _tmp_scheduleClazzUid = _result.getLong("scheduleClazzUid")
        val _tmp_scheduleMasterChangeSeqNum = _result.getLong("scheduleMasterChangeSeqNum")
        val _tmp_scheduleLocalChangeSeqNum = _result.getLong("scheduleLocalChangeSeqNum")
        val _tmp_scheduleLastChangedBy = _result.getInt("scheduleLastChangedBy")
        val _tmp_scheduleLastChangedTime = _result.getLong("scheduleLastChangedTime")
        val _tmp_scheduleActive = _result.getBoolean("scheduleActive")
        Schedule().apply {
          this.scheduleUid = _tmp_scheduleUid
          this.sceduleStartTime = _tmp_sceduleStartTime
          this.scheduleEndTime = _tmp_scheduleEndTime
          this.scheduleDay = _tmp_scheduleDay
          this.scheduleMonth = _tmp_scheduleMonth
          this.scheduleFrequency = _tmp_scheduleFrequency
          this.umCalendarUid = _tmp_umCalendarUid
          this.scheduleClazzUid = _tmp_scheduleClazzUid
          this.scheduleMasterChangeSeqNum = _tmp_scheduleMasterChangeSeqNum
          this.scheduleLocalChangeSeqNum = _tmp_scheduleLocalChangeSeqNum
          this.scheduleLastChangedBy = _tmp_scheduleLastChangedBy
          this.scheduleLastChangedTime = _tmp_scheduleLastChangedTime
          this.scheduleActive = _tmp_scheduleActive
        }
      }
    }
  }
}
