package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentJob
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public class ContentJobDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContentJobDao,
) : ContentJobDao() {
  override suspend fun insertAsync(contentJob: ContentJob): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(contentJob.cjUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(702)
      contentJob.cjUid = _newPk
    }
    contentJob.cjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(contentJob)
    return contentJob.cjUid
  }

  override fun findByUid(cjUid: Long): ContentJob? = throw
      IllegalStateException("findByUid: synchronous db access is NOT possible on Javascript!")

  override suspend fun findByUidAsync(cjUid: Long): ContentJob? = _dao.findByUidAsync(cjUid)

  override fun findLiveDataByUid(cjUid: Long): Flow<ContentJob?> = _dao.findLiveDataByUid(cjUid)

  override suspend fun updateDestinationDir(cjUid: Long, toUri: String) {
    _dao.updateDestinationDir(cjUid, toUri)
  }

  override fun findMeteredAllowedLiveData(contentJobId: Long): Flow<Boolean> =
      _dao.findMeteredAllowedLiveData(contentJobId)

  override suspend fun isConnectivityAcceptableForJob(jobId: Long): Boolean =
      _dao.isConnectivityAcceptableForJob(jobId)
}
