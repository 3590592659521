package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ContainerEntry
import com.ustadmobile.lib.db.entities.ContainerEntryWithContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryWithMd5
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class ContainerEntryDao : BaseDao<ContainerEntry> {
  public actual abstract suspend fun insertListAsync(containerEntryList: List<ContainerEntry>)

  public actual abstract fun findByContainer(containerUid: Long):
      List<ContainerEntryWithContainerEntryFile>

  public actual abstract fun findByPathInContainer(containerUid: Long, pathInContainer: String):
      ContainerEntryWithContainerEntryFile?

  public actual abstract fun findByContainerWithMd5(containerUid: Long): List<ContainerEntryWithMd5>

  public actual abstract suspend fun findByContainerAsync(containerUid: Long):
      List<ContainerEntryWithContainerEntryFile>

  public actual abstract fun deleteByContainerUid(containerUid: Long)

  public actual abstract fun deleteList(entries: List<ContainerEntry>)

  public actual abstract fun deleteByContentEntryUid(contentEntryUid: Long)

  public actual abstract suspend fun insertWithMd5SumsAsync(
    containerUid: Long,
    path: String,
    md5: String,
  )
}
