package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.CourseGroupMemberAndName
import com.ustadmobile.lib.db.entities.CourseGroupMemberPerson
import kotlin.Int
import kotlin.Long
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class CourseGroupMemberDao : BaseDao<CourseGroupMember> {
  public actual abstract suspend fun findByGroupSetAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson>

  public actual abstract suspend fun findByGroupSetUidAsync(groupSetUid: Long):
      List<CourseGroupMember>

  public actual abstract suspend fun findByGroupSetOrderedAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson>

  public actual abstract suspend fun findByPersonUid(groupSetUid: Long, studentUid: Long):
      CourseGroupMember?

  public actual abstract suspend fun insertListAsync(entityList: List<CourseGroupMember>)

  public actual abstract suspend fun updateListAsync(entityList: List<CourseGroupMember>)

  public actual abstract suspend fun findByCourseGroupSetAndClazz(
    cgsUid: Long,
    clazzUid: Long,
    time: Long,
    activeFilter: Int,
    accountPersonUid: Long,
  ): List<CourseGroupMemberAndName>

  public actual abstract fun findByCourseGroupSetAndClazzAsFlow(
    cgsUid: Long,
    clazzUid: Long,
    time: Long,
    activeFilter: Int,
    accountPersonUid: Long,
  ): Flow<List<CourseGroupMemberAndName>>

  public actual abstract suspend fun findByCourseGroupSetAndClazzAsFlowPersons(clazzUid: Long,
      cgsUid: Long): List<PersonAndPicture>

  public actual abstract suspend fun findByCourseGroupSetAndClazzAsFlowEnrolments(clazzUid: Long,
      cgsUid: Long): List<ClazzEnrolment>

  public actual abstract suspend fun upsertListAsync(list: List<CourseGroupMember>)
}
