package com.ustadmobile.door.paging

import app.cash.paging.PagingSource
import app.cash.paging.PagingSourceLoadParams
import app.cash.paging.PagingSourceLoadResult
import app.cash.paging.PagingState
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.log.DoorLogLevel
import io.github.aakira.napier.Napier
import kotlinx.atomicfu.atomic

/**
 * This is the primary offline-first PagingSource implementation - it is used for DAO functions that are annotated as
 * HttpAccessible that use the ClientStrategy PULL_REPLICATE_ENTITIES (which is used by default if the Strategy is set
 * to AUTO and the return type includes ReplicateEntity(s) via Embedded properties or inheritance).
 *
 * TODO: Update these docs
 *
 * When a page is loaded a background http request will be made (including the PagingLoadParams) to the http endpoint.
 * Any ReplicateEntities that are received will be inserted/handled the normal way. This will trigger the underlying
 * dbPagingSource to invalidate if new entities are inserted.
 *
 * The implementation delegates to the dbPagingSource which is called immediately. This ensures that any entities in the
 * local database can be displayed immediately.
 *
 * @param repo The DoorDatabaseRepository beign used
 * @param repoPath the endpoint path (used for logging)
 * @param dbPagingSource the PagingSource from the underlying database
 * @param onLoadHttp a function (generated by the DoorRepositoryProcessor) that runs the http request and inserts any
 *        new replicate entities into the database. It should return a Boolean indicating if the end of pagination
 *        has been reached.
 */
class DoorRepositoryReplicatePullPagingSource<Value: Any>(
    internal val repo: DoorDatabaseRepository,
    private val repoPath: String,
    private val dbPagingSource: PagingSource<Int, Value>,
    private val onLoadHttp: suspend (params: PagingSourceLoadParams<Int>) -> Boolean,
) : DoorRepositoryPagingSource<Int, Value>(){

    private val dbInvalidateCallbackRegistered = atomic(false)

    private val invalidated = atomic(false)

    private var lastLoadParams: PagingSourceLoadParams<Int>? = null

    private val onDbInvalidatedCallback: () -> Unit = {
        onDbInvalidated()
    }

    private fun onDbInvalidated() {
        Napier.v("DoorRepositoryReplicatePullPagingSource: onDbInvalidated")
        dbPagingSource.unregisterInvalidatedCallback(onDbInvalidatedCallback)
        if(!invalidated.getAndSet(true)) {
            Napier.v("DoorRepositoryReplicatePullPagingSource: call invalidate")
            invalidate()
        }

    }

    override fun getRefreshKey(state: PagingState<Int, Value>): Int? {
        return dbPagingSource.getRefreshKey(state).also {
            repo.config.logger.log(DoorLogLevel.VERBOSE, "DoorRepositoryReplicatePullPagingSource: getRefreshKey: $it")
        }
    }

    override suspend fun load(
        params: PagingSourceLoadParams<Int>
    ): PagingSourceLoadResult<Int, Value> {
        Napier.v("DoorRepositoryReplicatePullPagingSource: load key=${params.key}")
        lastLoadParams = params
        if(!dbInvalidateCallbackRegistered.getAndSet(true)) {
            Napier.v("DoorRepositoryReplicatePullPagingSource: register db invalidate callback")
            dbPagingSource.registerInvalidatedCallback(onDbInvalidatedCallback)
        }

        return dbPagingSource.load(params)
    }

    suspend fun loadHttp(
        params: PagingSourceLoadParams<Int>
    ): Boolean {
        return onLoadHttp(params)
    }

    companion object {

        const val PARAM_BATCHSIZE = "pagingBatchSize"

        const val PARAM_KEY = "pagingKey"

        const val PARAM_LOAD_PARAM_TYPE = "pagingLoadParamType"

    }
}