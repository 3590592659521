package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryFileUidAndPath
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ContainerEntryFileDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContainerEntryFileDao() {
  public val _insertAdapterContainerEntryFile_: EntityInsertionAdapter<ContainerEntryFile> = object
      : EntityInsertionAdapter<ContainerEntryFile>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ContainerEntryFile (cefUid, cefMd5, cefPath, ceTotalSize, ceCompressedSize, compression, lastModified) VALUES(?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContainerEntryFile) {
      if(entity.cefUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cefUid)
      }
      stmt.setString(2, entity.cefMd5)
      stmt.setString(3, entity.cefPath)
      stmt.setLong(4, entity.ceTotalSize)
      stmt.setLong(5, entity.ceCompressedSize)
      stmt.setInt(6, entity.compression)
      stmt.setLong(7, entity.lastModified)
    }
  }

  override suspend fun insertListAsync(list: List<ContainerEntryFile>) {
    _insertAdapterContainerEntryFile_.insertListAsync(list)
  }

  public override fun insert(entity: ContainerEntryFile): Long {
    val _retVal = _insertAdapterContainerEntryFile_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerEntryFile): Long {
    val _retVal = _insertAdapterContainerEntryFile_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContainerEntryFile>) {
    _insertAdapterContainerEntryFile_.insertList(entityList)
  }

  public override fun update(entity: ContainerEntryFile) {
    val _sql =
        "UPDATE ContainerEntryFile SET cefMd5 = ?, cefPath = ?, ceTotalSize = ?, ceCompressedSize = ?, compression = ?, lastModified = ? WHERE cefUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.cefMd5)
      _stmt.setString(2, entity.cefPath)
      _stmt.setLong(3, entity.ceTotalSize)
      _stmt.setLong(4, entity.ceCompressedSize)
      _stmt.setInt(5, entity.compression)
      _stmt.setLong(6, entity.lastModified)
      _stmt.setLong(7, entity.cefUid)
      _stmt.executeUpdate()
    }
  }

  override fun deleteListOfEntryFiles(entriesToDelete: List<ContainerEntryFile>) {
    var _numChanges = 0
    _db.prepareAndUseStatement("DELETE FROM ContainerEntryFile WHERE cefUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entriesToDelete) {
        _stmt.setLong(1, _entity.cefUid)
        _numChanges += _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  override fun findEntriesByMd5Sums(md5Sums: List<String>): List<ContainerEntryFile> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("TEXT",
        md5Sums.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_cefPath = _result.getString("cefPath")
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        val _tmp_compression = _result.getInt("compression")
        val _tmp_lastModified = _result.getLong("lastModified")
        ContainerEntryFile().apply {
          this.cefUid = _tmp_cefUid
          this.cefMd5 = _tmp_cefMd5
          this.cefPath = _tmp_cefPath
          this.ceTotalSize = _tmp_ceTotalSize
          this.ceCompressedSize = _tmp_ceCompressedSize
          this.compression = _tmp_compression
          this.lastModified = _tmp_lastModified
        }
      }
    }
  }

  override suspend fun findEntriesByMd5SumsAsync(md5Sums: List<String>): List<ContainerEntryFile> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("TEXT",
        md5Sums.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_cefPath = _result.getString("cefPath")
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        val _tmp_compression = _result.getInt("compression")
        val _tmp_lastModified = _result.getLong("lastModified")
        ContainerEntryFile().apply {
          this.cefUid = _tmp_cefUid
          this.cefMd5 = _tmp_cefMd5
          this.cefPath = _tmp_cefPath
          this.ceTotalSize = _tmp_ceTotalSize
          this.ceCompressedSize = _tmp_ceCompressedSize
          this.compression = _tmp_compression
          this.lastModified = _tmp_lastModified
        }
      }
    }
  }

  override suspend fun findExistingMd5SumsByMd5SumsAsync(md5Sums: List<String>): List<String?> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT ContainerEntryFile.cefMd5 FROM ContainerEntryFile WHERE cefMd5 IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("TEXT",
        md5Sums.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getString(1)
      }
    }
  }

  override fun findEntriesByUids(uidList: List<Long>): List<ContainerEntryFile> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefUid IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_cefPath = _result.getString("cefPath")
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        val _tmp_compression = _result.getInt("compression")
        val _tmp_lastModified = _result.getLong("lastModified")
        ContainerEntryFile().apply {
          this.cefUid = _tmp_cefUid
          this.cefMd5 = _tmp_cefMd5
          this.cefPath = _tmp_cefPath
          this.ceTotalSize = _tmp_ceTotalSize
          this.ceCompressedSize = _tmp_ceCompressedSize
          this.compression = _tmp_compression
          this.lastModified = _tmp_lastModified
        }
      }
    }
  }

  override fun updateFilePath(cefUid: Long, path: String) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "UPDATE ContainerEntryFile SET cefPath = ? WHERE cefUid = CAST(? AS BIGINT)",
      postgreSql = """
      |UPDATE ContainerEntryFile SET cefPath = ? WHERE cefUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setString(1,path)
      _stmt.setLong(2,cefUid)
      _stmt.executeUpdate()
    }
  }

  override fun findByUid(uid: Long): ContainerEntryFile? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM ContainerEntryFile WHERE cefUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM ContainerEntryFile WHERE cefUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_cefPath = _result.getString("cefPath")
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        val _tmp_compression = _result.getInt("compression")
        val _tmp_lastModified = _result.getLong("lastModified")
        ContainerEntryFile().apply {
          this.cefUid = _tmp_cefUid
          this.cefMd5 = _tmp_cefMd5
          this.cefPath = _tmp_cefPath
          this.ceTotalSize = _tmp_ceTotalSize
          this.ceCompressedSize = _tmp_ceCompressedSize
          this.compression = _tmp_compression
          this.lastModified = _tmp_lastModified
        }
      }
    }
  }

  override fun sumContainerFileEntrySizes(containerUid: Long): Long =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT SUM(ContainerEntryFile.ceCompressedSize) FROM ContainerEntry JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT SUM(ContainerEntryFile.ceCompressedSize) FROM ContainerEntry JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  override suspend fun getAllFilesForCompression(): List<ContainerEntryFile> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT * FROM ContainerEntryFile WHERE compression = 0 AND NOT EXISTS(SELECT * FROM ContainerEntry WHERE ceCefUid = ContainerEntryFile.cefUid AND (ContainerEntry.cePath LIKE '%.webm' OR ContainerEntry.cePath LIKE '%.mp4')) LIMIT 100",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_cefPath = _result.getString("cefPath")
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        val _tmp_compression = _result.getInt("compression")
        val _tmp_lastModified = _result.getLong("lastModified")
        ContainerEntryFile().apply {
          this.cefUid = _tmp_cefUid
          this.cefMd5 = _tmp_cefMd5
          this.cefPath = _tmp_cefPath
          this.ceTotalSize = _tmp_ceTotalSize
          this.ceCompressedSize = _tmp_ceCompressedSize
          this.compression = _tmp_compression
          this.lastModified = _tmp_lastModified
        }
      }
    }
  }

  override fun updateCompressedFile(
    compression: Int,
    ceCompressedSize: Long,
    cefUid: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql =
          "UPDATE ContainerEntryFile SET compression = ?, ceCompressedSize = CAST(? AS BIGINT) WHERE cefUid = CAST(? AS BIGINT)",
      postgreSql = """
      |UPDATE ContainerEntryFile SET compression = ?, ceCompressedSize = ? WHERE cefUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setInt(1,compression)
      _stmt.setLong(2,ceCompressedSize)
      _stmt.setLong(3,cefUid)
      _stmt.executeUpdate()
    }
  }

  override fun findZombieEntries(): List<ContainerEntryFile> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |SELECT ContainerEntryFile.* 
    |                      FROM ContainerEntryFile 
    |                     WHERE NOT EXISTS (SELECT ContainerEntry.ceCefUid 
    |                                     FROM ContainerEntry 
    |                                    WHERE ContainerEntryFile.cefUid = ContainerEntry.ceCefUid) 
    |                     LIMIT 100
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_cefPath = _result.getString("cefPath")
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        val _tmp_compression = _result.getInt("compression")
        val _tmp_lastModified = _result.getLong("lastModified")
        ContainerEntryFile().apply {
          this.cefUid = _tmp_cefUid
          this.cefMd5 = _tmp_cefMd5
          this.cefPath = _tmp_cefPath
          this.ceTotalSize = _tmp_ceTotalSize
          this.ceCompressedSize = _tmp_ceCompressedSize
          this.compression = _tmp_compression
          this.lastModified = _tmp_lastModified
        }
      }
    }
  }

  override suspend fun findZombieUidsAndPath(limit: Int): List<ContainerEntryFileUidAndPath> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT cefUid, cefPath
    |          FROM ContainerEntryFile
    |         WHERE NOT EXISTS 
    |               (SELECT ContainerEntry.ceCefUid 
    |                  FROM ContainerEntry 
    |                 WHERE ContainerEntry.ceCefUid = ContainerEntryFile.cefUid 
    |                 LIMIT 1)
    |         LIMIT ?     
    |    
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setInt(1,limit)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefPath = _result.getString("cefPath")
        ContainerEntryFileUidAndPath().apply {
          this.cefUid = _tmp_cefUid
          this.cefPath = _tmp_cefPath
        }
      }
    }
  }

  override suspend fun deleteByUidList(uidList: List<Long>) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        DELETE FROM ContainerEntryFile
      |              WHERE cefUid IN (?) 
      |    
      """.trimMargin(),
      hasListParams = true,
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          uidList.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override suspend fun findEntryByMd5Sum(md5Sum: String): ContainerEntryFile? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,md5Sum)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cefUid = _result.getLong("cefUid")
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_cefPath = _result.getString("cefPath")
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        val _tmp_compression = _result.getInt("compression")
        val _tmp_lastModified = _result.getLong("lastModified")
        ContainerEntryFile().apply {
          this.cefUid = _tmp_cefUid
          this.cefMd5 = _tmp_cefMd5
          this.cefPath = _tmp_cefPath
          this.ceTotalSize = _tmp_ceTotalSize
          this.ceCompressedSize = _tmp_ceCompressedSize
          this.compression = _tmp_compression
          this.lastModified = _tmp_lastModified
        }
      }
    }
  }
}
