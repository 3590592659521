package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.SchoolMember
import kotlin.Int
import kotlin.Long

public actual abstract class SchoolMemberDao : BaseDao<SchoolMember> {
  public actual abstract fun findByUidAsync(schoolMemberUid: Long): SchoolMember?

  public actual abstract suspend fun updateAsync(entity: SchoolMember): Int
}
