package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.Int
import kotlin.Long
import kotlin.collections.List

public actual abstract class XLangMapEntryDao : BaseDao<XLangMapEntry> {
  public actual abstract suspend fun getValuesWithListOfId(ids: List<Int>): List<XLangMapEntry>

  public actual abstract fun getXLangMapFromVerb(verbUid: Long, langMapUid: Long): XLangMapEntry?

  public actual abstract fun getXLangMapFromObject(objectUid: Long, langMapUid: Long):
      XLangMapEntry?
}
