package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.paging.DoorLimitOffsetPagingSource
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class VerbDao_JdbcImpl(
  public val _db: RoomDatabase,
) : VerbDao() {
  public val _insertAdapterVerbEntity_upsert: EntityInsertionAdapter<VerbEntity> = object :
      EntityInsertionAdapter<VerbEntity>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: VerbEntity) {
      if(entity.verbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.verbUid)
      }
      stmt.setString(2, entity.urlId)
      stmt.setBoolean(3, entity.verbInActive)
      stmt.setLong(4, entity.verbMasterChangeSeqNum)
      stmt.setLong(5, entity.verbLocalChangeSeqNum)
      stmt.setInt(6, entity.verbLastChangedBy)
      stmt.setLong(7, entity.verbLct)
    }
  }

  public val _insertAdapterVerbEntity_: EntityInsertionAdapter<VerbEntity> = object :
      EntityInsertionAdapter<VerbEntity>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: VerbEntity) {
      if(entity.verbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.verbUid)
      }
      stmt.setString(2, entity.urlId)
      stmt.setBoolean(3, entity.verbInActive)
      stmt.setLong(4, entity.verbMasterChangeSeqNum)
      stmt.setLong(5, entity.verbLocalChangeSeqNum)
      stmt.setInt(6, entity.verbLastChangedBy)
      stmt.setLong(7, entity.verbLct)
    }
  }

  override suspend fun replaceList(entityList: List<VerbEntity>) {
    _insertAdapterVerbEntity_upsert.insertListAsync(entityList)
  }

  public override fun insert(entity: VerbEntity): Long {
    val _retVal = _insertAdapterVerbEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: VerbEntity): Long {
    val _retVal = _insertAdapterVerbEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<VerbEntity>) {
    _insertAdapterVerbEntity_.insertList(entityList)
  }

  public override fun update(entity: VerbEntity) {
    val _sql =
        "UPDATE VerbEntity SET urlId = ?, verbInActive = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, verbLct = ? WHERE verbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.urlId)
      _stmt.setBoolean(2, entity.verbInActive)
      _stmt.setLong(3, entity.verbMasterChangeSeqNum)
      _stmt.setLong(4, entity.verbLocalChangeSeqNum)
      _stmt.setInt(5, entity.verbLastChangedBy)
      _stmt.setLong(6, entity.verbLct)
      _stmt.setLong(7, entity.verbUid)
      _stmt.executeUpdate()
    }
  }

  override fun findByUrl(urlId: String?): VerbEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM VerbEntity WHERE urlId = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,urlId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_verbUid = _result.getLong("verbUid")
        val _tmp_urlId = _result.getString("urlId")
        val _tmp_verbInActive = _result.getBoolean("verbInActive")
        val _tmp_verbMasterChangeSeqNum = _result.getLong("verbMasterChangeSeqNum")
        val _tmp_verbLocalChangeSeqNum = _result.getLong("verbLocalChangeSeqNum")
        val _tmp_verbLastChangedBy = _result.getInt("verbLastChangedBy")
        val _tmp_verbLct = _result.getLong("verbLct")
        VerbEntity().apply {
          this.verbUid = _tmp_verbUid
          this.urlId = _tmp_urlId
          this.verbInActive = _tmp_verbInActive
          this.verbMasterChangeSeqNum = _tmp_verbMasterChangeSeqNum
          this.verbLocalChangeSeqNum = _tmp_verbLocalChangeSeqNum
          this.verbLastChangedBy = _tmp_verbLastChangedBy
          this.verbLct = _tmp_verbLct
        }
      }
    }
  }

  override suspend fun findByUidList(uidList: List<Long>): List<Long> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT verbUid FROM VerbEntity WHERE verbUid IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  override fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display
    |        FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
    |         XLangMapEntry.verbLangMapUid NOT IN (?)
    """.trimMargin(),
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_verbUid = _result.getLong("verbUid")
        val _tmp_urlId = _result.getString("urlId")
        val _tmp_display = _result.getString("display")
        VerbDisplay().apply {
          this.verbUid = _tmp_verbUid
          this.urlId = _tmp_urlId
          this.display = _tmp_display
        }
      }
    }
  }

  override fun findAllVerbsAsc(uidList: List<Long>): PagingSource<Int, VerbDisplay> = object :
      DoorLimitOffsetPagingSource<VerbDisplay>(db = _db
  , tableNames = arrayOf("XLangMapEntry", "VerbEntity")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<VerbDisplay> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      hasListParams = true,
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          uidList.toTypedArray()))
      _stmt.setInt(2,_limit)
      _stmt.setInt(3,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_verbUid = _result.getLong("verbUid")
          val _tmp_urlId = _result.getString("urlId")
          val _tmp_display = _result.getString("display")
          VerbDisplay().apply {
            this.verbUid = _tmp_verbUid
            this.urlId = _tmp_urlId
            this.display = _tmp_display
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) AS _PagingCount
      """.trimMargin(),
      hasListParams = true,
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          uidList.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override fun findAllVerbsDesc(uidList: List<Long>): PagingSource<Int, VerbDisplay> = object :
      DoorLimitOffsetPagingSource<VerbDisplay>(db = _db
  , tableNames = arrayOf("XLangMapEntry", "VerbEntity")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<VerbDisplay> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      hasListParams = true,
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          uidList.toTypedArray()))
      _stmt.setInt(2,_limit)
      _stmt.setInt(3,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_verbUid = _result.getLong("verbUid")
          val _tmp_urlId = _result.getString("urlId")
          val _tmp_display = _result.getString("display")
          VerbDisplay().apply {
            this.verbUid = _tmp_verbUid
            this.urlId = _tmp_urlId
            this.display = _tmp_display
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
      |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
      |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) AS _PagingCount
      """.trimMargin(),
      hasListParams = true,
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          uidList.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }
}
