package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.composites.ContentEntryAndDetail
import com.ustadmobile.lib.db.composites.ContentEntryAndLanguage
import com.ustadmobile.lib.db.composites.ContentEntryAndListDetail
import com.ustadmobile.lib.db.composites.ContentEntryAndPicture
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryWithLanguage
import com.ustadmobile.lib.db.entities.DownloadJobSizeInfo
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ContentEntryDao : BaseDao<ContentEntry> {
  public actual abstract suspend fun insertListAsync(entityList: List<ContentEntry>)

  public actual abstract suspend fun upsertAsync(entity: ContentEntry)

  public actual abstract suspend fun findByUidAsync(entryUid: Long): ContentEntry?

  public actual abstract suspend fun findEntryWithLanguageByEntryIdAsync(entryUuid: Long):
      ContentEntryAndLanguage?

  public actual abstract suspend fun findByUidWithEditDetails(uid: Long): ContentEntryAndPicture?

  public actual abstract fun findEntryWithContainerByEntryIdLive(entryUuid: Long):
      Flow<ContentEntryAndDetail?>

  public actual abstract fun findBySourceUrl(sourceUrl: String): ContentEntry?

  public actual abstract suspend fun findTitleByUidAsync(entryUid: Long): String?

  public actual abstract fun getChildrenByParentUid(parentUid: Long):
      PagingSource<Int, ContentEntry>

  public actual abstract suspend fun getChildrenByParentAsync(parentUid: Long): List<ContentEntry>

  public actual abstract suspend fun getCountNumberOfChildrenByParentUUidAsync(parentUid: Long): Int

  public actual abstract suspend fun getContentByUuidAsync(parentUid: Long): ContentEntry?

  public actual abstract suspend fun findAllLanguageRelatedEntriesAsync(entryUuid: Long):
      List<ContentEntry>

  actual abstract override fun update(entity: ContentEntry)

  public actual abstract suspend fun findByUidWithLanguageAsync(uid: Long):
      ContentEntryWithLanguage?

  public actual abstract fun findByUid(entryUid: Long): ContentEntry?

  public actual abstract fun findByTitle(title: String): Flow<ContentEntry?>

  public actual abstract suspend fun findBySourceUrlWithContentEntryStatusAsync(sourceUrl: String):
      ContentEntry?

  public actual abstract fun getChildrenByParentUidWithCategoryFilterOrderByName(
    parentUid: Long,
    langParam: Long,
    categoryParam0: Long,
    sortOrder: Int,
    includeDeleted: Boolean,
  ): PagingSource<Int, ContentEntryAndListDetail>

  public actual abstract fun getContentFromMyCourses(personUid: Long):
      PagingSource<Int, ContentEntryAndListDetail>

  public actual abstract fun getContentByOwner(personUid: Long):
      PagingSource<Int, ContentEntryAndListDetail>

  public actual abstract suspend fun updateAsync(entity: ContentEntry): Int

  public actual abstract fun getChildrenByAll(parentUid: Long): List<ContentEntry>

  public actual abstract fun findLiveContentEntry(parentUid: Long): Flow<ContentEntry?>

  public actual abstract fun getContentEntryUidFromXapiObjectId(objectId: String): Long

  public actual abstract fun findSimilarIdEntryForKhan(sourceUrl: String): List<ContentEntry>

  public actual abstract suspend fun getRecursiveDownloadTotals(contentEntryUid: Long):
      DownloadJobSizeInfo?

  public actual abstract fun updateContentEntryInActive(
    contentEntryUid: Long,
    ceInactive: Boolean,
    changedTime: Long,
  )

  public actual abstract fun updateContentEntryContentFlag(
    contentFlag: Int,
    contentEntryUid: Long,
    changedTime: Long,
  )

  public actual abstract fun replaceList(entries: List<ContentEntry>)

  public actual abstract suspend fun getContentEntryFromUids(contentEntryUids: List<Long>):
      List<UidAndLabel>

  public actual abstract fun insertWithReplace(entry: ContentEntry)

  public actual abstract fun findAllLive(): Flow<List<ContentEntryWithLanguage>>

  public actual abstract suspend fun toggleVisibilityContentEntryItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long,
  )

  public actual abstract suspend
      fun findContentEntriesWhereIsLeafAndLatestContainerHasNoEntriesOrHasZeroFileSize():
      List<ContentEntry>
}
