package com.ustadmobile.lib.db.entities.ext

import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import kotlin.Unit

public actual
    fun SchoolWithHolidayCalendar.shallowCopy(block: SchoolWithHolidayCalendar.() -> Unit):
    SchoolWithHolidayCalendar = SchoolWithHolidayCalendar().also {
  it.holidayCalendar = this.holidayCalendar
  it.schoolUid = this.schoolUid
  it.schoolName = this.schoolName
  it.schoolDesc = this.schoolDesc
  it.schoolAddress = this.schoolAddress
  it.schoolActive = this.schoolActive
  it.schoolPhoneNumber = this.schoolPhoneNumber
  it.schoolGender = this.schoolGender
  it.schoolHolidayCalendarUid = this.schoolHolidayCalendarUid
  it.schoolFeatures = this.schoolFeatures
  it.schoolLocationLong = this.schoolLocationLong
  it.schoolLocationLatt = this.schoolLocationLatt
  it.schoolEmailAddress = this.schoolEmailAddress
  it.schoolTeachersPersonGroupUid = this.schoolTeachersPersonGroupUid
  it.schoolStudentsPersonGroupUid = this.schoolStudentsPersonGroupUid
  it.schoolPendingStudentsPersonGroupUid = this.schoolPendingStudentsPersonGroupUid
  it.schoolCode = this.schoolCode
  it.schoolMasterChangeSeqNum = this.schoolMasterChangeSeqNum
  it.schoolLocalChangeSeqNum = this.schoolLocalChangeSeqNum
  it.schoolLastChangedBy = this.schoolLastChangedBy
  it.schoolLct = this.schoolLct
  it.schoolTimeZone = this.schoolTimeZone
  block(it)
}
