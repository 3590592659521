package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.Container
import kotlin.collections.List

public actual abstract class ContainerDao {
  public actual abstract suspend fun findAllBatch(): List<Container>

  public actual abstract suspend fun deleteListAsync(list: List<Container>)
}
