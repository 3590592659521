package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class XLangMapEntryDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: XLangMapEntryDao,
) : XLangMapEntryDao() {
  override suspend fun getValuesWithListOfId(ids: List<Int>): List<XLangMapEntry> =
      _dao.getValuesWithListOfId(ids)

  override fun getXLangMapFromVerb(verbUid: Long, langMapUid: Long): XLangMapEntry? = throw
      IllegalStateException("getXLangMapFromVerb: synchronous db access is NOT possible on Javascript!")

  override fun getXLangMapFromObject(objectUid: Long, langMapUid: Long): XLangMapEntry? = throw
      IllegalStateException("getXLangMapFromObject: synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: XLangMapEntry): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: XLangMapEntry): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.statementLangMapUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(74)
      entity.statementLangMapUid = _newPk
    }
    entity.statementLangMapLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.statementLangMapUid
  }

  public override fun insertList(entityList: List<XLangMapEntry>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: XLangMapEntry): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
