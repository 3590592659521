package com.ustadmobile.core

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.ColorResource
import dev.icerock.moko.resources.FileResource
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.PluralsResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocale
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "ad84844822fea682bc22a8607452a14a"

  public val stringsLoader: RemoteJsStringLoader = strings.stringsLoader + plurals.stringsLoader

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    private val stringsFallbackFileUrl: String =
        js("require(\"localization/comustadmobilecore_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        SupportedLocale("rw", js("require(\"localization/comustadmobilecore_stringsJson_rw.json\")")
            as String),
        SupportedLocale("bn", js("require(\"localization/comustadmobilecore_stringsJson_bn.json\")")
            as String),
        SupportedLocale("ps", js("require(\"localization/comustadmobilecore_stringsJson_ps.json\")")
            as String),
        SupportedLocale("tg", js("require(\"localization/comustadmobilecore_stringsJson_tg.json\")")
            as String),
        SupportedLocale("km", js("require(\"localization/comustadmobilecore_stringsJson_km.json\")")
            as String),
        SupportedLocale("ne", js("require(\"localization/comustadmobilecore_stringsJson_ne.json\")")
            as String),
        SupportedLocale("fr", js("require(\"localization/comustadmobilecore_stringsJson_fr.json\")")
            as String),
        SupportedLocale("fa", js("require(\"localization/comustadmobilecore_stringsJson_fa.json\")")
            as String),
        SupportedLocale("ru", js("require(\"localization/comustadmobilecore_stringsJson_ru.json\")")
            as String),
        SupportedLocale("ar", js("require(\"localization/comustadmobilecore_stringsJson_ar.json\")")
            as String),
        SupportedLocale("nb-NO",
            js("require(\"localization/comustadmobilecore_stringsJson_nb-NO.json\")") as String),
        SupportedLocale("my", js("require(\"localization/comustadmobilecore_stringsJson_my.json\")")
            as String),
        ))

    override val stringsLoader: RemoteJsStringLoader = RemoteJsStringLoader.Impl(supportedLocales =
        supportedLocales, fallbackFileUri = stringsFallbackFileUrl)

    public actual val app_name: StringResource = StringResource(key = "app_name", loader =
        stringsLoader)

    public actual val blank: StringResource = StringResource(key = "blank", loader = stringsLoader)

    public actual val login: StringResource = StringResource(key = "login", loader = stringsLoader)

    public actual val error: StringResource = StringResource(key = "error", loader = stringsLoader)

    public actual val err_registering_new_user: StringResource = StringResource(key =
        "err_registering_new_user", loader = stringsLoader)

    public actual val register_empty_fields: StringResource = StringResource(key =
        "register_empty_fields", loader = stringsLoader)

    public actual val register_incorrect_email: StringResource = StringResource(key =
        "register_incorrect_email", loader = stringsLoader)

    public actual val group_activity: StringResource = StringResource(key = "group_activity", loader
        = stringsLoader)

    public actual val select_group_members: StringResource = StringResource(key =
        "select_group_members", loader = stringsLoader)

    public actual val confirm_delete_message: StringResource = StringResource(key =
        "confirm_delete_message", loader = stringsLoader)

    public actual val wrong_user_pass_combo: StringResource = StringResource(key =
        "wrong_user_pass_combo", loader = stringsLoader)

    public actual val login_network_error: StringResource = StringResource(key =
        "login_network_error", loader = stringsLoader)

    public actual val download: StringResource = StringResource(key = "download", loader =
        stringsLoader)

    public actual val downloaded: StringResource = StringResource(key = "downloaded", loader =
        stringsLoader)

    public actual val recycled: StringResource = StringResource(key = "recycled", loader =
        stringsLoader)

    public actual val libraries: StringResource = StringResource(key = "libraries", loader =
        stringsLoader)

    public actual val pause_download: StringResource = StringResource(key = "pause_download", loader
        = stringsLoader)

    public actual val ok: StringResource = StringResource(key = "ok", loader = stringsLoader)

    public actual val confirm: StringResource = StringResource(key = "confirm", loader =
        stringsLoader)

    public actual val cancel: StringResource = StringResource(key = "cancel", loader =
        stringsLoader)

    public actual val canceled: StringResource = StringResource(key = "canceled", loader =
        stringsLoader)

    public actual val delete: StringResource = StringResource(key = "delete", loader =
        stringsLoader)

    public actual val deleted: StringResource = StringResource(key = "deleted", loader =
        stringsLoader)

    public actual val new_tab: StringResource = StringResource(key = "new_tab", loader =
        stringsLoader)

    public actual val loading: StringResource = StringResource(key = "loading", loader =
        stringsLoader)

    public actual val deleting: StringResource = StringResource(key = "deleting", loader =
        stringsLoader)

    public actual val processing: StringResource = StringResource(key = "processing", loader =
        stringsLoader)

    public actual val uploading: StringResource = StringResource(key = "uploading", loader =
        stringsLoader)

    public actual val error_opening_file: StringResource = StringResource(key =
        "error_opening_file", loader = stringsLoader)

    public actual val logout: StringResource = StringResource(key = "logout", loader =
        stringsLoader)

    public actual val about: StringResource = StringResource(key = "about", loader = stringsLoader)

    public actual val download_all: StringResource = StringResource(key = "download_all", loader =
        stringsLoader)

    public actual val next: StringResource = StringResource(key = "next", loader = stringsLoader)

    public actual val previous: StringResource = StringResource(key = "previous", loader =
        stringsLoader)

    public actual val back: StringResource = StringResource(key = "back", loader = stringsLoader)

    public actual val username: StringResource = StringResource(key = "username", loader =
        stringsLoader)

    public actual val password: StringResource = StringResource(key = "password", loader =
        stringsLoader)

    public actual val confirm_password: StringResource = StringResource(key = "confirm_password",
        loader = stringsLoader)

    public actual val last_name: StringResource = StringResource(key = "last_name", loader =
        stringsLoader)

    public actual val first_name: StringResource = StringResource(key = "first_name", loader =
        stringsLoader)

    public actual val register_now: StringResource = StringResource(key = "register_now", loader =
        stringsLoader)

    public actual val new_password: StringResource = StringResource(key = "new_password", loader =
        stringsLoader)

    public actual val phone_memory: StringResource = StringResource(key = "phone_memory", loader =
        stringsLoader)

    public actual val memory_card: StringResource = StringResource(key = "memory_card", loader =
        stringsLoader)

    public actual val device: StringResource = StringResource(key = "device", loader =
        stringsLoader)

    public actual val register: StringResource = StringResource(key = "register", loader =
        stringsLoader)

    public actual val phone_number: StringResource = StringResource(key = "phone_number", loader =
        stringsLoader)

    public actual val name_key: StringResource = StringResource(key = "name_key", loader =
        stringsLoader)

    public actual val description: StringResource = StringResource(key = "description", loader =
        stringsLoader)

    public actual val male: StringResource = StringResource(key = "male", loader = stringsLoader)

    public actual val female: StringResource = StringResource(key = "female", loader =
        stringsLoader)

    public actual val other: StringResource = StringResource(key = "other", loader = stringsLoader)

    public actual val unset: StringResource = StringResource(key = "unset", loader = stringsLoader)

    public actual val settings: StringResource = StringResource(key = "settings", loader =
        stringsLoader)

    public actual val language: StringResource = StringResource(key = "language", loader =
        stringsLoader)

    public actual val add_a_new_language: StringResource = StringResource(key =
        "add_a_new_language", loader = stringsLoader)

    public actual val add_new_language: StringResource = StringResource(key = "add_new_language",
        loader = stringsLoader)

    public actual val select_language: StringResource = StringResource(key = "select_language",
        loader = stringsLoader)

    public actual val languages: StringResource = StringResource(key = "languages", loader =
        stringsLoader)

    public actual val edit_language: StringResource = StringResource(key = "edit_language", loader =
        stringsLoader)

    public actual val two_letter_code: StringResource = StringResource(key = "two_letter_code",
        loader = stringsLoader)

    public actual val three_letter_code: StringResource = StringResource(key = "three_letter_code",
        loader = stringsLoader)

    public actual val languages_description: StringResource = StringResource(key =
        "languages_description", loader = stringsLoader)

    public actual val advanced: StringResource = StringResource(key = "advanced", loader =
        stringsLoader)

    public actual val optional: StringResource = StringResource(key = "optional", loader =
        stringsLoader)

    public actual val email: StringResource = StringResource(key = "email", loader = stringsLoader)

    public actual val regcode: StringResource = StringResource(key = "regcode", loader =
        stringsLoader)

    public actual val home: StringResource = StringResource(key = "home", loader = stringsLoader)

    public actual val phone: StringResource = StringResource(key = "phone", loader = stringsLoader)

    public actual val attendance: StringResource = StringResource(key = "attendance", loader =
        stringsLoader)

    public actual val classes: StringResource = StringResource(key = "classes", loader =
        stringsLoader)

    public actual val exams: StringResource = StringResource(key = "exams", loader = stringsLoader)

    public actual val reports: StringResource = StringResource(key = "reports", loader =
        stringsLoader)

    public actual val choose_template: StringResource = StringResource(key = "choose_template",
        loader = stringsLoader)

    public actual val save_as_template: StringResource = StringResource(key = "save_as_template",
        loader = stringsLoader)

    public actual val contents: StringResource = StringResource(key = "contents", loader =
        stringsLoader)

    public actual val students: StringResource = StringResource(key = "students", loader =
        stringsLoader)

    public actual val add_a_student: StringResource = StringResource(key = "add_a_student", loader =
        stringsLoader)

    public actual val forgot_password: StringResource = StringResource(key = "forgot_password",
        loader = stringsLoader)

    public actual val notifications: StringResource = StringResource(key = "notifications", loader =
        stringsLoader)

    public actual val account: StringResource = StringResource(key = "account", loader =
        stringsLoader)

    public actual val accounts: StringResource = StringResource(key = "accounts", loader =
        stringsLoader)

    public actual val share_application: StringResource = StringResource(key = "share_application",
        loader = stringsLoader)

    public actual val share: StringResource = StringResource(key = "share", loader = stringsLoader)

    public actual val send: StringResource = StringResource(key = "send", loader = stringsLoader)

    public actual val `open`: StringResource = StringResource(key = "open", loader = stringsLoader)

    public actual val downloading: StringResource = StringResource(key = "downloading", loader =
        stringsLoader)

    public actual val waiting: StringResource = StringResource(key = "waiting", loader =
        stringsLoader)

    public actual val waiting_for_connection: StringResource = StringResource(key =
        "waiting_for_connection", loader = stringsLoader)

    public actual val queued: StringResource = StringResource(key = "queued", loader =
        stringsLoader)

    public actual val failed: StringResource = StringResource(key = "failed", loader =
        stringsLoader)

    public actual val passed: StringResource = StringResource(key = "passed", loader =
        stringsLoader)

    public actual val success: StringResource = StringResource(key = "success", loader =
        stringsLoader)

    public actual val incomplete: StringResource = StringResource(key = "incomplete", loader =
        stringsLoader)

    public actual val completed: StringResource = StringResource(key = "completed", loader =
        stringsLoader)

    public actual val select_subtitle_video: StringResource = StringResource(key =
        "select_subtitle_video", loader = stringsLoader)

    public actual val no_subtitle: StringResource = StringResource(key = "no_subtitle", loader =
        stringsLoader)

    public actual val no_video_file_found: StringResource = StringResource(key =
        "no_video_file_found", loader = stringsLoader)

    public actual val content_from_file: StringResource = StringResource(key = "content_from_file",
        loader = stringsLoader)

    public actual val content_from_link: StringResource = StringResource(key = "content_from_link",
        loader = stringsLoader)

    public actual val import_from_file: StringResource = StringResource(key = "import_from_file",
        loader = stringsLoader)

    public actual val import_from_link: StringResource = StringResource(key = "import_from_link",
        loader = stringsLoader)

    public actual val import_content: StringResource = StringResource(key = "import_content", loader
        = stringsLoader)

    public actual val update_content: StringResource = StringResource(key = "update_content", loader
        = stringsLoader)

    public actual val publicly_accessible: StringResource = StringResource(key =
        "publicly_accessible", loader = stringsLoader)

    public actual val title: StringResource = StringResource(key = "title", loader = stringsLoader)

    public actual val licence: StringResource = StringResource(key = "licence", loader =
        stringsLoader)

    public actual val ebook: StringResource = StringResource(key = "ebook", loader = stringsLoader)

    public actual val interactive: StringResource = StringResource(key = "interactive", loader =
        stringsLoader)

    public actual val collection: StringResource = StringResource(key = "collection", loader =
        stringsLoader)

    public actual val document: StringResource = StringResource(key = "document", loader =
        stringsLoader)

    public actual val article: StringResource = StringResource(key = "article", loader =
        stringsLoader)

    public actual val audio: StringResource = StringResource(key = "audio", loader = stringsLoader)

    public actual val move: StringResource = StringResource(key = "move", loader = stringsLoader)

    public actual val hide: StringResource = StringResource(key = "hide", loader = stringsLoader)

    public actual val unhide: StringResource = StringResource(key = "unhide", loader =
        stringsLoader)

    public actual val compress: StringResource = StringResource(key = "compress", loader =
        stringsLoader)

    public actual val and_key: StringResource = StringResource(key = "and_key", loader =
        stringsLoader)

    public actual val added: StringResource = StringResource(key = "added", loader = stringsLoader)

    public actual val attempts: StringResource = StringResource(key = "attempts", loader =
        stringsLoader)

    public actual val add_link_description: StringResource = StringResource(key =
        "add_link_description", loader = stringsLoader)

    public actual val add_gallery_description: StringResource = StringResource(key =
        "add_gallery_description", loader = stringsLoader)

    public actual val add_file_description: StringResource = StringResource(key =
        "add_file_description", loader = stringsLoader)

    public actual val error_this_device_doesnt_support_bluetooth_sharing: StringResource =
        StringResource(key = "error_this_device_doesnt_support_bluetooth_sharing", loader =
        stringsLoader)

    public actual val share_offline_zip_checkbox_label: StringResource = StringResource(key =
        "share_offline_zip_checkbox_label", loader = stringsLoader)

    public actual val share_offline_dialog_message: StringResource = StringResource(key =
        "share_offline_dialog_message", loader = stringsLoader)

    public actual val preparing: StringResource = StringResource(key = "preparing", loader =
        stringsLoader)

    public actual val fixed_date: StringResource = StringResource(key = "fixed_date", loader =
        stringsLoader)

    public actual val relative_date: StringResource = StringResource(key = "relative_date", loader =
        stringsLoader)

    public actual val field_required_prompt: StringResource = StringResource(key =
        "field_required_prompt", loader = stringsLoader)

    public actual val field_password_error_min: StringResource = StringResource(key =
        "field_password_error_min", loader = stringsLoader)

    public actual val filed_password_no_match: StringResource = StringResource(key =
        "filed_password_no_match", loader = stringsLoader)

    public actual val update: StringResource = StringResource(key = "update", loader =
        stringsLoader)

    public actual val profile: StringResource = StringResource(key = "profile", loader =
        stringsLoader)

    public actual val dialog_download_from_playstore_message: StringResource = StringResource(key =
        "dialog_download_from_playstore_message", loader = stringsLoader)

    public actual val dialog_download_from_playstore_ok: StringResource = StringResource(key =
        "dialog_download_from_playstore_ok", loader = stringsLoader)

    public actual val dialog_download_from_playstore_cancel: StringResource = StringResource(key =
        "dialog_download_from_playstore_cancel", loader = stringsLoader)

    public actual val accept: StringResource = StringResource(key = "accept", loader =
        stringsLoader)

    public actual val also_available_in: StringResource = StringResource(key = "also_available_in",
        loader = stringsLoader)

    public actual val created_partnership: StringResource = StringResource(key =
        "created_partnership", loader = stringsLoader)

    public actual val onboarding_get_started_label: StringResource = StringResource(key =
        "onboarding_get_started_label", loader = stringsLoader)

    public actual val lets_get_started_label: StringResource = StringResource(key =
        "lets_get_started_label", loader = stringsLoader)

    public actual val onboarding_headline1: StringResource = StringResource(key =
        "onboarding_headline1", loader = stringsLoader)

    public actual val onboarding_subheadline1: StringResource = StringResource(key =
        "onboarding_subheadline1", loader = stringsLoader)

    public actual val onboarding_headline2: StringResource = StringResource(key =
        "onboarding_headline2", loader = stringsLoader)

    public actual val onboarding_subheadline2: StringResource = StringResource(key =
        "onboarding_subheadline2", loader = stringsLoader)

    public actual val onboarding_headline3: StringResource = StringResource(key =
        "onboarding_headline3", loader = stringsLoader)

    public actual val onboarding_subheadline3: StringResource = StringResource(key =
        "onboarding_subheadline3", loader = stringsLoader)

    public actual val download_continue_stacked_label: StringResource = StringResource(key =
        "download_continue_stacked_label", loader = stringsLoader)

    public actual val download_continue_btn_label: StringResource = StringResource(key =
        "download_continue_btn_label", loader = stringsLoader)

    public actual val download_wifi_only: StringResource = StringResource(key =
        "download_wifi_only", loader = stringsLoader)

    public actual val download_calculating: StringResource = StringResource(key =
        "download_calculating", loader = stringsLoader)

    public actual val download_state_download: StringResource = StringResource(key =
        "download_state_download", loader = stringsLoader)

    public actual val download_state_downloading: StringResource = StringResource(key =
        "download_state_downloading", loader = stringsLoader)

    public actual val download_state_downloaded: StringResource = StringResource(key =
        "download_state_downloaded", loader = stringsLoader)

    public actual val download_downloading_placeholder: StringResource = StringResource(key =
        "download_downloading_placeholder", loader = stringsLoader)

    public actual val download_summary_title: StringResource = StringResource(key =
        "download_summary_title", loader = stringsLoader)

    public actual val download_cancel_label: StringResource = StringResource(key =
        "download_cancel_label", loader = stringsLoader)

    public actual val download_pause_download: StringResource = StringResource(key =
        "download_pause_download", loader = stringsLoader)

    public actual val download_storage_option_device: StringResource = StringResource(key =
        "download_storage_option_device", loader = stringsLoader)

    public actual val download_locally_availability: StringResource = StringResource(key =
        "download_locally_availability", loader = stringsLoader)

    public actual val download_cloud_availability: StringResource = StringResource(key =
        "download_cloud_availability", loader = stringsLoader)

    public actual val insufficient_space: StringResource = StringResource(key =
        "insufficient_space", loader = stringsLoader)

    public actual val download_entry_state_paused: StringResource = StringResource(key =
        "download_entry_state_paused", loader = stringsLoader)

    public actual val get_app: StringResource = StringResource(key = "get_app", loader =
        stringsLoader)

    public actual val no_app_found: StringResource = StringResource(key = "no_app_found", loader =
        stringsLoader)

    public actual val entry_details_author: StringResource = StringResource(key =
        "entry_details_author", loader = stringsLoader)

    public actual val entry_details_publisher: StringResource = StringResource(key =
        "entry_details_publisher", loader = stringsLoader)

    public actual val entry_details_license: StringResource = StringResource(key =
        "entry_details_license", loader = stringsLoader)

    public actual val file_not_found: StringResource = StringResource(key = "file_not_found", loader
        = stringsLoader)

    public actual val multiple_choice: StringResource = StringResource(key = "multiple_choice",
        loader = stringsLoader)

    public actual val preview: StringResource = StringResource(key = "preview", loader =
        stringsLoader)

    public actual val content_creation_storage_option_title: StringResource = StringResource(key =
        "content_creation_storage_option_title", loader = stringsLoader)

    public actual val file_required_prompt: StringResource = StringResource(key =
        "file_required_prompt", loader = stringsLoader)

    public actual val content_creation_folder_new_message: StringResource = StringResource(key =
        "content_creation_folder_new_message", loader = stringsLoader)

    public actual val content_creation_folder_update_message: StringResource = StringResource(key =
        "content_creation_folder_update_message", loader = stringsLoader)

    public actual val error_message_load_page: StringResource = StringResource(key =
        "error_message_load_page", loader = stringsLoader)

    public actual val error_message_update_document: StringResource = StringResource(key =
        "error_message_update_document", loader = stringsLoader)

    public actual val supported_files: StringResource = StringResource(key = "supported_files",
        loader = stringsLoader)

    public actual val content_editor_save_error: StringResource = StringResource(key =
        "content_editor_save_error", loader = stringsLoader)

    public actual val content_entry_export_inprogress: StringResource = StringResource(key =
        "content_entry_export_inprogress", loader = stringsLoader)

    public actual val content_entry_export_message: StringResource = StringResource(key =
        "content_entry_export_message", loader = stringsLoader)

    public actual val show_hidden_items: StringResource = StringResource(key = "show_hidden_items",
        loader = stringsLoader)

    public actual val open_folder: StringResource = StringResource(key = "open_folder", loader =
        stringsLoader)

    public actual val action_hidden: StringResource = StringResource(key = "action_hidden", loader =
        stringsLoader)

    public actual val invalid: StringResource = StringResource(key = "invalid", loader =
        stringsLoader)

    public actual val enter_register_code: StringResource = StringResource(key =
        "enter_register_code", loader = stringsLoader)

    public actual val bar_chart: StringResource = StringResource(key = "bar_chart", loader =
        stringsLoader)

    public actual val line_chart: StringResource = StringResource(key = "line_chart", loader =
        stringsLoader)

    public actual val score: StringResource = StringResource(key = "score", loader = stringsLoader)

    public actual val content_total_duration: StringResource = StringResource(key =
        "content_total_duration", loader = stringsLoader)

    public actual val content_average_duration: StringResource = StringResource(key =
        "content_average_duration", loader = stringsLoader)

    public actual val count_session: StringResource = StringResource(key = "count_session", loader =
        stringsLoader)

    public actual val interaction_recorded: StringResource = StringResource(key =
        "interaction_recorded", loader = stringsLoader)

    public actual val number_active_users: StringResource = StringResource(key =
        "number_active_users", loader = stringsLoader)

    public actual val average_usage_time_per_user: StringResource = StringResource(key =
        "average_usage_time_per_user", loader = stringsLoader)

    public actual val number_students_completed: StringResource = StringResource(key =
        "number_students_completed", loader = stringsLoader)

    public actual val percent_students_completed: StringResource = StringResource(key =
        "percent_students_completed", loader = stringsLoader)

    public actual val total_attendances: StringResource = StringResource(key = "total_attendances",
        loader = stringsLoader)

    public actual val total_absences: StringResource = StringResource(key = "total_absences", loader
        = stringsLoader)

    public actual val total_lates: StringResource = StringResource(key = "total_lates", loader =
        stringsLoader)

    public actual val percent_students_attended: StringResource = StringResource(key =
        "percent_students_attended", loader = stringsLoader)

    public actual val percent_students_attended_or_late: StringResource = StringResource(key =
        "percent_students_attended_or_late", loader = stringsLoader)

    public actual val total_number_of_classes: StringResource = StringResource(key =
        "total_number_of_classes", loader = stringsLoader)

    public actual val number_unique_students_attending: StringResource = StringResource(key =
        "number_unique_students_attending", loader = stringsLoader)

    public actual val xapi_day: StringResource = StringResource(key = "xapi_day", loader =
        stringsLoader)

    public actual val xapi_week: StringResource = StringResource(key = "xapi_week", loader =
        stringsLoader)

    public actual val xapi_month: StringResource = StringResource(key = "xapi_month", loader =
        stringsLoader)

    public actual val xapi_custom_date: StringResource = StringResource(key = "xapi_custom_date",
        loader = stringsLoader)

    public actual val xapi_content_entry: StringResource = StringResource(key =
        "xapi_content_entry", loader = stringsLoader)

    public actual val xapi_clear: StringResource = StringResource(key = "xapi_clear", loader =
        stringsLoader)

    public actual val last_week_date_range: StringResource = StringResource(key =
        "last_week_date_range", loader = stringsLoader)

    public actual val last_two_week_date_range: StringResource = StringResource(key =
        "last_two_week_date_range", loader = stringsLoader)

    public actual val last_month_date_range: StringResource = StringResource(key =
        "last_month_date_range", loader = stringsLoader)

    public actual val last_three_months_date_range: StringResource = StringResource(key =
        "last_three_months_date_range", loader = stringsLoader)

    public actual val new_custom_date_range: StringResource = StringResource(key =
        "new_custom_date_range", loader = stringsLoader)

    public actual val selected_custom_range: StringResource = StringResource(key =
        "selected_custom_range", loader = stringsLoader)

    public actual val time_range: StringResource = StringResource(key = "time_range", loader =
        stringsLoader)

    public actual val year: StringResource = StringResource(key = "year", loader = stringsLoader)

    public actual val from: StringResource = StringResource(key = "from", loader = stringsLoader)

    public actual val done: StringResource = StringResource(key = "done", loader = stringsLoader)

    public actual val xapi_options_general: StringResource = StringResource(key =
        "xapi_options_general", loader = stringsLoader)

    public actual val xapi_options_report_title: StringResource = StringResource(key =
        "xapi_options_report_title", loader = stringsLoader)

    public actual val xapi_options_visual_type: StringResource = StringResource(key =
        "xapi_options_visual_type", loader = stringsLoader)

    public actual val xapi_options_data_set: StringResource = StringResource(key =
        "xapi_options_data_set", loader = stringsLoader)

    public actual val xapi_options_series: StringResource = StringResource(key =
        "xapi_options_series", loader = stringsLoader)

    public actual val xapi_options_axes: StringResource = StringResource(key = "xapi_options_axes",
        loader = stringsLoader)

    public actual val xapi_options_y_axes: StringResource = StringResource(key =
        "xapi_options_y_axes", loader = stringsLoader)

    public actual val xapi_options_x_axes: StringResource = StringResource(key =
        "xapi_options_x_axes", loader = stringsLoader)

    public actual val xapi_options_subgroup: StringResource = StringResource(key =
        "xapi_options_subgroup", loader = stringsLoader)

    public actual val xapi_options_filters: StringResource = StringResource(key =
        "xapi_options_filters", loader = stringsLoader)

    public actual val xapi_options_who: StringResource = StringResource(key = "xapi_options_who",
        loader = stringsLoader)

    public actual val xapi_options_did: StringResource = StringResource(key = "xapi_options_did",
        loader = stringsLoader)

    public actual val xapi_options_what: StringResource = StringResource(key = "xapi_options_what",
        loader = stringsLoader)

    public actual val xapi_options_when: StringResource = StringResource(key = "xapi_options_when",
        loader = stringsLoader)

    public actual val xapi_options_where: StringResource = StringResource(key =
        "xapi_options_where", loader = stringsLoader)

    public actual val time_range_all: StringResource = StringResource(key = "time_range_all", loader
        = stringsLoader)

    public actual val report_filter_edit_field: StringResource = StringResource(key =
        "report_filter_edit_field", loader = stringsLoader)

    public actual val report_filter_edit_condition: StringResource = StringResource(key =
        "report_filter_edit_condition", loader = stringsLoader)

    public actual val report_filter_edit_values: StringResource = StringResource(key =
        "report_filter_edit_values", loader = stringsLoader)

    public actual val report: StringResource = StringResource(key = "report", loader =
        stringsLoader)

    public actual val create_a_new_report: StringResource = StringResource(key =
        "create_a_new_report", loader = stringsLoader)

    public actual val edit_report: StringResource = StringResource(key = "edit_report", loader =
        stringsLoader)

    public actual val verb: StringResource = StringResource(key = "verb", loader = stringsLoader)

    public actual val add_a_new_verb: StringResource = StringResource(key = "add_a_new_verb", loader
        = stringsLoader)

    public actual val id_verbentity_urlid: StringResource = StringResource(key =
        "id_verbentity_urlid", loader = stringsLoader)

    public actual val edit_filters: StringResource = StringResource(key = "edit_filters", loader =
        stringsLoader)

    public actual val field_person_gender: StringResource = StringResource(key =
        "field_person_gender", loader = stringsLoader)

    public actual val field_person_age: StringResource = StringResource(key = "field_person_age",
        loader = stringsLoader)

    public actual val field_content_completion: StringResource = StringResource(key =
        "field_content_completion", loader = stringsLoader)

    public actual val field_content_entry: StringResource = StringResource(key =
        "field_content_entry", loader = stringsLoader)

    public actual val field_content_progress: StringResource = StringResource(key =
        "field_content_progress", loader = stringsLoader)

    public actual val field_attendance_percentage: StringResource = StringResource(key =
        "field_attendance_percentage", loader = stringsLoader)

    public actual val condition_is: StringResource = StringResource(key = "condition_is", loader =
        stringsLoader)

    public actual val condition_is_not: StringResource = StringResource(key = "condition_is_not",
        loader = stringsLoader)

    public actual val condition_greater_than: StringResource = StringResource(key =
        "condition_greater_than", loader = stringsLoader)

    public actual val condition_less_than: StringResource = StringResource(key =
        "condition_less_than", loader = stringsLoader)

    public actual val condition_in_list: StringResource = StringResource(key = "condition_in_list",
        loader = stringsLoader)

    public actual val condition_not_in_list: StringResource = StringResource(key =
        "condition_not_in_list", loader = stringsLoader)

    public actual val condition_between: StringResource = StringResource(key = "condition_between",
        loader = stringsLoader)

    public actual val add_person_filter: StringResource = StringResource(key = "add_person_filter",
        loader = stringsLoader)

    public actual val add_verb_filter: StringResource = StringResource(key = "add_verb_filter",
        loader = stringsLoader)

    public actual val add_content_filter: StringResource = StringResource(key =
        "add_content_filter", loader = stringsLoader)

    public actual val dashboard: StringResource = StringResource(key = "dashboard", loader =
        stringsLoader)

    public actual val activity_import_link: StringResource = StringResource(key =
        "activity_import_link", loader = stringsLoader)

    public actual val xapi_hours: StringResource = StringResource(key = "xapi_hours", loader =
        stringsLoader)

    public actual val xapi_minutes: StringResource = StringResource(key = "xapi_minutes", loader =
        stringsLoader)

    public actual val xapi_seconds: StringResource = StringResource(key = "xapi_seconds", loader =
        stringsLoader)

    public actual val xapi_verb_header: StringResource = StringResource(key = "xapi_verb_header",
        loader = stringsLoader)

    public actual val xapi_result_header: StringResource = StringResource(key =
        "xapi_result_header", loader = stringsLoader)

    public actual val xapi_score: StringResource = StringResource(key = "xapi_score", loader =
        stringsLoader)

    public actual val import_title_not_entered: StringResource = StringResource(key =
        "import_title_not_entered", loader = stringsLoader)

    public actual val no_account: StringResource = StringResource(key = "no_account", loader =
        stringsLoader)

    public actual val enter_url: StringResource = StringResource(key = "enter_url", loader =
        stringsLoader)

    public actual val invalid_link: StringResource = StringResource(key = "invalid_link", loader =
        stringsLoader)

    public actual val invalid_file: StringResource = StringResource(key = "invalid_file", loader =
        stringsLoader)

    public actual val import_link_content_not_supported: StringResource = StringResource(key =
        "import_link_content_not_supported", loader = stringsLoader)

    public actual val unsupported_file_type: StringResource = StringResource(key =
        "unsupported_file_type", loader = stringsLoader)

    public actual val import_link_big_size: StringResource = StringResource(key =
        "import_link_big_size", loader = stringsLoader)

    public actual val import_link_error: StringResource = StringResource(key = "import_link_error",
        loader = stringsLoader)

    public actual val supported_link: StringResource = StringResource(key = "supported_link", loader
        = stringsLoader)

    public actual val offline_sharing_enable_bluetooth_prompt: StringResource = StringResource(key =
        "offline_sharing_enable_bluetooth_prompt", loader = stringsLoader)

    public actual val offline_sharing_enable_wifi_promot: StringResource = StringResource(key =
        "offline_sharing_enable_wifi_promot", loader = stringsLoader)

    public actual val enable: StringResource = StringResource(key = "enable", loader =
        stringsLoader)

    public actual val repo_loading_status_loading_cloud: StringResource = StringResource(key =
        "repo_loading_status_loading_cloud", loader = stringsLoader)

    public actual val repo_loading_status_loading_mirror: StringResource = StringResource(key =
        "repo_loading_status_loading_mirror", loader = stringsLoader)

    public actual val repo_loading_status_failed_noconnection: StringResource = StringResource(key =
        "repo_loading_status_failed_noconnection", loader = stringsLoader)

    public actual val repo_loading_status_failed_connection_error: StringResource =
        StringResource(key = "repo_loading_status_failed_connection_error", loader = stringsLoader)

    public actual val licence_type_cc_by: StringResource = StringResource(key =
        "licence_type_cc_by", loader = stringsLoader)

    public actual val licence_type_cc_by_sa: StringResource = StringResource(key =
        "licence_type_cc_by_sa", loader = stringsLoader)

    public actual val licence_type_cc_by_sa_nc: StringResource = StringResource(key =
        "licence_type_cc_by_sa_nc", loader = stringsLoader)

    public actual val licence_type_cc_by_nc: StringResource = StringResource(key =
        "licence_type_cc_by_nc", loader = stringsLoader)

    public actual val licence_type_cc_by_nc_sa: StringResource = StringResource(key =
        "licence_type_cc_by_nc_sa", loader = stringsLoader)

    public actual val licence_type_public_domain: StringResource = StringResource(key =
        "licence_type_public_domain", loader = stringsLoader)

    public actual val licence_type_all_rights: StringResource = StringResource(key =
        "licence_type_all_rights", loader = stringsLoader)

    public actual val license_type_cc_0: StringResource = StringResource(key = "license_type_cc_0",
        loader = stringsLoader)

    public actual val syncing: StringResource = StringResource(key = "syncing", loader =
        stringsLoader)

    public actual val feed: StringResource = StringResource(key = "feed", loader = stringsLoader)

    public actual val people: StringResource = StringResource(key = "people", loader =
        stringsLoader)

    public actual val record_attendance: StringResource = StringResource(key = "record_attendance",
        loader = stringsLoader)

    public actual val today: StringResource = StringResource(key = "today", loader = stringsLoader)

    public actual val sort_by: StringResource = StringResource(key = "sort_by", loader =
        stringsLoader)

    public actual val log: StringResource = StringResource(key = "log", loader = stringsLoader)

    public actual val schedule: StringResource = StringResource(key = "schedule", loader =
        stringsLoader)

    public actual val add_a_schedule: StringResource = StringResource(key = "add_a_schedule", loader
        = stringsLoader)

    public actual val edit_schedule: StringResource = StringResource(key = "edit_schedule", loader =
        stringsLoader)

    public actual val first_key: StringResource = StringResource(key = "first_key", loader =
        stringsLoader)

    public actual val last_key: StringResource = StringResource(key = "last_key", loader =
        stringsLoader)

    public actual val last_week: StringResource = StringResource(key = "last_week", loader =
        stringsLoader)

    public actual val mark_all: StringResource = StringResource(key = "mark_all", loader =
        stringsLoader)

    public actual val mark_all_present: StringResource = StringResource(key = "mark_all_present",
        loader = stringsLoader)

    public actual val mark_all_absent: StringResource = StringResource(key = "mark_all_absent",
        loader = stringsLoader)

    public actual val present: StringResource = StringResource(key = "present", loader =
        stringsLoader)

    public actual val absent: StringResource = StringResource(key = "absent", loader =
        stringsLoader)

    public actual val attendance_report: StringResource = StringResource(key = "attendance_report",
        loader = stringsLoader)

    public actual val father: StringResource = StringResource(key = "father", loader =
        stringsLoader)

    public actual val mother: StringResource = StringResource(key = "mother", loader =
        stringsLoader)

    public actual val birthday: StringResource = StringResource(key = "birthday", loader =
        stringsLoader)

    public actual val first_names: StringResource = StringResource(key = "first_names", loader =
        stringsLoader)

    public actual val basic_details: StringResource = StringResource(key = "basic_details", loader =
        stringsLoader)

    public actual val address: StringResource = StringResource(key = "address", loader =
        stringsLoader)

    public actual val add_person_to_class: StringResource = StringResource(key =
        "add_person_to_class", loader = stringsLoader)

    public actual val edit: StringResource = StringResource(key = "edit", loader = stringsLoader)

    public actual val clazz: StringResource = StringResource(key = "clazz", loader = stringsLoader)

    public actual val add_a_new_class: StringResource = StringResource(key = "add_a_new_class",
        loader = stringsLoader)

    public actual val edit_clazz: StringResource = StringResource(key = "edit_clazz", loader =
        stringsLoader)

    public actual val add_a_class: StringResource = StringResource(key = "add_a_class", loader =
        stringsLoader)

    public actual val join_existing_class: StringResource = StringResource(key =
        "join_existing_class", loader = stringsLoader)

    public actual val student_image: StringResource = StringResource(key = "student_image", loader =
        stringsLoader)

    public actual val question_key: StringResource = StringResource(key = "question_key", loader =
        stringsLoader)

    public actual val add_question: StringResource = StringResource(key = "add_question", loader =
        stringsLoader)

    public actual val edit_question: StringResource = StringResource(key = "edit_question", loader =
        stringsLoader)

    public actual val questions: StringResource = StringResource(key = "questions", loader =
        stringsLoader)

    public actual val add_person: StringResource = StringResource(key = "add_person", loader =
        stringsLoader)

    public actual val add_class: StringResource = StringResource(key = "add_class", loader =
        stringsLoader)

    public actual val class_name: StringResource = StringResource(key = "class_name", loader =
        stringsLoader)

    public actual val class_description: StringResource = StringResource(key = "class_description",
        loader = stringsLoader)

    public actual val to_key: StringResource = StringResource(key = "to_key", loader =
        stringsLoader)

    public actual val holiday: StringResource = StringResource(key = "holiday", loader =
        stringsLoader)

    public actual val add_a_holiday: StringResource = StringResource(key = "add_a_holiday", loader =
        stringsLoader)

    public actual val edit_holiday: StringResource = StringResource(key = "edit_holiday", loader =
        stringsLoader)

    public actual val holidays: StringResource = StringResource(key = "holidays", loader =
        stringsLoader)

    public actual val holiday_calendar: StringResource = StringResource(key = "holiday_calendar",
        loader = stringsLoader)

    public actual val add_a_new_holiday_calendar: StringResource = StringResource(key =
        "add_a_new_holiday_calendar", loader = stringsLoader)

    public actual val edit_holiday_calendar: StringResource = StringResource(key =
        "edit_holiday_calendar", loader = stringsLoader)

    public actual val features_enabled: StringResource = StringResource(key = "features_enabled",
        loader = stringsLoader)

    public actual val weekly: StringResource = StringResource(key = "weekly", loader =
        stringsLoader)

    public actual val once: StringResource = StringResource(key = "once", loader = stringsLoader)

    public actual val daily: StringResource = StringResource(key = "daily", loader = stringsLoader)

    public actual val monthly: StringResource = StringResource(key = "monthly", loader =
        stringsLoader)

    public actual val yearly: StringResource = StringResource(key = "yearly", loader =
        stringsLoader)

    public actual val sunday: StringResource = StringResource(key = "sunday", loader =
        stringsLoader)

    public actual val monday: StringResource = StringResource(key = "monday", loader =
        stringsLoader)

    public actual val tuesday: StringResource = StringResource(key = "tuesday", loader =
        stringsLoader)

    public actual val wednesday: StringResource = StringResource(key = "wednesday", loader =
        stringsLoader)

    public actual val thursday: StringResource = StringResource(key = "thursday", loader =
        stringsLoader)

    public actual val friday: StringResource = StringResource(key = "friday", loader =
        stringsLoader)

    public actual val saturday: StringResource = StringResource(key = "saturday", loader =
        stringsLoader)

    public actual val day: StringResource = StringResource(key = "day", loader = stringsLoader)

    public actual val toC: StringResource = StringResource(key = "toC", loader = stringsLoader)

    public actual val last_month: StringResource = StringResource(key = "last_month", loader =
        stringsLoader)

    public actual val activity_change: StringResource = StringResource(key = "activity_change",
        loader = stringsLoader)

    public actual val change_implemented_if_any: StringResource = StringResource(key =
        "change_implemented_if_any", loader = stringsLoader)

    public actual val duration: StringResource = StringResource(key = "duration", loader =
        stringsLoader)

    public actual val how_did_it_go: StringResource = StringResource(key = "how_did_it_go", loader =
        stringsLoader)

    public actual val notes: StringResource = StringResource(key = "notes", loader = stringsLoader)

    public actual val activity: StringResource = StringResource(key = "activity", loader =
        stringsLoader)

    public actual val sort_by_name: StringResource = StringResource(key = "sort_by_name", loader =
        stringsLoader)

    public actual val sort_by_name_asc: StringResource = StringResource(key = "sort_by_name_asc",
        loader = stringsLoader)

    public actual val sort_by_name_desc: StringResource = StringResource(key = "sort_by_name_desc",
        loader = stringsLoader)

    public actual val date_enroll: StringResource = StringResource(key = "date_enroll", loader =
        stringsLoader)

    public actual val date_left: StringResource = StringResource(key = "date_left", loader =
        stringsLoader)

    public actual val enroled: StringResource = StringResource(key = "enroled", loader =
        stringsLoader)

    public actual val class_enrolment: StringResource = StringResource(key = "class_enrolment",
        loader = stringsLoader)

    public actual val graduated: StringResource = StringResource(key = "graduated", loader =
        stringsLoader)

    public actual val dropped_out: StringResource = StringResource(key = "dropped_out", loader =
        stringsLoader)

    public actual val moved: StringResource = StringResource(key = "moved", loader = stringsLoader)

    public actual val new_leaving_reason: StringResource = StringResource(key =
        "new_leaving_reason", loader = stringsLoader)

    public actual val edit_leaving_reason: StringResource = StringResource(key =
        "edit_leaving_reason", loader = stringsLoader)

    public actual val edit_enrolment: StringResource = StringResource(key = "edit_enrolment", loader
        = stringsLoader)

    public actual val new_enrolment: StringResource = StringResource(key = "new_enrolment", loader =
        stringsLoader)

    public actual val select_leaving_reason: StringResource = StringResource(key =
        "select_leaving_reason", loader = stringsLoader)

    public actual val add_leaving_reason: StringResource = StringResource(key =
        "add_leaving_reason", loader = stringsLoader)

    public actual val leaving_reason: StringResource = StringResource(key = "leaving_reason", loader
        = stringsLoader)

    public actual val leaving_reasons: StringResource = StringResource(key = "leaving_reasons",
        loader = stringsLoader)

    public actual val leaving_reason_manage: StringResource = StringResource(key =
        "leaving_reason_manage", loader = stringsLoader)

    public actual val view_profile: StringResource = StringResource(key = "view_profile", loader =
        stringsLoader)

    public actual val class_enrolment_outcome: StringResource = StringResource(key =
        "class_enrolment_outcome", loader = stringsLoader)

    public actual val class_enrolment_leaving: StringResource = StringResource(key =
        "class_enrolment_leaving", loader = stringsLoader)

    public actual val in_progress: StringResource = StringResource(key = "in_progress", loader =
        stringsLoader)

    public actual val outcome: StringResource = StringResource(key = "outcome", loader =
        stringsLoader)

    public actual val person_enrolment_in_class: StringResource = StringResource(key =
        "person_enrolment_in_class", loader = stringsLoader)

    public actual val enrolment: StringResource = StringResource(key = "enrolment", loader =
        stringsLoader)

    public actual val error_start_date_before_clazz_date: StringResource = StringResource(key =
        "error_start_date_before_clazz_date", loader = stringsLoader)

    public actual val error_start_date_before_previous_enrolment_date: StringResource =
        StringResource(key = "error_start_date_before_previous_enrolment_date", loader =
        stringsLoader)

    public actual val time_present: StringResource = StringResource(key = "time_present", loader =
        stringsLoader)

    public actual val ascending: StringResource = StringResource(key = "ascending", loader =
        stringsLoader)

    public actual val descending: StringResource = StringResource(key = "descending", loader =
        stringsLoader)

    public actual val sort_by_text: StringResource = StringResource(key = "sort_by_text", loader =
        stringsLoader)

    public actual val attendance_high_to_low: StringResource = StringResource(key =
        "attendance_high_to_low", loader = stringsLoader)

    public actual val attendance_low_to_high: StringResource = StringResource(key =
        "attendance_low_to_high", loader = stringsLoader)

    public actual val teacher: StringResource = StringResource(key = "teacher", loader =
        stringsLoader)

    public actual val add_a_teacher: StringResource = StringResource(key = "add_a_teacher", loader =
        stringsLoader)

    public actual val partial: StringResource = StringResource(key = "partial", loader =
        stringsLoader)

    public actual val teachers_literal: StringResource = StringResource(key = "teachers_literal",
        loader = stringsLoader)

    public actual val participant: StringResource = StringResource(key = "participant", loader =
        stringsLoader)

    public actual val primary_user: StringResource = StringResource(key = "primary_user", loader =
        stringsLoader)

    public actual val up_key: StringResource = StringResource(key = "up_key", loader =
        stringsLoader)

    public actual val down_key: StringResource = StringResource(key = "down_key", loader =
        stringsLoader)

    public actual val activity_went_good: StringResource = StringResource(key =
        "activity_went_good", loader = stringsLoader)

    public actual val activity_went_bad: StringResource = StringResource(key = "activity_went_bad",
        loader = stringsLoader)

    public actual val activity_enter_any_notes: StringResource = StringResource(key =
        "activity_enter_any_notes", loader = stringsLoader)

    public actual val uom_frequency_title: StringResource = StringResource(key =
        "uom_frequency_title", loader = stringsLoader)

    public actual val uom_duration_title: StringResource = StringResource(key =
        "uom_duration_title", loader = stringsLoader)

    public actual val uom_boolean_title: StringResource = StringResource(key = "uom_boolean_title",
        loader = stringsLoader)

    public actual val uom_default_title: StringResource = StringResource(key = "uom_default_title",
        loader = stringsLoader)

    public actual val add_activity: StringResource = StringResource(key = "add_activity", loader =
        stringsLoader)

    public actual val add_activity_literal: StringResource = StringResource(key =
        "add_activity_literal", loader = stringsLoader)

    public actual val select_one: StringResource = StringResource(key = "select_one", loader =
        stringsLoader)

    public actual val measurement_type: StringResource = StringResource(key = "measurement_type",
        loader = stringsLoader)

    public actual val frequency: StringResource = StringResource(key = "frequency", loader =
        stringsLoader)

    public actual val yes_no: StringResource = StringResource(key = "yes_no", loader =
        stringsLoader)

    public actual val time_period: StringResource = StringResource(key = "time_period", loader =
        stringsLoader)

    public actual val location: StringResource = StringResource(key = "location", loader =
        stringsLoader)

    public actual val last_three_months: StringResource = StringResource(key = "last_three_months",
        loader = stringsLoader)

    public actual val export: StringResource = StringResource(key = "export", loader =
        stringsLoader)

    public actual val date: StringResource = StringResource(key = "date", loader = stringsLoader)

    public actual val average: StringResource = StringResource(key = "average", loader =
        stringsLoader)

    public actual val age: StringResource = StringResource(key = "age", loader = stringsLoader)

    public actual val days: StringResource = StringResource(key = "days", loader = stringsLoader)

    public actual val apply: StringResource = StringResource(key = "apply", loader = stringsLoader)

    public actual val file_selected: StringResource = StringResource(key = "file_selected", loader =
        stringsLoader)

    public actual val all: StringResource = StringResource(key = "all", loader = stringsLoader)

    public actual val holiday_calendars: StringResource = StringResource(key = "holiday_calendars",
        loader = stringsLoader)

    public actual val holiday_calendars_desc: StringResource = StringResource(key =
        "holiday_calendars_desc", loader = stringsLoader)

    public actual val sel_question_set: StringResource = StringResource(key = "sel_question_set",
        loader = stringsLoader)

    public actual val sel_question_set_desc: StringResource = StringResource(key =
        "sel_question_set_desc", loader = stringsLoader)

    public actual val users: StringResource = StringResource(key = "users", loader = stringsLoader)

    public actual val nomination: StringResource = StringResource(key = "nomination", loader =
        stringsLoader)

    public actual val active: StringResource = StringResource(key = "active", loader =
        stringsLoader)

    public actual val class_id: StringResource = StringResource(key = "class_id", loader =
        stringsLoader)

    public actual val gender_literal: StringResource = StringResource(key = "gender_literal", loader
        = stringsLoader)

    public actual val country: StringResource = StringResource(key = "country", loader =
        stringsLoader)

    public actual val connectivity: StringResource = StringResource(key = "connectivity", loader =
        stringsLoader)

    public actual val home_internet_access: StringResource = StringResource(key =
        "home_internet_access", loader = stringsLoader)

    public actual val mobile_internet_access: StringResource = StringResource(key =
        "mobile_internet_access", loader = stringsLoader)

    public actual val connectivity_limited: StringResource = StringResource(key =
        "connectivity_limited", loader = stringsLoader)

    public actual val connectivity_full: StringResource = StringResource(key = "connectivity_full",
        loader = stringsLoader)

    public actual val prefer_not_to_say: StringResource = StringResource(key = "prefer_not_to_say",
        loader = stringsLoader)

    public actual val sel_question_set_to_use: StringResource = StringResource(key =
        "sel_question_set_to_use", loader = stringsLoader)

    public actual val search: StringResource = StringResource(key = "search", loader =
        stringsLoader)

    public actual val attendance_percentage: StringResource = StringResource(key =
        "attendance_percentage", loader = stringsLoader)

    public actual val filter: StringResource = StringResource(key = "filter", loader =
        stringsLoader)

    public actual val over_key: StringResource = StringResource(key = "over_key", loader =
        stringsLoader)

    public actual val sync: StringResource = StringResource(key = "sync", loader = stringsLoader)

    public actual val not_recorded: StringResource = StringResource(key = "not_recorded", loader =
        stringsLoader)

    public actual val groups: StringResource = StringResource(key = "groups", loader =
        stringsLoader)

    public actual val group_setting_desc: StringResource = StringResource(key =
        "group_setting_desc", loader = stringsLoader)

    public actual val roles: StringResource = StringResource(key = "roles", loader = stringsLoader)

    public actual val role: StringResource = StringResource(key = "role", loader = stringsLoader)

    public actual val add_a_new_role: StringResource = StringResource(key = "add_a_new_role", loader
        = stringsLoader)

    public actual val edit_role: StringResource = StringResource(key = "edit_role", loader =
        stringsLoader)

    public actual val rols_assignment: StringResource = StringResource(key = "rols_assignment",
        loader = stringsLoader)

    public actual val role_assignment_setting_desc: StringResource = StringResource(key =
        "role_assignment_setting_desc", loader = stringsLoader)

    public actual val locations: StringResource = StringResource(key = "locations", loader =
        stringsLoader)

    public actual val locations_setting_desc: StringResource = StringResource(key =
        "locations_setting_desc", loader = stringsLoader)

    public actual val audit_log: StringResource = StringResource(key = "audit_log", loader =
        stringsLoader)

    public actual val audit_log_setting_desc: StringResource = StringResource(key =
        "audit_log_setting_desc", loader = stringsLoader)

    public actual val entry_key: StringResource = StringResource(key = "entry_key", loader =
        stringsLoader)

    public actual val permission_key: StringResource = StringResource(key = "permission_key", loader
        = stringsLoader)

    public actual val permissions: StringResource = StringResource(key = "permissions", loader =
        stringsLoader)

    public actual val role_name: StringResource = StringResource(key = "role_name", loader =
        stringsLoader)

    public actual val members_key: StringResource = StringResource(key = "members_key", loader =
        stringsLoader)

    public actual val member_key: StringResource = StringResource(key = "member_key", loader =
        stringsLoader)

    public actual val role_assignment: StringResource = StringResource(key = "role_assignment",
        loader = stringsLoader)

    public actual val scope_by: StringResource = StringResource(key = "scope_by", loader =
        stringsLoader)

    public actual val assign_to: StringResource = StringResource(key = "assign_to", loader =
        stringsLoader)

    public actual val person: StringResource = StringResource(key = "person", loader =
        stringsLoader)

    public actual val add_a_new_person: StringResource = StringResource(key = "add_a_new_person",
        loader = stringsLoader)

    public actual val edit_person: StringResource = StringResource(key = "edit_person", loader =
        stringsLoader)

    public actual val permission_person_select: StringResource = StringResource(key =
        "permission_person_select", loader = stringsLoader)

    public actual val permission_person_insert: StringResource = StringResource(key =
        "permission_person_insert", loader = stringsLoader)

    public actual val permission_person_update: StringResource = StringResource(key =
        "permission_person_update", loader = stringsLoader)

    public actual val permission_person_picture_select: StringResource = StringResource(key =
        "permission_person_picture_select", loader = stringsLoader)

    public actual val permission_person_picture_insert: StringResource = StringResource(key =
        "permission_person_picture_insert", loader = stringsLoader)

    public actual val permission_person_picture_update: StringResource = StringResource(key =
        "permission_person_picture_update", loader = stringsLoader)

    public actual val permission_clazz_select: StringResource = StringResource(key =
        "permission_clazz_select", loader = stringsLoader)

    public actual val permission_clazz_insert: StringResource = StringResource(key =
        "permission_clazz_insert", loader = stringsLoader)

    public actual val permission_clazz_update: StringResource = StringResource(key =
        "permission_clazz_update", loader = stringsLoader)

    public actual val permission_clazz_add_teacher: StringResource = StringResource(key =
        "permission_clazz_add_teacher", loader = stringsLoader)

    public actual val permission_clazz_add_student: StringResource = StringResource(key =
        "permission_clazz_add_student", loader = stringsLoader)

    public actual val permission_attendance_select: StringResource = StringResource(key =
        "permission_attendance_select", loader = stringsLoader)

    public actual val permission_attendance_insert: StringResource = StringResource(key =
        "permission_attendance_insert", loader = stringsLoader)

    public actual val permission_attendance_update: StringResource = StringResource(key =
        "permission_attendance_update", loader = stringsLoader)

    public actual val permission_activity_select: StringResource = StringResource(key =
        "permission_activity_select", loader = stringsLoader)

    public actual val permission_activity_insert: StringResource = StringResource(key =
        "permission_activity_insert", loader = stringsLoader)

    public actual val permission_activity_update: StringResource = StringResource(key =
        "permission_activity_update", loader = stringsLoader)

    public actual val permission_sel_question_select: StringResource = StringResource(key =
        "permission_sel_question_select", loader = stringsLoader)

    public actual val permission_sel_question_insert: StringResource = StringResource(key =
        "permission_sel_question_insert", loader = stringsLoader)

    public actual val permission_sel_question_update: StringResource = StringResource(key =
        "permission_sel_question_update", loader = stringsLoader)

    public actual val permission_sel_select: StringResource = StringResource(key =
        "permission_sel_select", loader = stringsLoader)

    public actual val permission_sel_update: StringResource = StringResource(key =
        "permission_sel_update", loader = stringsLoader)

    public actual val custom_fields: StringResource = StringResource(key = "custom_fields", loader =
        stringsLoader)

    public actual val custom_field: StringResource = StringResource(key = "custom_field", loader =
        stringsLoader)

    public actual val custom_fields_desc: StringResource = StringResource(key =
        "custom_fields_desc", loader = stringsLoader)

    public actual val field_type: StringResource = StringResource(key = "field_type", loader =
        stringsLoader)

    public actual val text: StringResource = StringResource(key = "text", loader = stringsLoader)

    public actual val dropdown: StringResource = StringResource(key = "dropdown", loader =
        stringsLoader)

    public actual val options: StringResource = StringResource(key = "options", loader =
        stringsLoader)

    public actual val option_value: StringResource = StringResource(key = "option_value", loader =
        stringsLoader)

    public actual val errors: StringResource = StringResource(key = "errors", loader =
        stringsLoader)

    public actual val shake_feedback: StringResource = StringResource(key = "shake_feedback", loader
        = stringsLoader)

    public actual val feedback_hint: StringResource = StringResource(key = "feedback_hint", loader =
        stringsLoader)

    public actual val send_feedback: StringResource = StringResource(key = "send_feedback", loader =
        stringsLoader)

    public actual val feedback_thanks: StringResource = StringResource(key = "feedback_thanks",
        loader = stringsLoader)

    public actual val start_date: StringResource = StringResource(key = "start_date", loader =
        stringsLoader)

    public actual val end_date: StringResource = StringResource(key = "end_date", loader =
        stringsLoader)

    public actual val last_active: StringResource = StringResource(key = "last_active", loader =
        stringsLoader)

    public actual val save: StringResource = StringResource(key = "save", loader = stringsLoader)

    public actual val change_password: StringResource = StringResource(key = "change_password",
        loader = stringsLoader)

    public actual val current_password: StringResource = StringResource(key = "current_password",
        loader = stringsLoader)

    public actual val password_unchanged: StringResource = StringResource(key =
        "password_unchanged", loader = stringsLoader)

    public actual val timezone: StringResource = StringResource(key = "timezone", loader =
        stringsLoader)

    public actual val users_settings_desc: StringResource = StringResource(key =
        "users_settings_desc", loader = stringsLoader)

    public actual val add: StringResource = StringResource(key = "add", loader = stringsLoader)

    public actual val refresh: StringResource = StringResource(key = "refresh", loader =
        stringsLoader)

    public actual val school: StringResource = StringResource(key = "school", loader =
        stringsLoader)

    public actual val add_a_new_school: StringResource = StringResource(key = "add_a_new_school",
        loader = stringsLoader)

    public actual val edit_school: StringResource = StringResource(key = "edit_school", loader =
        stringsLoader)

    public actual val join_existing_school: StringResource = StringResource(key =
        "join_existing_school", loader = stringsLoader)

    public actual val schools: StringResource = StringResource(key = "schools", loader =
        stringsLoader)

    public actual val assignments: StringResource = StringResource(key = "assignments", loader =
        stringsLoader)

    public actual val assignment: StringResource = StringResource(key = "assignment", loader =
        stringsLoader)

    public actual val none_key: StringResource = StringResource(key = "none_key", loader =
        stringsLoader)

    public actual val instructions_for_students: StringResource = StringResource(key =
        "instructions_for_students", loader = stringsLoader)

    public actual val not_started: StringResource = StringResource(key = "not_started", loader =
        stringsLoader)

    public actual val percentage_complete: StringResource = StringResource(key =
        "percentage_complete", loader = stringsLoader)

    public actual val percentage_score: StringResource = StringResource(key = "percentage_score",
        loader = stringsLoader)

    public actual val started_date: StringResource = StringResource(key = "started_date", loader =
        stringsLoader)

    public actual val started: StringResource = StringResource(key = "started", loader =
        stringsLoader)

    public actual val mixed: StringResource = StringResource(key = "mixed", loader = stringsLoader)

    public actual val store_title: StringResource = StringResource(key = "store_title", loader =
        stringsLoader)

    public actual val store_description_short: StringResource = StringResource(key =
        "store_description_short", loader = stringsLoader)

    public actual val store_description_full: StringResource = StringResource(key =
        "store_description_full", loader = stringsLoader)

    public actual val due_date: StringResource = StringResource(key = "due_date", loader =
        stringsLoader)

    public actual val content: StringResource = StringResource(key = "content", loader =
        stringsLoader)

    public actual val add_new_content: StringResource = StringResource(key = "add_new_content",
        loader = stringsLoader)

    public actual val add_content: StringResource = StringResource(key = "add_content", loader =
        stringsLoader)

    public actual val student_progress: StringResource = StringResource(key = "student_progress",
        loader = stringsLoader)

    public actual val status: StringResource = StringResource(key = "status", loader =
        stringsLoader)

    public actual val use_device_language: StringResource = StringResource(key =
        "use_device_language", loader = stringsLoader)

    public actual val select_item: StringResource = StringResource(key = "select_item", loader =
        stringsLoader)

    public actual val from_to_date: StringResource = StringResource(key = "from_to_date", loader =
        stringsLoader)

    public actual val num_items_with_name: StringResource = StringResource(key =
        "num_items_with_name", loader = stringsLoader)

    public actual val num_items_with_name_with_comma: StringResource = StringResource(key =
        "num_items_with_name_with_comma", loader = stringsLoader)

    public actual val add_to: StringResource = StringResource(key = "add_to", loader =
        stringsLoader)

    public actual val remove_from: StringResource = StringResource(key = "remove_from", loader =
        stringsLoader)

    public actual val change_photo: StringResource = StringResource(key = "change_photo", loader =
        stringsLoader)

    public actual val remove_photo: StringResource = StringResource(key = "remove_photo", loader =
        stringsLoader)

    public actual val take_new_photo_from_camera: StringResource = StringResource(key =
        "take_new_photo_from_camera", loader = stringsLoader)

    public actual val select_new_photo_from_gallery: StringResource = StringResource(key =
        "select_new_photo_from_gallery", loader = stringsLoader)

    public actual val select_file: StringResource = StringResource(key = "select_file", loader =
        stringsLoader)

    public actual val permission_clazz_assignment_view: StringResource = StringResource(key =
        "permission_clazz_assignment_view", loader = stringsLoader)

    public actual val permission_clazz_asignment_edit: StringResource = StringResource(key =
        "permission_clazz_asignment_edit", loader = stringsLoader)

    public actual val overview: StringResource = StringResource(key = "overview", loader =
        stringsLoader)

    public actual val add_new: StringResource = StringResource(key = "add_new", loader =
        stringsLoader)

    public actual val staff: StringResource = StringResource(key = "staff", loader = stringsLoader)

    public actual val x_teachers_y_students: StringResource = StringResource(key =
        "x_teachers_y_students", loader = stringsLoader)

    public actual val call: StringResource = StringResource(key = "call", loader = stringsLoader)

    public actual val organization_id: StringResource = StringResource(key = "organization_id",
        loader = stringsLoader)

    public actual val student: StringResource = StringResource(key = "student", loader =
        stringsLoader)

    public actual val contact_details: StringResource = StringResource(key = "contact_details",
        loader = stringsLoader)

    public actual val untitled: StringResource = StringResource(key = "untitled", loader =
        stringsLoader)

    public actual val present_late_absent: StringResource = StringResource(key =
        "present_late_absent", loader = stringsLoader)

    public actual val marked_cap: StringResource = StringResource(key = "marked_cap", loader =
        stringsLoader)

    public actual val submitted_cap: StringResource = StringResource(key = "submitted_cap", loader =
        stringsLoader)

    public actual val not_submitted_cap: StringResource = StringResource(key = "not_submitted_cap",
        loader = stringsLoader)

    public actual val submitliteral: StringResource = StringResource(key = "submitliteral", loader =
        stringsLoader)

    public actual val x_percent_attended: StringResource = StringResource(key =
        "x_percent_attended", loader = stringsLoader)

    public actual val answer: StringResource = StringResource(key = "answer", loader =
        stringsLoader)

    public actual val of_content: StringResource = StringResource(key = "of_content", loader =
        stringsLoader)

    public actual val nothing_here: StringResource = StringResource(key = "nothing_here", loader =
        stringsLoader)

    public actual val not_answered: StringResource = StringResource(key = "not_answered", loader =
        stringsLoader)

    public actual val clazz_work: StringResource = StringResource(key = "clazz_work", loader =
        stringsLoader)

    public actual val add_a_new_clazzwork: StringResource = StringResource(key =
        "add_a_new_clazzwork", loader = stringsLoader)

    public actual val edit_clazzwork: StringResource = StringResource(key = "edit_clazzwork", loader
        = stringsLoader)

    public actual val clazz_assignment: StringResource = StringResource(key = "clazz_assignment",
        loader = stringsLoader)

    public actual val new_assignment: StringResource = StringResource(key = "new_assignment", loader
        = stringsLoader)

    public actual val edit_assignment: StringResource = StringResource(key = "edit_assignment",
        loader = stringsLoader)

    public actual val total_score: StringResource = StringResource(key = "total_score", loader =
        stringsLoader)

    public actual val late_submission: StringResource = StringResource(key = "late_submission",
        loader = stringsLoader)

    public actual val mark_penalty: StringResource = StringResource(key = "mark_penalty", loader =
        stringsLoader)

    public actual val late_submission_penalty: StringResource = StringResource(key =
        "late_submission_penalty", loader = stringsLoader)

    public actual val end_of_grace_period: StringResource = StringResource(key =
        "end_of_grace_period", loader = stringsLoader)

    public actual val allow_class_comments: StringResource = StringResource(key =
        "allow_class_comments", loader = stringsLoader)

    public actual val after_deadline_date_error: StringResource = StringResource(key =
        "after_deadline_date_error", loader = stringsLoader)

    public actual val points: StringResource = StringResource(key = "points", loader =
        stringsLoader)

    public actual val minimum_score: StringResource = StringResource(key = "minimum_score", loader =
        stringsLoader)

    public actual val automatic: StringResource = StringResource(key = "automatic", loader =
        stringsLoader)

    public actual val student_marks_content: StringResource = StringResource(key =
        "student_marks_content", loader = stringsLoader)

    public actual val completion_criteria: StringResource = StringResource(key =
        "completion_criteria", loader = stringsLoader)

    public actual val mark_complete: StringResource = StringResource(key = "mark_complete", loader =
        stringsLoader)

    public actual val late_penalty: StringResource = StringResource(key = "late_penalty", loader =
        stringsLoader)

    public actual val marked_key: StringResource = StringResource(key = "marked_key", loader =
        stringsLoader)

    public actual val submitted_key: StringResource = StringResource(key = "submitted_key", loader =
        stringsLoader)

    public actual val not_submitted: StringResource = StringResource(key = "not_submitted", loader =
        stringsLoader)

    public actual val visible_from_date: StringResource = StringResource(key = "visible_from_date",
        loader = stringsLoader)

    public actual val submission_type: StringResource = StringResource(key = "submission_type",
        loader = stringsLoader)

    public actual val no_submission_required: StringResource = StringResource(key =
        "no_submission_required", loader = stringsLoader)

    public actual val short_text: StringResource = StringResource(key = "short_text", loader =
        stringsLoader)

    public actual val attachment: StringResource = StringResource(key = "attachment", loader =
        stringsLoader)

    public actual val quiz: StringResource = StringResource(key = "quiz", loader = stringsLoader)

    public actual val allow_private_comments_from_students: StringResource = StringResource(key =
        "allow_private_comments_from_students", loader = stringsLoader)

    public actual val deadline: StringResource = StringResource(key = "deadline", loader =
        stringsLoader)

    public actual val class_timezone: StringResource = StringResource(key = "class_timezone", loader
        = stringsLoader)

    public actual val maximum_score: StringResource = StringResource(key = "maximum_score", loader =
        stringsLoader)

    public actual val quiz_questions: StringResource = StringResource(key = "quiz_questions", loader
        = stringsLoader)

    public actual val time: StringResource = StringResource(key = "time", loader = stringsLoader)

    public actual val submission: StringResource = StringResource(key = "submission", loader =
        stringsLoader)

    public actual val public_comments: StringResource = StringResource(key = "public_comments",
        loader = stringsLoader)

    public actual val private_comments: StringResource = StringResource(key = "private_comments",
        loader = stringsLoader)

    public actual val submit_your_answer: StringResource = StringResource(key =
        "submit_your_answer", loader = stringsLoader)

    public actual val add_class_comment: StringResource = StringResource(key = "add_class_comment",
        loader = stringsLoader)

    public actual val add_private_comment: StringResource = StringResource(key =
        "add_private_comment", loader = stringsLoader)

    public actual val class_comments: StringResource = StringResource(key = "class_comments", loader
        = stringsLoader)

    public actual val submit: StringResource = StringResource(key = "submit", loader =
        stringsLoader)

    public actual val three_num_items_with_name_with_comma: StringResource = StringResource(key =
        "three_num_items_with_name_with_comma", loader = stringsLoader)

    public actual val marking: StringResource = StringResource(key = "marking", loader =
        stringsLoader)

    public actual val return_and_mark_next: StringResource = StringResource(key =
        "return_and_mark_next", loader = stringsLoader)

    public actual val return_only: StringResource = StringResource(key = "return_only", loader =
        stringsLoader)

    public actual val workspace: StringResource = StringResource(key = "workspace", loader =
        stringsLoader)

    public actual val organisation: StringResource = StringResource(key = "organisation", loader =
        stringsLoader)

    public actual val use_public_library_site: StringResource = StringResource(key =
        "use_public_library_site", loader = stringsLoader)

    public actual val create_site: StringResource = StringResource(key = "create_site", loader =
        stringsLoader)

    public actual val connect_as_guest: StringResource = StringResource(key = "connect_as_guest",
        loader = stringsLoader)

    public actual val create_account: StringResource = StringResource(key = "create_account", loader
        = stringsLoader)

    public actual val my: StringResource = StringResource(key = "my", loader = stringsLoader)

    public actual val add_another: StringResource = StringResource(key = "add_another", loader =
        stringsLoader)

    public actual val logged_in_as: StringResource = StringResource(key = "logged_in_as", loader =
        stringsLoader)

    public actual val person_exists: StringResource = StringResource(key = "person_exists", loader =
        stringsLoader)

    public actual val incorrect_current_password: StringResource = StringResource(key =
        "incorrect_current_password", loader = stringsLoader)

    public actual val sel_question_type_free_text: StringResource = StringResource(key =
        "sel_question_type_free_text", loader = stringsLoader)

    public actual val sel_question_type_multiple_choice: StringResource = StringResource(key =
        "sel_question_type_multiple_choice", loader = stringsLoader)

    public actual val question_text: StringResource = StringResource(key = "question_text", loader =
        stringsLoader)

    public actual val question_type: StringResource = StringResource(key = "question_type", loader =
        stringsLoader)

    public actual val add_option: StringResource = StringResource(key = "add_option", loader =
        stringsLoader)

    public actual val roles_and_permissions: StringResource = StringResource(key =
        "roles_and_permissions", loader = stringsLoader)

    public actual val add_role_permission: StringResource = StringResource(key =
        "add_role_permission", loader = stringsLoader)

    public actual val scope: StringResource = StringResource(key = "scope", loader = stringsLoader)

    public actual val scope_description: StringResource = StringResource(key = "scope_description",
        loader = stringsLoader)

    public actual val role_description: StringResource = StringResource(key = "role_description",
        loader = stringsLoader)

    public actual val assign_role: StringResource = StringResource(key = "assign_role", loader =
        stringsLoader)

    public actual val more_information: StringResource = StringResource(key = "more_information",
        loader = stringsLoader)

    public actual val pending_requests: StringResource = StringResource(key = "pending_requests",
        loader = stringsLoader)

    public actual val pending: StringResource = StringResource(key = "pending", loader =
        stringsLoader)

    public actual val please_wait_for_approval: StringResource = StringResource(key =
        "please_wait_for_approval", loader = stringsLoader)

    public actual val reject: StringResource = StringResource(key = "reject", loader =
        stringsLoader)

    public actual val ask_your_teacher_for_code: StringResource = StringResource(key =
        "ask_your_teacher_for_code", loader = stringsLoader)

    public actual val copied_to_clipboard: StringResource = StringResource(key =
        "copied_to_clipboard", loader = stringsLoader)

    public actual val invite_link_desc: StringResource = StringResource(key = "invite_link_desc",
        loader = stringsLoader)

    public actual val copy_link: StringResource = StringResource(key = "copy_link", loader =
        stringsLoader)

    public actual val copy_code: StringResource = StringResource(key = "copy_code", loader =
        stringsLoader)

    public actual val share_link: StringResource = StringResource(key = "share_link", loader =
        stringsLoader)

    public actual val invite_with_link: StringResource = StringResource(key = "invite_with_link",
        loader = stringsLoader)

    public actual val role_not_selected_error: StringResource = StringResource(key =
        "role_not_selected_error", loader = stringsLoader)

    public actual val permission_person_delegate: StringResource = StringResource(key =
        "permission_person_delegate", loader = stringsLoader)

    public actual val this_field_is_mandatory: StringResource = StringResource(key =
        "this_field_is_mandatory", loader = stringsLoader)

    public actual val permission_role_select: StringResource = StringResource(key =
        "permission_role_select", loader = stringsLoader)

    public actual val permission_role_insert: StringResource = StringResource(key =
        "permission_role_insert", loader = stringsLoader)

    public actual val permission_school_select: StringResource = StringResource(key =
        "permission_school_select", loader = stringsLoader)

    public actual val permission_school_insert: StringResource = StringResource(key =
        "permission_school_insert", loader = stringsLoader)

    public actual val permission_school_update: StringResource = StringResource(key =
        "permission_school_update", loader = stringsLoader)

    public actual val add_student_to_school: StringResource = StringResource(key =
        "add_student_to_school", loader = stringsLoader)

    public actual val add_staff_to_school: StringResource = StringResource(key =
        "add_staff_to_school", loader = stringsLoader)

    public actual val permission_password_reset: StringResource = StringResource(key =
        "permission_password_reset", loader = stringsLoader)

    public actual val school_code: StringResource = StringResource(key = "school_code", loader =
        stringsLoader)

    public actual val invite_code: StringResource = StringResource(key = "invite_code", loader =
        stringsLoader)

    public actual val entity_code: StringResource = StringResource(key = "entity_code", loader =
        stringsLoader)

    public actual val join_code_instructions: StringResource = StringResource(key =
        "join_code_instructions", loader = stringsLoader)

    public actual val record_for_student: StringResource = StringResource(key =
        "record_for_student", loader = stringsLoader)

    public actual val moved_x_entries: StringResource = StringResource(key = "moved_x_entries",
        loader = stringsLoader)

    public actual val end_is_before_start_error: StringResource = StringResource(key =
        "end_is_before_start_error", loader = stringsLoader)

    public actual val record_attendance_for_most_recent_occurrence: StringResource =
        StringResource(key = "record_attendance_for_most_recent_occurrence", loader = stringsLoader)

    public actual val add_a_new_occurrence: StringResource = StringResource(key =
        "add_a_new_occurrence", loader = stringsLoader)

    public actual val active_classes: StringResource = StringResource(key = "active_classes", loader
        = stringsLoader)

    public actual val terms_and_policies: StringResource = StringResource(key =
        "terms_and_policies", loader = stringsLoader)

    public actual val manage_site_settings: StringResource = StringResource(key =
        "manage_site_settings", loader = stringsLoader)

    public actual val guest_login_enabled: StringResource = StringResource(key =
        "guest_login_enabled", loader = stringsLoader)

    public actual val registration_allowed: StringResource = StringResource(key =
        "registration_allowed", loader = stringsLoader)

    public actual val yes: StringResource = StringResource(key = "yes", loader = stringsLoader)

    public actual val no: StringResource = StringResource(key = "no", loader = stringsLoader)

    public actual val edit_site: StringResource = StringResource(key = "edit_site", loader =
        stringsLoader)

    public actual val site: StringResource = StringResource(key = "site", loader = stringsLoader)

    public actual val site_link: StringResource = StringResource(key = "site_link", loader =
        stringsLoader)

    public actual val please_enter_the_linK: StringResource = StringResource(key =
        "please_enter_the_linK", loader = stringsLoader)

    public actual val or: StringResource = StringResource(key = "or", loader = stringsLoader)

    public actual val connect: StringResource = StringResource(key = "connect", loader =
        stringsLoader)

    public actual val terms_and_policies_text: StringResource = StringResource(key =
        "terms_and_policies_text", loader = stringsLoader)

    public actual val edit_terms_and_policies: StringResource = StringResource(key =
        "edit_terms_and_policies", loader = stringsLoader)

    public actual val strings_not_allowed: StringResource = StringResource(key =
        "strings_not_allowed", loader = stringsLoader)

    public actual val undo: StringResource = StringResource(key = "undo", loader = stringsLoader)

    public actual val video: StringResource = StringResource(key = "video", loader = stringsLoader)

    public actual val content_editor_create_new_category: StringResource = StringResource(key =
        "content_editor_create_new_category", loader = stringsLoader)

    public actual val content_pieces_completed: StringResource = StringResource(key =
        "content_pieces_completed", loader = stringsLoader)

    public actual val content_usage_duration: StringResource = StringResource(key =
        "content_usage_duration", loader = stringsLoader)

    public actual val manage_download: StringResource = StringResource(key = "manage_download",
        loader = stringsLoader)

    public actual val powered_by: StringResource = StringResource(key = "powered_by", loader =
        stringsLoader)

    public actual val invalid_email: StringResource = StringResource(key = "invalid_email", loader =
        stringsLoader)

    public actual val select_account: StringResource = StringResource(key = "select_account", loader
        = stringsLoader)

    public actual val opening_link: StringResource = StringResource(key = "opening_link", loader =
        stringsLoader)

    public actual val select_account_to_continue: StringResource = StringResource(key =
        "select_account_to_continue", loader = stringsLoader)

    public actual val you_are_already_in_class: StringResource = StringResource(key =
        "you_are_already_in_class", loader = stringsLoader)

    public actual val you_are_already_in_school: StringResource = StringResource(key =
        "you_are_already_in_school", loader = stringsLoader)

    public actual val join_class: StringResource = StringResource(key = "join_class", loader =
        stringsLoader)

    public actual val join_school: StringResource = StringResource(key = "join_school", loader =
        stringsLoader)

    public actual val what_is_your_date_of_birth: StringResource = StringResource(key =
        "what_is_your_date_of_birth", loader = stringsLoader)

    public actual val parents_email_address: StringResource = StringResource(key =
        "parents_email_address", loader = stringsLoader)

    public actual val we_sent_a_message_to_your_parent: StringResource = StringResource(key =
        "we_sent_a_message_to_your_parent", loader = stringsLoader)

    public actual val toggle_visibility: StringResource = StringResource(key = "toggle_visibility",
        loader = stringsLoader)

    public actual val parental_consent: StringResource = StringResource(key = "parental_consent",
        loader = stringsLoader)

    public actual val parent_child_register_message_subject: StringResource = StringResource(key =
        "parent_child_register_message_subject", loader = stringsLoader)

    public actual val parent_child_register_message: StringResource = StringResource(key =
        "parent_child_register_message", loader = stringsLoader)

    public actual val parent_consent_explanation: StringResource = StringResource(key =
        "parent_consent_explanation", loader = stringsLoader)

    public actual val relationship: StringResource = StringResource(key = "relationship", loader =
        stringsLoader)

    public actual val i_consent: StringResource = StringResource(key = "i_consent", loader =
        stringsLoader)

    public actual val i_do_not_consent: StringResource = StringResource(key = "i_do_not_consent",
        loader = stringsLoader)

    public actual val revoke_consent: StringResource = StringResource(key = "revoke_consent", loader
        = stringsLoader)

    public actual val restore_consent: StringResource = StringResource(key = "restore_consent",
        loader = stringsLoader)

    public actual val status_consent_granted: StringResource = StringResource(key =
        "status_consent_granted", loader = stringsLoader)

    public actual val status_consent_denied: StringResource = StringResource(key =
        "status_consent_denied", loader = stringsLoader)

    public actual val other_legal_guardian: StringResource = StringResource(key =
        "other_legal_guardian", loader = stringsLoader)

    public actual val manage_parental_consent: StringResource = StringResource(key =
        "manage_parental_consent", loader = stringsLoader)

    public actual val view_class_content: StringResource = StringResource(key =
        "view_class_content", loader = stringsLoader)

    public actual val edit_class_content: StringResource = StringResource(key =
        "edit_class_content", loader = stringsLoader)

    public actual val view_class_learning_records: StringResource = StringResource(key =
        "view_class_learning_records", loader = stringsLoader)

    public actual val view_contact_details_of_members: StringResource = StringResource(key =
        "view_contact_details_of_members", loader = stringsLoader)

    public actual val edit_contact_details_of_members: StringResource = StringResource(key =
        "edit_contact_details_of_members", loader = stringsLoader)

    public actual val view_socioeconomic_details_of_members: StringResource = StringResource(key =
        "view_socioeconomic_details_of_members", loader = stringsLoader)

    public actual val edit_socioeconomic_details_of_members: StringResource = StringResource(key =
        "edit_socioeconomic_details_of_members", loader = stringsLoader)

    public actual val add_person_or_group: StringResource = StringResource(key =
        "add_person_or_group", loader = stringsLoader)

    public actual val lets_get_started: StringResource = StringResource(key = "lets_get_started",
        loader = stringsLoader)

    public actual val looks_like_installed_app_from_link: StringResource = StringResource(key =
        "looks_like_installed_app_from_link", loader = stringsLoader)

    public actual val continue_using_link_recommended: StringResource = StringResource(key =
        "continue_using_link_recommended", loader = stringsLoader)

    public actual val continue_using_the_link: StringResource = StringResource(key =
        "continue_using_the_link", loader = stringsLoader)

    public actual val start_from_scratch: StringResource = StringResource(key =
        "start_from_scratch", loader = stringsLoader)

    public actual val ignore_the_link: StringResource = StringResource(key = "ignore_the_link",
        loader = stringsLoader)

    public actual val add_permission_for_a_person: StringResource = StringResource(key =
        "add_permission_for_a_person", loader = stringsLoader)

    public actual val add_permission_for_a_group: StringResource = StringResource(key =
        "add_permission_for_a_group", loader = stringsLoader)

    public actual val add_using_link: StringResource = StringResource(key = "add_using_link", loader
        = stringsLoader)

    public actual val add_using_a_web_link_then: StringResource = StringResource(key =
        "add_using_a_web_link_then", loader = stringsLoader)

    public actual val add_from_gallery: StringResource = StringResource(key = "add_from_gallery",
        loader = stringsLoader)

    public actual val add_video_or_audio_from_the_device_gallery: StringResource =
        StringResource(key = "add_video_or_audio_from_the_device_gallery", loader = stringsLoader)

    public actual val add_file: StringResource = StringResource(key = "add_file", loader =
        stringsLoader)

    public actual val add_folder: StringResource = StringResource(key = "add_folder", loader =
        stringsLoader)

    public actual val importing: StringResource = StringResource(key = "importing", loader =
        stringsLoader)

    public actual val downloading_content: StringResource = StringResource(key =
        "downloading_content", loader = stringsLoader)

    public actual val deleting_content: StringResource = StringResource(key = "deleting_content",
        loader = stringsLoader)

    public actual val add_folder_description: StringResource = StringResource(key =
        "add_folder_description", loader = stringsLoader)

    public actual val add_educational_content_using_supported_formats_eg_pdf_xapi_h5p:
        StringResource = StringResource(key =
        "add_educational_content_using_supported_formats_eg_pdf_xapi_h5p", loader = stringsLoader)

    public actual val current_status_consent_granted: StringResource = StringResource(key =
        "current_status_consent_granted", loader = stringsLoader)

    public actual val select_country: StringResource = StringResource(key = "select_country", loader
        = stringsLoader)

    public actual val enter_link: StringResource = StringResource(key = "enter_link", loader =
        stringsLoader)

    public actual val add_subject: StringResource = StringResource(key = "add_subject", loader =
        stringsLoader)

    public actual val share_via: StringResource = StringResource(key = "share_via", loader =
        stringsLoader)

    public actual val share_apk_file: StringResource = StringResource(key = "share_apk_file", loader
        = stringsLoader)

    public actual val recommended_for_advanced_users: StringResource = StringResource(key =
        "recommended_for_advanced_users", loader = stringsLoader)

    public actual val nearby_share: StringResource = StringResource(key = "nearby_share", loader =
        stringsLoader)

    public actual val recommended_for_offline_installation: StringResource = StringResource(key =
        "recommended_for_offline_installation", loader = stringsLoader)

    public actual val app_store_link: StringResource = StringResource(key = "app_store_link", loader
        = stringsLoader)

    public actual val everything_works_offline: StringResource = StringResource(key =
        "everything_works_offline", loader = stringsLoader)

    public actual val swipe_to_next: StringResource = StringResource(key = "swipe_to_next", loader =
        stringsLoader)

    public actual val content_entries_completed: StringResource = StringResource(key =
        "content_entries_completed", loader = stringsLoader)

    public actual val content_usage_time: StringResource = StringResource(key =
        "content_usage_time", loader = stringsLoader)

    public actual val all_permissions: StringResource = StringResource(key = "all_permissions",
        loader = stringsLoader)

    public actual val folder: StringResource = StringResource(key = "folder", loader =
        stringsLoader)

    public actual val create_a_new_learning_env: StringResource = StringResource(key =
        "create_a_new_learning_env", loader = stringsLoader)

    public actual val add_educational_content_to_new_learning_env: StringResource =
        StringResource(key = "add_educational_content_to_new_learning_env", loader = stringsLoader)

    public actual val connect_to_an_existing_learning_environment: StringResource =
        StringResource(key = "connect_to_an_existing_learning_environment", loader = stringsLoader)

    public actual val does_your_class_already_have_learning_env: StringResource = StringResource(key
        = "does_your_class_already_have_learning_env", loader = stringsLoader)

    public actual val use_the_public_lib_env: StringResource = StringResource(key =
        "use_the_public_lib_env", loader = stringsLoader)

    public actual val just_want_to_browse: StringResource = StringResource(key =
        "just_want_to_browse", loader = stringsLoader)

    public actual val revoking_consent_will: StringResource = StringResource(key =
        "revoking_consent_will", loader = stringsLoader)

    public actual val you_must_comply_with_license: StringResource = StringResource(key =
        "you_must_comply_with_license", loader = stringsLoader)

    public actual val you_can_copypaste_a_link: StringResource = StringResource(key =
        "you_can_copypaste_a_link", loader = stringsLoader)

    public actual val see_supported_sites: StringResource = StringResource(key =
        "see_supported_sites", loader = stringsLoader)

    public actual val class_setup: StringResource = StringResource(key = "class_setup", loader =
        stringsLoader)

    public actual val anyone_with_this_can_join_school: StringResource = StringResource(key =
        "anyone_with_this_can_join_school", loader = stringsLoader)

    public actual val this_class: StringResource = StringResource(key = "this_class", loader =
        stringsLoader)

    public actual val from_my_classes: StringResource = StringResource(key = "from_my_classes",
        loader = stringsLoader)

    public actual val my_content: StringResource = StringResource(key = "my_content", loader =
        stringsLoader)

    public actual val learning_environment: StringResource = StringResource(key =
        "learning_environment", loader = stringsLoader)

    public actual val grades_class_age: StringResource = StringResource(key = "grades_class_age",
        loader = stringsLoader)

    public actual val add_remove_and_modify_grades: StringResource = StringResource(key =
        "add_remove_and_modify_grades", loader = stringsLoader)

    public actual val subjects: StringResource = StringResource(key = "subjects", loader =
        stringsLoader)

    public actual val add_remove_and_modify_subjects: StringResource = StringResource(key =
        "add_remove_and_modify_subjects", loader = stringsLoader)

    public actual val create_my_account: StringResource = StringResource(key = "create_my_account",
        loader = stringsLoader)

    public actual val recommend_it: StringResource = StringResource(key = "recommend_it", loader =
        stringsLoader)

    public actual val edit_permissions: StringResource = StringResource(key = "edit_permissions",
        loader = stringsLoader)

    public actual val add_from_contacts: StringResource = StringResource(key = "add_from_contacts",
        loader = stringsLoader)

    public actual val almost_done: StringResource = StringResource(key = "almost_done", loader =
        stringsLoader)

    public actual val your_username: StringResource = StringResource(key = "your_username", loader =
        stringsLoader)

    public actual val select_content: StringResource = StringResource(key = "select_content", loader
        = stringsLoader)

    public actual val search_in: StringResource = StringResource(key = "search_in", loader =
        stringsLoader)

    public actual val type: StringResource = StringResource(key = "type", loader = stringsLoader)

    public actual val please_download_the_app: StringResource = StringResource(key =
        "please_download_the_app", loader = stringsLoader)

    public actual val alternatively_you_can_download_the_apk: StringResource = StringResource(key =
        "alternatively_you_can_download_the_apk", loader = stringsLoader)

    public actual val full_i_can_download_as_much_as_i_like: StringResource = StringResource(key =
        "full_i_can_download_as_much_as_i_like", loader = stringsLoader)

    public actual val limited_i_avoid_downloading_too_much: StringResource = StringResource(key =
        "limited_i_avoid_downloading_too_much", loader = stringsLoader)

    public actual val oops: StringResource = StringResource(key = "oops", loader = stringsLoader)

    public actual val sorry_something_went_wrong: StringResource = StringResource(key =
        "sorry_something_went_wrong", loader = stringsLoader)

    public actual val incident_id: StringResource = StringResource(key = "incident_id", loader =
        stringsLoader)

    public actual val error_code: StringResource = StringResource(key = "error_code", loader =
        stringsLoader)

    public actual val take_me_home: StringResource = StringResource(key = "take_me_home", loader =
        stringsLoader)

    public actual val view_clazz: StringResource = StringResource(key = "view_clazz", loader =
        stringsLoader)

    public actual val view_clazzes: StringResource = StringResource(key = "view_clazzes", loader =
        stringsLoader)

    public actual val enrol_and_unenrol_students: StringResource = StringResource(key =
        "enrol_and_unenrol_students", loader = stringsLoader)

    public actual val enrol_and_unenrol_teachers: StringResource = StringResource(key =
        "enrol_and_unenrol_teachers", loader = stringsLoader)

    public actual val view_assignments: StringResource = StringResource(key = "view_assignments",
        loader = stringsLoader)

    public actual val add_or_edit_assignment: StringResource = StringResource(key =
        "add_or_edit_assignment", loader = stringsLoader)

    public actual val view_basic_profile_of_members: StringResource = StringResource(key =
        "view_basic_profile_of_members", loader = stringsLoader)

    public actual val edit_basic_profile_of_members: StringResource = StringResource(key =
        "edit_basic_profile_of_members", loader = stringsLoader)

    public actual val view_school: StringResource = StringResource(key = "view_school", loader =
        stringsLoader)

    public actual val edit_clazzes: StringResource = StringResource(key = "edit_clazzes", loader =
        stringsLoader)

    public actual val add_new_clazz_to_school: StringResource = StringResource(key =
        "add_new_clazz_to_school", loader = stringsLoader)

    public actual val added_to_class_content: StringResource = StringResource(key =
        "added_to_class_content", loader = stringsLoader)

    public actual val content_already_added_to_class: StringResource = StringResource(key =
        "content_already_added_to_class", loader = stringsLoader)

    public actual val your_account_needs_approved: StringResource = StringResource(key =
        "your_account_needs_approved", loader = stringsLoader)

    public actual val adult_account_required: StringResource = StringResource(key =
        "adult_account_required", loader = stringsLoader)

    public actual val blank_report: StringResource = StringResource(key = "blank_report", loader =
        stringsLoader)

    public actual val attendance_over_time_by_class: StringResource = StringResource(key =
        "attendance_over_time_by_class", loader = stringsLoader)

    public actual val percentage_of_students_attending_over_time: StringResource =
        StringResource(key = "percentage_of_students_attending_over_time", loader = stringsLoader)

    public actual val content_completion: StringResource = StringResource(key =
        "content_completion", loader = stringsLoader)

    public actual val number_of_students_completed_time: StringResource = StringResource(key =
        "number_of_students_completed_time", loader = stringsLoader)

    public actual val content_usage_by_class: StringResource = StringResource(key =
        "content_usage_by_class", loader = stringsLoader)

    public actual val total_content_usage_duration_class: StringResource = StringResource(key =
        "total_content_usage_duration_class", loader = stringsLoader)

    public actual val content_usage_over_time: StringResource = StringResource(key =
        "content_usage_over_time", loader = stringsLoader)

    public actual val total_content_duration_gender: StringResource = StringResource(key =
        "total_content_duration_gender", loader = stringsLoader)

    public actual val unique_content_users_over_time: StringResource = StringResource(key =
        "unique_content_users_over_time", loader = stringsLoader)

    public actual val number_of_active_users_over_time: StringResource = StringResource(key =
        "number_of_active_users_over_time", loader = stringsLoader)

    public actual val parent: StringResource = StringResource(key = "parent", loader =
        stringsLoader)

    public actual val upload: StringResource = StringResource(key = "upload", loader =
        stringsLoader)

    public actual val selected_file_summary: StringResource = StringResource(key =
        "selected_file_summary", loader = stringsLoader)

    public actual val drag_and_drop_or_click_to_add_file: StringResource = StringResource(key =
        "drag_and_drop_or_click_to_add_file", loader = stringsLoader)

    public actual val extra_active_tab_warning: StringResource = StringResource(key =
        "extra_active_tab_warning", loader = stringsLoader)

    public actual val saved: StringResource = StringResource(key = "saved", loader = stringsLoader)

    public actual val individual: StringResource = StringResource(key = "individual", loader =
        stringsLoader)

    public actual val group: StringResource = StringResource(key = "group", loader = stringsLoader)

    public actual val dont_show_before: StringResource = StringResource(key = "dont_show_before",
        loader = stringsLoader)

    public actual val group_submission: StringResource = StringResource(key = "group_submission",
        loader = stringsLoader)

    public actual val require_file_submission: StringResource = StringResource(key =
        "require_file_submission", loader = stringsLoader)

    public actual val require_text_submission: StringResource = StringResource(key =
        "require_text_submission", loader = stringsLoader)

    public actual val maximum: StringResource = StringResource(key = "maximum", loader =
        stringsLoader)

    public actual val size_limit: StringResource = StringResource(key = "size_limit", loader =
        stringsLoader)

    public actual val size_limit_error: StringResource = StringResource(key = "size_limit_error",
        loader = stringsLoader)

    public actual val limit: StringResource = StringResource(key = "limit", loader = stringsLoader)

    public actual val number_of_files: StringResource = StringResource(key = "number_of_files",
        loader = stringsLoader)

    public actual val edit_after_submission: StringResource = StringResource(key =
        "edit_after_submission", loader = stringsLoader)

    public actual val allowed_till_deadline: StringResource = StringResource(key =
        "allowed_till_deadline", loader = stringsLoader)

    public actual val allowed_till_grace: StringResource = StringResource(key =
        "allowed_till_grace", loader = stringsLoader)

    public actual val not_allowed: StringResource = StringResource(key = "not_allowed", loader =
        stringsLoader)

    public actual val marked_by: StringResource = StringResource(key = "marked_by", loader =
        stringsLoader)

    public actual val peers: StringResource = StringResource(key = "peers", loader = stringsLoader)

    public actual val peers_to_review: StringResource = StringResource(key = "peers_to_review",
        loader = stringsLoader)

    public actual val file_type: StringResource = StringResource(key = "file_type", loader =
        stringsLoader)

    public actual val file_type_chosen: StringResource = StringResource(key = "file_type_chosen",
        loader = stringsLoader)

    public actual val file_type_any: StringResource = StringResource(key = "file_type_any", loader =
        stringsLoader)

    public actual val file_document: StringResource = StringResource(key = "file_document", loader =
        stringsLoader)

    public actual val file_image: StringResource = StringResource(key = "file_image", loader =
        stringsLoader)

    public actual val submit_grade: StringResource = StringResource(key = "submit_grade", loader =
        stringsLoader)

    public actual val submit_grade_and_mark_next: StringResource = StringResource(key =
        "submit_grade_and_mark_next", loader = stringsLoader)

    public actual val graded: StringResource = StringResource(key = "graded", loader =
        stringsLoader)

    public actual val score_greater_than_zero: StringResource = StringResource(key =
        "score_greater_than_zero", loader = stringsLoader)

    public actual val words: StringResource = StringResource(key = "words", loader = stringsLoader)

    public actual val characters: StringResource = StringResource(key = "characters", loader =
        stringsLoader)

    public actual val class_timezone_set: StringResource = StringResource(key =
        "class_timezone_set", loader = stringsLoader)

    public actual val maximum_points: StringResource = StringResource(key = "maximum_points", loader
        = stringsLoader)

    public actual val penalty_label: StringResource = StringResource(key = "penalty_label", loader =
        stringsLoader)

    public actual val submissions: StringResource = StringResource(key = "submissions", loader =
        stringsLoader)

    public actual val add_text: StringResource = StringResource(key = "add_text", loader =
        stringsLoader)

    public actual val course_blocks: StringResource = StringResource(key = "course_blocks", loader =
        stringsLoader)

    public actual val add_block: StringResource = StringResource(key = "add_block", loader =
        stringsLoader)

    public actual val edit_block: StringResource = StringResource(key = "edit_block", loader =
        stringsLoader)

    public actual val module: StringResource = StringResource(key = "module", loader =
        stringsLoader)

    public actual val course_module: StringResource = StringResource(key = "course_module", loader =
        stringsLoader)

    public actual val formatted_text_to_show_to_course_participants: StringResource =
        StringResource(key = "formatted_text_to_show_to_course_participants", loader =
        stringsLoader)

    public actual val add_course_block_content_desc: StringResource = StringResource(key =
        "add_course_block_content_desc", loader = stringsLoader)

    public actual val add_assignment_block_content_desc: StringResource = StringResource(key =
        "add_assignment_block_content_desc", loader = stringsLoader)

    public actual val discussion_board: StringResource = StringResource(key = "discussion_board",
        loader = stringsLoader)

    public actual val add_discussion_board_desc: StringResource = StringResource(key =
        "add_discussion_board_desc", loader = stringsLoader)

    public actual val new_module: StringResource = StringResource(key = "new_module", loader =
        stringsLoader)

    public actual val edit_module: StringResource = StringResource(key = "edit_module", loader =
        stringsLoader)

    public actual val indent: StringResource = StringResource(key = "indent", loader =
        stringsLoader)

    public actual val unindent: StringResource = StringResource(key = "unindent", loader =
        stringsLoader)

    public actual val institution: StringResource = StringResource(key = "institution", loader =
        stringsLoader)

    public actual val student_enrolment_policy: StringResource = StringResource(key =
        "student_enrolment_policy", loader = stringsLoader)

    public actual val teacher_enrolment_policy: StringResource = StringResource(key =
        "teacher_enrolment_policy", loader = stringsLoader)

    public actual val open_enrolment: StringResource = StringResource(key = "open_enrolment", loader
        = stringsLoader)

    public actual val enrolment_requests_must_be_approved: StringResource = StringResource(key =
        "enrolment_requests_must_be_approved", loader = stringsLoader)

    public actual val accept_enrolment_requests_automatically: StringResource = StringResource(key =
        "accept_enrolment_requests_automatically", loader = stringsLoader)

    public actual val visibility: StringResource = StringResource(key = "visibility", loader =
        stringsLoader)

    public actual val hidden_enrolment_via_links_code_or_invitation: StringResource =
        StringResource(key = "hidden_enrolment_via_links_code_or_invitation", loader =
        stringsLoader)

    public actual val visible_for_all: StringResource = StringResource(key = "visible_for_all",
        loader = stringsLoader)

    public actual val terminology: StringResource = StringResource(key = "terminology", loader =
        stringsLoader)

    public actual val select_terminology: StringResource = StringResource(key =
        "select_terminology", loader = stringsLoader)

    public actual val add_new_terminology: StringResource = StringResource(key =
        "add_new_terminology", loader = stringsLoader)

    public actual val edit_terminology: StringResource = StringResource(key = "edit_terminology",
        loader = stringsLoader)

    public actual val your_words_for: StringResource = StringResource(key = "your_words_for", loader
        = stringsLoader)

    public actual val course_setup: StringResource = StringResource(key = "course_setup", loader =
        stringsLoader)

    public actual val number_of_groups: StringResource = StringResource(key = "number_of_groups",
        loader = stringsLoader)

    public actual val assign_to_random_groups: StringResource = StringResource(key =
        "assign_to_random_groups", loader = stringsLoader)

    public actual val add_new_groups: StringResource = StringResource(key = "add_new_groups", loader
        = stringsLoader)

    public actual val edit_groups: StringResource = StringResource(key = "edit_groups", loader =
        stringsLoader)

    public actual val num_replies: StringResource = StringResource(key = "num_replies", loader =
        stringsLoader)

    public actual val topics: StringResource = StringResource(key = "topics", loader =
        stringsLoader)

    public actual val add_topic: StringResource = StringResource(key = "add_topic", loader =
        stringsLoader)

    public actual val add_a_reply: StringResource = StringResource(key = "add_a_reply", loader =
        stringsLoader)

    public actual val messages: StringResource = StringResource(key = "messages", loader =
        stringsLoader)

    public actual val message: StringResource = StringResource(key = "message", loader =
        stringsLoader)

    public actual val library: StringResource = StringResource(key = "library", loader =
        stringsLoader)

    public actual val courses: StringResource = StringResource(key = "courses", loader =
        stringsLoader)

    public actual val course: StringResource = StringResource(key = "course", loader =
        stringsLoader)

    public actual val reports_and_analytics: StringResource = StringResource(key =
        "reports_and_analytics", loader = stringsLoader)

    public actual val course_comments: StringResource = StringResource(key = "course_comments",
        loader = stringsLoader)

    public actual val currently_enroled: StringResource = StringResource(key = "currently_enroled",
        loader = stringsLoader)

    public actual val past_enrolments: StringResource = StringResource(key = "past_enrolments",
        loader = stringsLoader)

    public actual val standard: StringResource = StringResource(key = "standard", loader =
        stringsLoader)

    public actual val group_number: StringResource = StringResource(key = "group_number", loader =
        stringsLoader)

    public actual val add_group: StringResource = StringResource(key = "add_group", loader =
        stringsLoader)

    public actual val edit_group: StringResource = StringResource(key = "edit_group", loader =
        stringsLoader)

    public actual val new_group_set: StringResource = StringResource(key = "new_group_set", loader =
        stringsLoader)

    public actual val edit_text: StringResource = StringResource(key = "edit_text", loader =
        stringsLoader)

    public actual val add_module: StringResource = StringResource(key = "add_module", loader =
        stringsLoader)

    public actual val add_a_new_course: StringResource = StringResource(key = "add_a_new_course",
        loader = stringsLoader)

    public actual val join_existing_course: StringResource = StringResource(key =
        "join_existing_course", loader = stringsLoader)

    public actual val edit_course: StringResource = StringResource(key = "edit_course", loader =
        stringsLoader)

    public actual val currently_enrolled: StringResource = StringResource(key =
        "currently_enrolled", loader = stringsLoader)

    public actual val past_enrollments: StringResource = StringResource(key = "past_enrollments",
        loader = stringsLoader)

    public actual val unread: StringResource = StringResource(key = "unread", loader =
        stringsLoader)

    public actual val new_chat: StringResource = StringResource(key = "new_chat", loader =
        stringsLoader)

    public actual val new_group: StringResource = StringResource(key = "new_group", loader =
        stringsLoader)

    public actual val chat: StringResource = StringResource(key = "chat", loader = stringsLoader)

    public actual val you: StringResource = StringResource(key = "you", loader = stringsLoader)

    public actual val edit_topic: StringResource = StringResource(key = "edit_topic", loader =
        stringsLoader)

    public actual val posts: StringResource = StringResource(key = "posts", loader = stringsLoader)

    public actual val post: StringResource = StringResource(key = "post", loader = stringsLoader)

    public actual val individual_submission: StringResource = StringResource(key =
        "individual_submission", loader = stringsLoader)

    public actual val unassigned: StringResource = StringResource(key = "unassigned", loader =
        stringsLoader)

    public actual val error_too_long_text: StringResource = StringResource(key =
        "error_too_long_text", loader = stringsLoader)

    public actual val managed_enrolment: StringResource = StringResource(key = "managed_enrolment",
        loader = stringsLoader)

    public actual val text_file_submission_error: StringResource = StringResource(key =
        "text_file_submission_error", loader = stringsLoader)

    public actual val submit_all_at_once_submission_policy: StringResource = StringResource(key =
        "submit_all_at_once_submission_policy", loader = stringsLoader)

    public actual val multiple_submission_allowed_submission_policy: StringResource =
        StringResource(key = "multiple_submission_allowed_submission_policy", loader =
        stringsLoader)

    public actual val enrolment_policy: StringResource = StringResource(key = "enrolment_policy",
        loader = stringsLoader)

    public actual val submission_policy: StringResource = StringResource(key = "submission_policy",
        loader = stringsLoader)

    public actual val submission_already_made: StringResource = StringResource(key =
        "submission_already_made", loader = stringsLoader)

    public actual val grade_out_of_range: StringResource = StringResource(key =
        "grade_out_of_range", loader = stringsLoader)

    public actual val update_grade: StringResource = StringResource(key = "update_grade", loader =
        stringsLoader)

    public actual val update_grade_and_mark_next: StringResource = StringResource(key =
        "update_grade_and_mark_next", loader = stringsLoader)

    public actual val add_discussion: StringResource = StringResource(key = "add_discussion", loader
        = stringsLoader)

    public actual val edit_discussion: StringResource = StringResource(key = "edit_discussion",
        loader = stringsLoader)

    public actual val type_here: StringResource = StringResource(key = "type_here", loader =
        stringsLoader)

    public actual val deadline_has_passed: StringResource = StringResource(key =
        "deadline_has_passed", loader = stringsLoader)

    public actual val already_submitted: StringResource = StringResource(key = "already_submitted",
        loader = stringsLoader)

    public actual val exceeds_word_limit: StringResource = StringResource(key =
        "exceeds_word_limit", loader = stringsLoader)

    public actual val exceeds_char_limit: StringResource = StringResource(key =
        "exceeds_char_limit", loader = stringsLoader)

    public actual val unassigned_error: StringResource = StringResource(key = "unassigned_error",
        loader = stringsLoader)

    public actual val enabled: StringResource = StringResource(key = "enabled", loader =
        stringsLoader)

    public actual val disabled: StringResource = StringResource(key = "disabled", loader =
        stringsLoader)

    public actual val from_my_courses: StringResource = StringResource(key = "from_my_courses",
        loader = stringsLoader)

    public actual val assign_reviewers: StringResource = StringResource(key = "assign_reviewers",
        loader = stringsLoader)

    public actual val reviews_per_user_group: StringResource = StringResource(key =
        "reviews_per_user_group", loader = stringsLoader)

    public actual val assign_random_reviewers: StringResource = StringResource(key =
        "assign_random_reviewers", loader = stringsLoader)

    public actual val reviewer: StringResource = StringResource(key = "reviewer", loader =
        stringsLoader)

    public actual val app_language: StringResource = StringResource(key = "app_language", loader =
        stringsLoader)

    public actual val pdf: StringResource = StringResource(key = "pdf", loader = stringsLoader)

    public actual val panic_button_app: StringResource = StringResource(key = "panic_button_app",
        loader = stringsLoader)

    public actual val actions_to_be_taken_in_case_of_emergency: StringResource = StringResource(key
        = "actions_to_be_taken_in_case_of_emergency", loader = stringsLoader)

    public actual val exit_app: StringResource = StringResource(key = "exit_app", loader =
        stringsLoader)

    public actual val delete_app_data_from_device: StringResource = StringResource(key =
        "delete_app_data_from_device", loader = stringsLoader)

    public actual val more_options: StringResource = StringResource(key = "more_options", loader =
        stringsLoader)

    public actual val num_holidays: StringResource = StringResource(key = "num_holidays", loader =
        stringsLoader)

    public actual val hide_app: StringResource = StringResource(key = "hide_app", loader =
        stringsLoader)

    public actual val panic_button_explanation: StringResource = StringResource(key =
        "panic_button_explanation", loader = stringsLoader)

    public actual val hide_app_explanation: StringResource = StringResource(key =
        "hide_app_explanation", loader = stringsLoader)

    public actual val most_recent: StringResource = StringResource(key = "most_recent", loader =
        stringsLoader)

    public actual val comment: StringResource = StringResource(key = "comment", loader =
        stringsLoader)

    public actual val grant_app_permission: StringResource = StringResource(key =
        "grant_app_permission", loader = stringsLoader)

    public actual val this_app_will_receive: StringResource = StringResource(key =
        "this_app_will_receive", loader = stringsLoader)

    public actual val my_profile: StringResource = StringResource(key = "my_profile", loader =
        stringsLoader)

    public actual val add_another_account: StringResource = StringResource(key =
        "add_another_account", loader = stringsLoader)

    public actual val end_is_before_start: StringResource = StringResource(key =
        "end_is_before_start", loader = stringsLoader)

    public actual val select_person: StringResource = StringResource(key = "select_person", loader =
        stringsLoader)

    public actual val your_submission: StringResource = StringResource(key = "your_submission",
        loader = stringsLoader)

    public actual val edit_content: StringResource = StringResource(key = "edit_content", loader =
        stringsLoader)

    public actual val edit_folder: StringResource = StringResource(key = "edit_folder", loader =
        stringsLoader)

    public actual val add_new_post: StringResource = StringResource(key = "add_new_post", loader =
        stringsLoader)

    public actual val grades_scoring: StringResource = StringResource(key = "grades_scoring", loader
        = stringsLoader)

    public actual val mark_comment: StringResource = StringResource(key = "mark_comment", loader =
        stringsLoader)

    public actual val mark: StringResource = StringResource(key = "mark", loader = stringsLoader)

    public actual val too_high: StringResource = StringResource(key = "too_high", loader =
        stringsLoader)

    public actual val required: StringResource = StringResource(key = "required", loader =
        stringsLoader)

    public actual val expand: StringResource = StringResource(key = "expand", loader =
        stringsLoader)

    public actual val collapse: StringResource = StringResource(key = "collapse", loader =
        stringsLoader)

    public actual val remove: StringResource = StringResource(key = "remove", loader =
        stringsLoader)

    public actual val table_of_contents: StringResource = StringResource(key = "table_of_contents",
        loader = stringsLoader)

    public actual val select_date: StringResource = StringResource(key = "select_date", loader =
        stringsLoader)

    public actual val link: StringResource = StringResource(key = "link", loader = stringsLoader)

    public actual val time_submitted: StringResource = StringResource(key = "time_submitted", loader
        = stringsLoader)

    public actual val group_assignment: StringResource = StringResource(key = "group_assignment",
        loader = stringsLoader)

    public actual val menu: StringResource = StringResource(key = "menu", loader = stringsLoader)

    public actual val restarting: StringResource = StringResource(key = "restarting", loader =
        stringsLoader)

    public actual val terms_required_if_registration_enabled: StringResource = StringResource(key =
        "terms_required_if_registration_enabled", loader = stringsLoader)

    public actual val drop_files_to_import: StringResource = StringResource(key =
        "drop_files_to_import", loader = stringsLoader)

    public actual val compose_post: StringResource = StringResource(key = "compose_post", loader =
        stringsLoader)

    public actual val password_updated: StringResource = StringResource(key = "password_updated",
        loader = stringsLoader)

    public actual val html5_content_display_engine: StringResource = StringResource(key =
        "html5_content_display_engine", loader = stringsLoader)

    public actual val chrome_recommended: StringResource = StringResource(key =
        "chrome_recommended", loader = stringsLoader)

    public actual val chrome_recommended_info: StringResource = StringResource(key =
        "chrome_recommended_info", loader = stringsLoader)

    public actual val internal_webview: StringResource = StringResource(key = "internal_webview",
        loader = stringsLoader)

    public actual val internal_webview_info: StringResource = StringResource(key =
        "internal_webview_info", loader = stringsLoader)

    public actual val content_not_ready_try_later: StringResource = StringResource(key =
        "content_not_ready_try_later", loader = stringsLoader)

    public actual val version: StringResource = StringResource(key = "version", loader =
        stringsLoader)

    public actual val licenses: StringResource = StringResource(key = "licenses", loader =
        stringsLoader)

    public actual val cannot_record_attendance_for_future_date_time: StringResource =
        StringResource(key = "cannot_record_attendance_for_future_date_time", loader =
        stringsLoader)

    public actual val could_not_load_vlc: StringResource = StringResource(key =
        "could_not_load_vlc", loader = stringsLoader)

    public actual val selected: StringResource = StringResource(key = "selected", loader =
        stringsLoader)

    public actual val clear_selection: StringResource = StringResource(key = "clear_selection",
        loader = stringsLoader)

    public actual val move_entries_to_this_folder: StringResource = StringResource(key =
        "move_entries_to_this_folder", loader = stringsLoader)

    public actual val cannot_move_to_subfolder_of_self: StringResource = StringResource(key =
        "cannot_move_to_subfolder_of_self", loader = stringsLoader)

    public actual val cannot_move_already_in_same_folder: StringResource = StringResource(key =
        "cannot_move_already_in_same_folder", loader = stringsLoader)

    public actual val move_to: StringResource = StringResource(key = "move_to", loader =
        stringsLoader)

    public actual val restore: StringResource = StringResource(key = "restore", loader =
        stringsLoader)

    public actual val delete_permanently: StringResource = StringResource(key =
        "delete_permanently", loader = stringsLoader)

    public actual val deleted_items: StringResource = StringResource(key = "deleted_items", loader =
        stringsLoader)

    public actual val delete_or_restore_items: StringResource = StringResource(key =
        "delete_or_restore_items", loader = stringsLoader)

    public actual val yesterday: StringResource = StringResource(key = "yesterday", loader =
        stringsLoader)

    public actual val request_to_enrol_already_pending: StringResource = StringResource(key =
        "request_to_enrol_already_pending", loader = stringsLoader)

    public actual val request_submitted: StringResource = StringResource(key = "request_submitted",
        loader = stringsLoader)

    public actual val canceled_enrolment_request: StringResource = StringResource(key =
        "canceled_enrolment_request", loader = stringsLoader)

    public actual val enroled_into_name: StringResource = StringResource(key = "enroled_into_name",
        loader = stringsLoader)

    public actual val declined_request_from_name: StringResource = StringResource(key =
        "declined_request_from_name", loader = stringsLoader)

    public actual val view_course: StringResource = StringResource(key = "view_course", loader =
        stringsLoader)

    public actual val moderate: StringResource = StringResource(key = "moderate", loader =
        stringsLoader)

    public actual val manage_student_enrolments: StringResource = StringResource(key =
        "manage_student_enrolments", loader = stringsLoader)

    public actual val manage_teacher_enrolments: StringResource = StringResource(key =
        "manage_teacher_enrolments", loader = stringsLoader)

    public actual val view_learning_records: StringResource = StringResource(key =
        "view_learning_records", loader = stringsLoader)

    public actual val edit_learning_records: StringResource = StringResource(key =
        "edit_learning_records", loader = stringsLoader)

    public actual val view_members: StringResource = StringResource(key = "view_members", loader =
        stringsLoader)

    public actual val copy_invite_code: StringResource = StringResource(key = "copy_invite_code",
        loader = stringsLoader)

    public actual val direct_enrol_users_onto_courses: StringResource = StringResource(key =
        "direct_enrol_users_onto_courses", loader = stringsLoader)

    public actual val could_not_restart: StringResource = StringResource(key = "could_not_restart",
        loader = stringsLoader)

    public actual val close_now: StringResource = StringResource(key = "close_now", loader =
        stringsLoader)

    public actual val view_all_courses: StringResource = StringResource(key = "view_all_courses",
        loader = stringsLoader)

    public actual val edit_all_courses: StringResource = StringResource(key = "edit_all_courses",
        loader = stringsLoader)

    public actual val moderate_all_courses: StringResource = StringResource(key =
        "moderate_all_courses", loader = stringsLoader)

    public actual val manage_student_enrolments_for_all_courses: StringResource = StringResource(key
        = "manage_student_enrolments_for_all_courses", loader = stringsLoader)

    public actual val manage_teacher_enrolments_for_all_courses: StringResource = StringResource(key
        = "manage_teacher_enrolments_for_all_courses", loader = stringsLoader)

    public actual val view_attendance_records_for_all_courses: StringResource = StringResource(key =
        "view_attendance_records_for_all_courses", loader = stringsLoader)

    public actual val edit_attendance_records_for_all_courses: StringResource = StringResource(key =
        "edit_attendance_records_for_all_courses", loader = stringsLoader)

    public actual val add_new_users: StringResource = StringResource(key = "add_new_users", loader =
        stringsLoader)

    public actual val view_all_users: StringResource = StringResource(key = "view_all_users", loader
        = stringsLoader)

    public actual val edit_all_users: StringResource = StringResource(key = "edit_all_users", loader
        = stringsLoader)

    public actual val view_learning_records_for_all_courses: StringResource = StringResource(key =
        "view_learning_records_for_all_courses", loader = stringsLoader)

    public actual val edit_learning_records_for_all_courses: StringResource = StringResource(key =
        "edit_learning_records_for_all_courses", loader = stringsLoader)

    public actual val add_new_courses: StringResource = StringResource(key = "add_new_courses",
        loader = stringsLoader)

    public actual val manage_user_permissions: StringResource = StringResource(key =
        "manage_user_permissions", loader = stringsLoader)

    public actual val reset_passwords: StringResource = StringResource(key = "reset_passwords",
        loader = stringsLoader)

    public actual val upload_failed: StringResource = StringResource(key = "upload_failed", loader =
        stringsLoader)

    public actual val opening_name: StringResource = StringResource(key = "opening_name", loader =
        stringsLoader)

    public actual val invalid_invite_code: StringResource = StringResource(key =
        "invalid_invite_code", loader = stringsLoader)

    public actual val bulk_import: StringResource = StringResource(key = "bulk_import", loader =
        stringsLoader)

    public actual val import_key: StringResource = StringResource(key = "import_key", loader =
        stringsLoader)

    public actual val imported: StringResource = StringResource(key = "imported", loader =
        stringsLoader)

    public actual val you_can_import_users_using_a_csv_file: StringResource = StringResource(key =
        "you_can_import_users_using_a_csv_file", loader = stringsLoader)

    public actual val get_template: StringResource = StringResource(key = "get_template", loader =
        stringsLoader)

    public actual val line_number: StringResource = StringResource(key = "line_number", loader =
        stringsLoader)

    public actual val selected_content: StringResource = StringResource(key = "selected_content",
        loader = stringsLoader)

    public actual val edit_content_block: StringResource = StringResource(key =
        "edit_content_block", loader = stringsLoader)

    public actual val compression: StringResource = StringResource(key = "compression", loader =
        stringsLoader)

    public actual val compression_highest: StringResource = StringResource(key =
        "compression_highest", loader = stringsLoader)

    public actual val compression_high: StringResource = StringResource(key = "compression_high",
        loader = stringsLoader)

    public actual val compression_medium: StringResource = StringResource(key =
        "compression_medium", loader = stringsLoader)

    public actual val compression_low: StringResource = StringResource(key = "compression_low",
        loader = stringsLoader)

    public actual val compression_lowest: StringResource = StringResource(key =
        "compression_lowest", loader = stringsLoader)

    public actual val compression_none: StringResource = StringResource(key = "compression_none",
        loader = stringsLoader)

    public actual val size_compressed_was: StringResource = StringResource(key =
        "size_compressed_was", loader = stringsLoader)

    public actual val size: StringResource = StringResource(key = "size", loader = stringsLoader)

    public actual val import_error: StringResource = StringResource(key = "import_error", loader =
        stringsLoader)

    public actual val upload_error: StringResource = StringResource(key = "upload_error", loader =
        stringsLoader)

    public actual val offline_items_storage: StringResource = StringResource(key =
        "offline_items_storage", loader = stringsLoader)

    public actual val space_available: StringResource = StringResource(key = "space_available",
        loader = stringsLoader)

    override fun values(): List<StringResource> = listOf(app_name, blank, login, error,
        err_registering_new_user, register_empty_fields, register_incorrect_email, group_activity,
        select_group_members, confirm_delete_message, wrong_user_pass_combo, login_network_error,
        download, downloaded, recycled, libraries, pause_download, ok, confirm, cancel, canceled,
        delete, deleted, new_tab, loading, deleting, processing, uploading, error_opening_file,
        logout, about, download_all, next, previous, back, username, password, confirm_password,
        last_name, first_name, register_now, new_password, phone_memory, memory_card, device,
        register, phone_number, name_key, description, male, female, other, unset, settings,
        language, add_a_new_language, add_new_language, select_language, languages, edit_language,
        two_letter_code, three_letter_code, languages_description, advanced, optional, email,
        regcode, home, phone, attendance, classes, exams, reports, choose_template,
        save_as_template, contents, students, add_a_student, forgot_password, notifications,
        account, accounts, share_application, share, send, open, downloading, waiting,
        waiting_for_connection, queued, failed, passed, success, incomplete, completed,
        select_subtitle_video, no_subtitle, no_video_file_found, content_from_file,
        content_from_link, import_from_file, import_from_link, import_content, update_content,
        publicly_accessible, title, licence, ebook, interactive, collection, document, article,
        audio, move, hide, unhide, compress, and_key, added, attempts, add_link_description,
        add_gallery_description, add_file_description,
        error_this_device_doesnt_support_bluetooth_sharing, share_offline_zip_checkbox_label,
        share_offline_dialog_message, preparing, fixed_date, relative_date, field_required_prompt,
        field_password_error_min, filed_password_no_match, update, profile,
        dialog_download_from_playstore_message, dialog_download_from_playstore_ok,
        dialog_download_from_playstore_cancel, accept, also_available_in, created_partnership,
        onboarding_get_started_label, lets_get_started_label, onboarding_headline1,
        onboarding_subheadline1, onboarding_headline2, onboarding_subheadline2,
        onboarding_headline3, onboarding_subheadline3, download_continue_stacked_label,
        download_continue_btn_label, download_wifi_only, download_calculating,
        download_state_download, download_state_downloading, download_state_downloaded,
        download_downloading_placeholder, download_summary_title, download_cancel_label,
        download_pause_download, download_storage_option_device, download_locally_availability,
        download_cloud_availability, insufficient_space, download_entry_state_paused, get_app,
        no_app_found, entry_details_author, entry_details_publisher, entry_details_license,
        file_not_found, multiple_choice, preview, content_creation_storage_option_title,
        file_required_prompt, content_creation_folder_new_message,
        content_creation_folder_update_message, error_message_load_page,
        error_message_update_document, supported_files, content_editor_save_error,
        content_entry_export_inprogress, content_entry_export_message, show_hidden_items,
        open_folder, action_hidden, invalid, enter_register_code, bar_chart, line_chart, score,
        content_total_duration, content_average_duration, count_session, interaction_recorded,
        number_active_users, average_usage_time_per_user, number_students_completed,
        percent_students_completed, total_attendances, total_absences, total_lates,
        percent_students_attended, percent_students_attended_or_late, total_number_of_classes,
        number_unique_students_attending, xapi_day, xapi_week, xapi_month, xapi_custom_date,
        xapi_content_entry, xapi_clear, last_week_date_range, last_two_week_date_range,
        last_month_date_range, last_three_months_date_range, new_custom_date_range,
        selected_custom_range, time_range, year, from, done, xapi_options_general,
        xapi_options_report_title, xapi_options_visual_type, xapi_options_data_set,
        xapi_options_series, xapi_options_axes, xapi_options_y_axes, xapi_options_x_axes,
        xapi_options_subgroup, xapi_options_filters, xapi_options_who, xapi_options_did,
        xapi_options_what, xapi_options_when, xapi_options_where, time_range_all,
        report_filter_edit_field, report_filter_edit_condition, report_filter_edit_values, report,
        create_a_new_report, edit_report, verb, add_a_new_verb, id_verbentity_urlid, edit_filters,
        field_person_gender, field_person_age, field_content_completion, field_content_entry,
        field_content_progress, field_attendance_percentage, condition_is, condition_is_not,
        condition_greater_than, condition_less_than, condition_in_list, condition_not_in_list,
        condition_between, add_person_filter, add_verb_filter, add_content_filter, dashboard,
        activity_import_link, xapi_hours, xapi_minutes, xapi_seconds, xapi_verb_header,
        xapi_result_header, xapi_score, import_title_not_entered, no_account, enter_url,
        invalid_link, invalid_file, import_link_content_not_supported, unsupported_file_type,
        import_link_big_size, import_link_error, supported_link,
        offline_sharing_enable_bluetooth_prompt, offline_sharing_enable_wifi_promot, enable,
        repo_loading_status_loading_cloud, repo_loading_status_loading_mirror,
        repo_loading_status_failed_noconnection, repo_loading_status_failed_connection_error,
        licence_type_cc_by, licence_type_cc_by_sa, licence_type_cc_by_sa_nc, licence_type_cc_by_nc,
        licence_type_cc_by_nc_sa, licence_type_public_domain, licence_type_all_rights,
        license_type_cc_0, syncing, feed, people, record_attendance, today, sort_by, log, schedule,
        add_a_schedule, edit_schedule, first_key, last_key, last_week, mark_all, mark_all_present,
        mark_all_absent, present, absent, attendance_report, father, mother, birthday, first_names,
        basic_details, address, add_person_to_class, edit, clazz, add_a_new_class, edit_clazz,
        add_a_class, join_existing_class, student_image, question_key, add_question, edit_question,
        questions, add_person, add_class, class_name, class_description, to_key, holiday,
        add_a_holiday, edit_holiday, holidays, holiday_calendar, add_a_new_holiday_calendar,
        edit_holiday_calendar, features_enabled, weekly, once, daily, monthly, yearly, sunday,
        monday, tuesday, wednesday, thursday, friday, saturday, day, toC, last_month,
        activity_change, change_implemented_if_any, duration, how_did_it_go, notes, activity,
        sort_by_name, sort_by_name_asc, sort_by_name_desc, date_enroll, date_left, enroled,
        class_enrolment, graduated, dropped_out, moved, new_leaving_reason, edit_leaving_reason,
        edit_enrolment, new_enrolment, select_leaving_reason, add_leaving_reason, leaving_reason,
        leaving_reasons, leaving_reason_manage, view_profile, class_enrolment_outcome,
        class_enrolment_leaving, in_progress, outcome, person_enrolment_in_class, enrolment,
        error_start_date_before_clazz_date, error_start_date_before_previous_enrolment_date,
        time_present, ascending, descending, sort_by_text, attendance_high_to_low,
        attendance_low_to_high, teacher, add_a_teacher, partial, teachers_literal, participant,
        primary_user, up_key, down_key, activity_went_good, activity_went_bad,
        activity_enter_any_notes, uom_frequency_title, uom_duration_title, uom_boolean_title,
        uom_default_title, add_activity, add_activity_literal, select_one, measurement_type,
        frequency, yes_no, time_period, location, last_three_months, export, date, average, age,
        days, apply, file_selected, all, holiday_calendars, holiday_calendars_desc,
        sel_question_set, sel_question_set_desc, users, nomination, active, class_id,
        gender_literal, country, connectivity, home_internet_access, mobile_internet_access,
        connectivity_limited, connectivity_full, prefer_not_to_say, sel_question_set_to_use, search,
        attendance_percentage, filter, over_key, sync, not_recorded, groups, group_setting_desc,
        roles, role, add_a_new_role, edit_role, rols_assignment, role_assignment_setting_desc,
        locations, locations_setting_desc, audit_log, audit_log_setting_desc, entry_key,
        permission_key, permissions, role_name, members_key, member_key, role_assignment, scope_by,
        assign_to, person, add_a_new_person, edit_person, permission_person_select,
        permission_person_insert, permission_person_update, permission_person_picture_select,
        permission_person_picture_insert, permission_person_picture_update, permission_clazz_select,
        permission_clazz_insert, permission_clazz_update, permission_clazz_add_teacher,
        permission_clazz_add_student, permission_attendance_select, permission_attendance_insert,
        permission_attendance_update, permission_activity_select, permission_activity_insert,
        permission_activity_update, permission_sel_question_select, permission_sel_question_insert,
        permission_sel_question_update, permission_sel_select, permission_sel_update, custom_fields,
        custom_field, custom_fields_desc, field_type, text, dropdown, options, option_value, errors,
        shake_feedback, feedback_hint, send_feedback, feedback_thanks, start_date, end_date,
        last_active, save, change_password, current_password, password_unchanged, timezone,
        users_settings_desc, add, refresh, school, add_a_new_school, edit_school,
        join_existing_school, schools, assignments, assignment, none_key, instructions_for_students,
        not_started, percentage_complete, percentage_score, started_date, started, mixed,
        store_title, store_description_short, store_description_full, due_date, content,
        add_new_content, add_content, student_progress, status, use_device_language, select_item,
        from_to_date, num_items_with_name, num_items_with_name_with_comma, add_to, remove_from,
        change_photo, remove_photo, take_new_photo_from_camera, select_new_photo_from_gallery,
        select_file, permission_clazz_assignment_view, permission_clazz_asignment_edit, overview,
        add_new, staff, x_teachers_y_students, call, organization_id, student, contact_details,
        untitled, present_late_absent, marked_cap, submitted_cap, not_submitted_cap, submitliteral,
        x_percent_attended, answer, of_content, nothing_here, not_answered, clazz_work,
        add_a_new_clazzwork, edit_clazzwork, clazz_assignment, new_assignment, edit_assignment,
        total_score, late_submission, mark_penalty, late_submission_penalty, end_of_grace_period,
        allow_class_comments, after_deadline_date_error, points, minimum_score, automatic,
        student_marks_content, completion_criteria, mark_complete, late_penalty, marked_key,
        submitted_key, not_submitted, visible_from_date, submission_type, no_submission_required,
        short_text, attachment, quiz, allow_private_comments_from_students, deadline,
        class_timezone, maximum_score, quiz_questions, time, submission, public_comments,
        private_comments, submit_your_answer, add_class_comment, add_private_comment,
        class_comments, submit, three_num_items_with_name_with_comma, marking, return_and_mark_next,
        return_only, workspace, organisation, use_public_library_site, create_site,
        connect_as_guest, create_account, my, add_another, logged_in_as, person_exists,
        incorrect_current_password, sel_question_type_free_text, sel_question_type_multiple_choice,
        question_text, question_type, add_option, roles_and_permissions, add_role_permission, scope,
        scope_description, role_description, assign_role, more_information, pending_requests,
        pending, please_wait_for_approval, reject, ask_your_teacher_for_code, copied_to_clipboard,
        invite_link_desc, copy_link, copy_code, share_link, invite_with_link,
        role_not_selected_error, permission_person_delegate, this_field_is_mandatory,
        permission_role_select, permission_role_insert, permission_school_select,
        permission_school_insert, permission_school_update, add_student_to_school,
        add_staff_to_school, permission_password_reset, school_code, invite_code, entity_code,
        join_code_instructions, record_for_student, moved_x_entries, end_is_before_start_error,
        record_attendance_for_most_recent_occurrence, add_a_new_occurrence, active_classes,
        terms_and_policies, manage_site_settings, guest_login_enabled, registration_allowed, yes,
        no, edit_site, site, site_link, please_enter_the_linK, or, connect, terms_and_policies_text,
        edit_terms_and_policies, strings_not_allowed, undo, video,
        content_editor_create_new_category, content_pieces_completed, content_usage_duration,
        manage_download, powered_by, invalid_email, select_account, opening_link,
        select_account_to_continue, you_are_already_in_class, you_are_already_in_school, join_class,
        join_school, what_is_your_date_of_birth, parents_email_address,
        we_sent_a_message_to_your_parent, toggle_visibility, parental_consent,
        parent_child_register_message_subject, parent_child_register_message,
        parent_consent_explanation, relationship, i_consent, i_do_not_consent, revoke_consent,
        restore_consent, status_consent_granted, status_consent_denied, other_legal_guardian,
        manage_parental_consent, view_class_content, edit_class_content,
        view_class_learning_records, view_contact_details_of_members,
        edit_contact_details_of_members, view_socioeconomic_details_of_members,
        edit_socioeconomic_details_of_members, add_person_or_group, lets_get_started,
        looks_like_installed_app_from_link, continue_using_link_recommended,
        continue_using_the_link, start_from_scratch, ignore_the_link, add_permission_for_a_person,
        add_permission_for_a_group, add_using_link, add_using_a_web_link_then, add_from_gallery,
        add_video_or_audio_from_the_device_gallery, add_file, add_folder, importing,
        downloading_content, deleting_content, add_folder_description,
        add_educational_content_using_supported_formats_eg_pdf_xapi_h5p,
        current_status_consent_granted, select_country, enter_link, add_subject, share_via,
        share_apk_file, recommended_for_advanced_users, nearby_share,
        recommended_for_offline_installation, app_store_link, everything_works_offline,
        swipe_to_next, content_entries_completed, content_usage_time, all_permissions, folder,
        create_a_new_learning_env, add_educational_content_to_new_learning_env,
        connect_to_an_existing_learning_environment, does_your_class_already_have_learning_env,
        use_the_public_lib_env, just_want_to_browse, revoking_consent_will,
        you_must_comply_with_license, you_can_copypaste_a_link, see_supported_sites, class_setup,
        anyone_with_this_can_join_school, this_class, from_my_classes, my_content,
        learning_environment, grades_class_age, add_remove_and_modify_grades, subjects,
        add_remove_and_modify_subjects, create_my_account, recommend_it, edit_permissions,
        add_from_contacts, almost_done, your_username, select_content, search_in, type,
        please_download_the_app, alternatively_you_can_download_the_apk,
        full_i_can_download_as_much_as_i_like, limited_i_avoid_downloading_too_much, oops,
        sorry_something_went_wrong, incident_id, error_code, take_me_home, view_clazz, view_clazzes,
        enrol_and_unenrol_students, enrol_and_unenrol_teachers, view_assignments,
        add_or_edit_assignment, view_basic_profile_of_members, edit_basic_profile_of_members,
        view_school, edit_clazzes, add_new_clazz_to_school, added_to_class_content,
        content_already_added_to_class, your_account_needs_approved, adult_account_required,
        blank_report, attendance_over_time_by_class, percentage_of_students_attending_over_time,
        content_completion, number_of_students_completed_time, content_usage_by_class,
        total_content_usage_duration_class, content_usage_over_time, total_content_duration_gender,
        unique_content_users_over_time, number_of_active_users_over_time, parent, upload,
        selected_file_summary, drag_and_drop_or_click_to_add_file, extra_active_tab_warning, saved,
        individual, group, dont_show_before, group_submission, require_file_submission,
        require_text_submission, maximum, size_limit, size_limit_error, limit, number_of_files,
        edit_after_submission, allowed_till_deadline, allowed_till_grace, not_allowed, marked_by,
        peers, peers_to_review, file_type, file_type_chosen, file_type_any, file_document,
        file_image, submit_grade, submit_grade_and_mark_next, graded, score_greater_than_zero,
        words, characters, class_timezone_set, maximum_points, penalty_label, submissions, add_text,
        course_blocks, add_block, edit_block, module, course_module,
        formatted_text_to_show_to_course_participants, add_course_block_content_desc,
        add_assignment_block_content_desc, discussion_board, add_discussion_board_desc, new_module,
        edit_module, indent, unindent, institution, student_enrolment_policy,
        teacher_enrolment_policy, open_enrolment, enrolment_requests_must_be_approved,
        accept_enrolment_requests_automatically, visibility,
        hidden_enrolment_via_links_code_or_invitation, visible_for_all, terminology,
        select_terminology, add_new_terminology, edit_terminology, your_words_for, course_setup,
        number_of_groups, assign_to_random_groups, add_new_groups, edit_groups, num_replies, topics,
        add_topic, add_a_reply, messages, message, library, courses, course, reports_and_analytics,
        course_comments, currently_enroled, past_enrolments, standard, group_number, add_group,
        edit_group, new_group_set, edit_text, add_module, add_a_new_course, join_existing_course,
        edit_course, currently_enrolled, past_enrollments, unread, new_chat, new_group, chat, you,
        edit_topic, posts, post, individual_submission, unassigned, error_too_long_text,
        managed_enrolment, text_file_submission_error, submit_all_at_once_submission_policy,
        multiple_submission_allowed_submission_policy, enrolment_policy, submission_policy,
        submission_already_made, grade_out_of_range, update_grade, update_grade_and_mark_next,
        add_discussion, edit_discussion, type_here, deadline_has_passed, already_submitted,
        exceeds_word_limit, exceeds_char_limit, unassigned_error, enabled, disabled,
        from_my_courses, assign_reviewers, reviews_per_user_group, assign_random_reviewers,
        reviewer, app_language, pdf, panic_button_app, actions_to_be_taken_in_case_of_emergency,
        exit_app, delete_app_data_from_device, more_options, num_holidays, hide_app,
        panic_button_explanation, hide_app_explanation, most_recent, comment, grant_app_permission,
        this_app_will_receive, my_profile, add_another_account, end_is_before_start, select_person,
        your_submission, edit_content, edit_folder, add_new_post, grades_scoring, mark_comment,
        mark, too_high, required, expand, collapse, remove, table_of_contents, select_date, link,
        time_submitted, group_assignment, menu, restarting, terms_required_if_registration_enabled,
        drop_files_to_import, compose_post, password_updated, html5_content_display_engine,
        chrome_recommended, chrome_recommended_info, internal_webview, internal_webview_info,
        content_not_ready_try_later, version, licenses,
        cannot_record_attendance_for_future_date_time, could_not_load_vlc, selected,
        clear_selection, move_entries_to_this_folder, cannot_move_to_subfolder_of_self,
        cannot_move_already_in_same_folder, move_to, restore, delete_permanently, deleted_items,
        delete_or_restore_items, yesterday, request_to_enrol_already_pending, request_submitted,
        canceled_enrolment_request, enroled_into_name, declined_request_from_name, view_course,
        moderate, manage_student_enrolments, manage_teacher_enrolments, view_learning_records,
        edit_learning_records, view_members, copy_invite_code, direct_enrol_users_onto_courses,
        could_not_restart, close_now, view_all_courses, edit_all_courses, moderate_all_courses,
        manage_student_enrolments_for_all_courses, manage_teacher_enrolments_for_all_courses,
        view_attendance_records_for_all_courses, edit_attendance_records_for_all_courses,
        add_new_users, view_all_users, edit_all_users, view_learning_records_for_all_courses,
        edit_learning_records_for_all_courses, add_new_courses, manage_user_permissions,
        reset_passwords, upload_failed, opening_name, invalid_invite_code, bulk_import, import_key,
        imported, you_can_import_users_using_a_csv_file, get_template, line_number,
        selected_content, edit_content_block, compression, compression_highest, compression_high,
        compression_medium, compression_low, compression_lowest, compression_none,
        size_compressed_was, size, import_error, upload_error, offline_items_storage,
        space_available)
  }

  public actual object plurals : ResourceContainer<PluralsResource>, RemoteJsStringLoaderHolder {
    private val stringsFallbackFileUrl: String =
        js("require(\"localization/comustadmobilecore_pluralsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        SupportedLocale("rw", js("require(\"localization/comustadmobilecore_pluralsJson_rw.json\")")
            as String),
        SupportedLocale("bn", js("require(\"localization/comustadmobilecore_pluralsJson_bn.json\")")
            as String),
        SupportedLocale("ps", js("require(\"localization/comustadmobilecore_pluralsJson_ps.json\")")
            as String),
        SupportedLocale("tg", js("require(\"localization/comustadmobilecore_pluralsJson_tg.json\")")
            as String),
        SupportedLocale("ne", js("require(\"localization/comustadmobilecore_pluralsJson_ne.json\")")
            as String),
        SupportedLocale("fr", js("require(\"localization/comustadmobilecore_pluralsJson_fr.json\")")
            as String),
        SupportedLocale("fa", js("require(\"localization/comustadmobilecore_pluralsJson_fa.json\")")
            as String),
        SupportedLocale("ru", js("require(\"localization/comustadmobilecore_pluralsJson_ru.json\")")
            as String),
        SupportedLocale("ar", js("require(\"localization/comustadmobilecore_pluralsJson_ar.json\")")
            as String),
        SupportedLocale("nb-NO",
            js("require(\"localization/comustadmobilecore_pluralsJson_nb-NO.json\")") as String),
        SupportedLocale("my", js("require(\"localization/comustadmobilecore_pluralsJson_my.json\")")
            as String),
        ))

    override val stringsLoader: RemoteJsStringLoader = RemoteJsStringLoader.Impl(supportedLocales =
        supportedLocales, fallbackFileUri = stringsFallbackFileUrl)

    public actual val number_of_attempts: PluralsResource = PluralsResource(key =
        "number_of_attempts", loader = stringsLoader)

    public actual val duration_hours: PluralsResource = PluralsResource(key = "duration_hours",
        loader = stringsLoader)

    public actual val duration_minutes: PluralsResource = PluralsResource(key = "duration_minutes",
        loader = stringsLoader)

    public actual val duration_seconds: PluralsResource = PluralsResource(key = "duration_seconds",
        loader = stringsLoader)

    public actual val items_selected: PluralsResource = PluralsResource(key = "items_selected",
        loader = stringsLoader)

    public actual val items_deleted: PluralsResource = PluralsResource(key = "items_deleted", loader
        = stringsLoader)

    public actual val items_restored: PluralsResource = PluralsResource(key = "items_restored",
        loader = stringsLoader)

    public actual val are_you_sure_you_want_to_permanently_delete: PluralsResource =
        PluralsResource(key = "are_you_sure_you_want_to_permanently_delete", loader = stringsLoader)

    override fun values(): List<PluralsResource> = listOf(number_of_attempts, duration_hours,
        duration_minutes, duration_seconds, items_selected, items_deleted, items_restored,
        are_you_sure_you_want_to_permanently_delete)
  }

  public actual object images : ResourceContainer<ImageResource> {
    public actual val illustration_connect: ImageResource = ImageResource(fileUrl =
        js("require(\"images/illustration_connect.svg\")") as String, fileName =
        "illustration_connect.svg")

    public actual val ustad_logo: ImageResource = ImageResource(fileUrl =
        js("require(\"images/ustad_logo.svg\")") as String, fileName = "ustad_logo.svg")

    override fun values(): List<ImageResource> = listOf(illustration_connect, ustad_logo)
  }

  public actual object fonts : ResourceContainer<FontResource> {
    override fun values(): List<FontResource> = listOf()
  }

  public actual object files : ResourceContainer<FileResource> {
    override fun values(): List<FileResource> = listOf()
  }

  public actual object colors : ResourceContainer<ColorResource> {
    public actual val success: ColorResource = ColorResource(lightColor = Color(0x4CAF50FF),
        darkColor = Color(0x4CAF50FF))

    override fun values(): List<ColorResource> = listOf(success)
  }

  public actual object assets : ResourceContainer<AssetResource> {
    override fun values(): List<AssetResource> = listOf()
  }
}
