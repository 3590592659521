package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.LocallyAvailableContainer
import kotlin.collections.List

public actual abstract class LocallyAvailableContainerDao {
  public actual abstract suspend
      fun insertList(locallyAvailableContainers: List<LocallyAvailableContainer>)

  public actual abstract suspend
      fun deleteList(locallyAvailableContainers: List<LocallyAvailableContainer>)

  public actual abstract fun deleteAll()
}
