package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.StateContentEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class StateContentDao_JdbcImpl(
  public val _db: RoomDatabase,
) : StateContentDao() {
  public val _insertAdapterStateContentEntity_: EntityInsertionAdapter<StateContentEntity> = object
      : EntityInsertionAdapter<StateContentEntity>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO StateContentEntity (stateContentUid, stateContentStateUid, stateContentKey, stateContentValue, isIsactive, stateContentMasterChangeSeqNum, stateContentLocalChangeSeqNum, stateContentLastChangedBy, stateContentLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: StateContentEntity) {
      if(entity.stateContentUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.stateContentUid)
      }
      stmt.setLong(2, entity.stateContentStateUid)
      stmt.setString(3, entity.stateContentKey)
      stmt.setString(4, entity.stateContentValue)
      stmt.setBoolean(5, entity.isIsactive)
      stmt.setLong(6, entity.stateContentMasterChangeSeqNum)
      stmt.setLong(7, entity.stateContentLocalChangeSeqNum)
      stmt.setInt(8, entity.stateContentLastChangedBy)
      stmt.setLong(9, entity.stateContentLct)
    }
  }

  public override fun insert(entity: StateContentEntity): Long {
    val _retVal = _insertAdapterStateContentEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: StateContentEntity): Long {
    val _retVal = _insertAdapterStateContentEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<StateContentEntity>) {
    _insertAdapterStateContentEntity_.insertList(entityList)
  }

  public override fun update(entity: StateContentEntity) {
    val _sql =
        "UPDATE StateContentEntity SET stateContentStateUid = ?, stateContentKey = ?, stateContentValue = ?, isIsactive = ?, stateContentMasterChangeSeqNum = ?, stateContentLocalChangeSeqNum = ?, stateContentLastChangedBy = ?, stateContentLct = ? WHERE stateContentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.stateContentStateUid)
      _stmt.setString(2, entity.stateContentKey)
      _stmt.setString(3, entity.stateContentValue)
      _stmt.setBoolean(4, entity.isIsactive)
      _stmt.setLong(5, entity.stateContentMasterChangeSeqNum)
      _stmt.setLong(6, entity.stateContentLocalChangeSeqNum)
      _stmt.setInt(7, entity.stateContentLastChangedBy)
      _stmt.setLong(8, entity.stateContentLct)
      _stmt.setLong(9, entity.stateContentUid)
      _stmt.executeUpdate()
    }
  }

  override fun findAllStateContentWithStateUid(id: Long): List<StateContentEntity> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM StateContentEntity WHERE stateContentStateUid = CAST(? AS BIGINT) AND isIsactive",
    postgreSql = """
    |SELECT * FROM StateContentEntity WHERE stateContentStateUid = ? AND isIsactive
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,id)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_stateContentUid = _result.getLong("stateContentUid")
        val _tmp_stateContentStateUid = _result.getLong("stateContentStateUid")
        val _tmp_stateContentKey = _result.getString("stateContentKey")
        val _tmp_stateContentValue = _result.getString("stateContentValue")
        val _tmp_isIsactive = _result.getBoolean("isIsactive")
        val _tmp_stateContentMasterChangeSeqNum = _result.getLong("stateContentMasterChangeSeqNum")
        val _tmp_stateContentLocalChangeSeqNum = _result.getLong("stateContentLocalChangeSeqNum")
        val _tmp_stateContentLastChangedBy = _result.getInt("stateContentLastChangedBy")
        val _tmp_stateContentLct = _result.getLong("stateContentLct")
        StateContentEntity().apply {
          this.stateContentUid = _tmp_stateContentUid
          this.stateContentStateUid = _tmp_stateContentStateUid
          this.stateContentKey = _tmp_stateContentKey
          this.stateContentValue = _tmp_stateContentValue
          this.isIsactive = _tmp_isIsactive
          this.stateContentMasterChangeSeqNum = _tmp_stateContentMasterChangeSeqNum
          this.stateContentLocalChangeSeqNum = _tmp_stateContentLocalChangeSeqNum
          this.stateContentLastChangedBy = _tmp_stateContentLastChangedBy
          this.stateContentLct = _tmp_stateContentLct
        }
      }
    }
  }

  override fun findStateContentByKeyAndStateUid(key: String, stateUid: Long): StateContentEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM StateContentEntity WHERE stateContentStateUid = CAST(? AS BIGINT) AND stateContentKey = ? AND isIsactive",
    postgreSql = """
    |SELECT * FROM StateContentEntity WHERE stateContentStateUid = ? AND stateContentKey = ? AND isIsactive
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,stateUid)
    _stmt.setString(2,key)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_stateContentUid = _result.getLong("stateContentUid")
        val _tmp_stateContentStateUid = _result.getLong("stateContentStateUid")
        val _tmp_stateContentKey = _result.getString("stateContentKey")
        val _tmp_stateContentValue = _result.getString("stateContentValue")
        val _tmp_isIsactive = _result.getBoolean("isIsactive")
        val _tmp_stateContentMasterChangeSeqNum = _result.getLong("stateContentMasterChangeSeqNum")
        val _tmp_stateContentLocalChangeSeqNum = _result.getLong("stateContentLocalChangeSeqNum")
        val _tmp_stateContentLastChangedBy = _result.getInt("stateContentLastChangedBy")
        val _tmp_stateContentLct = _result.getLong("stateContentLct")
        StateContentEntity().apply {
          this.stateContentUid = _tmp_stateContentUid
          this.stateContentStateUid = _tmp_stateContentStateUid
          this.stateContentKey = _tmp_stateContentKey
          this.stateContentValue = _tmp_stateContentValue
          this.isIsactive = _tmp_isIsactive
          this.stateContentMasterChangeSeqNum = _tmp_stateContentMasterChangeSeqNum
          this.stateContentLocalChangeSeqNum = _tmp_stateContentLocalChangeSeqNum
          this.stateContentLastChangedBy = _tmp_stateContentLastChangedBy
          this.stateContentLct = _tmp_stateContentLct
        }
      }
    }
  }

  override fun setInActiveStateContentByKeyAndUid(
    isActive: Boolean,
    stateUid: Long,
    updateTime: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE StateContentEntity 
      |           SET isIsactive = ?,  
      |               stateContentLct = CAST(? AS BIGINT)
      |         WHERE stateContentUid = CAST(? AS BIGINT)
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE StateContentEntity 
      |           SET isIsactive = ?,  
      |               stateContentLct = ?
      |         WHERE stateContentUid = ?
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,isActive)
      _stmt.setLong(2,updateTime)
      _stmt.setLong(3,stateUid)
      _stmt.executeUpdate()
    }
  }
}
