package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary
import com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay
import com.ustadmobile.lib.db.entities.StatementEntity
import com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails
import com.ustadmobile.lib.db.entities.StatementReportData
import com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class StatementDao : BaseDao<StatementEntity> {
  public actual abstract suspend fun insertListAsync(entityList: List<StatementEntity>)

  public actual abstract fun getOneStatement(): Flow<StatementEntity?>

  public actual abstract fun findByStatementId(id: String): StatementEntity?

  public actual abstract fun findByStatementIdList(id: List<String>): List<StatementEntity>

  public actual abstract suspend fun getResults(query: DoorQuery): List<StatementReportData>

  public actual abstract fun getListResults(query: DoorQuery):
      PagingSource<Int, StatementEntityWithDisplayDetails>

  public actual abstract fun getPerson(): Person?

  public actual abstract fun getXLangMap(): XLangMapEntry?

  public actual abstract fun updateProgress(
    uid: Long,
    progress: Int,
    updateTime: Long,
  )

  public actual abstract fun findPersonsWithContentEntryAttempts(
    contentEntryUid: Long,
    accountPersonUid: Long,
    searchText: String,
    sortOrder: Int,
  ): PagingSource<Int, PersonWithAttemptsSummary>

  public actual abstract suspend fun getBestScoreForContentForPerson(contentEntryUid: Long,
      accountPersonUid: Long): ContentEntryStatementScoreProgress?

  public actual abstract suspend fun findNextStudentNotMarkedForAssignment(assignmentUid: Long,
      currentStudentUid: Long): Long

  public actual abstract suspend fun findSubmittedStatementFromStudent(studentUid: Long,
      assignmentObjectUid: Long): StatementEntity?

  public actual abstract fun findScoreStatementForStudent(studentUid: Long): StatementEntity?

  public actual abstract fun findSessionsForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
  ): PagingSource<Int, PersonWithSessionsDisplay>

  public actual abstract fun findSessionDetailForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
    contextRegistration: String,
  ): PagingSource<Int, StatementWithSessionDetailDisplay>

  public actual abstract suspend fun calculateScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress?

  public actual abstract suspend fun findCompletedScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress?

  public actual abstract suspend fun findLatestRegistrationStatement(accountPersonUid: Long,
      entryUid: Long): String?
}
