package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.PersonAndClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonPicture
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ClazzLogAttendanceRecordDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ClazzLogAttendanceRecordDao() {
  public val _insertAdapterClazzLogAttendanceRecord_:
      EntityInsertionAdapter<ClazzLogAttendanceRecord> = object :
      EntityInsertionAdapter<ClazzLogAttendanceRecord>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ClazzLogAttendanceRecord (clazzLogAttendanceRecordUid, clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ClazzLogAttendanceRecord) {
      if(entity.clazzLogAttendanceRecordUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogAttendanceRecordUid)
      }
      stmt.setLong(2, entity.clazzLogAttendanceRecordClazzLogUid)
      stmt.setLong(3, entity.clazzLogAttendanceRecordPersonUid)
      stmt.setInt(4, entity.attendanceStatus)
      stmt.setLong(5, entity.clazzLogAttendanceRecordMasterChangeSeqNum)
      stmt.setLong(6, entity.clazzLogAttendanceRecordLocalChangeSeqNum)
      stmt.setInt(7, entity.clazzLogAttendanceRecordLastChangedBy)
      stmt.setLong(8, entity.clazzLogAttendanceRecordLastChangedTime)
    }
  }

  public val _insertAdapterClazzLogAttendanceRecord_upsert:
      EntityInsertionAdapter<ClazzLogAttendanceRecord> = object :
      EntityInsertionAdapter<ClazzLogAttendanceRecord>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO ClazzLogAttendanceRecord (clazzLogAttendanceRecordUid, clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ClazzLogAttendanceRecord) {
      if(entity.clazzLogAttendanceRecordUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogAttendanceRecordUid)
      }
      stmt.setLong(2, entity.clazzLogAttendanceRecordClazzLogUid)
      stmt.setLong(3, entity.clazzLogAttendanceRecordPersonUid)
      stmt.setInt(4, entity.attendanceStatus)
      stmt.setLong(5, entity.clazzLogAttendanceRecordMasterChangeSeqNum)
      stmt.setLong(6, entity.clazzLogAttendanceRecordLocalChangeSeqNum)
      stmt.setInt(7, entity.clazzLogAttendanceRecordLastChangedBy)
      stmt.setLong(8, entity.clazzLogAttendanceRecordLastChangedTime)
    }
  }

  override suspend fun insertListAsync(entities: List<ClazzLogAttendanceRecord>) {
    _insertAdapterClazzLogAttendanceRecord_.insertListAsync(entities)
  }

  override suspend fun upsertListAsync(entityList: List<ClazzLogAttendanceRecord>) {
    _insertAdapterClazzLogAttendanceRecord_upsert.insertListAsync(entityList)
  }

  public override fun insert(entity: ClazzLogAttendanceRecord): Long {
    val _retVal = _insertAdapterClazzLogAttendanceRecord_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzLogAttendanceRecord): Long {
    val _retVal = _insertAdapterClazzLogAttendanceRecord_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ClazzLogAttendanceRecord>) {
    _insertAdapterClazzLogAttendanceRecord_.insertList(entityList)
  }

  override suspend fun updateListAsync(entities: List<ClazzLogAttendanceRecord>) {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entities) {
        _stmt.setLong(1, _entity.clazzLogAttendanceRecordClazzLogUid)
        _stmt.setLong(2, _entity.clazzLogAttendanceRecordPersonUid)
        _stmt.setInt(3, _entity.attendanceStatus)
        _stmt.setLong(4, _entity.clazzLogAttendanceRecordMasterChangeSeqNum)
        _stmt.setLong(5, _entity.clazzLogAttendanceRecordLocalChangeSeqNum)
        _stmt.setInt(6, _entity.clazzLogAttendanceRecordLastChangedBy)
        _stmt.setLong(7, _entity.clazzLogAttendanceRecordLastChangedTime)
        _stmt.setLong(8, _entity.clazzLogAttendanceRecordUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzLogAttendanceRecord) {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzLogAttendanceRecordClazzLogUid)
      _stmt.setLong(2, entity.clazzLogAttendanceRecordPersonUid)
      _stmt.setInt(3, entity.attendanceStatus)
      _stmt.setLong(4, entity.clazzLogAttendanceRecordMasterChangeSeqNum)
      _stmt.setLong(5, entity.clazzLogAttendanceRecordLocalChangeSeqNum)
      _stmt.setInt(6, entity.clazzLogAttendanceRecordLastChangedBy)
      _stmt.setLong(7, entity.clazzLogAttendanceRecordLastChangedTime)
      _stmt.setLong(8, entity.clazzLogAttendanceRecordUid)
      _stmt.executeUpdate()
    }
  }

  override fun findByUid(uid: Long): ClazzLogAttendanceRecord? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * from ClazzLogAttendanceRecord WHERE clazzLogAttendanceRecordUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * from ClazzLogAttendanceRecord WHERE clazzLogAttendanceRecordUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_clazzLogAttendanceRecordUid = _result.getLong("clazzLogAttendanceRecordUid")
        val _tmp_clazzLogAttendanceRecordClazzLogUid =
            _result.getLong("clazzLogAttendanceRecordClazzLogUid")
        val _tmp_clazzLogAttendanceRecordPersonUid =
            _result.getLong("clazzLogAttendanceRecordPersonUid")
        val _tmp_attendanceStatus = _result.getInt("attendanceStatus")
        val _tmp_clazzLogAttendanceRecordMasterChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordMasterChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLocalChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordLocalChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLastChangedBy =
            _result.getInt("clazzLogAttendanceRecordLastChangedBy")
        val _tmp_clazzLogAttendanceRecordLastChangedTime =
            _result.getLong("clazzLogAttendanceRecordLastChangedTime")
        ClazzLogAttendanceRecord().apply {
          this.clazzLogAttendanceRecordUid = _tmp_clazzLogAttendanceRecordUid
          this.clazzLogAttendanceRecordClazzLogUid = _tmp_clazzLogAttendanceRecordClazzLogUid
          this.clazzLogAttendanceRecordPersonUid = _tmp_clazzLogAttendanceRecordPersonUid
          this.attendanceStatus = _tmp_attendanceStatus
          this.clazzLogAttendanceRecordMasterChangeSeqNum =
              _tmp_clazzLogAttendanceRecordMasterChangeSeqNum
          this.clazzLogAttendanceRecordLocalChangeSeqNum =
              _tmp_clazzLogAttendanceRecordLocalChangeSeqNum
          this.clazzLogAttendanceRecordLastChangedBy = _tmp_clazzLogAttendanceRecordLastChangedBy
          this.clazzLogAttendanceRecordLastChangedTime =
              _tmp_clazzLogAttendanceRecordLastChangedTime
        }
      }
    }
  }

  override suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |SELECT ClazzLogAttendanceRecord.*, Person.*
    |         FROM ClazzLogAttendanceRecord 
    |         LEFT JOIN Person ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid
    |         WHERE clazzLogAttendanceRecordClazzLogUid = CAST(? AS BIGINT)
    """.trimMargin(),
    postgreSql = """
    |SELECT ClazzLogAttendanceRecord.*, Person.*
    |         FROM ClazzLogAttendanceRecord 
    |         LEFT JOIN Person ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid
    |         WHERE clazzLogAttendanceRecordClazzLogUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,clazzLogUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_clazzLogAttendanceRecordUid = _result.getLong("clazzLogAttendanceRecordUid")
        val _tmp_clazzLogAttendanceRecordClazzLogUid =
            _result.getLong("clazzLogAttendanceRecordClazzLogUid")
        val _tmp_clazzLogAttendanceRecordPersonUid =
            _result.getLong("clazzLogAttendanceRecordPersonUid")
        val _tmp_attendanceStatus = _result.getInt("attendanceStatus")
        val _tmp_clazzLogAttendanceRecordMasterChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordMasterChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLocalChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordLocalChangeSeqNum")
        val _tmp_clazzLogAttendanceRecordLastChangedBy =
            _result.getInt("clazzLogAttendanceRecordLastChangedBy")
        val _tmp_clazzLogAttendanceRecordLastChangedTime =
            _result.getLong("clazzLogAttendanceRecordLastChangedTime")
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        ClazzLogAttendanceRecordWithPerson().apply {
          this.clazzLogAttendanceRecordUid = _tmp_clazzLogAttendanceRecordUid
          this.clazzLogAttendanceRecordClazzLogUid = _tmp_clazzLogAttendanceRecordClazzLogUid
          this.clazzLogAttendanceRecordPersonUid = _tmp_clazzLogAttendanceRecordPersonUid
          this.attendanceStatus = _tmp_attendanceStatus
          this.clazzLogAttendanceRecordMasterChangeSeqNum =
              _tmp_clazzLogAttendanceRecordMasterChangeSeqNum
          this.clazzLogAttendanceRecordLocalChangeSeqNum =
              _tmp_clazzLogAttendanceRecordLocalChangeSeqNum
          this.clazzLogAttendanceRecordLastChangedBy = _tmp_clazzLogAttendanceRecordLastChangedBy
          this.clazzLogAttendanceRecordLastChangedTime =
              _tmp_clazzLogAttendanceRecordLastChangedTime
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
        }
      }
    }
  }

  override fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE ClazzLogAttendanceRecord
      |           SET clazzLogAttendanceRecordClazzLogUid = CAST(? AS BIGINT),
      |               clazzLogAttendanceRecordLastChangedTime = CAST(? AS BIGINT)
      |        WHERE clazzLogAttendanceRecordClazzLogUid = CAST(? AS BIGINT)
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE ClazzLogAttendanceRecord
      |           SET clazzLogAttendanceRecordClazzLogUid = ?,
      |               clazzLogAttendanceRecordLastChangedTime = ?
      |        WHERE clazzLogAttendanceRecordClazzLogUid = ?
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,newClazzLogUid)
      _stmt.setLong(2,changedTime)
      _stmt.setLong(3,oldClazzLogUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun findByClazzAndTime(
    clazzUid: Long,
    clazzLogUid: Long,
    time: Long,
  ): List<PersonAndClazzLogAttendanceRecord> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        
    |        WITH CurrentlyEnrolledPersonUids(enroledPersonUid) AS
    |              (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS enroledPersonUid
    |                 FROM ClazzEnrolment
    |                WHERE ClazzEnrolment.clazzEnrolmentClazzUid = CAST(? AS BIGINT)
    |                  AND ClazzEnrolment.clazzEnrolmentRole = 1000
    |                  AND CAST(? AS BIGINT) BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft)
    |    
    |                  
    |        SELECT Person.*, ClazzLogAttendanceRecord.*, PersonPicture.*
    |          FROM Person
    |               LEFT JOIN ClazzLogAttendanceRecord 
    |                         ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid = 
    |                            (SELECT ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordUid  
    |                               FROM ClazzLogAttendanceRecord ClazzLogAttendanceRecordInner
    |                              WHERE ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordClazzLogUid = CAST(? AS BIGINT)
    |                                AND ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordPersonUid = Person.personUid
    |                           ORDER BY ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordLastChangedTime DESC     
    |                              LIMIT 1  
    |                            )
    |               LEFT JOIN PersonPicture
    |                         ON PersonPicture.personPictureUid = Person.personUid
    |         WHERE Person.personUid IN 
    |               (SELECT CurrentlyEnrolledPersonUids.enroledPersonUid
    |                  FROM CurrentlyEnrolledPersonUids)                
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        
    |        WITH CurrentlyEnrolledPersonUids(enroledPersonUid) AS
    |              (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS enroledPersonUid
    |                 FROM ClazzEnrolment
    |                WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ?
    |                  AND ClazzEnrolment.clazzEnrolmentRole = 1000
    |                  AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft)
    |    
    |                  
    |        SELECT Person.*, ClazzLogAttendanceRecord.*, PersonPicture.*
    |          FROM Person
    |               LEFT JOIN ClazzLogAttendanceRecord 
    |                         ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid = 
    |                            (SELECT ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordUid  
    |                               FROM ClazzLogAttendanceRecord ClazzLogAttendanceRecordInner
    |                              WHERE ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordClazzLogUid = ?
    |                                AND ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordPersonUid = Person.personUid
    |                           ORDER BY ClazzLogAttendanceRecordInner.clazzLogAttendanceRecordLastChangedTime DESC     
    |                              LIMIT 1  
    |                            )
    |               LEFT JOIN PersonPicture
    |                         ON PersonPicture.personPictureUid = Person.personUid
    |         WHERE Person.personUid IN 
    |               (SELECT CurrentlyEnrolledPersonUids.enroledPersonUid
    |                  FROM CurrentlyEnrolledPersonUids)                
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,clazzUid)
    _stmt.setLong(2,time)
    _stmt.setLong(3,clazzLogUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        var _tmp_Person_nullCount = 0
        val _tmp_personUid = _result.getLong("personUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_username = _result.getString("username")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_firstNames = _result.getString("firstNames")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_lastName = _result.getString("lastName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_emailAddr = _result.getString("emailAddr")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_phoneNum = _result.getString("phoneNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_gender = _result.getInt("gender")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_active = _result.getBoolean("active")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_admin = _result.getBoolean("admin")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personNotes = _result.getString("personNotes")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherName = _result.getString("fatherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_fatherNumber = _result.getString("fatherNumber")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherName = _result.getString("motherName")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_motherNum = _result.getString("motherNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_dateOfBirth = _result.getLong("dateOfBirth")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personAddress = _result.getString("personAddress")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personOrgId = _result.getString("personOrgId")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personGroupUid = _result.getLong("personGroupUid")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personMasterChangeSeqNum = _result.getLong("personMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLocalChangeSeqNum = _result.getLong("personLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLastChangedBy = _result.getInt("personLastChangedBy")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personLct = _result.getLong("personLct")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personCountry = _result.getString("personCountry")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_personType = _result.getInt("personType")
        if(_result.wasNull()) _tmp_Person_nullCount++
        val _tmp_Person_isAllNull = _tmp_Person_nullCount == 24
        var _tmp_PersonPicture_nullCount = 0
        val _tmp_personPictureUid = _result.getLong("personPictureUid")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureLct = _result.getLong("personPictureLct")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureUri = _result.getString("personPictureUri")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureThumbnailUri = _result.getString("personPictureThumbnailUri")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_fileSize = _result.getInt("fileSize")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_personPictureActive = _result.getBoolean("personPictureActive")
        if(_result.wasNull()) _tmp_PersonPicture_nullCount++
        val _tmp_PersonPicture_isAllNull = _tmp_PersonPicture_nullCount == 6
        var _tmp_ClazzLogAttendanceRecord_nullCount = 0
        val _tmp_clazzLogAttendanceRecordUid = _result.getLong("clazzLogAttendanceRecordUid")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_clazzLogAttendanceRecordClazzLogUid =
            _result.getLong("clazzLogAttendanceRecordClazzLogUid")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_clazzLogAttendanceRecordPersonUid =
            _result.getLong("clazzLogAttendanceRecordPersonUid")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_attendanceStatus = _result.getInt("attendanceStatus")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_clazzLogAttendanceRecordMasterChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_clazzLogAttendanceRecordLocalChangeSeqNum =
            _result.getLong("clazzLogAttendanceRecordLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_clazzLogAttendanceRecordLastChangedBy =
            _result.getInt("clazzLogAttendanceRecordLastChangedBy")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_clazzLogAttendanceRecordLastChangedTime =
            _result.getLong("clazzLogAttendanceRecordLastChangedTime")
        if(_result.wasNull()) _tmp_ClazzLogAttendanceRecord_nullCount++
        val _tmp_ClazzLogAttendanceRecord_isAllNull = _tmp_ClazzLogAttendanceRecord_nullCount == 8
        PersonAndClazzLogAttendanceRecord().apply {
          if(!_tmp_Person_isAllNull) {
            this.person = Person().apply {
              this.personUid = _tmp_personUid
              this.username = _tmp_username
              this.firstNames = _tmp_firstNames
              this.lastName = _tmp_lastName
              this.emailAddr = _tmp_emailAddr
              this.phoneNum = _tmp_phoneNum
              this.gender = _tmp_gender
              this.active = _tmp_active
              this.admin = _tmp_admin
              this.personNotes = _tmp_personNotes
              this.fatherName = _tmp_fatherName
              this.fatherNumber = _tmp_fatherNumber
              this.motherName = _tmp_motherName
              this.motherNum = _tmp_motherNum
              this.dateOfBirth = _tmp_dateOfBirth
              this.personAddress = _tmp_personAddress
              this.personOrgId = _tmp_personOrgId
              this.personGroupUid = _tmp_personGroupUid
              this.personMasterChangeSeqNum = _tmp_personMasterChangeSeqNum
              this.personLocalChangeSeqNum = _tmp_personLocalChangeSeqNum
              this.personLastChangedBy = _tmp_personLastChangedBy
              this.personLct = _tmp_personLct
              this.personCountry = _tmp_personCountry
              this.personType = _tmp_personType
            }
          }
          if(!_tmp_PersonPicture_isAllNull) {
            this.personPicture = PersonPicture().apply {
              this.personPictureUid = _tmp_personPictureUid
              this.personPictureLct = _tmp_personPictureLct
              this.personPictureUri = _tmp_personPictureUri
              this.personPictureThumbnailUri = _tmp_personPictureThumbnailUri
              this.fileSize = _tmp_fileSize
              this.personPictureActive = _tmp_personPictureActive
            }
          }
          if(!_tmp_ClazzLogAttendanceRecord_isAllNull) {
            this.attendanceRecord = ClazzLogAttendanceRecord().apply {
              this.clazzLogAttendanceRecordUid = _tmp_clazzLogAttendanceRecordUid
              this.clazzLogAttendanceRecordClazzLogUid = _tmp_clazzLogAttendanceRecordClazzLogUid
              this.clazzLogAttendanceRecordPersonUid = _tmp_clazzLogAttendanceRecordPersonUid
              this.attendanceStatus = _tmp_attendanceStatus
              this.clazzLogAttendanceRecordMasterChangeSeqNum =
                  _tmp_clazzLogAttendanceRecordMasterChangeSeqNum
              this.clazzLogAttendanceRecordLocalChangeSeqNum =
                  _tmp_clazzLogAttendanceRecordLocalChangeSeqNum
              this.clazzLogAttendanceRecordLastChangedBy =
                  _tmp_clazzLogAttendanceRecordLastChangedBy
              this.clazzLogAttendanceRecordLastChangedTime =
                  _tmp_clazzLogAttendanceRecordLastChangedTime
            }
          }
        }
      }
    }
  }
}
