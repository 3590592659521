package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ConnectivityStatus
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public class ConnectivityStatusDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ConnectivityStatusDao() {
  public val _insertAdapterConnectivityStatus_upsert: EntityInsertionAdapter<ConnectivityStatus> =
      object : EntityInsertionAdapter<ConnectivityStatus>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO ConnectivityStatus (csUid, connectivityState, wifiSsid, connectedOrConnecting) VALUES(?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ConnectivityStatus) {
      stmt.setInt(1, entity.csUid)
      stmt.setInt(2, entity.connectivityState)
      stmt.setString(3, entity.wifiSsid)
      stmt.setBoolean(4, entity.connectedOrConnecting)
    }
  }

  override fun insert(connectivityStatus: ConnectivityStatus): Long {
    val _retVal = _insertAdapterConnectivityStatus_upsert.insertAndReturnId(connectivityStatus)
    return _retVal
  }

  override suspend fun insertAsync(connectivityStatus: ConnectivityStatus): Long {
    val _retVal = _insertAdapterConnectivityStatus_upsert.insertAndReturnIdAsync(connectivityStatus)
    return _retVal
  }

  override fun statusLive(): Flow<ConnectivityStatus?> =
      _db.doorFlow(arrayOf("ConnectivityStatus")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1",
      readOnly = true,)
    ) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_csUid = _result.getInt("csUid")
          val _tmp_connectivityState = _result.getInt("connectivityState")
          val _tmp_wifiSsid = _result.getString("wifiSsid")
          val _tmp_connectedOrConnecting = _result.getBoolean("connectedOrConnecting")
          ConnectivityStatus().apply {
            this.csUid = _tmp_csUid
            this.connectivityState = _tmp_connectivityState
            this.wifiSsid = _tmp_wifiSsid
            this.connectedOrConnecting = _tmp_connectedOrConnecting
          }
        }
      }
    }
  }

  override fun status(): ConnectivityStatus? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_csUid = _result.getInt("csUid")
        val _tmp_connectivityState = _result.getInt("connectivityState")
        val _tmp_wifiSsid = _result.getString("wifiSsid")
        val _tmp_connectedOrConnecting = _result.getBoolean("connectedOrConnecting")
        ConnectivityStatus().apply {
          this.csUid = _tmp_csUid
          this.connectivityState = _tmp_connectivityState
          this.wifiSsid = _tmp_wifiSsid
          this.connectedOrConnecting = _tmp_connectedOrConnecting
        }
      }
    }
  }

  override suspend fun updateStateAsync(connectivityState: Int) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "UPDATE ConnectivityStatus SET connectivityState = ?",
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setInt(1,connectivityState)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override suspend fun updateState(connectivityState: Int, wifiSsid: String) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?",
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setInt(1,connectivityState)
      _stmt.setString(2,wifiSsid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override fun updateStateSync(connectivityState: Int, wifiSsid: String) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?",
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setInt(1,connectivityState)
      _stmt.setString(2,wifiSsid)
      _stmt.executeUpdate()
    }
  }
}
