package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.StateContentEntity
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class StateContentDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: StateContentDao,
) : StateContentDao() {
  override fun findAllStateContentWithStateUid(id: Long): List<StateContentEntity> = throw
      IllegalStateException("findAllStateContentWithStateUid: synchronous db access is NOT possible on Javascript!")

  override fun findStateContentByKeyAndStateUid(key: String, stateUid: Long): StateContentEntity? =
      throw
      IllegalStateException("findStateContentByKeyAndStateUid: synchronous db access is NOT possible on Javascript!")

  override fun setInActiveStateContentByKeyAndUid(
    isActive: Boolean,
    stateUid: Long,
    updateTime: Long,
  ): Unit = throw
      IllegalStateException("setInActiveStateContentByKeyAndUid: synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: StateContentEntity): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: StateContentEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.stateContentUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(72)
      entity.stateContentUid = _newPk
    }
    entity.stateContentLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.stateContentUid
  }

  public override fun insertList(entityList: List<StateContentEntity>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: StateContentEntity): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
