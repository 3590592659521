package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContainerETag
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ContainerETagDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContainerETagDao() {
  public val _insertAdapterContainerETag_: EntityInsertionAdapter<ContainerETag> = object :
      EntityInsertionAdapter<ContainerETag>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ContainerETag (ceContainerUid, cetag) VALUES(?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContainerETag) {
      stmt.setLong(1, entity.ceContainerUid)
      stmt.setString(2, entity.cetag)
    }
  }

  public override fun insert(entity: ContainerETag): Long {
    val _retVal = _insertAdapterContainerETag_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerETag): Long {
    val _retVal = _insertAdapterContainerETag_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContainerETag>) {
    _insertAdapterContainerETag_.insertList(entityList)
  }

  public override fun update(entity: ContainerETag) {
    val _sql = "UPDATE ContainerETag SET cetag = ? WHERE ceContainerUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.cetag)
      _stmt.setLong(2, entity.ceContainerUid)
      _stmt.executeUpdate()
    }
  }

  override fun getEtagOfContainer(containerUid: Long): String? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT cetag FROM ContainerETag WHERE ceContainerUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT cetag FROM ContainerETag WHERE ceContainerUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }
}
