package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SchoolDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: SchoolDao,
) : SchoolDao() {
  override suspend fun findByUidAsync(schoolUid: Long): School? = _dao.findByUidAsync(schoolUid)

  override suspend fun findByUidWithHolidayCalendarAsync(uid: Long): SchoolWithHolidayCalendar? =
      _dao.findByUidWithHolidayCalendarAsync(uid)

  override suspend fun findBySchoolCode(code: String): School? = _dao.findBySchoolCode(code)

  override suspend fun findBySchoolCodeFromWeb(code: String): School? =
      _dao.findBySchoolCodeFromWeb(code)

  override suspend fun updateAsync(entity: School): Int {
    entity.schoolLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun insert(entity: School): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: School): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.schoolUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(164)
      entity.schoolUid = _newPk
    }
    entity.schoolLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.schoolUid
  }

  public override fun insertList(entityList: List<School>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: School): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
