package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.paging.DoorLimitOffsetPagingSource
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.ContentEntryAndDetail
import com.ustadmobile.lib.db.composites.ContentEntryAndLanguage
import com.ustadmobile.lib.db.composites.ContentEntryAndListDetail
import com.ustadmobile.lib.db.composites.ContentEntryAndPicture
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import com.ustadmobile.lib.db.entities.ContentEntryPicture2
import com.ustadmobile.lib.db.entities.ContentEntryVersion
import com.ustadmobile.lib.db.entities.ContentEntryWithLanguage
import com.ustadmobile.lib.db.entities.DownloadJobSizeInfo
import com.ustadmobile.lib.db.entities.Language
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ContentEntryDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContentEntryDao() {
  public val _insertAdapterContentEntry_: EntityInsertionAdapter<ContentEntry> = object :
      EntityInsertionAdapter<ContentEntry>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentOwnerType, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentEntry) {
      if(entity.contentEntryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentEntryUid)
      }
      stmt.setString(2, entity.title)
      stmt.setString(3, entity.description)
      stmt.setString(4, entity.entryId)
      stmt.setString(5, entity.author)
      stmt.setString(6, entity.publisher)
      stmt.setInt(7, entity.licenseType)
      stmt.setString(8, entity.licenseName)
      stmt.setString(9, entity.licenseUrl)
      stmt.setString(10, entity.sourceUrl)
      stmt.setString(11, entity.thumbnailUrl)
      stmt.setLong(12, entity.lastModified)
      stmt.setLong(13, entity.primaryLanguageUid)
      stmt.setLong(14, entity.languageVariantUid)
      stmt.setInt(15, entity.contentFlags)
      stmt.setBoolean(16, entity.leaf)
      stmt.setBoolean(17, entity.publik)
      stmt.setBoolean(18, entity.ceInactive)
      stmt.setInt(19, entity.completionCriteria)
      stmt.setInt(20, entity.minScore)
      stmt.setInt(21, entity.contentTypeFlag)
      stmt.setLong(22, entity.contentOwner)
      stmt.setInt(23, entity.contentOwnerType)
      stmt.setLong(24, entity.contentEntryLocalChangeSeqNum)
      stmt.setLong(25, entity.contentEntryMasterChangeSeqNum)
      stmt.setInt(26, entity.contentEntryLastChangedBy)
      stmt.setLong(27, entity.contentEntryLct)
    }
  }

  public val _insertAdapterContentEntry_upsert: EntityInsertionAdapter<ContentEntry> = object :
      EntityInsertionAdapter<ContentEntry>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentOwnerType, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentEntry) {
      if(entity.contentEntryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentEntryUid)
      }
      stmt.setString(2, entity.title)
      stmt.setString(3, entity.description)
      stmt.setString(4, entity.entryId)
      stmt.setString(5, entity.author)
      stmt.setString(6, entity.publisher)
      stmt.setInt(7, entity.licenseType)
      stmt.setString(8, entity.licenseName)
      stmt.setString(9, entity.licenseUrl)
      stmt.setString(10, entity.sourceUrl)
      stmt.setString(11, entity.thumbnailUrl)
      stmt.setLong(12, entity.lastModified)
      stmt.setLong(13, entity.primaryLanguageUid)
      stmt.setLong(14, entity.languageVariantUid)
      stmt.setInt(15, entity.contentFlags)
      stmt.setBoolean(16, entity.leaf)
      stmt.setBoolean(17, entity.publik)
      stmt.setBoolean(18, entity.ceInactive)
      stmt.setInt(19, entity.completionCriteria)
      stmt.setInt(20, entity.minScore)
      stmt.setInt(21, entity.contentTypeFlag)
      stmt.setLong(22, entity.contentOwner)
      stmt.setInt(23, entity.contentOwnerType)
      stmt.setLong(24, entity.contentEntryLocalChangeSeqNum)
      stmt.setLong(25, entity.contentEntryMasterChangeSeqNum)
      stmt.setInt(26, entity.contentEntryLastChangedBy)
      stmt.setLong(27, entity.contentEntryLct)
    }
  }

  override suspend fun insertListAsync(entityList: List<ContentEntry>) {
    _insertAdapterContentEntry_.insertListAsync(entityList)
  }

  override suspend fun upsertAsync(entity: ContentEntry) {
    _insertAdapterContentEntry_upsert.insertAsync(entity)
  }

  override fun replaceList(entries: List<ContentEntry>) {
    _insertAdapterContentEntry_upsert.insertList(entries)
  }

  override fun insertWithReplace(entry: ContentEntry) {
    _insertAdapterContentEntry_upsert.insert(entry)
  }

  public override fun insert(entity: ContentEntry): Long {
    val _retVal = _insertAdapterContentEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntry): Long {
    val _retVal = _insertAdapterContentEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContentEntry>) {
    _insertAdapterContentEntry_.insertList(entityList)
  }

  override fun update(entity: ContentEntry) {
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentOwnerType = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.title)
      _stmt.setString(2, entity.description)
      _stmt.setString(3, entity.entryId)
      _stmt.setString(4, entity.author)
      _stmt.setString(5, entity.publisher)
      _stmt.setInt(6, entity.licenseType)
      _stmt.setString(7, entity.licenseName)
      _stmt.setString(8, entity.licenseUrl)
      _stmt.setString(9, entity.sourceUrl)
      _stmt.setString(10, entity.thumbnailUrl)
      _stmt.setLong(11, entity.lastModified)
      _stmt.setLong(12, entity.primaryLanguageUid)
      _stmt.setLong(13, entity.languageVariantUid)
      _stmt.setInt(14, entity.contentFlags)
      _stmt.setBoolean(15, entity.leaf)
      _stmt.setBoolean(16, entity.publik)
      _stmt.setBoolean(17, entity.ceInactive)
      _stmt.setInt(18, entity.completionCriteria)
      _stmt.setInt(19, entity.minScore)
      _stmt.setInt(20, entity.contentTypeFlag)
      _stmt.setLong(21, entity.contentOwner)
      _stmt.setInt(22, entity.contentOwnerType)
      _stmt.setLong(23, entity.contentEntryLocalChangeSeqNum)
      _stmt.setLong(24, entity.contentEntryMasterChangeSeqNum)
      _stmt.setInt(25, entity.contentEntryLastChangedBy)
      _stmt.setLong(26, entity.contentEntryLct)
      _stmt.setLong(27, entity.contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun updateAsync(entity: ContentEntry): Int {
    var _result = 0
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentOwnerType = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.title)
      _stmt.setString(2, entity.description)
      _stmt.setString(3, entity.entryId)
      _stmt.setString(4, entity.author)
      _stmt.setString(5, entity.publisher)
      _stmt.setInt(6, entity.licenseType)
      _stmt.setString(7, entity.licenseName)
      _stmt.setString(8, entity.licenseUrl)
      _stmt.setString(9, entity.sourceUrl)
      _stmt.setString(10, entity.thumbnailUrl)
      _stmt.setLong(11, entity.lastModified)
      _stmt.setLong(12, entity.primaryLanguageUid)
      _stmt.setLong(13, entity.languageVariantUid)
      _stmt.setInt(14, entity.contentFlags)
      _stmt.setBoolean(15, entity.leaf)
      _stmt.setBoolean(16, entity.publik)
      _stmt.setBoolean(17, entity.ceInactive)
      _stmt.setInt(18, entity.completionCriteria)
      _stmt.setInt(19, entity.minScore)
      _stmt.setInt(20, entity.contentTypeFlag)
      _stmt.setLong(21, entity.contentOwner)
      _stmt.setInt(22, entity.contentOwnerType)
      _stmt.setLong(23, entity.contentEntryLocalChangeSeqNum)
      _stmt.setLong(24, entity.contentEntryMasterChangeSeqNum)
      _stmt.setInt(25, entity.contentEntryLastChangedBy)
      _stmt.setLong(26, entity.contentEntryLct)
      _stmt.setLong(27, entity.contentEntryUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  override suspend fun findByUidAsync(entryUid: Long): ContentEntry? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM ContentEntry WHERE contentEntryUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM ContentEntry WHERE contentEntryUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,entryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override suspend fun findEntryWithLanguageByEntryIdAsync(entryUuid: Long):
      ContentEntryAndLanguage? = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT ContentEntry.*, Language.* 
    |          FROM ContentEntry 
    |               LEFT JOIN Language 
    |                         ON Language.langUid = ContentEntry.primaryLanguageUid
    |         WHERE ContentEntry.contentEntryUid=CAST(? AS BIGINT)
    |        
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT ContentEntry.*, Language.* 
    |          FROM ContentEntry 
    |               LEFT JOIN Language 
    |                         ON Language.langUid = ContentEntry.primaryLanguageUid
    |         WHERE ContentEntry.contentEntryUid=?
    |        
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,entryUuid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        var _tmp_ContentEntry_nullCount = 0
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_title = _result.getString("title")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_description = _result.getString("description")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_entryId = _result.getString("entryId")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_author = _result.getString("author")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publisher = _result.getString("publisher")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseType = _result.getInt("licenseType")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseName = _result.getString("licenseName")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentFlags = _result.getInt("contentFlags")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_leaf = _result.getBoolean("leaf")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publik = _result.getBoolean("publik")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_minScore = _result.getInt("minScore")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentOwner = _result.getLong("contentOwner")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        ContentEntryAndLanguage().apply {
          if(!_tmp_ContentEntry_isAllNull) {
            this.contentEntry = ContentEntry().apply {
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentOwnerType = _tmp_contentOwnerType
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
            }
          }
          if(!_tmp_Language_isAllNull) {
            this.language = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
        }
      }
    }
  }

  override suspend fun findByUidWithEditDetails(uid: Long): ContentEntryAndPicture? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT ContentEntry.*, ContentEntryPicture2.*
    |          FROM ContentEntry
    |               LEFT JOIN ContentEntryPicture2 
    |                         ON ContentEntryPicture2.cepUid = CAST(? AS BIGINT)
    |         WHERE ContentEntry.contentEntryUid = CAST(? AS BIGINT)                
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT ContentEntry.*, ContentEntryPicture2.*
    |          FROM ContentEntry
    |               LEFT JOIN ContentEntryPicture2 
    |                         ON ContentEntryPicture2.cepUid = ?
    |         WHERE ContentEntry.contentEntryUid = ?                
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.setLong(2,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        var _tmp_ContentEntry_nullCount = 0
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_title = _result.getString("title")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_description = _result.getString("description")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_entryId = _result.getString("entryId")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_author = _result.getString("author")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publisher = _result.getString("publisher")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseType = _result.getInt("licenseType")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseName = _result.getString("licenseName")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentFlags = _result.getInt("contentFlags")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_leaf = _result.getBoolean("leaf")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_publik = _result.getBoolean("publik")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_minScore = _result.getInt("minScore")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentOwner = _result.getLong("contentOwner")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        if(_result.wasNull()) _tmp_ContentEntry_nullCount++
        val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
        var _tmp_ContentEntryPicture2_nullCount = 0
        val _tmp_cepUid = _result.getLong("cepUid")
        if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
        val _tmp_cepLct = _result.getLong("cepLct")
        if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
        val _tmp_cepPictureUri = _result.getString("cepPictureUri")
        if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
        val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
        if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
        val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
        ContentEntryAndPicture().apply {
          if(!_tmp_ContentEntry_isAllNull) {
            this.entry = ContentEntry().apply {
              this.contentEntryUid = _tmp_contentEntryUid
              this.title = _tmp_title
              this.description = _tmp_description
              this.entryId = _tmp_entryId
              this.author = _tmp_author
              this.publisher = _tmp_publisher
              this.licenseType = _tmp_licenseType
              this.licenseName = _tmp_licenseName
              this.licenseUrl = _tmp_licenseUrl
              this.sourceUrl = _tmp_sourceUrl
              this.thumbnailUrl = _tmp_thumbnailUrl
              this.lastModified = _tmp_lastModified
              this.primaryLanguageUid = _tmp_primaryLanguageUid
              this.languageVariantUid = _tmp_languageVariantUid
              this.contentFlags = _tmp_contentFlags
              this.leaf = _tmp_leaf
              this.publik = _tmp_publik
              this.ceInactive = _tmp_ceInactive
              this.completionCriteria = _tmp_completionCriteria
              this.minScore = _tmp_minScore
              this.contentTypeFlag = _tmp_contentTypeFlag
              this.contentOwner = _tmp_contentOwner
              this.contentOwnerType = _tmp_contentOwnerType
              this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
              this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
              this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
              this.contentEntryLct = _tmp_contentEntryLct
            }
          }
          if(!_tmp_ContentEntryPicture2_isAllNull) {
            this.picture = ContentEntryPicture2().apply {
              this.cepUid = _tmp_cepUid
              this.cepLct = _tmp_cepLct
              this.cepPictureUri = _tmp_cepPictureUri
              this.cepThumbnailUri = _tmp_cepThumbnailUri
            }
          }
        }
      }
    }
  }

  override fun findEntryWithContainerByEntryIdLive(entryUuid: Long): Flow<ContentEntryAndDetail?> =
      _db.doorFlow(arrayOf("ContentEntryVersion", "ContentEntry", "ContentEntryPicture2")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |            SELECT ContentEntry.*, ContentEntryVersion.*, ContentEntryPicture2.*
      |              FROM ContentEntry
      |                   LEFT JOIN ContentEntryVersion
      |                             ON ContentEntryVersion.cevUid = 
      |                             (SELECT ContentEntryVersion.cevUid
      |                                FROM ContentEntryVersion
      |                               WHERE ContentEntryVersion.cevContentEntryUid = CAST(? AS BIGINT)
      |                                 AND CAST(cevInActive AS INTEGER) = 0
      |                            ORDER BY ContentEntryVersion.cevLct DESC
      |                              LIMIT 1)
      |                   LEFT JOIN ContentEntryPicture2
      |                             ON ContentEntryPicture2.cepUid = CAST(? AS BIGINT)   
      |             WHERE ContentEntry.contentEntryUid = CAST(? AS BIGINT)
      |            
      """.trimMargin(),
      postgreSql = """
      |
      |            SELECT ContentEntry.*, ContentEntryVersion.*, ContentEntryPicture2.*
      |              FROM ContentEntry
      |                   LEFT JOIN ContentEntryVersion
      |                             ON ContentEntryVersion.cevUid = 
      |                             (SELECT ContentEntryVersion.cevUid
      |                                FROM ContentEntryVersion
      |                               WHERE ContentEntryVersion.cevContentEntryUid = ?
      |                                 AND CAST(cevInActive AS INTEGER) = 0
      |                            ORDER BY ContentEntryVersion.cevLct DESC
      |                              LIMIT 1)
      |                   LEFT JOIN ContentEntryPicture2
      |                             ON ContentEntryPicture2.cepUid = ?   
      |             WHERE ContentEntry.contentEntryUid = ?
      |            
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,entryUuid)
      _stmt.setLong(2,entryUuid)
      _stmt.setLong(3,entryUuid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          var _tmp_ContentEntry_nullCount = 0
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_title = _result.getString("title")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_description = _result.getString("description")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_entryId = _result.getString("entryId")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_author = _result.getString("author")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publisher = _result.getString("publisher")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseType = _result.getInt("licenseType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseName = _result.getString("licenseName")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_lastModified = _result.getLong("lastModified")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentFlags = _result.getInt("contentFlags")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_leaf = _result.getBoolean("leaf")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publik = _result.getBoolean("publik")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_minScore = _result.getInt("minScore")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwner = _result.getLong("contentOwner")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
          var _tmp_ContentEntryVersion_nullCount = 0
          val _tmp_cevUid = _result.getLong("cevUid")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevContentEntryUid = _result.getLong("cevContentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevOpenUri = _result.getString("cevOpenUri")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevContentType = _result.getString("cevContentType")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevManifestUrl = _result.getString("cevManifestUrl")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevSize = _result.getLong("cevSize")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevInActive = _result.getBoolean("cevInActive")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevLastModified = _result.getLong("cevLastModified")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevLct = _result.getLong("cevLct")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevStorageSize = _result.getLong("cevStorageSize")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_cevOriginalSize = _result.getLong("cevOriginalSize")
          if(_result.wasNull()) _tmp_ContentEntryVersion_nullCount++
          val _tmp_ContentEntryVersion_isAllNull = _tmp_ContentEntryVersion_nullCount == 11
          var _tmp_ContentEntryPicture2_nullCount = 0
          val _tmp_cepUid = _result.getLong("cepUid")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepLct = _result.getLong("cepLct")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepPictureUri = _result.getString("cepPictureUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
          ContentEntryAndDetail().apply {
            if(!_tmp_ContentEntry_isAllNull) {
              this.entry = ContentEntry().apply {
                this.contentEntryUid = _tmp_contentEntryUid
                this.title = _tmp_title
                this.description = _tmp_description
                this.entryId = _tmp_entryId
                this.author = _tmp_author
                this.publisher = _tmp_publisher
                this.licenseType = _tmp_licenseType
                this.licenseName = _tmp_licenseName
                this.licenseUrl = _tmp_licenseUrl
                this.sourceUrl = _tmp_sourceUrl
                this.thumbnailUrl = _tmp_thumbnailUrl
                this.lastModified = _tmp_lastModified
                this.primaryLanguageUid = _tmp_primaryLanguageUid
                this.languageVariantUid = _tmp_languageVariantUid
                this.contentFlags = _tmp_contentFlags
                this.leaf = _tmp_leaf
                this.publik = _tmp_publik
                this.ceInactive = _tmp_ceInactive
                this.completionCriteria = _tmp_completionCriteria
                this.minScore = _tmp_minScore
                this.contentTypeFlag = _tmp_contentTypeFlag
                this.contentOwner = _tmp_contentOwner
                this.contentOwnerType = _tmp_contentOwnerType
                this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                this.contentEntryLct = _tmp_contentEntryLct
              }
            }
            if(!_tmp_ContentEntryVersion_isAllNull) {
              this.latestVersion = ContentEntryVersion().apply {
                this.cevUid = _tmp_cevUid
                this.cevContentEntryUid = _tmp_cevContentEntryUid
                this.cevOpenUri = _tmp_cevOpenUri
                this.cevContentType = _tmp_cevContentType
                this.cevManifestUrl = _tmp_cevManifestUrl
                this.cevSize = _tmp_cevSize
                this.cevInActive = _tmp_cevInActive
                this.cevLastModified = _tmp_cevLastModified
                this.cevLct = _tmp_cevLct
                this.cevStorageSize = _tmp_cevStorageSize
                this.cevOriginalSize = _tmp_cevOriginalSize
              }
            }
            if(!_tmp_ContentEntryPicture2_isAllNull) {
              this.picture = ContentEntryPicture2().apply {
                this.cepUid = _tmp_cepUid
                this.cepLct = _tmp_cepLct
                this.cepPictureUri = _tmp_cepPictureUri
                this.cepThumbnailUri = _tmp_cepThumbnailUri
              }
            }
          }
        }
      }
    }
  }

  override fun findBySourceUrl(sourceUrl: String): ContentEntry? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM ContentEntry WHERE sourceUrl = ? LIMIT 1",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,sourceUrl)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override suspend fun findTitleByUidAsync(entryUid: Long): String? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT title FROM ContentEntry WHERE contentEntryUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT title FROM ContentEntry WHERE contentEntryUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,entryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }

  override fun getChildrenByParentUid(parentUid: Long): PagingSource<Int, ContentEntry> = object :
      DoorLimitOffsetPagingSource<ContentEntry>(db = _db
  , tableNames = arrayOf("ContentEntry", "ContentEntryParentChildJoin")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<ContentEntry> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "SELECT * FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = CAST(? AS BIGINT)) AS _PagingData LIMIT ? OFFSET ?",
      postgreSql = """
      |SELECT * FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,parentUid)
      _stmt.setInt(2,_limit)
      _stmt.setInt(3,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          ContentEntry().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentOwnerType = _tmp_contentOwnerType
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "SELECT COUNT(*) FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = CAST(? AS BIGINT)) AS _PagingCount",
      postgreSql = """
      |SELECT COUNT(*) FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,parentUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override suspend fun getChildrenByParentAsync(parentUid: Long): List<ContentEntry> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT ContentEntry.*
    |          FROM ContentEntryParentChildJoin
    |               JOIN ContentEntry 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
    |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = CAST(? AS BIGINT)
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT ContentEntry.*
    |          FROM ContentEntryParentChildJoin
    |               JOIN ContentEntry 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
    |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override suspend fun getCountNumberOfChildrenByParentUUidAsync(parentUid: Long): Int =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT COUNT(*) FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT COUNT(*) FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(0) {
        _result.getInt(1)
      }
    }
  }

  override suspend fun getContentByUuidAsync(parentUid: Long): ContentEntry? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM ContentEntry where contentEntryUid = CAST(? AS BIGINT) LIMIT 1",
    postgreSql = """
    |SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override suspend fun findAllLanguageRelatedEntriesAsync(entryUuid: Long): List<ContentEntry> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryRelatedEntryJoin ON ContentEntryRelatedEntryJoin.cerejRelatedEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryRelatedEntryJoin.relType = 1 AND ContentEntryRelatedEntryJoin.cerejRelatedEntryUid != CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryRelatedEntryJoin ON ContentEntryRelatedEntryJoin.cerejRelatedEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryRelatedEntryJoin.relType = 1 AND ContentEntryRelatedEntryJoin.cerejRelatedEntryUid != ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,entryUuid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override suspend fun findByUidWithLanguageAsync(uid: Long): ContentEntryWithLanguage? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT ContentEntry.*, Language.*
    |          FROM ContentEntry
    |               LEFT JOIN Language 
    |                      ON Language.langUid = ContentEntry.primaryLanguageUid 
    |         WHERE ContentEntry.contentEntryUid = CAST(? AS BIGINT)              
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT ContentEntry.*, Language.*
    |          FROM ContentEntry
    |               LEFT JOIN Language 
    |                      ON Language.langUid = ContentEntry.primaryLanguageUid 
    |         WHERE ContentEntry.contentEntryUid = ?              
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        var _tmp_Language_nullCount = 0
        val _tmp_langUid = _result.getLong("langUid")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_name = _result.getString("name")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_Type = _result.getString("Language_Type")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_languageActive = _result.getBoolean("languageActive")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_langLct = _result.getLong("langLct")
        if(_result.wasNull()) _tmp_Language_nullCount++
        val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
        ContentEntryWithLanguage().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
          if(!_tmp_Language_isAllNull) {
            this.language = Language().apply {
              this.langUid = _tmp_langUid
              this.name = _tmp_name
              this.iso_639_1_standard = _tmp_iso_639_1_standard
              this.iso_639_2_standard = _tmp_iso_639_2_standard
              this.iso_639_3_standard = _tmp_iso_639_3_standard
              this.Language_Type = _tmp_Language_Type
              this.languageActive = _tmp_languageActive
              this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
              this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
              this.langLastChangedBy = _tmp_langLastChangedBy
              this.langLct = _tmp_langLct
            }
          }
        }
      }
    }
  }

  override fun findByUid(entryUid: Long): ContentEntry? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM ContentEntry WHERE contentEntryUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM ContentEntry WHERE contentEntryUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,entryUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override fun findByTitle(title: String): Flow<ContentEntry?> =
      _db.doorFlow(arrayOf("ContentEntry")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * FROM ContentEntry WHERE title = ?",
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setString(1,title)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          ContentEntry().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentOwnerType = _tmp_contentOwnerType
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
          }
        }
      }
    }
  }

  override suspend fun findBySourceUrlWithContentEntryStatusAsync(sourceUrl: String): ContentEntry?
      = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT ContentEntry.* FROM ContentEntry WHERE ContentEntry.sourceUrl = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,sourceUrl)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override fun getChildrenByParentUidWithCategoryFilterOrderByName(
    parentUid: Long,
    langParam: Long,
    categoryParam0: Long,
    sortOrder: Int,
    includeDeleted: Boolean,
  ): PagingSource<Int, ContentEntryAndListDetail> = object :
      DoorLimitOffsetPagingSource<ContentEntryAndListDetail>(db = _db
  , tableNames = arrayOf("ContentEntry", "ContentEntryContentCategoryJoin", "ContentEntryPicture2",
      "ContentEntryParentChildJoin")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<ContentEntryAndListDetail> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (
      |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |              FROM ContentEntry 
      |                    LEFT JOIN ContentEntryParentChildJoin 
      |                         ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                    LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |             WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = CAST(? AS BIGINT) 
      |               AND (CAST(? AS BIGINT) = 0 OR ContentEntry.primaryLanguageUid = CAST(? AS BIGINT))
      |               AND (CAST(? AS BIGINT) = 0 OR CAST(? AS BIGINT) 
      |                    IN (SELECT ceccjContentCategoryUid 
      |                          FROM ContentEntryContentCategoryJoin 
      |                         WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
      |               AND (CAST(? AS INTEGER) = 1 OR CAST(ContentEntryParentChildJoin.cepcjDeleted AS INTEGER) = 0)          
      |            ORDER BY ContentEntryParentChildJoin.childIndex,
      |                     CASE(?)
      |                     WHEN 1 THEN ContentEntry.title
      |                     ELSE ''
      |                     END ASC,
      |                     CASE(?)
      |                     WHEN 2 THEN ContentEntry.title
      |                     ELSE ''
      |                     END DESC,             
      |                     ContentEntry.contentEntryUid) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      postgreSql = """
      |SELECT * FROM (
      |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |              FROM ContentEntry 
      |                    LEFT JOIN ContentEntryParentChildJoin 
      |                         ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                    LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |             WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
      |               AND (? = 0 OR ContentEntry.primaryLanguageUid = ?)
      |               AND (? = 0 OR ? 
      |                    IN (SELECT ceccjContentCategoryUid 
      |                          FROM ContentEntryContentCategoryJoin 
      |                         WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
      |               AND (CAST(? AS INTEGER) = 1 OR CAST(ContentEntryParentChildJoin.cepcjDeleted AS INTEGER) = 0)          
      |            ORDER BY ContentEntryParentChildJoin.childIndex,
      |                     CASE(?)
      |                     WHEN 1 THEN ContentEntry.title
      |                     ELSE ''
      |                     END ASC,
      |                     CASE(?)
      |                     WHEN 2 THEN ContentEntry.title
      |                     ELSE ''
      |                     END DESC,             
      |                     ContentEntry.contentEntryUid) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,parentUid)
      _stmt.setLong(2,langParam)
      _stmt.setLong(3,langParam)
      _stmt.setLong(4,categoryParam0)
      _stmt.setLong(5,categoryParam0)
      _stmt.setBoolean(6,includeDeleted)
      _stmt.setInt(7,sortOrder)
      _stmt.setInt(8,sortOrder)
      _stmt.setInt(9,_limit)
      _stmt.setInt(10,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_ContentEntry_nullCount = 0
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_title = _result.getString("title")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_description = _result.getString("description")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_entryId = _result.getString("entryId")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_author = _result.getString("author")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publisher = _result.getString("publisher")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseType = _result.getInt("licenseType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseName = _result.getString("licenseName")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_lastModified = _result.getLong("lastModified")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentFlags = _result.getInt("contentFlags")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_leaf = _result.getBoolean("leaf")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publik = _result.getBoolean("publik")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_minScore = _result.getInt("minScore")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwner = _result.getLong("contentOwner")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
          var _tmp_ContentEntryPicture2_nullCount = 0
          val _tmp_cepUid = _result.getLong("cepUid")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepLct = _result.getLong("cepLct")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepPictureUri = _result.getString("cepPictureUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
          var _tmp_ContentEntryParentChildJoin_nullCount = 0
          val _tmp_cepcjUid = _result.getLong("cepcjUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLct = _result.getLong("cepcjLct")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_childIndex = _result.getInt("childIndex")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 9
          ContentEntryAndListDetail().apply {
            if(!_tmp_ContentEntry_isAllNull) {
              this.contentEntry = ContentEntry().apply {
                this.contentEntryUid = _tmp_contentEntryUid
                this.title = _tmp_title
                this.description = _tmp_description
                this.entryId = _tmp_entryId
                this.author = _tmp_author
                this.publisher = _tmp_publisher
                this.licenseType = _tmp_licenseType
                this.licenseName = _tmp_licenseName
                this.licenseUrl = _tmp_licenseUrl
                this.sourceUrl = _tmp_sourceUrl
                this.thumbnailUrl = _tmp_thumbnailUrl
                this.lastModified = _tmp_lastModified
                this.primaryLanguageUid = _tmp_primaryLanguageUid
                this.languageVariantUid = _tmp_languageVariantUid
                this.contentFlags = _tmp_contentFlags
                this.leaf = _tmp_leaf
                this.publik = _tmp_publik
                this.ceInactive = _tmp_ceInactive
                this.completionCriteria = _tmp_completionCriteria
                this.minScore = _tmp_minScore
                this.contentTypeFlag = _tmp_contentTypeFlag
                this.contentOwner = _tmp_contentOwner
                this.contentOwnerType = _tmp_contentOwnerType
                this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                this.contentEntryLct = _tmp_contentEntryLct
              }
            }
            if(!_tmp_ContentEntryPicture2_isAllNull) {
              this.picture = ContentEntryPicture2().apply {
                this.cepUid = _tmp_cepUid
                this.cepLct = _tmp_cepLct
                this.cepPictureUri = _tmp_cepPictureUri
                this.cepThumbnailUri = _tmp_cepThumbnailUri
              }
            }
            if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
              this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                this.cepcjUid = _tmp_cepcjUid
                this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                this.cepcjLct = _tmp_cepcjLct
                this.cepcjDeleted = _tmp_cepcjDeleted
                this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                this.childIndex = _tmp_childIndex
              }
            }
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (
      |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |              FROM ContentEntry 
      |                    LEFT JOIN ContentEntryParentChildJoin 
      |                         ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                    LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |             WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = CAST(? AS BIGINT) 
      |               AND (CAST(? AS BIGINT) = 0 OR ContentEntry.primaryLanguageUid = CAST(? AS BIGINT))
      |               AND (CAST(? AS BIGINT) = 0 OR CAST(? AS BIGINT) 
      |                    IN (SELECT ceccjContentCategoryUid 
      |                          FROM ContentEntryContentCategoryJoin 
      |                         WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
      |               AND (CAST(? AS INTEGER) = 1 OR CAST(ContentEntryParentChildJoin.cepcjDeleted AS INTEGER) = 0)          
      |            ORDER BY ContentEntryParentChildJoin.childIndex,
      |                     CASE(?)
      |                     WHEN 1 THEN ContentEntry.title
      |                     ELSE ''
      |                     END ASC,
      |                     CASE(?)
      |                     WHEN 2 THEN ContentEntry.title
      |                     ELSE ''
      |                     END DESC,             
      |                     ContentEntry.contentEntryUid) AS _PagingCount
      """.trimMargin(),
      postgreSql = """
      |SELECT COUNT(*) FROM (
      |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |              FROM ContentEntry 
      |                    LEFT JOIN ContentEntryParentChildJoin 
      |                         ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
      |                    LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |             WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
      |               AND (? = 0 OR ContentEntry.primaryLanguageUid = ?)
      |               AND (? = 0 OR ? 
      |                    IN (SELECT ceccjContentCategoryUid 
      |                          FROM ContentEntryContentCategoryJoin 
      |                         WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
      |               AND (CAST(? AS INTEGER) = 1 OR CAST(ContentEntryParentChildJoin.cepcjDeleted AS INTEGER) = 0)          
      |            ORDER BY ContentEntryParentChildJoin.childIndex,
      |                     CASE(?)
      |                     WHEN 1 THEN ContentEntry.title
      |                     ELSE ''
      |                     END ASC,
      |                     CASE(?)
      |                     WHEN 2 THEN ContentEntry.title
      |                     ELSE ''
      |                     END DESC,             
      |                     ContentEntry.contentEntryUid) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,parentUid)
      _stmt.setLong(2,langParam)
      _stmt.setLong(3,langParam)
      _stmt.setLong(4,categoryParam0)
      _stmt.setLong(5,categoryParam0)
      _stmt.setBoolean(6,includeDeleted)
      _stmt.setInt(7,sortOrder)
      _stmt.setInt(8,sortOrder)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override fun getContentFromMyCourses(personUid: Long):
      PagingSource<Int, ContentEntryAndListDetail> = object :
      DoorLimitOffsetPagingSource<ContentEntryAndListDetail>(db = _db
  , tableNames = arrayOf("ContentEntry", "CourseBlock", "ClazzEnrolment", "ContentEntryPicture2",
      "ContentEntryParentChildJoin")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<ContentEntryAndListDetail> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               JOIN ContentEntry 
      |                    ON CourseBlock.cbType = 104
      |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid          
      |         WHERE CourseBlock.cbClazzUid IN
      |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
      |                  FROM ClazzEnrolment
      |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = CAST(? AS BIGINT))
      |    ) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      postgreSql = """
      |SELECT * FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               JOIN ContentEntry 
      |                    ON CourseBlock.cbType = 104
      |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid          
      |         WHERE CourseBlock.cbClazzUid IN
      |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
      |                  FROM ClazzEnrolment
      |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
      |    ) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.setInt(2,_limit)
      _stmt.setInt(3,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_ContentEntry_nullCount = 0
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_title = _result.getString("title")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_description = _result.getString("description")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_entryId = _result.getString("entryId")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_author = _result.getString("author")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publisher = _result.getString("publisher")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseType = _result.getInt("licenseType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseName = _result.getString("licenseName")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_lastModified = _result.getLong("lastModified")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentFlags = _result.getInt("contentFlags")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_leaf = _result.getBoolean("leaf")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publik = _result.getBoolean("publik")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_minScore = _result.getInt("minScore")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwner = _result.getLong("contentOwner")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
          var _tmp_ContentEntryPicture2_nullCount = 0
          val _tmp_cepUid = _result.getLong("cepUid")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepLct = _result.getLong("cepLct")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepPictureUri = _result.getString("cepPictureUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
          var _tmp_ContentEntryParentChildJoin_nullCount = 0
          val _tmp_cepcjUid = _result.getLong("cepcjUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLct = _result.getLong("cepcjLct")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_childIndex = _result.getInt("childIndex")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 9
          ContentEntryAndListDetail().apply {
            if(!_tmp_ContentEntry_isAllNull) {
              this.contentEntry = ContentEntry().apply {
                this.contentEntryUid = _tmp_contentEntryUid
                this.title = _tmp_title
                this.description = _tmp_description
                this.entryId = _tmp_entryId
                this.author = _tmp_author
                this.publisher = _tmp_publisher
                this.licenseType = _tmp_licenseType
                this.licenseName = _tmp_licenseName
                this.licenseUrl = _tmp_licenseUrl
                this.sourceUrl = _tmp_sourceUrl
                this.thumbnailUrl = _tmp_thumbnailUrl
                this.lastModified = _tmp_lastModified
                this.primaryLanguageUid = _tmp_primaryLanguageUid
                this.languageVariantUid = _tmp_languageVariantUid
                this.contentFlags = _tmp_contentFlags
                this.leaf = _tmp_leaf
                this.publik = _tmp_publik
                this.ceInactive = _tmp_ceInactive
                this.completionCriteria = _tmp_completionCriteria
                this.minScore = _tmp_minScore
                this.contentTypeFlag = _tmp_contentTypeFlag
                this.contentOwner = _tmp_contentOwner
                this.contentOwnerType = _tmp_contentOwnerType
                this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                this.contentEntryLct = _tmp_contentEntryLct
              }
            }
            if(!_tmp_ContentEntryPicture2_isAllNull) {
              this.picture = ContentEntryPicture2().apply {
                this.cepUid = _tmp_cepUid
                this.cepLct = _tmp_cepLct
                this.cepPictureUri = _tmp_cepPictureUri
                this.cepThumbnailUri = _tmp_cepThumbnailUri
              }
            }
            if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
              this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                this.cepcjUid = _tmp_cepcjUid
                this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                this.cepcjLct = _tmp_cepcjLct
                this.cepcjDeleted = _tmp_cepcjDeleted
                this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                this.childIndex = _tmp_childIndex
              }
            }
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               JOIN ContentEntry 
      |                    ON CourseBlock.cbType = 104
      |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid          
      |         WHERE CourseBlock.cbClazzUid IN
      |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
      |                  FROM ClazzEnrolment
      |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = CAST(? AS BIGINT))
      |    ) AS _PagingCount
      """.trimMargin(),
      postgreSql = """
      |SELECT COUNT(*) FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM CourseBlock
      |               JOIN ContentEntry 
      |                    ON CourseBlock.cbType = 104
      |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
      |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid          
      |         WHERE CourseBlock.cbClazzUid IN
      |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
      |                  FROM ClazzEnrolment
      |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
      |    ) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override fun getContentByOwner(personUid: Long): PagingSource<Int, ContentEntryAndListDetail> =
      object : DoorLimitOffsetPagingSource<ContentEntryAndListDetail>(db = _db
  , tableNames = arrayOf("ContentEntry", "ContentEntryPicture2", "ContentEntryParentChildJoin")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<ContentEntryAndListDetail> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM ContentEntry
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |         WHERE ContentEntry.contentOwner = CAST(? AS BIGINT)
      |    ) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      postgreSql = """
      |SELECT * FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM ContentEntry
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |         WHERE ContentEntry.contentOwner = ?
      |    ) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.setInt(2,_limit)
      _stmt.setInt(3,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          var _tmp_ContentEntry_nullCount = 0
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_title = _result.getString("title")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_description = _result.getString("description")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_entryId = _result.getString("entryId")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_author = _result.getString("author")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publisher = _result.getString("publisher")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseType = _result.getInt("licenseType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseName = _result.getString("licenseName")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_lastModified = _result.getLong("lastModified")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentFlags = _result.getInt("contentFlags")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_leaf = _result.getBoolean("leaf")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_publik = _result.getBoolean("publik")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_minScore = _result.getInt("minScore")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwner = _result.getLong("contentOwner")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          if(_result.wasNull()) _tmp_ContentEntry_nullCount++
          val _tmp_ContentEntry_isAllNull = _tmp_ContentEntry_nullCount == 27
          var _tmp_ContentEntryPicture2_nullCount = 0
          val _tmp_cepUid = _result.getLong("cepUid")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepLct = _result.getLong("cepLct")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepPictureUri = _result.getString("cepPictureUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_cepThumbnailUri = _result.getString("cepThumbnailUri")
          if(_result.wasNull()) _tmp_ContentEntryPicture2_nullCount++
          val _tmp_ContentEntryPicture2_isAllNull = _tmp_ContentEntryPicture2_nullCount == 4
          var _tmp_ContentEntryParentChildJoin_nullCount = 0
          val _tmp_cepcjUid = _result.getLong("cepcjUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjLct = _result.getLong("cepcjLct")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_childIndex = _result.getInt("childIndex")
          if(_result.wasNull()) _tmp_ContentEntryParentChildJoin_nullCount++
          val _tmp_ContentEntryParentChildJoin_isAllNull = _tmp_ContentEntryParentChildJoin_nullCount == 9
          ContentEntryAndListDetail().apply {
            if(!_tmp_ContentEntry_isAllNull) {
              this.contentEntry = ContentEntry().apply {
                this.contentEntryUid = _tmp_contentEntryUid
                this.title = _tmp_title
                this.description = _tmp_description
                this.entryId = _tmp_entryId
                this.author = _tmp_author
                this.publisher = _tmp_publisher
                this.licenseType = _tmp_licenseType
                this.licenseName = _tmp_licenseName
                this.licenseUrl = _tmp_licenseUrl
                this.sourceUrl = _tmp_sourceUrl
                this.thumbnailUrl = _tmp_thumbnailUrl
                this.lastModified = _tmp_lastModified
                this.primaryLanguageUid = _tmp_primaryLanguageUid
                this.languageVariantUid = _tmp_languageVariantUid
                this.contentFlags = _tmp_contentFlags
                this.leaf = _tmp_leaf
                this.publik = _tmp_publik
                this.ceInactive = _tmp_ceInactive
                this.completionCriteria = _tmp_completionCriteria
                this.minScore = _tmp_minScore
                this.contentTypeFlag = _tmp_contentTypeFlag
                this.contentOwner = _tmp_contentOwner
                this.contentOwnerType = _tmp_contentOwnerType
                this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
                this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
                this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
                this.contentEntryLct = _tmp_contentEntryLct
              }
            }
            if(!_tmp_ContentEntryPicture2_isAllNull) {
              this.picture = ContentEntryPicture2().apply {
                this.cepUid = _tmp_cepUid
                this.cepLct = _tmp_cepLct
                this.cepPictureUri = _tmp_cepPictureUri
                this.cepThumbnailUri = _tmp_cepThumbnailUri
              }
            }
            if(!_tmp_ContentEntryParentChildJoin_isAllNull) {
              this.contentEntryParentChildJoin = ContentEntryParentChildJoin().apply {
                this.cepcjUid = _tmp_cepcjUid
                this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
                this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
                this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
                this.cepcjLct = _tmp_cepcjLct
                this.cepcjDeleted = _tmp_cepcjDeleted
                this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
                this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
                this.childIndex = _tmp_childIndex
              }
            }
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM ContentEntry
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |         WHERE ContentEntry.contentOwner = CAST(? AS BIGINT)
      |    ) AS _PagingCount
      """.trimMargin(),
      postgreSql = """
      |SELECT COUNT(*) FROM (
      |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, ContentEntryPicture2.*
      |          FROM ContentEntry
      |               LEFT JOIN ContentEntryParentChildJoin
      |                         ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = 0
      |               LEFT JOIN ContentEntryPicture2
      |                         ON ContentEntryPicture2.cepUid = ContentEntry.contentEntryUid
      |         WHERE ContentEntry.contentOwner = ?
      |    ) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override fun getChildrenByAll(parentUid: Long): List<ContentEntry> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override fun findLiveContentEntry(parentUid: Long): Flow<ContentEntry?> =
      _db.doorFlow(arrayOf("ContentEntry")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * FROM ContentEntry where contentEntryUid = CAST(? AS BIGINT) LIMIT 1",
      postgreSql = """
      |SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,parentUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          ContentEntry().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentOwnerType = _tmp_contentOwnerType
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
          }
        }
      }
    }
  }

  override fun getContentEntryUidFromXapiObjectId(objectId: String): Long =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |SELECT COALESCE((SELECT contentEntryUid 
    |                                      FROM ContentEntry 
    |                                     WHERE entryId = ? 
    |                                     LIMIT 1),0) AS ID
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,objectId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(0L) {
        _result.getLong(1)
      }
    }
  }

  override fun findSimilarIdEntryForKhan(sourceUrl: String): List<ContentEntry> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM ContentEntry WHERE sourceUrl LIKE ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,sourceUrl)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override suspend fun getRecursiveDownloadTotals(contentEntryUid: Long): DownloadJobSizeInfo? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        WITH RECURSIVE 
    |               ContentEntry_recursive(contentEntryUid, containerSize) AS (
    |               SELECT contentEntryUid, 
    |                            (SELECT COALESCE((SELECT fileSize 
    |                                           FROM Container 
    |                                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                       ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize 
    |                 FROM ContentEntry 
    |                WHERE contentEntryUid = CAST(? AS BIGINT)
    |                  AND NOT ceInactive
    |        UNION 
    |            SELECT ContentEntry.contentEntryUid, 
    |                (SELECT COALESCE((SELECT fileSize 
    |                                    FROM Container 
    |                                   WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize  
    |                  FROM ContentEntry
    |             LEFT JOIN ContentEntryParentChildJoin 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid,
    |                            ContentEntry_recursive
    |                  WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid
    |                    AND NOT ceInactive)
    |        SELECT COUNT(*) AS numEntries, 
    |               SUM(containerSize) AS totalSize 
    |          FROM ContentEntry_recursive
    """.trimMargin(),
    postgreSql = """
    |
    |        WITH RECURSIVE 
    |               ContentEntry_recursive(contentEntryUid, containerSize) AS (
    |               SELECT contentEntryUid, 
    |                            (SELECT COALESCE((SELECT fileSize 
    |                                           FROM Container 
    |                                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                       ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize 
    |                 FROM ContentEntry 
    |                WHERE contentEntryUid = ?
    |                  AND NOT ceInactive
    |        UNION 
    |            SELECT ContentEntry.contentEntryUid, 
    |                (SELECT COALESCE((SELECT fileSize 
    |                                    FROM Container 
    |                                   WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize  
    |                  FROM ContentEntry
    |             LEFT JOIN ContentEntryParentChildJoin 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid,
    |                            ContentEntry_recursive
    |                  WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid
    |                    AND NOT ceInactive)
    |        SELECT COUNT(*) AS numEntries, 
    |               SUM(containerSize) AS totalSize 
    |          FROM ContentEntry_recursive
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,contentEntryUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_numEntries = _result.getInt("numEntries")
        val _tmp_totalSize = _result.getLong("totalSize")
        DownloadJobSizeInfo().apply {
          this.numEntries = _tmp_numEntries
          this.totalSize = _tmp_totalSize
        }
      }
    }
  }

  override fun updateContentEntryInActive(
    contentEntryUid: Long,
    ceInactive: Boolean,
    changedTime: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |
      |            UPDATE ContentEntry 
      |               SET ceInactive = ?,
      |                   contentEntryLct = CAST(? AS BIGINT)        
      |            WHERE ContentEntry.contentEntryUid = CAST(? AS BIGINT)
      """.trimMargin(),
      postgreSql = """
      |
      |            UPDATE ContentEntry 
      |               SET ceInactive = ?,
      |                   contentEntryLct = ?        
      |            WHERE ContentEntry.contentEntryUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,ceInactive)
      _stmt.setLong(2,changedTime)
      _stmt.setLong(3,contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  override fun updateContentEntryContentFlag(
    contentFlag: Int,
    contentEntryUid: Long,
    changedTime: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE ContentEntry 
      |           SET contentTypeFlag = ?,
      |               contentEntryLct = CAST(? AS BIGINT) 
      |         WHERE ContentEntry.contentEntryUid = CAST(? AS BIGINT)
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE ContentEntry 
      |           SET contentTypeFlag = ?,
      |               contentEntryLct = ? 
      |         WHERE ContentEntry.contentEntryUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setInt(1,contentFlag)
      _stmt.setLong(2,changedTime)
      _stmt.setLong(3,contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun getContentEntryFromUids(contentEntryUids: List<Long>): List<UidAndLabel> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |Select ContentEntry.contentEntryUid AS uid, ContentEntry.title As labelName 
    |                    from ContentEntry WHERE contentEntryUid IN (?)
    """.trimMargin(),
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        contentEntryUids.toTypedArray()))
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_uid = _result.getLong("uid")
        val _tmp_labelName = _result.getString("labelName")
        UidAndLabel().apply {
          this.uid = _tmp_uid
          this.labelName = _tmp_labelName
        }
      }
    }
  }

  override fun findAllLive(): Flow<List<ContentEntryWithLanguage>> =
      _db.doorFlow(arrayOf("Language", "ContentEntry")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "SELECT ContentEntry.*, Language.* FROM ContentEntry LEFT JOIN Language ON Language.langUid = ContentEntry.primaryLanguageUid",
      readOnly = true,)
    ) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
          val _tmp_title = _result.getString("title")
          val _tmp_description = _result.getString("description")
          val _tmp_entryId = _result.getString("entryId")
          val _tmp_author = _result.getString("author")
          val _tmp_publisher = _result.getString("publisher")
          val _tmp_licenseType = _result.getInt("licenseType")
          val _tmp_licenseName = _result.getString("licenseName")
          val _tmp_licenseUrl = _result.getString("licenseUrl")
          val _tmp_sourceUrl = _result.getString("sourceUrl")
          val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
          val _tmp_lastModified = _result.getLong("lastModified")
          val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
          val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
          val _tmp_contentFlags = _result.getInt("contentFlags")
          val _tmp_leaf = _result.getBoolean("leaf")
          val _tmp_publik = _result.getBoolean("publik")
          val _tmp_ceInactive = _result.getBoolean("ceInactive")
          val _tmp_completionCriteria = _result.getInt("completionCriteria")
          val _tmp_minScore = _result.getInt("minScore")
          val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
          val _tmp_contentOwner = _result.getLong("contentOwner")
          val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
          val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
          val _tmp_contentEntryMasterChangeSeqNum =
              _result.getLong("contentEntryMasterChangeSeqNum")
          val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
          val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
          var _tmp_Language_nullCount = 0
          val _tmp_langUid = _result.getLong("langUid")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_name = _result.getString("name")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_iso_639_1_standard = _result.getString("iso_639_1_standard")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_iso_639_2_standard = _result.getString("iso_639_2_standard")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_iso_639_3_standard = _result.getString("iso_639_3_standard")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_Language_Type = _result.getString("Language_Type")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_languageActive = _result.getBoolean("languageActive")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langLocalChangeSeqNum = _result.getLong("langLocalChangeSeqNum")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langMasterChangeSeqNum = _result.getLong("langMasterChangeSeqNum")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langLastChangedBy = _result.getInt("langLastChangedBy")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_langLct = _result.getLong("langLct")
          if(_result.wasNull()) _tmp_Language_nullCount++
          val _tmp_Language_isAllNull = _tmp_Language_nullCount == 11
          ContentEntryWithLanguage().apply {
            this.contentEntryUid = _tmp_contentEntryUid
            this.title = _tmp_title
            this.description = _tmp_description
            this.entryId = _tmp_entryId
            this.author = _tmp_author
            this.publisher = _tmp_publisher
            this.licenseType = _tmp_licenseType
            this.licenseName = _tmp_licenseName
            this.licenseUrl = _tmp_licenseUrl
            this.sourceUrl = _tmp_sourceUrl
            this.thumbnailUrl = _tmp_thumbnailUrl
            this.lastModified = _tmp_lastModified
            this.primaryLanguageUid = _tmp_primaryLanguageUid
            this.languageVariantUid = _tmp_languageVariantUid
            this.contentFlags = _tmp_contentFlags
            this.leaf = _tmp_leaf
            this.publik = _tmp_publik
            this.ceInactive = _tmp_ceInactive
            this.completionCriteria = _tmp_completionCriteria
            this.minScore = _tmp_minScore
            this.contentTypeFlag = _tmp_contentTypeFlag
            this.contentOwner = _tmp_contentOwner
            this.contentOwnerType = _tmp_contentOwnerType
            this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
            this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
            this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
            this.contentEntryLct = _tmp_contentEntryLct
            if(!_tmp_Language_isAllNull) {
              this.language = Language().apply {
                this.langUid = _tmp_langUid
                this.name = _tmp_name
                this.iso_639_1_standard = _tmp_iso_639_1_standard
                this.iso_639_2_standard = _tmp_iso_639_2_standard
                this.iso_639_3_standard = _tmp_iso_639_3_standard
                this.Language_Type = _tmp_Language_Type
                this.languageActive = _tmp_languageActive
                this.langLocalChangeSeqNum = _tmp_langLocalChangeSeqNum
                this.langMasterChangeSeqNum = _tmp_langMasterChangeSeqNum
                this.langLastChangedBy = _tmp_langLastChangedBy
                this.langLct = _tmp_langLct
              }
            }
          }
        }
      }
    }
  }

  override suspend fun toggleVisibilityContentEntryItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE ContentEntry 
      |           SET ceInactive = ?, 
      |               contentEntryLct = CAST(? AS BIGINT) 
      |         WHERE contentEntryUid IN (?)
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |
      |        UPDATE ContentEntry 
      |           SET ceInactive = ?, 
      |               contentEntryLct = ? 
      |         WHERE contentEntryUid IN (?)
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,toggleVisibility)
      _stmt.setLong(2,changedTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedItem.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override suspend
      fun findContentEntriesWhereIsLeafAndLatestContainerHasNoEntriesOrHasZeroFileSize():
      List<ContentEntry> = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |SELECT ContentEntry.*
    |  FROM ContentEntry
    |       JOIN Container ON Container.containerUid = 
    |       (SELECT containerUid 
    |          FROM Container
    |         WHERE Container.containercontententryUid = ContentEntry.contentEntryUid
    |           AND Container.cntLastModified = 
    |               (SELECT MAX(ContainerInternal.cntLastModified)
    |                  FROM Container ContainerInternal
    |                 WHERE ContainerInternal.containercontententryUid = ContentEntry.contentEntryUid))
    | WHERE ContentEntry.leaf 
    |   AND NOT ContentEntry.ceInactive
    |   AND (NOT EXISTS 
    |       (SELECT ContainerEntry.ceUid
    |          FROM ContainerEntry
    |         WHERE ContainerEntry.ceContainerUid = Container.containerUid)
    |        OR Container.fileSize = 0)   
    |    
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }
}
