package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ContentEntryParentChildJoinDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContentEntryParentChildJoinDao() {
  public val _insertAdapterContentEntryParentChildJoin_:
      EntityInsertionAdapter<ContentEntryParentChildJoin> = object :
      EntityInsertionAdapter<ContentEntryParentChildJoin>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ContentEntryParentChildJoin (cepcjUid, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct, cepcjDeleted, cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryParentChildJoin) {
      if(entity.cepcjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cepcjUid)
      }
      stmt.setLong(2, entity.cepcjLocalChangeSeqNum)
      stmt.setLong(3, entity.cepcjMasterChangeSeqNum)
      stmt.setInt(4, entity.cepcjLastChangedBy)
      stmt.setLong(5, entity.cepcjLct)
      stmt.setBoolean(6, entity.cepcjDeleted)
      stmt.setLong(7, entity.cepcjParentContentEntryUid)
      stmt.setLong(8, entity.cepcjChildContentEntryUid)
      stmt.setInt(9, entity.childIndex)
    }
  }

  public val _insertAdapterContentEntryParentChildJoin_upsert:
      EntityInsertionAdapter<ContentEntryParentChildJoin> = object :
      EntityInsertionAdapter<ContentEntryParentChildJoin>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO ContentEntryParentChildJoin (cepcjUid, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct, cepcjDeleted, cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryParentChildJoin) {
      if(entity.cepcjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cepcjUid)
      }
      stmt.setLong(2, entity.cepcjLocalChangeSeqNum)
      stmt.setLong(3, entity.cepcjMasterChangeSeqNum)
      stmt.setInt(4, entity.cepcjLastChangedBy)
      stmt.setLong(5, entity.cepcjLct)
      stmt.setBoolean(6, entity.cepcjDeleted)
      stmt.setLong(7, entity.cepcjParentContentEntryUid)
      stmt.setLong(8, entity.cepcjChildContentEntryUid)
      stmt.setInt(9, entity.childIndex)
    }
  }

  override suspend fun insertListAsync(entityList: List<ContentEntryParentChildJoin>) {
    _insertAdapterContentEntryParentChildJoin_.insertListAsync(entityList)
  }

  override fun replaceList(entries: List<ContentEntryParentChildJoin>) {
    _insertAdapterContentEntryParentChildJoin_upsert.insertList(entries)
  }

  override fun insertWithReplace(parentChildJoinDao: ContentEntryParentChildJoin) {
    _insertAdapterContentEntryParentChildJoin_upsert.insert(parentChildJoinDao)
  }

  public override fun insert(entity: ContentEntryParentChildJoin): Long {
    val _retVal = _insertAdapterContentEntryParentChildJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntryParentChildJoin): Long {
    val _retVal = _insertAdapterContentEntryParentChildJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContentEntryParentChildJoin>) {
    _insertAdapterContentEntryParentChildJoin_.insertList(entityList)
  }

  public override fun update(entity: ContentEntryParentChildJoin) {
    val _sql =
        "UPDATE ContentEntryParentChildJoin SET cepcjLocalChangeSeqNum = ?, cepcjMasterChangeSeqNum = ?, cepcjLastChangedBy = ?, cepcjLct = ?, cepcjDeleted = ?, cepcjParentContentEntryUid = ?, cepcjChildContentEntryUid = ?, childIndex = ? WHERE cepcjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cepcjLocalChangeSeqNum)
      _stmt.setLong(2, entity.cepcjMasterChangeSeqNum)
      _stmt.setInt(3, entity.cepcjLastChangedBy)
      _stmt.setLong(4, entity.cepcjLct)
      _stmt.setBoolean(5, entity.cepcjDeleted)
      _stmt.setLong(6, entity.cepcjParentContentEntryUid)
      _stmt.setLong(7, entity.cepcjChildContentEntryUid)
      _stmt.setInt(8, entity.childIndex)
      _stmt.setLong(9, entity.cepcjUid)
      _stmt.executeUpdate()
    }
  }

  override fun publicContentEntryParentChildJoins(): List<ContentEntryParentChildJoin> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT ContentEntryParentChildJoin.* FROM ContentEntryParentChildJoin LEFT JOIN ContentEntry parentEntry ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = parentEntry.contentEntryUid LEFT JOIN ContentEntry childEntry ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = childEntry.contentEntryUid WHERE parentEntry.publik AND childEntry.publik",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        val _tmp_childIndex = _result.getInt("childIndex")
        ContentEntryParentChildJoin().apply {
          this.cepcjUid = _tmp_cepcjUid
          this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
          this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
          this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
          this.cepcjLct = _tmp_cepcjLct
          this.cepcjDeleted = _tmp_cepcjDeleted
          this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
          this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
          this.childIndex = _tmp_childIndex
        }
      }
    }
  }

  override fun all(): List<ContentEntryParentChildJoin> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM ContentEntryParentChildJoin",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        val _tmp_childIndex = _result.getInt("childIndex")
        ContentEntryParentChildJoin().apply {
          this.cepcjUid = _tmp_cepcjUid
          this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
          this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
          this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
          this.cepcjLct = _tmp_cepcjLct
          this.cepcjDeleted = _tmp_cepcjDeleted
          this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
          this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
          this.childIndex = _tmp_childIndex
        }
      }
    }
  }

  override fun findParentByChildUuids(childEntryContentUid: Long): ContentEntryParentChildJoin? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = CAST(? AS BIGINT) LIMIT 1",
    postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,childEntryContentUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        val _tmp_childIndex = _result.getInt("childIndex")
        ContentEntryParentChildJoin().apply {
          this.cepcjUid = _tmp_cepcjUid
          this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
          this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
          this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
          this.cepcjLct = _tmp_cepcjLct
          this.cepcjDeleted = _tmp_cepcjDeleted
          this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
          this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
          this.childIndex = _tmp_childIndex
        }
      }
    }
  }

  override fun findListOfParentsByChildUuid(childEntryContentUid: Long):
      List<ContentEntryParentChildJoin> = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,childEntryContentUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        val _tmp_childIndex = _result.getInt("childIndex")
        ContentEntryParentChildJoin().apply {
          this.cepcjUid = _tmp_cepcjUid
          this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
          this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
          this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
          this.cepcjLct = _tmp_cepcjLct
          this.cepcjDeleted = _tmp_cepcjDeleted
          this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
          this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
          this.childIndex = _tmp_childIndex
        }
      }
    }
  }

  override suspend fun findListOfChildsByParentUuid(parentUid: Long):
      List<ContentEntryParentChildJoin> = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT * 
    |          FROM ContentEntryParentChildJoin 
    |         WHERE cepcjParentContentEntryUid = CAST(? AS BIGINT)
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT * 
    |          FROM ContentEntryParentChildJoin 
    |         WHERE cepcjParentContentEntryUid = ?
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        val _tmp_childIndex = _result.getInt("childIndex")
        ContentEntryParentChildJoin().apply {
          this.cepcjUid = _tmp_cepcjUid
          this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
          this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
          this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
          this.cepcjLct = _tmp_cepcjLct
          this.cepcjDeleted = _tmp_cepcjDeleted
          this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
          this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
          this.childIndex = _tmp_childIndex
        }
      }
    }
  }

  override fun findJoinByParentChildUuids(parentUid: Long, childUid: Long):
      ContentEntryParentChildJoin? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = CAST(? AS BIGINT) AND cepcjChildContentEntryUid = CAST(? AS BIGINT) LIMIT 1",
    postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ? AND cepcjChildContentEntryUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,parentUid)
    _stmt.setLong(2,childUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        val _tmp_childIndex = _result.getInt("childIndex")
        ContentEntryParentChildJoin().apply {
          this.cepcjUid = _tmp_cepcjUid
          this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
          this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
          this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
          this.cepcjLct = _tmp_cepcjLct
          this.cepcjDeleted = _tmp_cepcjDeleted
          this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
          this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
          this.childIndex = _tmp_childIndex
        }
      }
    }
  }

  override suspend fun selectTopEntries(): List<ContentEntry> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT ContentEntry.* FROM ContentEntry WHERE NOT EXISTS(SELECT cepcjUid FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ContentEntry.contentEntryUid) AND EXISTS(SELECT cepcjUid FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ContentEntry.contentEntryUid)",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_contentEntryUid = _result.getLong("contentEntryUid")
        val _tmp_title = _result.getString("title")
        val _tmp_description = _result.getString("description")
        val _tmp_entryId = _result.getString("entryId")
        val _tmp_author = _result.getString("author")
        val _tmp_publisher = _result.getString("publisher")
        val _tmp_licenseType = _result.getInt("licenseType")
        val _tmp_licenseName = _result.getString("licenseName")
        val _tmp_licenseUrl = _result.getString("licenseUrl")
        val _tmp_sourceUrl = _result.getString("sourceUrl")
        val _tmp_thumbnailUrl = _result.getString("thumbnailUrl")
        val _tmp_lastModified = _result.getLong("lastModified")
        val _tmp_primaryLanguageUid = _result.getLong("primaryLanguageUid")
        val _tmp_languageVariantUid = _result.getLong("languageVariantUid")
        val _tmp_contentFlags = _result.getInt("contentFlags")
        val _tmp_leaf = _result.getBoolean("leaf")
        val _tmp_publik = _result.getBoolean("publik")
        val _tmp_ceInactive = _result.getBoolean("ceInactive")
        val _tmp_completionCriteria = _result.getInt("completionCriteria")
        val _tmp_minScore = _result.getInt("minScore")
        val _tmp_contentTypeFlag = _result.getInt("contentTypeFlag")
        val _tmp_contentOwner = _result.getLong("contentOwner")
        val _tmp_contentOwnerType = _result.getInt("contentOwnerType")
        val _tmp_contentEntryLocalChangeSeqNum = _result.getLong("contentEntryLocalChangeSeqNum")
        val _tmp_contentEntryMasterChangeSeqNum = _result.getLong("contentEntryMasterChangeSeqNum")
        val _tmp_contentEntryLastChangedBy = _result.getInt("contentEntryLastChangedBy")
        val _tmp_contentEntryLct = _result.getLong("contentEntryLct")
        ContentEntry().apply {
          this.contentEntryUid = _tmp_contentEntryUid
          this.title = _tmp_title
          this.description = _tmp_description
          this.entryId = _tmp_entryId
          this.author = _tmp_author
          this.publisher = _tmp_publisher
          this.licenseType = _tmp_licenseType
          this.licenseName = _tmp_licenseName
          this.licenseUrl = _tmp_licenseUrl
          this.sourceUrl = _tmp_sourceUrl
          this.thumbnailUrl = _tmp_thumbnailUrl
          this.lastModified = _tmp_lastModified
          this.primaryLanguageUid = _tmp_primaryLanguageUid
          this.languageVariantUid = _tmp_languageVariantUid
          this.contentFlags = _tmp_contentFlags
          this.leaf = _tmp_leaf
          this.publik = _tmp_publik
          this.ceInactive = _tmp_ceInactive
          this.completionCriteria = _tmp_completionCriteria
          this.minScore = _tmp_minScore
          this.contentTypeFlag = _tmp_contentTypeFlag
          this.contentOwner = _tmp_contentOwner
          this.contentOwnerType = _tmp_contentOwnerType
          this.contentEntryLocalChangeSeqNum = _tmp_contentEntryLocalChangeSeqNum
          this.contentEntryMasterChangeSeqNum = _tmp_contentEntryMasterChangeSeqNum
          this.contentEntryLastChangedBy = _tmp_contentEntryLastChangedBy
          this.contentEntryLct = _tmp_contentEntryLct
        }
      }
    }
  }

  override suspend fun moveListOfEntriesToNewParent(
    contentEntryUid: Long,
    selectedItems: List<Long>,
    updateTime: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE ContentEntryParentChildJoin 
      |           SET cepcjParentContentEntryUid = CAST(? AS BIGINT), 
      |               cepcjLct = CAST(? AS BIGINT) 
      |               WHERE cepcjUid IN (?)
      |    
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |
      |        UPDATE ContentEntryParentChildJoin 
      |           SET cepcjParentContentEntryUid = ?, 
      |               cepcjLct = ? 
      |               WHERE cepcjUid IN (?)
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,contentEntryUid)
      _stmt.setLong(2,updateTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedItems.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override suspend fun setEntriesDeleted(
    selectedUids: List<Long>,
    isDeleted: Boolean,
    updateTime: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE ContentEntryParentChildJoin
      |           SET cepcjDeleted = ?,
      |               cepcjLct = CAST(? AS BIGINT)
      |         WHERE cepcjUid IN (?) 
      |    
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |
      |        UPDATE ContentEntryParentChildJoin
      |           SET cepcjDeleted = ?,
      |               cepcjLct = ?
      |         WHERE cepcjUid IN (?) 
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,isDeleted)
      _stmt.setLong(2,updateTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedUids.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override suspend fun findByUid(uid: Long): ContentEntryParentChildJoin? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = """
    |
    |        SELECT ContentEntryParentChildJoin.*
    |          FROM ContentEntryParentChildJoin
    |         WHERE  ContentEntryParentChildJoin.cepcjUid = CAST(? AS BIGINT)
    |    
    """.trimMargin(),
    postgreSql = """
    |
    |        SELECT ContentEntryParentChildJoin.*
    |          FROM ContentEntryParentChildJoin
    |         WHERE  ContentEntryParentChildJoin.cepcjUid = ?
    |    
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cepcjUid = _result.getLong("cepcjUid")
        val _tmp_cepcjLocalChangeSeqNum = _result.getLong("cepcjLocalChangeSeqNum")
        val _tmp_cepcjMasterChangeSeqNum = _result.getLong("cepcjMasterChangeSeqNum")
        val _tmp_cepcjLastChangedBy = _result.getInt("cepcjLastChangedBy")
        val _tmp_cepcjLct = _result.getLong("cepcjLct")
        val _tmp_cepcjDeleted = _result.getBoolean("cepcjDeleted")
        val _tmp_cepcjParentContentEntryUid = _result.getLong("cepcjParentContentEntryUid")
        val _tmp_cepcjChildContentEntryUid = _result.getLong("cepcjChildContentEntryUid")
        val _tmp_childIndex = _result.getInt("childIndex")
        ContentEntryParentChildJoin().apply {
          this.cepcjUid = _tmp_cepcjUid
          this.cepcjLocalChangeSeqNum = _tmp_cepcjLocalChangeSeqNum
          this.cepcjMasterChangeSeqNum = _tmp_cepcjMasterChangeSeqNum
          this.cepcjLastChangedBy = _tmp_cepcjLastChangedBy
          this.cepcjLct = _tmp_cepcjLct
          this.cepcjDeleted = _tmp_cepcjDeleted
          this.cepcjParentContentEntryUid = _tmp_cepcjParentContentEntryUid
          this.cepcjChildContentEntryUid = _tmp_cepcjChildContentEntryUid
          this.childIndex = _tmp_childIndex
        }
      }
    }
  }
}
