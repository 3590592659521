package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ChatMember
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ChatMemberDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ChatMemberDao() {
  public val _insertAdapterChatMember_: EntityInsertionAdapter<ChatMember> = object :
      EntityInsertionAdapter<ChatMember>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ChatMember (chatMemberUid, chatMemberChatUid, chatMemberPersonUid, chatMemberJoinedDate, chatMemberLeftDate, chatMemberLct) VALUES(?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ChatMember) {
      if(entity.chatMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.chatMemberUid)
      }
      stmt.setLong(2, entity.chatMemberChatUid)
      stmt.setLong(3, entity.chatMemberPersonUid)
      stmt.setLong(4, entity.chatMemberJoinedDate)
      stmt.setLong(5, entity.chatMemberLeftDate)
      stmt.setLong(6, entity.chatMemberLct)
    }
  }

  public override fun insert(entity: ChatMember): Long {
    val _retVal = _insertAdapterChatMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ChatMember): Long {
    val _retVal = _insertAdapterChatMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ChatMember>) {
    _insertAdapterChatMember_.insertList(entityList)
  }

  public override fun update(entity: ChatMember) {
    val _sql =
        "UPDATE ChatMember SET chatMemberChatUid = ?, chatMemberPersonUid = ?, chatMemberJoinedDate = ?, chatMemberLeftDate = ?, chatMemberLct = ? WHERE chatMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.chatMemberChatUid)
      _stmt.setLong(2, entity.chatMemberPersonUid)
      _stmt.setLong(3, entity.chatMemberJoinedDate)
      _stmt.setLong(4, entity.chatMemberLeftDate)
      _stmt.setLong(5, entity.chatMemberLct)
      _stmt.setLong(6, entity.chatMemberUid)
      _stmt.executeUpdate()
    }
  }
}
