package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.StateContentEntity
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class StateContentDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: StateContentDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : StateContentDao() {
  override fun findAllStateContentWithStateUid(id: Long): List<StateContentEntity> {
    val _result = _dao.findAllStateContentWithStateUid(id)
    return _result
  }

  override fun findStateContentByKeyAndStateUid(key: String, stateUid: Long): StateContentEntity? {
    val _result = _dao.findStateContentByKeyAndStateUid(key, stateUid)
    return _result
  }

  override fun setInActiveStateContentByKeyAndUid(
    isActive: Boolean,
    stateUid: Long,
    updateTime: Long,
  ) {
    _repo.withRepoChangeMonitor("StateContentEntity") {
      _dao.setInActiveStateContentByKeyAndUid(isActive, stateUid, updateTime)
    }
  }

  public override fun insert(entity: StateContentEntity): Long {
    val _result = _repo.withRepoChangeMonitor("StateContentEntity") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: StateContentEntity): Long {
    val _result = _repo.withRepoChangeMonitorAsync("StateContentEntity") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<StateContentEntity>) {
    _repo.withRepoChangeMonitor("StateContentEntity") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: StateContentEntity) {
    _repo.withRepoChangeMonitor("StateContentEntity") {
      _dao.update(entity)
    }
  }
}
