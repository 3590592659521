package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.SchoolMember
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class SchoolMemberDao_JdbcImpl(
  public val _db: RoomDatabase,
) : SchoolMemberDao() {
  public val _insertAdapterSchoolMember_: EntityInsertionAdapter<SchoolMember> = object :
      EntityInsertionAdapter<SchoolMember>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO SchoolMember (schoolMemberUid, schoolMemberPersonUid, schoolMemberSchoolUid, schoolMemberJoinDate, schoolMemberLeftDate, schoolMemberRole, schoolMemberActive, schoolMemberLocalChangeSeqNum, schoolMemberMasterChangeSeqNum, schoolMemberLastChangedBy, schoolMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: SchoolMember) {
      if(entity.schoolMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.schoolMemberUid)
      }
      stmt.setLong(2, entity.schoolMemberPersonUid)
      stmt.setLong(3, entity.schoolMemberSchoolUid)
      stmt.setLong(4, entity.schoolMemberJoinDate)
      stmt.setLong(5, entity.schoolMemberLeftDate)
      stmt.setInt(6, entity.schoolMemberRole)
      stmt.setBoolean(7, entity.schoolMemberActive)
      stmt.setLong(8, entity.schoolMemberLocalChangeSeqNum)
      stmt.setLong(9, entity.schoolMemberMasterChangeSeqNum)
      stmt.setInt(10, entity.schoolMemberLastChangedBy)
      stmt.setLong(11, entity.schoolMemberLct)
    }
  }

  public override fun insert(entity: SchoolMember): Long {
    val _retVal = _insertAdapterSchoolMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: SchoolMember): Long {
    val _retVal = _insertAdapterSchoolMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<SchoolMember>) {
    _insertAdapterSchoolMember_.insertList(entityList)
  }

  override suspend fun updateAsync(entity: SchoolMember): Int {
    var _result = 0
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.schoolMemberPersonUid)
      _stmt.setLong(2, entity.schoolMemberSchoolUid)
      _stmt.setLong(3, entity.schoolMemberJoinDate)
      _stmt.setLong(4, entity.schoolMemberLeftDate)
      _stmt.setInt(5, entity.schoolMemberRole)
      _stmt.setBoolean(6, entity.schoolMemberActive)
      _stmt.setLong(7, entity.schoolMemberLocalChangeSeqNum)
      _stmt.setLong(8, entity.schoolMemberMasterChangeSeqNum)
      _stmt.setInt(9, entity.schoolMemberLastChangedBy)
      _stmt.setLong(10, entity.schoolMemberLct)
      _stmt.setLong(11, entity.schoolMemberUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun update(entity: SchoolMember) {
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.schoolMemberPersonUid)
      _stmt.setLong(2, entity.schoolMemberSchoolUid)
      _stmt.setLong(3, entity.schoolMemberJoinDate)
      _stmt.setLong(4, entity.schoolMemberLeftDate)
      _stmt.setInt(5, entity.schoolMemberRole)
      _stmt.setBoolean(6, entity.schoolMemberActive)
      _stmt.setLong(7, entity.schoolMemberLocalChangeSeqNum)
      _stmt.setLong(8, entity.schoolMemberMasterChangeSeqNum)
      _stmt.setInt(9, entity.schoolMemberLastChangedBy)
      _stmt.setLong(10, entity.schoolMemberLct)
      _stmt.setLong(11, entity.schoolMemberUid)
      _stmt.executeUpdate()
    }
  }

  override fun findByUidAsync(schoolMemberUid: Long): SchoolMember? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM SchoolMember WHERE schoolMemberUid = CAST(? AS BIGINT)  AND CAST(schoolMemberActive AS INTEGER) = 1",
    postgreSql = """
    |SELECT * FROM SchoolMember WHERE schoolMemberUid = ?  AND CAST(schoolMemberActive AS INTEGER) = 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,schoolMemberUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_schoolMemberUid = _result.getLong("schoolMemberUid")
        val _tmp_schoolMemberPersonUid = _result.getLong("schoolMemberPersonUid")
        val _tmp_schoolMemberSchoolUid = _result.getLong("schoolMemberSchoolUid")
        val _tmp_schoolMemberJoinDate = _result.getLong("schoolMemberJoinDate")
        val _tmp_schoolMemberLeftDate = _result.getLong("schoolMemberLeftDate")
        val _tmp_schoolMemberRole = _result.getInt("schoolMemberRole")
        val _tmp_schoolMemberActive = _result.getBoolean("schoolMemberActive")
        val _tmp_schoolMemberLocalChangeSeqNum = _result.getLong("schoolMemberLocalChangeSeqNum")
        val _tmp_schoolMemberMasterChangeSeqNum = _result.getLong("schoolMemberMasterChangeSeqNum")
        val _tmp_schoolMemberLastChangedBy = _result.getInt("schoolMemberLastChangedBy")
        val _tmp_schoolMemberLct = _result.getLong("schoolMemberLct")
        SchoolMember().apply {
          this.schoolMemberUid = _tmp_schoolMemberUid
          this.schoolMemberPersonUid = _tmp_schoolMemberPersonUid
          this.schoolMemberSchoolUid = _tmp_schoolMemberSchoolUid
          this.schoolMemberJoinDate = _tmp_schoolMemberJoinDate
          this.schoolMemberLeftDate = _tmp_schoolMemberLeftDate
          this.schoolMemberRole = _tmp_schoolMemberRole
          this.schoolMemberActive = _tmp_schoolMemberActive
          this.schoolMemberLocalChangeSeqNum = _tmp_schoolMemberLocalChangeSeqNum
          this.schoolMemberMasterChangeSeqNum = _tmp_schoolMemberMasterChangeSeqNum
          this.schoolMemberLastChangedBy = _tmp_schoolMemberLastChangedBy
          this.schoolMemberLct = _tmp_schoolMemberLct
        }
      }
    }
  }
}
