package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContainerEntry
import com.ustadmobile.lib.db.entities.ContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryWithContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryWithMd5
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ContainerEntryDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContainerEntryDao() {
  public val _insertAdapterContainerEntry_: EntityInsertionAdapter<ContainerEntry> = object :
      EntityInsertionAdapter<ContainerEntry>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ContainerEntry (ceUid, ceContainerUid, cePath, ceCefUid) VALUES(?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContainerEntry) {
      if(entity.ceUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ceUid)
      }
      stmt.setLong(2, entity.ceContainerUid)
      stmt.setString(3, entity.cePath)
      stmt.setLong(4, entity.ceCefUid)
    }
  }

  override suspend fun insertListAsync(containerEntryList: List<ContainerEntry>) {
    _insertAdapterContainerEntry_.insertListAsync(containerEntryList)
  }

  public override fun insert(entity: ContainerEntry): Long {
    val _retVal = _insertAdapterContainerEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerEntry): Long {
    val _retVal = _insertAdapterContainerEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContainerEntry>) {
    _insertAdapterContainerEntry_.insertList(entityList)
  }

  public override fun update(entity: ContainerEntry) {
    val _sql =
        "UPDATE ContainerEntry SET ceContainerUid = ?, cePath = ?, ceCefUid = ? WHERE ceUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ceContainerUid)
      _stmt.setString(2, entity.cePath)
      _stmt.setLong(3, entity.ceCefUid)
      _stmt.setLong(4, entity.ceUid)
      _stmt.executeUpdate()
    }
  }

  override fun deleteList(entries: List<ContainerEntry>) {
    var _numChanges = 0
    _db.prepareAndUseStatement("DELETE FROM ContainerEntry WHERE ceUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entries) {
        _stmt.setLong(1, _entity.ceUid)
        _numChanges += _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  override fun findByContainer(containerUid: Long): List<ContainerEntryWithContainerEntryFile> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        var _tmp_ContainerEntryFile_nullCount = 0
        val _tmp_cefUid = _result.getLong("cefUid")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefMd5 = _result.getString("cefMd5")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefPath = _result.getString("cefPath")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_compression = _result.getInt("compression")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ContainerEntryFile_isAllNull = _tmp_ContainerEntryFile_nullCount == 7
        ContainerEntryWithContainerEntryFile().apply {
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
          if(!_tmp_ContainerEntryFile_isAllNull) {
            this.containerEntryFile = ContainerEntryFile().apply {
              this.cefUid = _tmp_cefUid
              this.cefMd5 = _tmp_cefMd5
              this.cefPath = _tmp_cefPath
              this.ceTotalSize = _tmp_ceTotalSize
              this.ceCompressedSize = _tmp_ceCompressedSize
              this.compression = _tmp_compression
              this.lastModified = _tmp_lastModified
            }
          }
        }
      }
    }
  }

  override fun findByPathInContainer(containerUid: Long, pathInContainer: String):
      ContainerEntryWithContainerEntryFile? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = CAST(? AS BIGINT) AND ContainerEntry.cePath = ?",
    postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ? AND ContainerEntry.cePath = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.setString(2,pathInContainer)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        var _tmp_ContainerEntryFile_nullCount = 0
        val _tmp_cefUid = _result.getLong("cefUid")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefMd5 = _result.getString("cefMd5")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefPath = _result.getString("cefPath")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_compression = _result.getInt("compression")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ContainerEntryFile_isAllNull = _tmp_ContainerEntryFile_nullCount == 7
        ContainerEntryWithContainerEntryFile().apply {
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
          if(!_tmp_ContainerEntryFile_isAllNull) {
            this.containerEntryFile = ContainerEntryFile().apply {
              this.cefUid = _tmp_cefUid
              this.cefMd5 = _tmp_cefMd5
              this.cefPath = _tmp_cefPath
              this.ceTotalSize = _tmp_ceTotalSize
              this.ceCompressedSize = _tmp_ceCompressedSize
              this.compression = _tmp_compression
              this.lastModified = _tmp_lastModified
            }
          }
        }
      }
    }
  }

  override fun findByContainerWithMd5(containerUid: Long): List<ContainerEntryWithMd5> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT ContainerEntry.*, ContainerEntryFile.cefMd5 AS cefMd5 FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.cefMd5 AS cefMd5 FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_cefMd5 = _result.getString("cefMd5")
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        ContainerEntryWithMd5().apply {
          this.cefMd5 = _tmp_cefMd5
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
        }
      }
    }
  }

  override suspend fun findByContainerAsync(containerUid: Long):
      List<ContainerEntryWithContainerEntryFile> =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,containerUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapRows {
        val _tmp_ceUid = _result.getLong("ceUid")
        val _tmp_ceContainerUid = _result.getLong("ceContainerUid")
        val _tmp_cePath = _result.getString("cePath")
        val _tmp_ceCefUid = _result.getLong("ceCefUid")
        var _tmp_ContainerEntryFile_nullCount = 0
        val _tmp_cefUid = _result.getLong("cefUid")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefMd5 = _result.getString("cefMd5")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_cefPath = _result.getString("cefPath")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceTotalSize = _result.getLong("ceTotalSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ceCompressedSize = _result.getLong("ceCompressedSize")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_compression = _result.getInt("compression")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_lastModified = _result.getLong("lastModified")
        if(_result.wasNull()) _tmp_ContainerEntryFile_nullCount++
        val _tmp_ContainerEntryFile_isAllNull = _tmp_ContainerEntryFile_nullCount == 7
        ContainerEntryWithContainerEntryFile().apply {
          this.ceUid = _tmp_ceUid
          this.ceContainerUid = _tmp_ceContainerUid
          this.cePath = _tmp_cePath
          this.ceCefUid = _tmp_ceCefUid
          if(!_tmp_ContainerEntryFile_isAllNull) {
            this.containerEntryFile = ContainerEntryFile().apply {
              this.cefUid = _tmp_cefUid
              this.cefMd5 = _tmp_cefMd5
              this.cefPath = _tmp_cefPath
              this.ceTotalSize = _tmp_ceTotalSize
              this.ceCompressedSize = _tmp_ceCompressedSize
              this.compression = _tmp_compression
              this.lastModified = _tmp_lastModified
            }
          }
        }
      }
    }
  }

  override fun deleteByContainerUid(containerUid: Long) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "DELETE FROM ContainerEntry WHERE ceContainerUid = CAST(? AS BIGINT)",
      postgreSql = """
      |DELETE FROM ContainerEntry WHERE ceContainerUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,containerUid)
      _stmt.executeUpdate()
    }
  }

  override fun deleteByContentEntryUid(contentEntryUid: Long) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |
      |        DELETE FROM ContainerEntry
      |         WHERE ceContainerUid
      |            IN (SELECT containerUid
      |                  FROM Container
      |                 WHERE containerContentEntryUid = CAST(? AS BIGINT)) 
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        DELETE FROM ContainerEntry
      |         WHERE ceContainerUid
      |            IN (SELECT containerUid
      |                  FROM Container
      |                 WHERE containerContentEntryUid = ?) 
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun insertWithMd5SumsAsync(
    containerUid: Long,
    path: String,
    md5: String,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        INSERT INTO ContainerEntry(ceContainerUid, cePath, ceCefUid) 
      |        SELECT CAST(? AS BIGINT) AS ceContainerUid, ? AS cePath, 
      |               (SELECT COALESCE(
      |                      (SELECT cefUid 
      |                         FROM ContainerEntryFile
      |                        WHERE cefMd5 = ?
      |                        LIMIT 1), 0))  
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        INSERT INTO ContainerEntry(ceContainerUid, cePath, ceCefUid) 
      |        SELECT ? AS ceContainerUid, ? AS cePath, 
      |               (SELECT COALESCE(
      |                      (SELECT cefUid 
      |                         FROM ContainerEntryFile
      |                        WHERE cefMd5 = ?
      |                        LIMIT 1), 0))  
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,containerUid)
      _stmt.setString(2,path)
      _stmt.setString(3,md5)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
