package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeIdHeader
import com.ustadmobile.door.ext.pagingSourceLoadParameters
import com.ustadmobile.door.ext.setRepoUrl
import com.ustadmobile.door.http.RepoDaoFlowHelper
import com.ustadmobile.door.http.RepositoryDaoWithFlowHelper
import com.ustadmobile.door.http.replicateHttpRequestCatchAndLog
import com.ustadmobile.door.http.replicateHttpRequestOrThrow
import com.ustadmobile.door.paging.DoorRepositoryReplicatePullPagingSource
import com.ustadmobile.door.paging.endOfPaginationReached
import com.ustadmobile.door.replication.onClientRepoDoorMessageHttpResponse
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.composites.AssignmentSubmitterUidAndName
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.AssignmentSubmitterSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentCourseBlockAndSubmitterUid
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithCourseBlock
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.PeerReviewerAllocation
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.`header`
import io.ktor.client.request.parameter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzAssignmentDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzAssignmentDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : ClazzAssignmentDao(), RepositoryDaoWithFlowHelper {
  override val repoDaoFlowHelper: RepoDaoFlowHelper = RepoDaoFlowHelper(_repo)


  override suspend fun getAllAssignmentsByClazzUidAsync(clazzUid: Long): List<ClazzAssignment> {
    val _result = _dao.getAllAssignmentsByClazzUidAsync(clazzUid)
    return _result
  }

  override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ClazzAssignment") {
      _dao.updateActiveByUid(cbUid, active, changeTime)
    }
  }

  override fun getProgressSummaryForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    accountPersonUid: Long,
    group: String,
  ): Flow<AssignmentProgressSummary?> {
    val _result = _dao.getProgressSummaryForAssignment(assignmentUid, clazzUid, accountPersonUid,
        group)
    return _result
  }

  override fun getAssignmentSubmitterSummaryListForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    accountPersonUid: Long,
    group: String,
    searchText: String,
    sortOption: Int,
  ): PagingSource<Int, AssignmentSubmitterSummary> = DoorRepositoryReplicatePullPagingSource(
    repo = _repo,
    repoPath = "ClazzAssignmentDao/getAssignmentSubmitterSummaryListForAssignment",
    dbPagingSource = _dao.getAssignmentSubmitterSummaryListForAssignment(assignmentUid, clazzUid,
        accountPersonUid, group, searchText, sortOption),
    onLoadHttp =  {
      _pagingParams -> 
      _repo.replicateHttpRequestOrThrow(repoPath =
          "ClazzAssignmentDao/getAssignmentSubmitterSummaryListForAssignment") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "ClazzAssignmentDao/getAssignmentSubmitterSummaryListForAssignment")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
          parameter("group", _repo.config.json.encodeToString(String.serializer(), group))
          parameter("searchText", _repo.config.json.encodeToString(String.serializer(), searchText))
          parameter("sortOption", _repo.config.json.encodeToString(Int.serializer(), sortOption))
          pagingSourceLoadParameters(
            json = _repo.config.json, 
            keySerializer = Int.serializer().nullable,
            loadParams = _pagingParams
          )
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
        _response.endOfPaginationReached()
      }
    },
  )

  override suspend fun getCourseGroupMembersByAssignmentUid(assignmentUid: Long):
      List<CourseGroupMember> {
    val _result = _dao.getCourseGroupMembersByAssignmentUid(assignmentUid)
    return _result
  }

  override suspend fun getAssignmentSubmissionsByAssignmentUid(
    assignmentUid: Long,
    clazzUid: Long,
    accountPersonUid: Long,
    group: String,
  ): List<CourseAssignmentSubmission> {
    val _result = _dao.getAssignmentSubmissionsByAssignmentUid(assignmentUid, clazzUid,
        accountPersonUid, group)
    return _result
  }

  override suspend fun getAssignmentMarksByAssignmentUid(
    assignmentUid: Long,
    clazzUid: Long,
    accountPersonUid: Long,
    group: String,
  ): List<CourseAssignmentMark> {
    val _result = _dao.getAssignmentMarksByAssignmentUid(assignmentUid, clazzUid, accountPersonUid,
        group)
    return _result
  }

  override suspend fun getPeerReviewerAllocationsByAssignmentUid(
    assignmentUid: Long,
    clazzUid: Long,
    accountPersonUid: Long,
  ): List<PeerReviewerAllocation> {
    val _result = _dao.getPeerReviewerAllocationsByAssignmentUid(assignmentUid, clazzUid,
        accountPersonUid)
    return _result
  }

  override suspend fun getPeerReviewAllocationsForPerson(assignmentUid: Long,
      accountPersonUid: Long): List<PeerReviewerAllocation> {
    val _result = _dao.getPeerReviewAllocationsForPerson(assignmentUid, accountPersonUid)
    return _result
  }

  override suspend fun getSubmitterUidsByClazzOrGroupSetUid(
    clazzUid: Long,
    groupSetUid: Long,
    time: Long,
  ): List<Long> {
    val _result = _dao.getSubmitterUidsByClazzOrGroupSetUid(clazzUid, groupSetUid, time)
    return _result
  }

  override suspend fun getSubmitterUidsAndNameByClazzOrGroupSetUid(
    clazzUid: Long,
    groupSetUid: Long,
    date: Long,
    groupStr: String,
  ): List<AssignmentSubmitterUidAndName> {
    _repo.replicateHttpRequestCatchAndLog(repoPath =
        "ClazzAssignmentDao/getSubmitterUidsAndNameByClazzOrGroupSetUid") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzAssignmentDao/getSubmitterUidsAndNameByClazzOrGroupSetUid")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("groupSetUid", _repo.config.json.encodeToString(Long.serializer(), groupSetUid))
        parameter("date", _repo.config.json.encodeToString(Long.serializer(), date))
        parameter("groupStr", _repo.config.json.encodeToString(String.serializer(), groupStr))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getSubmitterUidsAndNameByClazzOrGroupSetUid(clazzUid, groupSetUid, date,
        groupStr)
    return _result
  }

  override suspend fun getSubmitterCountFromAssignment(
    groupUid: Long,
    clazzUid: Long,
    group: String,
  ): Int {
    val _result = _dao.getSubmitterCountFromAssignment(groupUid, clazzUid, group)
    return _result
  }

  override suspend fun getSubmitterUid(
    assignmentUid: Long,
    clazzUid: Long,
    accountPersonUid: Long,
  ): Long {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "ClazzAssignmentDao/getSubmitterUid") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzAssignmentDao/getSubmitterUid")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
            assignmentUid))
        parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
            accountPersonUid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.getSubmitterUid(assignmentUid, clazzUid, accountPersonUid)
    return _result
  }

  override suspend fun updateAsync(clazzAssignment: ClazzAssignment) {
    _repo.withRepoChangeMonitorAsync("ClazzAssignment") {
      _dao.updateAsync(clazzAssignment)
    }
  }

  override suspend fun findByUidAsync(uid: Long): ClazzAssignment? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "ClazzAssignmentDao/findByUidAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzAssignmentDao/findByUidAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  override fun findByUidAndClazzUidAsFlow(uid: Long, clazzUid: Long): Flow<ClazzAssignment?> =
      repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findByUidAndClazzUidAsFlow(uid, clazzUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "ClazzAssignmentDao/findByUidAndClazzUidAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config, "ClazzAssignmentDao/findByUidAndClazzUidAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun findByUidAndClazzUidAsync(assignmentUid: Long, clazzUid: Long):
      ClazzAssignment? {
    val _result = _dao.findByUidAndClazzUidAsync(assignmentUid, clazzUid)
    return _result
  }

  override suspend fun getGroupUidFromAssignment(uid: Long): Long {
    val _result = _dao.getGroupUidFromAssignment(uid)
    return _result
  }

  override suspend fun getMarkingTypeFromAssignment(uid: Long): Int {
    val _result = _dao.getMarkingTypeFromAssignment(uid)
    return _result
  }

  override suspend fun findByUidWithBlockAsync(uid: Long): ClazzAssignmentWithCourseBlock? {
    _repo.replicateHttpRequestCatchAndLog(repoPath = "ClazzAssignmentDao/findByUidWithBlockAsync") {
      val _response = _httpClient.`get` {
        setRepoUrl(_repo.config, "ClazzAssignmentDao/findByUidWithBlockAsync")
        doorNodeIdHeader(_repo)
        `header`("cache-control", "no-store")
        parameter("uid", _repo.config.json.encodeToString(Long.serializer(), uid))
      }

      _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
    }
    val _result = _dao.findByUidWithBlockAsync(uid)
    return _result
  }

  override fun findClazzAssignment(): ClazzAssignment? {
    val _result = _dao.findClazzAssignment()
    return _result
  }

  override fun findByUidLive(uid: Long): Flow<ClazzAssignment?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  override suspend fun upsertListAsync(entities: List<ClazzAssignment>) {
    _repo.withRepoChangeMonitorAsync("ClazzAssignment") {
      _dao.upsertListAsync(entities)
    }
  }

  override suspend fun updateActiveByList(
    uidList: List<Long>,
    active: Boolean,
    changeTime: Long,
  ) {
    _repo.withRepoChangeMonitorAsync("ClazzAssignment") {
      _dao.updateActiveByList(uidList, active, changeTime)
    }
  }

  override fun findAssignmentCourseBlockAndSubmitterUidAsFlow(
    assignmentUid: Long,
    clazzUid: Long,
    accountPersonUid: Long,
  ): Flow<ClazzAssignmentCourseBlockAndSubmitterUid?> = repoDaoFlowHelper.asRepoFlow(
    dbFlow = _dao.findAssignmentCourseBlockAndSubmitterUidAsFlow(assignmentUid, clazzUid,
        accountPersonUid),
    onMakeHttpRequest =  {
      _repo.replicateHttpRequestCatchAndLog(repoPath =
          "ClazzAssignmentDao/findAssignmentCourseBlockAndSubmitterUidAsFlow") {
        val _response = _httpClient.`get` {
          setRepoUrl(_repo.config,
              "ClazzAssignmentDao/findAssignmentCourseBlockAndSubmitterUidAsFlow")
          doorNodeIdHeader(_repo)
          `header`("cache-control", "no-store")
          parameter("assignmentUid", _repo.config.json.encodeToString(Long.serializer(),
              assignmentUid))
          parameter("clazzUid", _repo.config.json.encodeToString(Long.serializer(), clazzUid))
          parameter("accountPersonUid", _repo.config.json.encodeToString(Long.serializer(),
              accountPersonUid))
        }

        _db.onClientRepoDoorMessageHttpResponse(_response, _repo.config.json)
      }
    },
  )

  override suspend fun findEnrolmentsByPersonUidAndAssignmentUid(assignmentUid: Long,
      accountPersonUid: Long): List<ClazzEnrolment> {
    val _result = _dao.findEnrolmentsByPersonUidAndAssignmentUid(assignmentUid, accountPersonUid)
    return _result
  }

  override suspend fun findCourseGroupMembersByPersonUidAndAssignmentUid(assignmentUid: Long,
      accountPersonUid: Long): List<CourseGroupMember> {
    val _result = _dao.findCourseGroupMembersByPersonUidAndAssignmentUid(assignmentUid,
        accountPersonUid)
    return _result
  }

  override suspend fun findPeerReviewerAllocationsByPersonUidAndAssignmentUid(assignmentUid: Long,
      accountPersonUid: Long): List<PeerReviewerAllocation> {
    val _result = _dao.findPeerReviewerAllocationsByPersonUidAndAssignmentUid(assignmentUid,
        accountPersonUid)
    return _result
  }

  override suspend fun getLatestSubmissionTimeAllowed(assignmentUid: Long): Long {
    val _result = _dao.getLatestSubmissionTimeAllowed(assignmentUid)
    return _result
  }

  public override fun insert(entity: ClazzAssignment): Long {
    val _result = _repo.withRepoChangeMonitor("ClazzAssignment") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzAssignment): Long {
    val _result = _repo.withRepoChangeMonitorAsync("ClazzAssignment") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<ClazzAssignment>) {
    _repo.withRepoChangeMonitor("ClazzAssignment") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: ClazzAssignment) {
    _repo.withRepoChangeMonitor("ClazzAssignment") {
      _dao.update(entity)
    }
  }

  public override suspend fun insertListAsync(entityList: List<ClazzAssignment>) {
    _repo.withRepoChangeMonitorAsync("ClazzAssignment") {
      _dao.insertListAsync(entityList)
    }
  }

  public override suspend fun updateListAsync(entityList: List<ClazzAssignment>) {
    _repo.withRepoChangeMonitorAsync("ClazzAssignment") {
      _dao.updateListAsync(entityList)
    }
  }
}
