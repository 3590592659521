package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ScrapeQueueItem
import com.ustadmobile.lib.db.entities.ScrapeQueueItemWithScrapeRun
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public actual abstract class ScrapeQueueItemDao : BaseDao<ScrapeQueueItem> {
  public actual abstract fun findNextQueueItems(itemType: Int): Flow<List<ScrapeQueueItem>>

  public actual abstract fun updateSetStatusById(
    uid: Int,
    status: Int,
    errorCode: Int,
  )

  public actual abstract fun getExistingQueueItem(runId: Int, indexUrl: String): ScrapeQueueItem?

  public actual abstract fun findExistingQueueItem(runId: Int, entryUid: Long): ScrapeQueueItem?

  public actual abstract fun setTimeStarted(uid: Int, timeStarted: Long)

  public actual abstract fun setTimeFinished(uid: Int, timeFinished: Long)

  public actual abstract fun findByUid(sqiUid: Int): ScrapeQueueItemWithScrapeRun?
}
