package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Chat
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ChatDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ChatDao() {
  public val _insertAdapterChat_: EntityInsertionAdapter<Chat> = object :
      EntityInsertionAdapter<Chat>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO Chat (chatUid, chatStartDate, chatTitle, chatGroup, chatLct) VALUES(?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Chat) {
      if(entity.chatUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.chatUid)
      }
      stmt.setLong(2, entity.chatStartDate)
      stmt.setString(3, entity.chatTitle)
      stmt.setBoolean(4, entity.chatGroup)
      stmt.setLong(5, entity.chatLct)
    }
  }

  public override fun insert(entity: Chat): Long {
    val _retVal = _insertAdapterChat_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Chat): Long {
    val _retVal = _insertAdapterChat_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Chat>) {
    _insertAdapterChat_.insertList(entityList)
  }

  public override fun update(entity: Chat) {
    val _sql =
        "UPDATE Chat SET chatStartDate = ?, chatTitle = ?, chatGroup = ?, chatLct = ? WHERE chatUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.chatStartDate)
      _stmt.setString(2, entity.chatTitle)
      _stmt.setBoolean(3, entity.chatGroup)
      _stmt.setLong(4, entity.chatLct)
      _stmt.setLong(5, entity.chatUid)
      _stmt.executeUpdate()
    }
  }
}
