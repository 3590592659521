package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.createArrayOrProxyArrayOf
import com.ustadmobile.door.ext.hasListOrArrayParams
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.paging.DoorLimitOffsetPagingSource
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.Report
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ReportDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ReportDao() {
  public val _insertAdapterReport_upsert: EntityInsertionAdapter<Report> = object :
      EntityInsertionAdapter<Report>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT OR REPLACE INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Report) {
      if(entity.reportUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.reportUid)
      }
      stmt.setLong(2, entity.reportOwnerUid)
      stmt.setInt(3, entity.xAxis)
      stmt.setInt(4, entity.reportDateRangeSelection)
      stmt.setLong(5, entity.fromDate)
      stmt.setInt(6, entity.fromRelTo)
      stmt.setInt(7, entity.fromRelOffSet)
      stmt.setInt(8, entity.fromRelUnit)
      stmt.setLong(9, entity.toDate)
      stmt.setInt(10, entity.toRelTo)
      stmt.setInt(11, entity.toRelOffSet)
      stmt.setInt(12, entity.toRelUnit)
      stmt.setString(13, entity.reportTitle)
      stmt.setString(14, entity.reportDescription)
      stmt.setString(15, entity.reportSeries)
      stmt.setBoolean(16, entity.reportInactive)
      stmt.setBoolean(17, entity.isTemplate)
      stmt.setInt(18, entity.priority)
      stmt.setInt(19, entity.reportTitleId)
      stmt.setInt(20, entity.reportDescId)
      stmt.setLong(21, entity.reportMasterChangeSeqNum)
      stmt.setLong(22, entity.reportLocalChangeSeqNum)
      stmt.setInt(23, entity.reportLastChangedBy)
      stmt.setLong(24, entity.reportLct)
    }
  }

  public val _insertAdapterReport_: EntityInsertionAdapter<Report> = object :
      EntityInsertionAdapter<Report>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Report) {
      if(entity.reportUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.reportUid)
      }
      stmt.setLong(2, entity.reportOwnerUid)
      stmt.setInt(3, entity.xAxis)
      stmt.setInt(4, entity.reportDateRangeSelection)
      stmt.setLong(5, entity.fromDate)
      stmt.setInt(6, entity.fromRelTo)
      stmt.setInt(7, entity.fromRelOffSet)
      stmt.setInt(8, entity.fromRelUnit)
      stmt.setLong(9, entity.toDate)
      stmt.setInt(10, entity.toRelTo)
      stmt.setInt(11, entity.toRelOffSet)
      stmt.setInt(12, entity.toRelUnit)
      stmt.setString(13, entity.reportTitle)
      stmt.setString(14, entity.reportDescription)
      stmt.setString(15, entity.reportSeries)
      stmt.setBoolean(16, entity.reportInactive)
      stmt.setBoolean(17, entity.isTemplate)
      stmt.setInt(18, entity.priority)
      stmt.setInt(19, entity.reportTitleId)
      stmt.setInt(20, entity.reportDescId)
      stmt.setLong(21, entity.reportMasterChangeSeqNum)
      stmt.setLong(22, entity.reportLocalChangeSeqNum)
      stmt.setInt(23, entity.reportLastChangedBy)
      stmt.setLong(24, entity.reportLct)
    }
  }

  override fun replaceList(entityList: List<Report>) {
    _insertAdapterReport_upsert.insertList(entityList)
  }

  public override fun insert(entity: Report): Long {
    val _retVal = _insertAdapterReport_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Report): Long {
    val _retVal = _insertAdapterReport_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<Report>) {
    _insertAdapterReport_.insertList(entityList)
  }

  override suspend fun updateAsync(entity: Report) {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.reportOwnerUid)
      _stmt.setInt(2, entity.xAxis)
      _stmt.setInt(3, entity.reportDateRangeSelection)
      _stmt.setLong(4, entity.fromDate)
      _stmt.setInt(5, entity.fromRelTo)
      _stmt.setInt(6, entity.fromRelOffSet)
      _stmt.setInt(7, entity.fromRelUnit)
      _stmt.setLong(8, entity.toDate)
      _stmt.setInt(9, entity.toRelTo)
      _stmt.setInt(10, entity.toRelOffSet)
      _stmt.setInt(11, entity.toRelUnit)
      _stmt.setString(12, entity.reportTitle)
      _stmt.setString(13, entity.reportDescription)
      _stmt.setString(14, entity.reportSeries)
      _stmt.setBoolean(15, entity.reportInactive)
      _stmt.setBoolean(16, entity.isTemplate)
      _stmt.setInt(17, entity.priority)
      _stmt.setInt(18, entity.reportTitleId)
      _stmt.setInt(19, entity.reportDescId)
      _stmt.setLong(20, entity.reportMasterChangeSeqNum)
      _stmt.setLong(21, entity.reportLocalChangeSeqNum)
      _stmt.setInt(22, entity.reportLastChangedBy)
      _stmt.setLong(23, entity.reportLct)
      _stmt.setLong(24, entity.reportUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun update(entity: Report) {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.reportOwnerUid)
      _stmt.setInt(2, entity.xAxis)
      _stmt.setInt(3, entity.reportDateRangeSelection)
      _stmt.setLong(4, entity.fromDate)
      _stmt.setInt(5, entity.fromRelTo)
      _stmt.setInt(6, entity.fromRelOffSet)
      _stmt.setInt(7, entity.fromRelUnit)
      _stmt.setLong(8, entity.toDate)
      _stmt.setInt(9, entity.toRelTo)
      _stmt.setInt(10, entity.toRelOffSet)
      _stmt.setInt(11, entity.toRelUnit)
      _stmt.setString(12, entity.reportTitle)
      _stmt.setString(13, entity.reportDescription)
      _stmt.setString(14, entity.reportSeries)
      _stmt.setBoolean(15, entity.reportInactive)
      _stmt.setBoolean(16, entity.isTemplate)
      _stmt.setInt(17, entity.priority)
      _stmt.setInt(18, entity.reportTitleId)
      _stmt.setInt(19, entity.reportDescId)
      _stmt.setLong(20, entity.reportMasterChangeSeqNum)
      _stmt.setLong(21, entity.reportLocalChangeSeqNum)
      _stmt.setInt(22, entity.reportLastChangedBy)
      _stmt.setLong(23, entity.reportLct)
      _stmt.setLong(24, entity.reportUid)
      _stmt.executeUpdate()
    }
  }

  override fun getResults(query: DoorQuery): List<Report> =
      _db.prepareAndUseStatement(PreparedStatementConfig(query.sql, hasListParams =
      query.hasListOrArrayParams(), readOnly = true)
  ) { _stmt -> 
    query.bindToPreparedStmt(_stmt, _db)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_reportUid = _result.getLong("reportUid")
        val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
        val _tmp_xAxis = _result.getInt("xAxis")
        val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
        val _tmp_fromDate = _result.getLong("fromDate")
        val _tmp_fromRelTo = _result.getInt("fromRelTo")
        val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
        val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
        val _tmp_toDate = _result.getLong("toDate")
        val _tmp_toRelTo = _result.getInt("toRelTo")
        val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
        val _tmp_toRelUnit = _result.getInt("toRelUnit")
        val _tmp_reportTitle = _result.getString("reportTitle")
        val _tmp_reportDescription = _result.getString("reportDescription")
        val _tmp_reportSeries = _result.getString("reportSeries")
        val _tmp_reportInactive = _result.getBoolean("reportInactive")
        val _tmp_isTemplate = _result.getBoolean("isTemplate")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_reportTitleId = _result.getInt("reportTitleId")
        val _tmp_reportDescId = _result.getInt("reportDescId")
        val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
        val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
        val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
        val _tmp_reportLct = _result.getLong("reportLct")
        Report().apply {
          this.reportUid = _tmp_reportUid
          this.reportOwnerUid = _tmp_reportOwnerUid
          this.xAxis = _tmp_xAxis
          this.reportDateRangeSelection = _tmp_reportDateRangeSelection
          this.fromDate = _tmp_fromDate
          this.fromRelTo = _tmp_fromRelTo
          this.fromRelOffSet = _tmp_fromRelOffSet
          this.fromRelUnit = _tmp_fromRelUnit
          this.toDate = _tmp_toDate
          this.toRelTo = _tmp_toRelTo
          this.toRelOffSet = _tmp_toRelOffSet
          this.toRelUnit = _tmp_toRelUnit
          this.reportTitle = _tmp_reportTitle
          this.reportDescription = _tmp_reportDescription
          this.reportSeries = _tmp_reportSeries
          this.reportInactive = _tmp_reportInactive
          this.isTemplate = _tmp_isTemplate
          this.priority = _tmp_priority
          this.reportTitleId = _tmp_reportTitleId
          this.reportDescId = _tmp_reportDescId
          this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
          this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
          this.reportLastChangedBy = _tmp_reportLastChangedBy
          this.reportLct = _tmp_reportLct
        }
      }
    }
  }

  override fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean,
  ): PagingSource<Int, Report> = object : DoorLimitOffsetPagingSource<Report>(db = _db
  , tableNames = arrayOf("REPORT")
  ) {
    override suspend fun loadRows(_limit: Int, _offset: Int): List<Report> =
        _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND reportOwnerUid = CAST(? AS BIGINT)
      |        AND isTemplate = ?
      |        AND reportTitle LIKE ?
      |        ORDER BY priority, CASE(?)
      |            WHEN 1 THEN Report.reportTitle
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Report.reportTitle
      |            ELSE ''
      |        END DESC
      |            ) AS _PagingData LIMIT ? OFFSET ?
      """.trimMargin(),
      postgreSql = """
      |SELECT * FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND reportOwnerUid = ?
      |        AND isTemplate = ?
      |        AND reportTitle LIKE ?
      |        ORDER BY priority, CASE(?)
      |            WHEN 1 THEN Report.reportTitle
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Report.reportTitle
      |            ELSE ''
      |        END DESC
      |            ) AS _PagingData LIMIT ? OFFSET ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.setBoolean(2,isTemplate)
      _stmt.setString(3,searchBit)
      _stmt.setInt(4,sortOrder)
      _stmt.setInt(5,sortOrder)
      _stmt.setInt(6,_limit)
      _stmt.setInt(7,_offset)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_reportUid = _result.getLong("reportUid")
          val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
          val _tmp_xAxis = _result.getInt("xAxis")
          val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
          val _tmp_fromDate = _result.getLong("fromDate")
          val _tmp_fromRelTo = _result.getInt("fromRelTo")
          val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
          val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
          val _tmp_toDate = _result.getLong("toDate")
          val _tmp_toRelTo = _result.getInt("toRelTo")
          val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
          val _tmp_toRelUnit = _result.getInt("toRelUnit")
          val _tmp_reportTitle = _result.getString("reportTitle")
          val _tmp_reportDescription = _result.getString("reportDescription")
          val _tmp_reportSeries = _result.getString("reportSeries")
          val _tmp_reportInactive = _result.getBoolean("reportInactive")
          val _tmp_isTemplate = _result.getBoolean("isTemplate")
          val _tmp_priority = _result.getInt("priority")
          val _tmp_reportTitleId = _result.getInt("reportTitleId")
          val _tmp_reportDescId = _result.getInt("reportDescId")
          val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
          val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
          val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
          val _tmp_reportLct = _result.getLong("reportLct")
          Report().apply {
            this.reportUid = _tmp_reportUid
            this.reportOwnerUid = _tmp_reportOwnerUid
            this.xAxis = _tmp_xAxis
            this.reportDateRangeSelection = _tmp_reportDateRangeSelection
            this.fromDate = _tmp_fromDate
            this.fromRelTo = _tmp_fromRelTo
            this.fromRelOffSet = _tmp_fromRelOffSet
            this.fromRelUnit = _tmp_fromRelUnit
            this.toDate = _tmp_toDate
            this.toRelTo = _tmp_toRelTo
            this.toRelOffSet = _tmp_toRelOffSet
            this.toRelUnit = _tmp_toRelUnit
            this.reportTitle = _tmp_reportTitle
            this.reportDescription = _tmp_reportDescription
            this.reportSeries = _tmp_reportSeries
            this.reportInactive = _tmp_reportInactive
            this.isTemplate = _tmp_isTemplate
            this.priority = _tmp_priority
            this.reportTitleId = _tmp_reportTitleId
            this.reportDescId = _tmp_reportDescId
            this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
            this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
            this.reportLastChangedBy = _tmp_reportLastChangedBy
            this.reportLct = _tmp_reportLct
          }
        }
      }
    }

    override suspend fun countRows(): Int = _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT COUNT(*) FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND reportOwnerUid = CAST(? AS BIGINT)
      |        AND isTemplate = ?
      |        AND reportTitle LIKE ?
      |        ORDER BY priority, CASE(?)
      |            WHEN 1 THEN Report.reportTitle
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Report.reportTitle
      |            ELSE ''
      |        END DESC
      |            ) AS _PagingCount
      """.trimMargin(),
      postgreSql = """
      |SELECT COUNT(*) FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND reportOwnerUid = ?
      |        AND isTemplate = ?
      |        AND reportTitle LIKE ?
      |        ORDER BY priority, CASE(?)
      |            WHEN 1 THEN Report.reportTitle
      |            ELSE ''
      |        END ASC,
      |        CASE(?)
      |            WHEN 2 THEN Report.reportTitle
      |            ELSE ''
      |        END DESC
      |            ) AS _PagingCount
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,personUid)
      _stmt.setBoolean(2,isTemplate)
      _stmt.setString(3,searchBit)
      _stmt.setInt(4,sortOrder)
      _stmt.setInt(5,sortOrder)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(0) {
          _result.getInt(1)
        }
      }
    }
  }

  override suspend fun findByUid(entityUid: Long): Report? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql = "SELECT * FROM Report WHERE reportUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM Report WHERE reportUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,entityUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_reportUid = _result.getLong("reportUid")
        val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
        val _tmp_xAxis = _result.getInt("xAxis")
        val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
        val _tmp_fromDate = _result.getLong("fromDate")
        val _tmp_fromRelTo = _result.getInt("fromRelTo")
        val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
        val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
        val _tmp_toDate = _result.getLong("toDate")
        val _tmp_toRelTo = _result.getInt("toRelTo")
        val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
        val _tmp_toRelUnit = _result.getInt("toRelUnit")
        val _tmp_reportTitle = _result.getString("reportTitle")
        val _tmp_reportDescription = _result.getString("reportDescription")
        val _tmp_reportSeries = _result.getString("reportSeries")
        val _tmp_reportInactive = _result.getBoolean("reportInactive")
        val _tmp_isTemplate = _result.getBoolean("isTemplate")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_reportTitleId = _result.getInt("reportTitleId")
        val _tmp_reportDescId = _result.getInt("reportDescId")
        val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
        val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
        val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
        val _tmp_reportLct = _result.getLong("reportLct")
        Report().apply {
          this.reportUid = _tmp_reportUid
          this.reportOwnerUid = _tmp_reportOwnerUid
          this.xAxis = _tmp_xAxis
          this.reportDateRangeSelection = _tmp_reportDateRangeSelection
          this.fromDate = _tmp_fromDate
          this.fromRelTo = _tmp_fromRelTo
          this.fromRelOffSet = _tmp_fromRelOffSet
          this.fromRelUnit = _tmp_fromRelUnit
          this.toDate = _tmp_toDate
          this.toRelTo = _tmp_toRelTo
          this.toRelOffSet = _tmp_toRelOffSet
          this.toRelUnit = _tmp_toRelUnit
          this.reportTitle = _tmp_reportTitle
          this.reportDescription = _tmp_reportDescription
          this.reportSeries = _tmp_reportSeries
          this.reportInactive = _tmp_reportInactive
          this.isTemplate = _tmp_isTemplate
          this.priority = _tmp_priority
          this.reportTitleId = _tmp_reportTitleId
          this.reportDescId = _tmp_reportDescId
          this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
          this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
          this.reportLastChangedBy = _tmp_reportLastChangedBy
          this.reportLct = _tmp_reportLct
        }
      }
    }
  }

  override fun findByUidLive(uid: Long): Flow<Report?> = _db.doorFlow(arrayOf("Report")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * From Report WHERE  reportUid = CAST(? AS BIGINT)",
      postgreSql = """
      |SELECT * From Report WHERE  reportUid = ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,uid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_reportUid = _result.getLong("reportUid")
          val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
          val _tmp_xAxis = _result.getInt("xAxis")
          val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
          val _tmp_fromDate = _result.getLong("fromDate")
          val _tmp_fromRelTo = _result.getInt("fromRelTo")
          val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
          val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
          val _tmp_toDate = _result.getLong("toDate")
          val _tmp_toRelTo = _result.getInt("toRelTo")
          val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
          val _tmp_toRelUnit = _result.getInt("toRelUnit")
          val _tmp_reportTitle = _result.getString("reportTitle")
          val _tmp_reportDescription = _result.getString("reportDescription")
          val _tmp_reportSeries = _result.getString("reportSeries")
          val _tmp_reportInactive = _result.getBoolean("reportInactive")
          val _tmp_isTemplate = _result.getBoolean("isTemplate")
          val _tmp_priority = _result.getInt("priority")
          val _tmp_reportTitleId = _result.getInt("reportTitleId")
          val _tmp_reportDescId = _result.getInt("reportDescId")
          val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
          val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
          val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
          val _tmp_reportLct = _result.getLong("reportLct")
          Report().apply {
            this.reportUid = _tmp_reportUid
            this.reportOwnerUid = _tmp_reportOwnerUid
            this.xAxis = _tmp_xAxis
            this.reportDateRangeSelection = _tmp_reportDateRangeSelection
            this.fromDate = _tmp_fromDate
            this.fromRelTo = _tmp_fromRelTo
            this.fromRelOffSet = _tmp_fromRelOffSet
            this.fromRelUnit = _tmp_fromRelUnit
            this.toDate = _tmp_toDate
            this.toRelTo = _tmp_toRelTo
            this.toRelOffSet = _tmp_toRelOffSet
            this.toRelUnit = _tmp_toRelUnit
            this.reportTitle = _tmp_reportTitle
            this.reportDescription = _tmp_reportDescription
            this.reportSeries = _tmp_reportSeries
            this.reportInactive = _tmp_reportInactive
            this.isTemplate = _tmp_isTemplate
            this.priority = _tmp_priority
            this.reportTitleId = _tmp_reportTitleId
            this.reportDescId = _tmp_reportDescId
            this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
            this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
            this.reportLastChangedBy = _tmp_reportLastChangedBy
            this.reportLct = _tmp_reportLct
          }
        }
      }
    }
  }

  override fun findAllActiveReportLive(isTemplate: Boolean): Flow<List<Report>> =
      _db.doorFlow(arrayOf("REPORT")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND isTemplate = ?
      |        ORDER BY priority ASC
      |            
      """.trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setBoolean(1,isTemplate)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_reportUid = _result.getLong("reportUid")
          val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
          val _tmp_xAxis = _result.getInt("xAxis")
          val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
          val _tmp_fromDate = _result.getLong("fromDate")
          val _tmp_fromRelTo = _result.getInt("fromRelTo")
          val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
          val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
          val _tmp_toDate = _result.getLong("toDate")
          val _tmp_toRelTo = _result.getInt("toRelTo")
          val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
          val _tmp_toRelUnit = _result.getInt("toRelUnit")
          val _tmp_reportTitle = _result.getString("reportTitle")
          val _tmp_reportDescription = _result.getString("reportDescription")
          val _tmp_reportSeries = _result.getString("reportSeries")
          val _tmp_reportInactive = _result.getBoolean("reportInactive")
          val _tmp_isTemplate = _result.getBoolean("isTemplate")
          val _tmp_priority = _result.getInt("priority")
          val _tmp_reportTitleId = _result.getInt("reportTitleId")
          val _tmp_reportDescId = _result.getInt("reportDescId")
          val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
          val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
          val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
          val _tmp_reportLct = _result.getLong("reportLct")
          Report().apply {
            this.reportUid = _tmp_reportUid
            this.reportOwnerUid = _tmp_reportOwnerUid
            this.xAxis = _tmp_xAxis
            this.reportDateRangeSelection = _tmp_reportDateRangeSelection
            this.fromDate = _tmp_fromDate
            this.fromRelTo = _tmp_fromRelTo
            this.fromRelOffSet = _tmp_fromRelOffSet
            this.fromRelUnit = _tmp_fromRelUnit
            this.toDate = _tmp_toDate
            this.toRelTo = _tmp_toRelTo
            this.toRelOffSet = _tmp_toRelOffSet
            this.toRelUnit = _tmp_toRelUnit
            this.reportTitle = _tmp_reportTitle
            this.reportDescription = _tmp_reportDescription
            this.reportSeries = _tmp_reportSeries
            this.reportInactive = _tmp_reportInactive
            this.isTemplate = _tmp_isTemplate
            this.priority = _tmp_priority
            this.reportTitleId = _tmp_reportTitleId
            this.reportDescId = _tmp_reportDescId
            this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
            this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
            this.reportLastChangedBy = _tmp_reportLastChangedBy
            this.reportLct = _tmp_reportLct
          }
        }
      }
    }
  }

  override fun findAllActiveReportList(isTemplate: Boolean): List<Report> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |SELECT * FROM REPORT WHERE NOT reportInactive 
    |        AND isTemplate = ?
    |        ORDER BY priority ASC
    |            
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setBoolean(1,isTemplate)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_reportUid = _result.getLong("reportUid")
        val _tmp_reportOwnerUid = _result.getLong("reportOwnerUid")
        val _tmp_xAxis = _result.getInt("xAxis")
        val _tmp_reportDateRangeSelection = _result.getInt("reportDateRangeSelection")
        val _tmp_fromDate = _result.getLong("fromDate")
        val _tmp_fromRelTo = _result.getInt("fromRelTo")
        val _tmp_fromRelOffSet = _result.getInt("fromRelOffSet")
        val _tmp_fromRelUnit = _result.getInt("fromRelUnit")
        val _tmp_toDate = _result.getLong("toDate")
        val _tmp_toRelTo = _result.getInt("toRelTo")
        val _tmp_toRelOffSet = _result.getInt("toRelOffSet")
        val _tmp_toRelUnit = _result.getInt("toRelUnit")
        val _tmp_reportTitle = _result.getString("reportTitle")
        val _tmp_reportDescription = _result.getString("reportDescription")
        val _tmp_reportSeries = _result.getString("reportSeries")
        val _tmp_reportInactive = _result.getBoolean("reportInactive")
        val _tmp_isTemplate = _result.getBoolean("isTemplate")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_reportTitleId = _result.getInt("reportTitleId")
        val _tmp_reportDescId = _result.getInt("reportDescId")
        val _tmp_reportMasterChangeSeqNum = _result.getLong("reportMasterChangeSeqNum")
        val _tmp_reportLocalChangeSeqNum = _result.getLong("reportLocalChangeSeqNum")
        val _tmp_reportLastChangedBy = _result.getInt("reportLastChangedBy")
        val _tmp_reportLct = _result.getLong("reportLct")
        Report().apply {
          this.reportUid = _tmp_reportUid
          this.reportOwnerUid = _tmp_reportOwnerUid
          this.xAxis = _tmp_xAxis
          this.reportDateRangeSelection = _tmp_reportDateRangeSelection
          this.fromDate = _tmp_fromDate
          this.fromRelTo = _tmp_fromRelTo
          this.fromRelOffSet = _tmp_fromRelOffSet
          this.fromRelUnit = _tmp_fromRelUnit
          this.toDate = _tmp_toDate
          this.toRelTo = _tmp_toRelTo
          this.toRelOffSet = _tmp_toRelOffSet
          this.toRelUnit = _tmp_toRelUnit
          this.reportTitle = _tmp_reportTitle
          this.reportDescription = _tmp_reportDescription
          this.reportSeries = _tmp_reportSeries
          this.reportInactive = _tmp_reportInactive
          this.isTemplate = _tmp_isTemplate
          this.priority = _tmp_priority
          this.reportTitleId = _tmp_reportTitleId
          this.reportDescId = _tmp_reportDescId
          this.reportMasterChangeSeqNum = _tmp_reportMasterChangeSeqNum
          this.reportLocalChangeSeqNum = _tmp_reportLocalChangeSeqNum
          this.reportLastChangedBy = _tmp_reportLastChangedBy
          this.reportLct = _tmp_reportLct
        }
      }
    }
  }

  override fun findByUidList(uidList: List<Long>): List<Long> =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT reportUid FROM Report WHERE reportUid IN (?)",
    hasListParams = true,
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setArray(1, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
        uidList.toTypedArray()))
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        _result.getLong(1)
      }
    }
  }

  override suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long,
  ) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE Report 
      |           SET reportInactive = ?,
      |               reportLct = CAST(? AS BIGINT) 
      |         WHERE reportUid IN (?)
      |    
      """.trimMargin(),
      hasListParams = true,
      postgreSql = """
      |
      |        UPDATE Report 
      |           SET reportInactive = ?,
      |               reportLct = ? 
      |         WHERE reportUid IN (?)
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,toggleVisibility)
      _stmt.setLong(2,updateTime)
      _stmt.setArray(3, _stmt.getConnection().createArrayOrProxyArrayOf("BIGINT",
          selectedItem.toTypedArray()))
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
