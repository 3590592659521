package com.ustadmobile.core.db.dao

import com.ustadmobile.lib.db.entities.ConnectivityStatus
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.coroutines.flow.Flow

public actual abstract class ConnectivityStatusDao {
  public actual abstract fun statusLive(): Flow<ConnectivityStatus?>

  public actual abstract fun status(): ConnectivityStatus?

  public actual abstract fun insert(connectivityStatus: ConnectivityStatus): Long

  public actual abstract suspend fun insertAsync(connectivityStatus: ConnectivityStatus): Long

  public actual abstract suspend fun updateStateAsync(connectivityState: Int)

  public actual abstract suspend fun updateState(connectivityState: Int, wifiSsid: String)

  public actual abstract fun updateStateSync(connectivityState: Int, wifiSsid: String)
}
