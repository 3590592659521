package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.XObjectEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class XObjectDao_JdbcImpl(
  public val _db: RoomDatabase,
) : XObjectDao() {
  public val _insertAdapterXObjectEntity_: EntityInsertionAdapter<XObjectEntity> = object :
      EntityInsertionAdapter<XObjectEntity>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO XObjectEntity (xObjectUid, objectType, objectId, definitionType, interactionType, correctResponsePattern, objectContentEntryUid, objectStatementRefUid, xObjectMasterChangeSeqNum, xObjectocalChangeSeqNum, xObjectLastChangedBy, xObjectLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: XObjectEntity) {
      if(entity.xObjectUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.xObjectUid)
      }
      stmt.setString(2, entity.objectType)
      stmt.setString(3, entity.objectId)
      stmt.setString(4, entity.definitionType)
      stmt.setString(5, entity.interactionType)
      stmt.setString(6, entity.correctResponsePattern)
      stmt.setLong(7, entity.objectContentEntryUid)
      stmt.setLong(8, entity.objectStatementRefUid)
      stmt.setLong(9, entity.xObjectMasterChangeSeqNum)
      stmt.setLong(10, entity.xObjectocalChangeSeqNum)
      stmt.setInt(11, entity.xObjectLastChangedBy)
      stmt.setLong(12, entity.xObjectLct)
    }
  }

  public override fun insert(entity: XObjectEntity): Long {
    val _retVal = _insertAdapterXObjectEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: XObjectEntity): Long {
    val _retVal = _insertAdapterXObjectEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<XObjectEntity>) {
    _insertAdapterXObjectEntity_.insertList(entityList)
  }

  public override fun update(entity: XObjectEntity) {
    val _sql =
        "UPDATE XObjectEntity SET objectType = ?, objectId = ?, definitionType = ?, interactionType = ?, correctResponsePattern = ?, objectContentEntryUid = ?, objectStatementRefUid = ?, xObjectMasterChangeSeqNum = ?, xObjectocalChangeSeqNum = ?, xObjectLastChangedBy = ?, xObjectLct = ? WHERE xObjectUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.objectType)
      _stmt.setString(2, entity.objectId)
      _stmt.setString(3, entity.definitionType)
      _stmt.setString(4, entity.interactionType)
      _stmt.setString(5, entity.correctResponsePattern)
      _stmt.setLong(6, entity.objectContentEntryUid)
      _stmt.setLong(7, entity.objectStatementRefUid)
      _stmt.setLong(8, entity.xObjectMasterChangeSeqNum)
      _stmt.setLong(9, entity.xObjectocalChangeSeqNum)
      _stmt.setInt(10, entity.xObjectLastChangedBy)
      _stmt.setLong(11, entity.xObjectLct)
      _stmt.setLong(12, entity.xObjectUid)
      _stmt.executeUpdate()
    }
  }

  override fun findByObjectId(id: String?): XObjectEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * from XObjectEntity WHERE objectId = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,id)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_xObjectUid = _result.getLong("xObjectUid")
        val _tmp_objectType = _result.getString("objectType")
        val _tmp_objectId = _result.getString("objectId")
        val _tmp_definitionType = _result.getString("definitionType")
        val _tmp_interactionType = _result.getString("interactionType")
        val _tmp_correctResponsePattern = _result.getString("correctResponsePattern")
        val _tmp_objectContentEntryUid = _result.getLong("objectContentEntryUid")
        val _tmp_objectStatementRefUid = _result.getLong("objectStatementRefUid")
        val _tmp_xObjectMasterChangeSeqNum = _result.getLong("xObjectMasterChangeSeqNum")
        val _tmp_xObjectocalChangeSeqNum = _result.getLong("xObjectocalChangeSeqNum")
        val _tmp_xObjectLastChangedBy = _result.getInt("xObjectLastChangedBy")
        val _tmp_xObjectLct = _result.getLong("xObjectLct")
        XObjectEntity().apply {
          this.xObjectUid = _tmp_xObjectUid
          this.objectType = _tmp_objectType
          this.objectId = _tmp_objectId
          this.definitionType = _tmp_definitionType
          this.interactionType = _tmp_interactionType
          this.correctResponsePattern = _tmp_correctResponsePattern
          this.objectContentEntryUid = _tmp_objectContentEntryUid
          this.objectStatementRefUid = _tmp_objectStatementRefUid
          this.xObjectMasterChangeSeqNum = _tmp_xObjectMasterChangeSeqNum
          this.xObjectocalChangeSeqNum = _tmp_xObjectocalChangeSeqNum
          this.xObjectLastChangedBy = _tmp_xObjectLastChangedBy
          this.xObjectLct = _tmp_xObjectLct
        }
      }
    }
  }

  override fun findByXobjectUid(xObjectUid: Long): XObjectEntity? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * from XObjectEntity WHERE xObjectUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * from XObjectEntity WHERE xObjectUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,xObjectUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_xObjectUid = _result.getLong("xObjectUid")
        val _tmp_objectType = _result.getString("objectType")
        val _tmp_objectId = _result.getString("objectId")
        val _tmp_definitionType = _result.getString("definitionType")
        val _tmp_interactionType = _result.getString("interactionType")
        val _tmp_correctResponsePattern = _result.getString("correctResponsePattern")
        val _tmp_objectContentEntryUid = _result.getLong("objectContentEntryUid")
        val _tmp_objectStatementRefUid = _result.getLong("objectStatementRefUid")
        val _tmp_xObjectMasterChangeSeqNum = _result.getLong("xObjectMasterChangeSeqNum")
        val _tmp_xObjectocalChangeSeqNum = _result.getLong("xObjectocalChangeSeqNum")
        val _tmp_xObjectLastChangedBy = _result.getInt("xObjectLastChangedBy")
        val _tmp_xObjectLct = _result.getLong("xObjectLct")
        XObjectEntity().apply {
          this.xObjectUid = _tmp_xObjectUid
          this.objectType = _tmp_objectType
          this.objectId = _tmp_objectId
          this.definitionType = _tmp_definitionType
          this.interactionType = _tmp_interactionType
          this.correctResponsePattern = _tmp_correctResponsePattern
          this.objectContentEntryUid = _tmp_objectContentEntryUid
          this.objectStatementRefUid = _tmp_objectStatementRefUid
          this.xObjectMasterChangeSeqNum = _tmp_xObjectMasterChangeSeqNum
          this.xObjectocalChangeSeqNum = _tmp_xObjectocalChangeSeqNum
          this.xObjectLastChangedBy = _tmp_xObjectLastChangedBy
          this.xObjectLct = _tmp_xObjectLct
        }
      }
    }
  }
}
