package com.ustadmobile.core.db.dao

import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Container
import kotlin.collections.List

public class ContainerDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: ContainerDao,
) : ContainerDao() {
  override suspend fun findAllBatch(): List<Container> = _dao.findAllBatch()

  override suspend fun deleteListAsync(list: List<Container>) {
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      it.cntLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.deleteListAsync(list)
  }
}
