package com.ustadmobile.core.db.dao

import androidx.paging.PagingSource
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public actual abstract class VerbDao : BaseDao<VerbEntity> {
  public actual abstract fun findByUrl(urlId: String?): VerbEntity?

  public actual abstract suspend fun findByUidList(uidList: List<Long>): List<Long>

  public actual abstract suspend fun replaceList(entityList: List<VerbEntity>)

  public actual abstract fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay>

  public actual abstract fun findAllVerbsAsc(uidList: List<Long>): PagingSource<Int, VerbDisplay>

  public actual abstract fun findAllVerbsDesc(uidList: List<Long>): PagingSource<Int, VerbDisplay>
}
