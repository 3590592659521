package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.AgentEntity
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class AgentDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: AgentDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : AgentDao() {
  override fun getAgentByAnyId(
    openId: String?,
    mbox: String?,
    account: String?,
    homepage: String?,
    sha1: String?,
  ): AgentEntity? {
    val _result = _dao.getAgentByAnyId(openId, mbox, account, homepage, sha1)
    return _result
  }

  override suspend fun getAgentFromPersonUsername(endpoint: String, username: String):
      AgentEntity? {
    val _result = _dao.getAgentFromPersonUsername(endpoint, username)
    return _result
  }

  public override fun insert(entity: AgentEntity): Long {
    val _result = _repo.withRepoChangeMonitor("AgentEntity") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: AgentEntity): Long {
    val _result = _repo.withRepoChangeMonitorAsync("AgentEntity") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<AgentEntity>) {
    _repo.withRepoChangeMonitor("AgentEntity") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: AgentEntity) {
    _repo.withRepoChangeMonitor("AgentEntity") {
      _dao.update(entity)
    }
  }
}
