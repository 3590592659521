package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.UserSession
import com.ustadmobile.lib.db.entities.UserSessionAndPerson
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class UserSessionDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: UserSessionDao,
) : UserSessionDao() {
  override suspend fun insertSession(session: UserSession): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(session.usUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(679)
      session.usUid = _newPk
    }
    session.usLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertSession(session)
    return session.usUid
  }

  override suspend fun findSessionsByPerson(personUid: Long): List<UserSession> =
      _dao.findSessionsByPerson(personUid)

  override fun findAllLocalSessionsLive(): Flow<List<UserSessionAndPerson>> =
      _dao.findAllLocalSessionsLive()

  override suspend fun findAllLocalSessionsAsync(): List<UserSessionAndPerson> =
      _dao.findAllLocalSessionsAsync()

  override suspend fun findLocalSessionByUsername(username: String?): UserSessionAndPerson? =
      _dao.findLocalSessionByUsername(username)

  override suspend fun countAllLocalSessionsAsync(maxDateOfBirth: Long): Int =
      _dao.countAllLocalSessionsAsync(maxDateOfBirth)

  override suspend fun endSession(
    sessionUid: Long,
    newStatus: Int,
    reason: Int,
    endTime: Long,
  ) {
    _dao.endSession(sessionUid, newStatus, reason, endTime)
  }

  override fun findByUidLive(sessionUid: Long): Flow<UserSession?> = _dao.findByUidLive(sessionUid)

  override suspend fun endOtherSessions(
    personUid: Long,
    exemptNodeId: Long,
    newStatus: Int,
    reason: Int,
    changeTime: Long,
  ) {
    _dao.endOtherSessions(personUid, exemptNodeId, newStatus, reason, changeTime)
  }

  override suspend fun findActiveNodeIdsByPersonUids(personUids: List<Long>): List<Long> =
      _dao.findActiveNodeIdsByPersonUids(personUids)

  override suspend fun findActiveNodesIdsByGroupUids(groupUids: List<Long>): List<Long> =
      _dao.findActiveNodesIdsByGroupUids(groupUids)

  override suspend fun findAllActiveNodeIdsWithClazzBasedPermission(clazzUids: List<Long>):
      List<Long> = _dao.findAllActiveNodeIdsWithClazzBasedPermission(clazzUids)

  override suspend fun findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids: List<Long>):
      List<Long> = _dao.findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids)

  override suspend fun countActiveSessionsForUserAndNode(personUid: Long, nodeId: Long): Int =
      _dao.countActiveSessionsForUserAndNode(personUid, nodeId)
}
