package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ScrapeQueueItem
import com.ustadmobile.lib.db.entities.ScrapeQueueItemWithScrapeRun
import com.ustadmobile.lib.db.entities.ScrapeRun
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ScrapeQueueItemDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ScrapeQueueItemDao() {
  public val _insertAdapterScrapeQueueItem_: EntityInsertionAdapter<ScrapeQueueItem> = object :
      EntityInsertionAdapter<ScrapeQueueItem>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ScrapeQueueItem (sqiUid, sqiContentEntryParentUid, sqiContentEntryUid, destDir, scrapeUrl, status, runId, itemType, errorCode, contentType, timeAdded, timeStarted, timeFinished, priority, overrideEntry) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ScrapeQueueItem) {
      if(entity.sqiUid == 0) {
        stmt.setObject(1, null)
      } else {
        stmt.setInt(1, entity.sqiUid)
      }
      stmt.setLong(2, entity.sqiContentEntryParentUid)
      stmt.setLong(3, entity.sqiContentEntryUid)
      stmt.setString(4, entity.destDir)
      stmt.setString(5, entity.scrapeUrl)
      stmt.setInt(6, entity.status)
      stmt.setInt(7, entity.runId)
      stmt.setInt(8, entity.itemType)
      stmt.setInt(9, entity.errorCode)
      stmt.setString(10, entity.contentType)
      stmt.setLong(11, entity.timeAdded)
      stmt.setLong(12, entity.timeStarted)
      stmt.setLong(13, entity.timeFinished)
      stmt.setInt(14, entity.priority)
      stmt.setBoolean(15, entity.overrideEntry)
    }
  }

  public override fun insert(entity: ScrapeQueueItem): Long {
    val _retVal = _insertAdapterScrapeQueueItem_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ScrapeQueueItem): Long {
    val _retVal = _insertAdapterScrapeQueueItem_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ScrapeQueueItem>) {
    _insertAdapterScrapeQueueItem_.insertList(entityList)
  }

  public override fun update(entity: ScrapeQueueItem) {
    val _sql =
        "UPDATE ScrapeQueueItem SET sqiContentEntryParentUid = ?, sqiContentEntryUid = ?, destDir = ?, scrapeUrl = ?, status = ?, runId = ?, itemType = ?, errorCode = ?, contentType = ?, timeAdded = ?, timeStarted = ?, timeFinished = ?, priority = ?, overrideEntry = ? WHERE sqiUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sqiContentEntryParentUid)
      _stmt.setLong(2, entity.sqiContentEntryUid)
      _stmt.setString(3, entity.destDir)
      _stmt.setString(4, entity.scrapeUrl)
      _stmt.setInt(5, entity.status)
      _stmt.setInt(6, entity.runId)
      _stmt.setInt(7, entity.itemType)
      _stmt.setInt(8, entity.errorCode)
      _stmt.setString(9, entity.contentType)
      _stmt.setLong(10, entity.timeAdded)
      _stmt.setLong(11, entity.timeStarted)
      _stmt.setLong(12, entity.timeFinished)
      _stmt.setInt(13, entity.priority)
      _stmt.setBoolean(14, entity.overrideEntry)
      _stmt.setInt(15, entity.sqiUid)
      _stmt.executeUpdate()
    }
  }

  override fun findNextQueueItems(itemType: Int): Flow<List<ScrapeQueueItem>> =
      _db.doorFlow(arrayOf("ScrapeQueueItem")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "SELECT * FROM ScrapeQueueItem WHERE status = 1 AND itemType = ? ORDER BY priority ASC LIMIT 10",
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setInt(1,itemType)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_sqiUid = _result.getInt("sqiUid")
          val _tmp_sqiContentEntryParentUid = _result.getLong("sqiContentEntryParentUid")
          val _tmp_sqiContentEntryUid = _result.getLong("sqiContentEntryUid")
          val _tmp_destDir = _result.getString("destDir")
          val _tmp_scrapeUrl = _result.getString("scrapeUrl")
          val _tmp_status = _result.getInt("status")
          val _tmp_runId = _result.getInt("runId")
          val _tmp_itemType = _result.getInt("itemType")
          val _tmp_errorCode = _result.getInt("errorCode")
          val _tmp_contentType = _result.getString("contentType")
          val _tmp_timeAdded = _result.getLong("timeAdded")
          val _tmp_timeStarted = _result.getLong("timeStarted")
          val _tmp_timeFinished = _result.getLong("timeFinished")
          val _tmp_priority = _result.getInt("priority")
          val _tmp_overrideEntry = _result.getBoolean("overrideEntry")
          ScrapeQueueItem().apply {
            this.sqiUid = _tmp_sqiUid
            this.sqiContentEntryParentUid = _tmp_sqiContentEntryParentUid
            this.sqiContentEntryUid = _tmp_sqiContentEntryUid
            this.destDir = _tmp_destDir
            this.scrapeUrl = _tmp_scrapeUrl
            this.status = _tmp_status
            this.runId = _tmp_runId
            this.itemType = _tmp_itemType
            this.errorCode = _tmp_errorCode
            this.contentType = _tmp_contentType
            this.timeAdded = _tmp_timeAdded
            this.timeStarted = _tmp_timeStarted
            this.timeFinished = _tmp_timeFinished
            this.priority = _tmp_priority
            this.overrideEntry = _tmp_overrideEntry
          }
        }
      }
    }
  }

  override fun updateSetStatusById(
    uid: Int,
    status: Int,
    errorCode: Int,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "UPDATE ScrapeQueueItem SET status = ?, errorCode = ? WHERE sqiUid = ?",
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setInt(1,status)
      _stmt.setInt(2,errorCode)
      _stmt.setInt(3,uid)
      _stmt.executeUpdate()
    }
  }

  override fun getExistingQueueItem(runId: Int, indexUrl: String): ScrapeQueueItem? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * from ScrapeQueueItem WHERE runId = ? AND scrapeUrl = ? LIMIT 1",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setInt(1,runId)
    _stmt.setString(2,indexUrl)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_sqiUid = _result.getInt("sqiUid")
        val _tmp_sqiContentEntryParentUid = _result.getLong("sqiContentEntryParentUid")
        val _tmp_sqiContentEntryUid = _result.getLong("sqiContentEntryUid")
        val _tmp_destDir = _result.getString("destDir")
        val _tmp_scrapeUrl = _result.getString("scrapeUrl")
        val _tmp_status = _result.getInt("status")
        val _tmp_runId = _result.getInt("runId")
        val _tmp_itemType = _result.getInt("itemType")
        val _tmp_errorCode = _result.getInt("errorCode")
        val _tmp_contentType = _result.getString("contentType")
        val _tmp_timeAdded = _result.getLong("timeAdded")
        val _tmp_timeStarted = _result.getLong("timeStarted")
        val _tmp_timeFinished = _result.getLong("timeFinished")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_overrideEntry = _result.getBoolean("overrideEntry")
        ScrapeQueueItem().apply {
          this.sqiUid = _tmp_sqiUid
          this.sqiContentEntryParentUid = _tmp_sqiContentEntryParentUid
          this.sqiContentEntryUid = _tmp_sqiContentEntryUid
          this.destDir = _tmp_destDir
          this.scrapeUrl = _tmp_scrapeUrl
          this.status = _tmp_status
          this.runId = _tmp_runId
          this.itemType = _tmp_itemType
          this.errorCode = _tmp_errorCode
          this.contentType = _tmp_contentType
          this.timeAdded = _tmp_timeAdded
          this.timeStarted = _tmp_timeStarted
          this.timeFinished = _tmp_timeFinished
          this.priority = _tmp_priority
          this.overrideEntry = _tmp_overrideEntry
        }
      }
    }
  }

  override fun findExistingQueueItem(runId: Int, entryUid: Long): ScrapeQueueItem? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * from ScrapeQueueItem WHERE runId = ? AND sqiContentEntryUid = CAST(? AS BIGINT) LIMIT 1",
    postgreSql = """
    |SELECT * from ScrapeQueueItem WHERE runId = ? AND sqiContentEntryUid = ? LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setInt(1,runId)
    _stmt.setLong(2,entryUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_sqiUid = _result.getInt("sqiUid")
        val _tmp_sqiContentEntryParentUid = _result.getLong("sqiContentEntryParentUid")
        val _tmp_sqiContentEntryUid = _result.getLong("sqiContentEntryUid")
        val _tmp_destDir = _result.getString("destDir")
        val _tmp_scrapeUrl = _result.getString("scrapeUrl")
        val _tmp_status = _result.getInt("status")
        val _tmp_runId = _result.getInt("runId")
        val _tmp_itemType = _result.getInt("itemType")
        val _tmp_errorCode = _result.getInt("errorCode")
        val _tmp_contentType = _result.getString("contentType")
        val _tmp_timeAdded = _result.getLong("timeAdded")
        val _tmp_timeStarted = _result.getLong("timeStarted")
        val _tmp_timeFinished = _result.getLong("timeFinished")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_overrideEntry = _result.getBoolean("overrideEntry")
        ScrapeQueueItem().apply {
          this.sqiUid = _tmp_sqiUid
          this.sqiContentEntryParentUid = _tmp_sqiContentEntryParentUid
          this.sqiContentEntryUid = _tmp_sqiContentEntryUid
          this.destDir = _tmp_destDir
          this.scrapeUrl = _tmp_scrapeUrl
          this.status = _tmp_status
          this.runId = _tmp_runId
          this.itemType = _tmp_itemType
          this.errorCode = _tmp_errorCode
          this.contentType = _tmp_contentType
          this.timeAdded = _tmp_timeAdded
          this.timeStarted = _tmp_timeStarted
          this.timeFinished = _tmp_timeFinished
          this.priority = _tmp_priority
          this.overrideEntry = _tmp_overrideEntry
        }
      }
    }
  }

  override fun setTimeStarted(uid: Int, timeStarted: Long) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "UPDATE ScrapeQueueItem SET timeStarted = CAST(? AS BIGINT) WHERE sqiUid = ?",
      postgreSql = """
      |UPDATE ScrapeQueueItem SET timeStarted = ? WHERE sqiUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,timeStarted)
      _stmt.setInt(2,uid)
      _stmt.executeUpdate()
    }
  }

  override fun setTimeFinished(uid: Int, timeFinished: Long) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "UPDATE ScrapeQueueItem SET timeFinished = CAST(? AS BIGINT) WHERE sqiUid = ?",
      postgreSql = """
      |UPDATE ScrapeQueueItem SET timeFinished = ? WHERE sqiUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,timeFinished)
      _stmt.setInt(2,uid)
      _stmt.executeUpdate()
    }
  }

  override fun findByUid(sqiUid: Int): ScrapeQueueItemWithScrapeRun? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = """
    |SELECT ScrapeQueueItem.*, ScrapeRun.* FROM ScrapeQueueItem 
    |                    LEFT JOIN ScrapeRun ON  ScrapeQueueItem.runId = ScrapeRun.scrapeRunUid
    |                    WHERE ScrapeQueueItem.sqiUid = ?
    """.trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setInt(1,sqiUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_sqiUid = _result.getInt("sqiUid")
        val _tmp_sqiContentEntryParentUid = _result.getLong("sqiContentEntryParentUid")
        val _tmp_sqiContentEntryUid = _result.getLong("sqiContentEntryUid")
        val _tmp_destDir = _result.getString("destDir")
        val _tmp_scrapeUrl = _result.getString("scrapeUrl")
        val _tmp_status = _result.getInt("status")
        val _tmp_runId = _result.getInt("runId")
        val _tmp_itemType = _result.getInt("itemType")
        val _tmp_errorCode = _result.getInt("errorCode")
        val _tmp_contentType = _result.getString("contentType")
        val _tmp_timeAdded = _result.getLong("timeAdded")
        val _tmp_timeStarted = _result.getLong("timeStarted")
        val _tmp_timeFinished = _result.getLong("timeFinished")
        val _tmp_priority = _result.getInt("priority")
        val _tmp_overrideEntry = _result.getBoolean("overrideEntry")
        var _tmp_ScrapeRun_nullCount = 0
        val _tmp_scrapeRunUid = _result.getInt("scrapeRunUid")
        if(_result.wasNull()) _tmp_ScrapeRun_nullCount++
        val _tmp_scrapeType = _result.getString("scrapeType")
        if(_result.wasNull()) _tmp_ScrapeRun_nullCount++
        val _tmp_scrapeRunStatus = _result.getInt("scrapeRunStatus")
        if(_result.wasNull()) _tmp_ScrapeRun_nullCount++
        val _tmp_conversionParams = _result.getString("conversionParams")
        if(_result.wasNull()) _tmp_ScrapeRun_nullCount++
        val _tmp_ScrapeRun_isAllNull = _tmp_ScrapeRun_nullCount == 4
        ScrapeQueueItemWithScrapeRun().apply {
          this.sqiUid = _tmp_sqiUid
          this.sqiContentEntryParentUid = _tmp_sqiContentEntryParentUid
          this.sqiContentEntryUid = _tmp_sqiContentEntryUid
          this.destDir = _tmp_destDir
          this.scrapeUrl = _tmp_scrapeUrl
          this.status = _tmp_status
          this.runId = _tmp_runId
          this.itemType = _tmp_itemType
          this.errorCode = _tmp_errorCode
          this.contentType = _tmp_contentType
          this.timeAdded = _tmp_timeAdded
          this.timeStarted = _tmp_timeStarted
          this.timeFinished = _tmp_timeFinished
          this.priority = _tmp_priority
          this.overrideEntry = _tmp_overrideEntry
          if(!_tmp_ScrapeRun_isAllNull) {
            this.scrapeRun = ScrapeRun().apply {
              this.scrapeRunUid = _tmp_scrapeRunUid
              this.scrapeType = _tmp_scrapeType
              this.scrapeRunStatus = _tmp_scrapeRunStatus
              this.conversionParams = _tmp_conversionParams
            }
          }
        }
      }
    }
  }
}
