package com.ustadmobile.core.db.dao

import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.composites.PersonAndPicture
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.CourseGroupMemberAndName
import com.ustadmobile.lib.db.entities.CourseGroupMemberPerson
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class CourseGroupMemberDao_DoorWrapper(
  private val _db: RoomDatabase,
  private val _dao: CourseGroupMemberDao,
) : CourseGroupMemberDao() {
  override suspend fun findByGroupSetAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> = _dao.findByGroupSetAsync(setUid, clazzUid)

  override suspend fun findByGroupSetUidAsync(groupSetUid: Long): List<CourseGroupMember> =
      _dao.findByGroupSetUidAsync(groupSetUid)

  override suspend fun findByGroupSetOrderedAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> = _dao.findByGroupSetOrderedAsync(setUid, clazzUid)

  override suspend fun findByPersonUid(groupSetUid: Long, studentUid: Long): CourseGroupMember? =
      _dao.findByPersonUid(groupSetUid, studentUid)

  override suspend fun insertListAsync(entityList: List<CourseGroupMember>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.cgmUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(243)
        it.cgmUid = _newPk
        _generatedPks += _newPk
      }
      it.cgmLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  override suspend fun updateListAsync(entityList: List<CourseGroupMember>) {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.cgmLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }

  override suspend fun findByCourseGroupSetAndClazz(
    cgsUid: Long,
    clazzUid: Long,
    time: Long,
    activeFilter: Int,
    accountPersonUid: Long,
  ): List<CourseGroupMemberAndName> = _dao.findByCourseGroupSetAndClazz(cgsUid, clazzUid, time,
      activeFilter, accountPersonUid)

  override fun findByCourseGroupSetAndClazzAsFlow(
    cgsUid: Long,
    clazzUid: Long,
    time: Long,
    activeFilter: Int,
    accountPersonUid: Long,
  ): Flow<List<CourseGroupMemberAndName>> = _dao.findByCourseGroupSetAndClazzAsFlow(cgsUid,
      clazzUid, time, activeFilter, accountPersonUid)

  override suspend fun findByCourseGroupSetAndClazzAsFlowPersons(clazzUid: Long, cgsUid: Long):
      List<PersonAndPicture> = _dao.findByCourseGroupSetAndClazzAsFlowPersons(clazzUid, cgsUid)

  override suspend fun findByCourseGroupSetAndClazzAsFlowEnrolments(clazzUid: Long, cgsUid: Long):
      List<ClazzEnrolment> = _dao.findByCourseGroupSetAndClazzAsFlowEnrolments(clazzUid, cgsUid)

  override suspend fun upsertListAsync(list: List<CourseGroupMember>) {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      if(it.cgmUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(243)
        it.cgmUid = _newPk
        _generatedPks += _newPk
      }
      it.cgmLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.upsertListAsync(list)
  }

  public override fun insert(entity: CourseGroupMember): Long = throw
      IllegalStateException("insert: synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseGroupMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cgmUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(243)
      entity.cgmUid = _newPk
    }
    entity.cgmLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cgmUid
  }

  public override fun insertList(entityList: List<CourseGroupMember>): Unit = throw
      IllegalStateException("insertList: synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseGroupMember): Unit = throw
      IllegalStateException("update: synchronous db access is NOT possible on Javascript!")
}
