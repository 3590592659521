package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContextXObjectStatementJoin
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class ContextXObjectStatementJoinDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContextXObjectStatementJoinDao() {
  public val _insertAdapterContextXObjectStatementJoin_:
      EntityInsertionAdapter<ContextXObjectStatementJoin> = object :
      EntityInsertionAdapter<ContextXObjectStatementJoin>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ContextXObjectStatementJoin (contextXObjectStatementJoinUid, contextActivityFlag, contextStatementUid, contextXObjectUid, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, contextXObjectLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContextXObjectStatementJoin) {
      if(entity.contextXObjectStatementJoinUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contextXObjectStatementJoinUid)
      }
      stmt.setInt(2, entity.contextActivityFlag)
      stmt.setLong(3, entity.contextStatementUid)
      stmt.setLong(4, entity.contextXObjectUid)
      stmt.setLong(5, entity.verbMasterChangeSeqNum)
      stmt.setLong(6, entity.verbLocalChangeSeqNum)
      stmt.setInt(7, entity.verbLastChangedBy)
      stmt.setLong(8, entity.contextXObjectLct)
    }
  }

  public override fun insert(entity: ContextXObjectStatementJoin): Long {
    val _retVal = _insertAdapterContextXObjectStatementJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContextXObjectStatementJoin): Long {
    val _retVal = _insertAdapterContextXObjectStatementJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContextXObjectStatementJoin>) {
    _insertAdapterContextXObjectStatementJoin_.insertList(entityList)
  }

  public override fun update(entity: ContextXObjectStatementJoin) {
    val _sql =
        "UPDATE ContextXObjectStatementJoin SET contextActivityFlag = ?, contextStatementUid = ?, contextXObjectUid = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, contextXObjectLct = ? WHERE contextXObjectStatementJoinUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.contextActivityFlag)
      _stmt.setLong(2, entity.contextStatementUid)
      _stmt.setLong(3, entity.contextXObjectUid)
      _stmt.setLong(4, entity.verbMasterChangeSeqNum)
      _stmt.setLong(5, entity.verbLocalChangeSeqNum)
      _stmt.setInt(6, entity.verbLastChangedBy)
      _stmt.setLong(7, entity.contextXObjectLct)
      _stmt.setLong(8, entity.contextXObjectStatementJoinUid)
      _stmt.executeUpdate()
    }
  }

  override fun findByStatementAndObjectUid(statementUid: Long, objectUid: Long):
      ContextXObjectStatementJoin? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM ContextXObjectStatementJoin where contextStatementUid = CAST(? AS BIGINT) and contextXObjectUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM ContextXObjectStatementJoin where contextStatementUid = ? and contextXObjectUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,statementUid)
    _stmt.setLong(2,objectUid)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_contextXObjectStatementJoinUid = _result.getLong("contextXObjectStatementJoinUid")
        val _tmp_contextActivityFlag = _result.getInt("contextActivityFlag")
        val _tmp_contextStatementUid = _result.getLong("contextStatementUid")
        val _tmp_contextXObjectUid = _result.getLong("contextXObjectUid")
        val _tmp_verbMasterChangeSeqNum = _result.getLong("verbMasterChangeSeqNum")
        val _tmp_verbLocalChangeSeqNum = _result.getLong("verbLocalChangeSeqNum")
        val _tmp_verbLastChangedBy = _result.getInt("verbLastChangedBy")
        val _tmp_contextXObjectLct = _result.getLong("contextXObjectLct")
        ContextXObjectStatementJoin().apply {
          this.contextXObjectStatementJoinUid = _tmp_contextXObjectStatementJoinUid
          this.contextActivityFlag = _tmp_contextActivityFlag
          this.contextStatementUid = _tmp_contextStatementUid
          this.contextXObjectUid = _tmp_contextXObjectUid
          this.verbMasterChangeSeqNum = _tmp_verbMasterChangeSeqNum
          this.verbLocalChangeSeqNum = _tmp_verbLocalChangeSeqNum
          this.verbLastChangedBy = _tmp_verbLastChangedBy
          this.contextXObjectLct = _tmp_contextXObjectLct
        }
      }
    }
  }
}
