package com.ustadmobile

import kotlin.String

internal object BuildConfigJs {
  internal const val APP_VERSION: String = "0.4.121"

  internal const val APP_PRESET_LOCALE: String = ""

  internal const val APP_UI_LANGUAGES: String = ""

  internal const val APP_UI_GOPTS: String = ""

  internal const val APP_UI_SHOW_POWERED_BY: String = "false"
}
