package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.replication.withRepoChangeMonitor
import com.ustadmobile.door.replication.withRepoChangeMonitorAsync
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SchoolDao_Repo(
  public val _db: RoomDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SchoolDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String,
) : SchoolDao() {
  override suspend fun findByUidAsync(schoolUid: Long): School? {
    val _result = _dao.findByUidAsync(schoolUid)
    return _result
  }

  override suspend fun findByUidWithHolidayCalendarAsync(uid: Long): SchoolWithHolidayCalendar? {
    val _result = _dao.findByUidWithHolidayCalendarAsync(uid)
    return _result
  }

  override suspend fun findBySchoolCode(code: String): School? {
    val _result = _dao.findBySchoolCode(code)
    return _result
  }

  override suspend fun findBySchoolCodeFromWeb(code: String): School? {
    val _result = _dao.findBySchoolCodeFromWeb(code)
    return _result
  }

  override suspend fun updateAsync(entity: School): Int {
    val _result = _repo.withRepoChangeMonitorAsync("School") {
      _dao.updateAsync(entity)
    }
    return _result
  }

  public override fun insert(entity: School): Long {
    val _result = _repo.withRepoChangeMonitor("School") {
      _dao.insert(entity)
    }
    return _result
  }

  public override suspend fun insertAsync(entity: School): Long {
    val _result = _repo.withRepoChangeMonitorAsync("School") {
      _dao.insertAsync(entity)
    }
    return _result
  }

  public override fun insertList(entityList: List<School>) {
    _repo.withRepoChangeMonitor("School") {
      _dao.insertList(entityList)
    }
  }

  public override fun update(entity: School) {
    _repo.withRepoChangeMonitor("School") {
      _dao.update(entity)
    }
  }
}
