package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.flow.doorFlow
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.ContainerImportJob
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlinx.coroutines.flow.Flow

public class ContainerImportJobDao_JdbcImpl(
  public val _db: RoomDatabase,
) : ContainerImportJobDao() {
  public val _insertAdapterContainerImportJob_: EntityInsertionAdapter<ContainerImportJob> = object
      : EntityInsertionAdapter<ContainerImportJob>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO ContainerImportJob (cijUid, cijContainerUid, cijUri, cijImportMode, cijContainerBaseDir, cijContentEntryUid, cijMimeType, cijSessionId, cijJobStatus, cijBytesSoFar, cijImportCompleted, cijContentLength, cijContainerEntryFileUids, cijConversionParams) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContainerImportJob) {
      if(entity.cijUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cijUid)
      }
      stmt.setLong(2, entity.cijContainerUid)
      stmt.setString(3, entity.cijUri)
      stmt.setInt(4, entity.cijImportMode)
      stmt.setString(5, entity.cijContainerBaseDir)
      stmt.setLong(6, entity.cijContentEntryUid)
      stmt.setString(7, entity.cijMimeType)
      stmt.setString(8, entity.cijSessionId)
      stmt.setInt(9, entity.cijJobStatus)
      stmt.setLong(10, entity.cijBytesSoFar)
      stmt.setBoolean(11, entity.cijImportCompleted)
      stmt.setLong(12, entity.cijContentLength)
      stmt.setString(13, entity.cijContainerEntryFileUids)
      stmt.setString(14, entity.cijConversionParams)
    }
  }

  public override fun insert(entity: ContainerImportJob): Long {
    val _retVal = _insertAdapterContainerImportJob_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerImportJob): Long {
    val _retVal = _insertAdapterContainerImportJob_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<ContainerImportJob>) {
    _insertAdapterContainerImportJob_.insertList(entityList)
  }

  public override fun update(entity: ContainerImportJob) {
    val _sql =
        "UPDATE ContainerImportJob SET cijContainerUid = ?, cijUri = ?, cijImportMode = ?, cijContainerBaseDir = ?, cijContentEntryUid = ?, cijMimeType = ?, cijSessionId = ?, cijJobStatus = ?, cijBytesSoFar = ?, cijImportCompleted = ?, cijContentLength = ?, cijContainerEntryFileUids = ?, cijConversionParams = ? WHERE cijUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cijContainerUid)
      _stmt.setString(2, entity.cijUri)
      _stmt.setInt(3, entity.cijImportMode)
      _stmt.setString(4, entity.cijContainerBaseDir)
      _stmt.setLong(5, entity.cijContentEntryUid)
      _stmt.setString(6, entity.cijMimeType)
      _stmt.setString(7, entity.cijSessionId)
      _stmt.setInt(8, entity.cijJobStatus)
      _stmt.setLong(9, entity.cijBytesSoFar)
      _stmt.setBoolean(10, entity.cijImportCompleted)
      _stmt.setLong(11, entity.cijContentLength)
      _stmt.setString(12, entity.cijContainerEntryFileUids)
      _stmt.setString(13, entity.cijConversionParams)
      _stmt.setLong(14, entity.cijUid)
      _stmt.executeUpdate()
    }
  }

  override fun findBySessionId(sessionId: String): ContainerImportJob? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM ContainerImportJob WHERE cijSessionId = ?",
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,sessionId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cijUid = _result.getLong("cijUid")
        val _tmp_cijContainerUid = _result.getLong("cijContainerUid")
        val _tmp_cijUri = _result.getString("cijUri")
        val _tmp_cijImportMode = _result.getInt("cijImportMode")
        val _tmp_cijContainerBaseDir = _result.getString("cijContainerBaseDir")
        val _tmp_cijContentEntryUid = _result.getLong("cijContentEntryUid")
        val _tmp_cijMimeType = _result.getString("cijMimeType")
        val _tmp_cijSessionId = _result.getString("cijSessionId")
        val _tmp_cijJobStatus = _result.getInt("cijJobStatus")
        val _tmp_cijBytesSoFar = _result.getLong("cijBytesSoFar")
        val _tmp_cijImportCompleted = _result.getBoolean("cijImportCompleted")
        val _tmp_cijContentLength = _result.getLong("cijContentLength")
        val _tmp_cijContainerEntryFileUids = _result.getString("cijContainerEntryFileUids")
        val _tmp_cijConversionParams = _result.getString("cijConversionParams")
        ContainerImportJob().apply {
          this.cijUid = _tmp_cijUid
          this.cijContainerUid = _tmp_cijContainerUid
          this.cijUri = _tmp_cijUri
          this.cijImportMode = _tmp_cijImportMode
          this.cijContainerBaseDir = _tmp_cijContainerBaseDir
          this.cijContentEntryUid = _tmp_cijContentEntryUid
          this.cijMimeType = _tmp_cijMimeType
          this.cijSessionId = _tmp_cijSessionId
          this.cijJobStatus = _tmp_cijJobStatus
          this.cijBytesSoFar = _tmp_cijBytesSoFar
          this.cijImportCompleted = _tmp_cijImportCompleted
          this.cijContentLength = _tmp_cijContentLength
          this.cijContainerEntryFileUids = _tmp_cijContainerEntryFileUids
          this.cijConversionParams = _tmp_cijConversionParams
        }
      }
    }
  }

  override fun findByUid(uploadId: Long): ContainerImportJob? =
      _db.prepareAndUseStatement(PreparedStatementConfig(
    sql = "SELECT * FROM ContainerImportJob where cijUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT * FROM ContainerImportJob where cijUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,uploadId)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_cijUid = _result.getLong("cijUid")
        val _tmp_cijContainerUid = _result.getLong("cijContainerUid")
        val _tmp_cijUri = _result.getString("cijUri")
        val _tmp_cijImportMode = _result.getInt("cijImportMode")
        val _tmp_cijContainerBaseDir = _result.getString("cijContainerBaseDir")
        val _tmp_cijContentEntryUid = _result.getLong("cijContentEntryUid")
        val _tmp_cijMimeType = _result.getString("cijMimeType")
        val _tmp_cijSessionId = _result.getString("cijSessionId")
        val _tmp_cijJobStatus = _result.getInt("cijJobStatus")
        val _tmp_cijBytesSoFar = _result.getLong("cijBytesSoFar")
        val _tmp_cijImportCompleted = _result.getBoolean("cijImportCompleted")
        val _tmp_cijContentLength = _result.getLong("cijContentLength")
        val _tmp_cijContainerEntryFileUids = _result.getString("cijContainerEntryFileUids")
        val _tmp_cijConversionParams = _result.getString("cijConversionParams")
        ContainerImportJob().apply {
          this.cijUid = _tmp_cijUid
          this.cijContainerUid = _tmp_cijContainerUid
          this.cijUri = _tmp_cijUri
          this.cijImportMode = _tmp_cijImportMode
          this.cijContainerBaseDir = _tmp_cijContainerBaseDir
          this.cijContentEntryUid = _tmp_cijContentEntryUid
          this.cijMimeType = _tmp_cijMimeType
          this.cijSessionId = _tmp_cijSessionId
          this.cijJobStatus = _tmp_cijJobStatus
          this.cijBytesSoFar = _tmp_cijBytesSoFar
          this.cijImportCompleted = _tmp_cijImportCompleted
          this.cijContentLength = _tmp_cijContentLength
          this.cijContainerEntryFileUids = _tmp_cijContainerEntryFileUids
          this.cijConversionParams = _tmp_cijConversionParams
        }
      }
    }
  }

  override fun findJobs(): Flow<List<ContainerImportJob>> =
      _db.doorFlow(arrayOf("ConnectivityStatus", "ContainerImportJob")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = """
      |
      |            SELECT * 
      |              FROM ContainerImportJob 
      |             WHERE cijJobStatus = 4
      |                   AND (NOT cijImportCompleted OR 
      |                   (SELECT connectivityState 
      |                      FROM ConnectivityStatus)
      |                   IN (3, 4))
      |             LIMIT 10
      """.trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapRows {
          val _tmp_cijUid = _result.getLong("cijUid")
          val _tmp_cijContainerUid = _result.getLong("cijContainerUid")
          val _tmp_cijUri = _result.getString("cijUri")
          val _tmp_cijImportMode = _result.getInt("cijImportMode")
          val _tmp_cijContainerBaseDir = _result.getString("cijContainerBaseDir")
          val _tmp_cijContentEntryUid = _result.getLong("cijContentEntryUid")
          val _tmp_cijMimeType = _result.getString("cijMimeType")
          val _tmp_cijSessionId = _result.getString("cijSessionId")
          val _tmp_cijJobStatus = _result.getInt("cijJobStatus")
          val _tmp_cijBytesSoFar = _result.getLong("cijBytesSoFar")
          val _tmp_cijImportCompleted = _result.getBoolean("cijImportCompleted")
          val _tmp_cijContentLength = _result.getLong("cijContentLength")
          val _tmp_cijContainerEntryFileUids = _result.getString("cijContainerEntryFileUids")
          val _tmp_cijConversionParams = _result.getString("cijConversionParams")
          ContainerImportJob().apply {
            this.cijUid = _tmp_cijUid
            this.cijContainerUid = _tmp_cijContainerUid
            this.cijUri = _tmp_cijUri
            this.cijImportMode = _tmp_cijImportMode
            this.cijContainerBaseDir = _tmp_cijContainerBaseDir
            this.cijContentEntryUid = _tmp_cijContentEntryUid
            this.cijMimeType = _tmp_cijMimeType
            this.cijSessionId = _tmp_cijSessionId
            this.cijJobStatus = _tmp_cijJobStatus
            this.cijBytesSoFar = _tmp_cijBytesSoFar
            this.cijImportCompleted = _tmp_cijImportCompleted
            this.cijContentLength = _tmp_cijContentLength
            this.cijContainerEntryFileUids = _tmp_cijContainerEntryFileUids
            this.cijConversionParams = _tmp_cijConversionParams
          }
        }
      }
    }
  }

  override suspend fun setStatusToQueueAsync(uploadJobId: Long) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql =
          "UPDATE ContainerImportJob SET cijJobStatus = 4 WHERE cijUid = CAST(? AS BIGINT) AND cijJobStatus = 0",
      postgreSql = """
      |UPDATE ContainerImportJob SET cijJobStatus = 4 WHERE cijUid = ? AND cijJobStatus = 0
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,uploadJobId)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  override fun updateProgress(
    progress: Long,
    contentLength: Long,
    uploadJobId: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql =
          "UPDATE ContainerImportJob SET cijBytesSoFar = CAST(? AS BIGINT), cijContentLength = CAST(? AS BIGINT) WHERE cijUid = CAST(? AS BIGINT)",
      postgreSql = """
      |UPDATE ContainerImportJob SET cijBytesSoFar = ?, cijContentLength = ? WHERE cijUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setLong(1,progress)
      _stmt.setLong(2,contentLength)
      _stmt.setLong(3,uploadJobId)
      _stmt.executeUpdate()
    }
  }

  override fun updateStatus(status: Int, uploadJobId: Long) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = "UPDATE ContainerImportJob SET cijJobStatus = ? WHERE cijUid = CAST(? AS BIGINT)",
      postgreSql = """
      |UPDATE ContainerImportJob SET cijJobStatus = ? WHERE cijUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setInt(1,status)
      _stmt.setLong(2,uploadJobId)
      _stmt.executeUpdate()
    }
  }

  override fun updateImportComplete(
    importCompleted: Boolean,
    containerUid: Long,
    importJobUid: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |UPDATE ContainerImportJob 
      |                       SET cijImportCompleted = ?,
      |                           cijContainerUid = CAST(? AS BIGINT)
      |                     WHERE cijUid = CAST(? AS BIGINT)
      """.trimMargin(),
      postgreSql = """
      |UPDATE ContainerImportJob 
      |                       SET cijImportCompleted = ?,
      |                           cijContainerUid = ?
      |                     WHERE cijUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,importCompleted)
      _stmt.setLong(2,containerUid)
      _stmt.setLong(3,importJobUid)
      _stmt.executeUpdate()
    }
  }

  override suspend fun getTitleOfEntry(importJobUid: Long): String? =
      _db.prepareAndUseStatementAsync(PreparedStatementConfig(
    sql =
        "SELECT ContentEntry.title FROM ContainerImportJob LEFT JOIN ContentEntry ON ContainerImportJob.cijContentEntryUid = ContentEntry.contentEntryUid WHERE ContainerImportJob.cijUid = CAST(? AS BIGINT)",
    postgreSql = """
    |SELECT ContentEntry.title FROM ContainerImportJob LEFT JOIN ContentEntry ON ContainerImportJob.cijContentEntryUid = ContentEntry.contentEntryUid WHERE ContainerImportJob.cijUid = ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,importJobUid)
    _stmt.executeQueryAsyncKmp().useResults{ _result -> 
      _result.mapNextRow(null) {
        _result.getString(1)
      }
    }
  }

  override fun getImportJobLiveData(importJobUid: Long): Flow<ContainerImportJob?> =
      _db.doorFlow(arrayOf("ContainerImportJob")) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "SELECT * From  ContainerImportJob WHERE ContainerImportJob.cijUid = CAST(? AS BIGINT)",
      postgreSql = """
      |SELECT * From  ContainerImportJob WHERE ContainerImportJob.cijUid = ?
      |""".trimMargin(),
      readOnly = true,)
    ) { _stmt -> 
      _stmt.setLong(1,importJobUid)
      _stmt.executeQueryAsyncKmp().useResults{ _result -> 
        _result.mapNextRow(null) {
          val _tmp_cijUid = _result.getLong("cijUid")
          val _tmp_cijContainerUid = _result.getLong("cijContainerUid")
          val _tmp_cijUri = _result.getString("cijUri")
          val _tmp_cijImportMode = _result.getInt("cijImportMode")
          val _tmp_cijContainerBaseDir = _result.getString("cijContainerBaseDir")
          val _tmp_cijContentEntryUid = _result.getLong("cijContentEntryUid")
          val _tmp_cijMimeType = _result.getString("cijMimeType")
          val _tmp_cijSessionId = _result.getString("cijSessionId")
          val _tmp_cijJobStatus = _result.getInt("cijJobStatus")
          val _tmp_cijBytesSoFar = _result.getLong("cijBytesSoFar")
          val _tmp_cijImportCompleted = _result.getBoolean("cijImportCompleted")
          val _tmp_cijContentLength = _result.getLong("cijContentLength")
          val _tmp_cijContainerEntryFileUids = _result.getString("cijContainerEntryFileUids")
          val _tmp_cijConversionParams = _result.getString("cijConversionParams")
          ContainerImportJob().apply {
            this.cijUid = _tmp_cijUid
            this.cijContainerUid = _tmp_cijContainerUid
            this.cijUri = _tmp_cijUri
            this.cijImportMode = _tmp_cijImportMode
            this.cijContainerBaseDir = _tmp_cijContainerBaseDir
            this.cijContentEntryUid = _tmp_cijContentEntryUid
            this.cijMimeType = _tmp_cijMimeType
            this.cijSessionId = _tmp_cijSessionId
            this.cijJobStatus = _tmp_cijJobStatus
            this.cijBytesSoFar = _tmp_cijBytesSoFar
            this.cijImportCompleted = _tmp_cijImportCompleted
            this.cijContentLength = _tmp_cijContentLength
            this.cijContainerEntryFileUids = _tmp_cijContainerEntryFileUids
            this.cijConversionParams = _tmp_cijConversionParams
          }
        }
      }
    }
  }

  override suspend fun updateSessionId(importJobUid: Long, sessionId: String) {
    _db.prepareAndUseStatementAsync(PreparedStatementConfig(
      sql = "UPDATE ContainerImportJob SET cijSessionId = ? WHERE cijUid = CAST(? AS BIGINT)",
      postgreSql = """
      |UPDATE ContainerImportJob SET cijSessionId = ? WHERE cijUid = ?
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setString(1,sessionId)
      _stmt.setLong(2,importJobUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }
}
