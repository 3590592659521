package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.mapNextRow
import com.ustadmobile.door.jdbc.ext.mapRows
import com.ustadmobile.door.jdbc.ext.useResults
import com.ustadmobile.door.room.RoomDatabase
import com.ustadmobile.lib.db.entities.StateEntity
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.collections.List

public class StateDao_JdbcImpl(
  public val _db: RoomDatabase,
) : StateDao() {
  public val _insertAdapterStateEntity_: EntityInsertionAdapter<StateEntity> = object :
      EntityInsertionAdapter<StateEntity>(_db) {
    override fun makeSql(returnsId: Boolean): String =
        "INSERT INTO StateEntity (stateUid, stateId, agentUid, activityId, registration, isIsactive, timestamp, stateMasterChangeSeqNum, stateLocalChangeSeqNum, stateLastChangedBy, stateLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"

    override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: StateEntity) {
      if(entity.stateUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.stateUid)
      }
      stmt.setString(2, entity.stateId)
      stmt.setLong(3, entity.agentUid)
      stmt.setString(4, entity.activityId)
      stmt.setString(5, entity.registration)
      stmt.setBoolean(6, entity.isIsactive)
      stmt.setLong(7, entity.timestamp)
      stmt.setLong(8, entity.stateMasterChangeSeqNum)
      stmt.setLong(9, entity.stateLocalChangeSeqNum)
      stmt.setInt(10, entity.stateLastChangedBy)
      stmt.setLong(11, entity.stateLct)
    }
  }

  public override fun insert(entity: StateEntity): Long {
    val _retVal = _insertAdapterStateEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: StateEntity): Long {
    val _retVal = _insertAdapterStateEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<StateEntity>) {
    _insertAdapterStateEntity_.insertList(entityList)
  }

  public override fun update(entity: StateEntity) {
    val _sql =
        "UPDATE StateEntity SET stateId = ?, agentUid = ?, activityId = ?, registration = ?, isIsactive = ?, timestamp = ?, stateMasterChangeSeqNum = ?, stateLocalChangeSeqNum = ?, stateLastChangedBy = ?, stateLct = ? WHERE stateUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.stateId)
      _stmt.setLong(2, entity.agentUid)
      _stmt.setString(3, entity.activityId)
      _stmt.setString(4, entity.registration)
      _stmt.setBoolean(5, entity.isIsactive)
      _stmt.setLong(6, entity.timestamp)
      _stmt.setLong(7, entity.stateMasterChangeSeqNum)
      _stmt.setLong(8, entity.stateLocalChangeSeqNum)
      _stmt.setInt(9, entity.stateLastChangedBy)
      _stmt.setLong(10, entity.stateLct)
      _stmt.setLong(11, entity.stateUid)
      _stmt.executeUpdate()
    }
  }

  override fun findByStateId(
    id: String?,
    agentUid: Long,
    activityId: String?,
    registration: String?,
  ): StateEntity? = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM StateEntity WHERE stateId = ? AND agentUid = CAST(? AS BIGINT) AND activityId = ? AND registration = ? AND isIsactive LIMIT 1",
    postgreSql = """
    |SELECT * FROM StateEntity WHERE stateId = ? AND agentUid = ? AND activityId = ? AND registration = ? AND isIsactive LIMIT 1
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setString(1,id)
    _stmt.setLong(2,agentUid)
    _stmt.setString(3,activityId)
    _stmt.setString(4,registration)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapNextRow(null) {
        val _tmp_stateUid = _result.getLong("stateUid")
        val _tmp_stateId = _result.getString("stateId")
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_activityId = _result.getString("activityId")
        val _tmp_registration = _result.getString("registration")
        val _tmp_isIsactive = _result.getBoolean("isIsactive")
        val _tmp_timestamp = _result.getLong("timestamp")
        val _tmp_stateMasterChangeSeqNum = _result.getLong("stateMasterChangeSeqNum")
        val _tmp_stateLocalChangeSeqNum = _result.getLong("stateLocalChangeSeqNum")
        val _tmp_stateLastChangedBy = _result.getInt("stateLastChangedBy")
        val _tmp_stateLct = _result.getLong("stateLct")
        StateEntity().apply {
          this.stateUid = _tmp_stateUid
          this.stateId = _tmp_stateId
          this.agentUid = _tmp_agentUid
          this.activityId = _tmp_activityId
          this.registration = _tmp_registration
          this.isIsactive = _tmp_isIsactive
          this.timestamp = _tmp_timestamp
          this.stateMasterChangeSeqNum = _tmp_stateMasterChangeSeqNum
          this.stateLocalChangeSeqNum = _tmp_stateLocalChangeSeqNum
          this.stateLastChangedBy = _tmp_stateLastChangedBy
          this.stateLct = _tmp_stateLct
        }
      }
    }
  }

  override fun findStateIdByAgentAndActivity(
    agentUid: Long,
    activityId: String,
    registration: String,
    since: String,
  ): List<StateEntity> = _db.prepareAndUseStatement(PreparedStatementConfig(
    sql =
        "SELECT * FROM StateEntity WHERE agentUid = CAST(? AS BIGINT) AND activityId = ? AND registration = ? AND isIsactive AND timestamp > ?",
    postgreSql = """
    |SELECT * FROM StateEntity WHERE agentUid = ? AND activityId = ? AND registration = ? AND isIsactive AND timestamp > ?
    |""".trimMargin(),
    readOnly = true,)
  ) { _stmt -> 
    _stmt.setLong(1,agentUid)
    _stmt.setString(2,activityId)
    _stmt.setString(3,registration)
    _stmt.setString(4,since)
    _stmt.executeQuery().useResults{ _result -> 
      _result.mapRows {
        val _tmp_stateUid = _result.getLong("stateUid")
        val _tmp_stateId = _result.getString("stateId")
        val _tmp_agentUid = _result.getLong("agentUid")
        val _tmp_activityId = _result.getString("activityId")
        val _tmp_registration = _result.getString("registration")
        val _tmp_isIsactive = _result.getBoolean("isIsactive")
        val _tmp_timestamp = _result.getLong("timestamp")
        val _tmp_stateMasterChangeSeqNum = _result.getLong("stateMasterChangeSeqNum")
        val _tmp_stateLocalChangeSeqNum = _result.getLong("stateLocalChangeSeqNum")
        val _tmp_stateLastChangedBy = _result.getInt("stateLastChangedBy")
        val _tmp_stateLct = _result.getLong("stateLct")
        StateEntity().apply {
          this.stateUid = _tmp_stateUid
          this.stateId = _tmp_stateId
          this.agentUid = _tmp_agentUid
          this.activityId = _tmp_activityId
          this.registration = _tmp_registration
          this.isIsactive = _tmp_isIsactive
          this.timestamp = _tmp_timestamp
          this.stateMasterChangeSeqNum = _tmp_stateMasterChangeSeqNum
          this.stateLocalChangeSeqNum = _tmp_stateLocalChangeSeqNum
          this.stateLastChangedBy = _tmp_stateLastChangedBy
          this.stateLct = _tmp_stateLct
        }
      }
    }
  }

  override fun updateStateToInActive(
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE StateEntity 
      |           SET isIsactive = ?,
      |               stateLct = CAST(? AS BIGINT)
      |         WHERE agentUid = CAST(? AS BIGINT) AND activityId = ? 
      |           AND registration = ? AND isIsactive
      |    
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE StateEntity 
      |           SET isIsactive = ?,
      |               stateLct = ?
      |         WHERE agentUid = ? AND activityId = ? 
      |           AND registration = ? AND isIsactive
      |    
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,isActive)
      _stmt.setLong(2,updateTime)
      _stmt.setLong(3,agentUid)
      _stmt.setString(4,activityId)
      _stmt.setString(5,registration)
      _stmt.executeUpdate()
    }
  }

  override fun setStateInActive(
    stateId: String,
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long,
  ) {
    _db.prepareAndUseStatement(PreparedStatementConfig(
      sql = """
      |
      |        UPDATE StateEntity 
      |          SET isIsactive = ?, 
      |              stateLct = CAST(? AS BIGINT)
      |        WHERE stateId = ? AND agentUid = CAST(? AS BIGINT) 
      |          AND activityId = ? AND registration = ? 
      |          AND isIsactive
      """.trimMargin(),
      postgreSql = """
      |
      |        UPDATE StateEntity 
      |          SET isIsactive = ?, 
      |              stateLct = ?
      |        WHERE stateId = ? AND agentUid = ? 
      |          AND activityId = ? AND registration = ? 
      |          AND isIsactive
      |""".trimMargin(),
      readOnly = false,)
    ) { _stmt -> 
      _stmt.setBoolean(1,isActive)
      _stmt.setLong(2,updateTime)
      _stmt.setString(3,stateId)
      _stmt.setLong(4,agentUid)
      _stmt.setString(5,activityId)
      _stmt.setString(6,registration)
      _stmt.executeUpdate()
    }
  }
}
