package com.ustadmobile.core.db

import com.ustadmobile.core.db.dao.AgentDao
import com.ustadmobile.core.db.dao.AgentDao_DoorWrapper
import com.ustadmobile.core.db.dao.CacheLockJoinDao
import com.ustadmobile.core.db.dao.ChatDao
import com.ustadmobile.core.db.dao.ChatDao_DoorWrapper
import com.ustadmobile.core.db.dao.ChatMemberDao
import com.ustadmobile.core.db.dao.ChatMemberDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzAssignmentDao
import com.ustadmobile.core.db.dao.ClazzAssignmentDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzDao
import com.ustadmobile.core.db.dao.ClazzDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao
import com.ustadmobile.core.db.dao.ClazzEnrolmentDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao
import com.ustadmobile.core.db.dao.ClazzLogAttendanceRecordDao_DoorWrapper
import com.ustadmobile.core.db.dao.ClazzLogDao
import com.ustadmobile.core.db.dao.ClazzLogDao_DoorWrapper
import com.ustadmobile.core.db.dao.CommentsDao
import com.ustadmobile.core.db.dao.CommentsDao_DoorWrapper
import com.ustadmobile.core.db.dao.ConnectivityStatusDao
import com.ustadmobile.core.db.dao.ContainerDao
import com.ustadmobile.core.db.dao.ContainerDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContainerETagDao
import com.ustadmobile.core.db.dao.ContainerEntryDao
import com.ustadmobile.core.db.dao.ContainerEntryFileDao
import com.ustadmobile.core.db.dao.ContainerImportJobDao
import com.ustadmobile.core.db.dao.ContentCategoryDao
import com.ustadmobile.core.db.dao.ContentCategoryDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao
import com.ustadmobile.core.db.dao.ContentCategorySchemaDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryContentCategoryJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryDao
import com.ustadmobile.core.db.dao.ContentEntryDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryImportJobDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao
import com.ustadmobile.core.db.dao.ContentEntryParentChildJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao
import com.ustadmobile.core.db.dao.ContentEntryPicture2Dao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryPictureDao
import com.ustadmobile.core.db.dao.ContentEntryPictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao
import com.ustadmobile.core.db.dao.ContentEntryRelatedEntryJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentEntryVersionDao
import com.ustadmobile.core.db.dao.ContentEntryVersionDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContentJobDao
import com.ustadmobile.core.db.dao.ContentJobDao_DoorWrapper
import com.ustadmobile.core.db.dao.ContextXObjectStatementJoinDao
import com.ustadmobile.core.db.dao.ContextXObjectStatementJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao
import com.ustadmobile.core.db.dao.CourseAssignmentMarkDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao
import com.ustadmobile.core.db.dao.CourseAssignmentSubmissionFileDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseBlockDao
import com.ustadmobile.core.db.dao.CourseBlockDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseBlockPictureDao
import com.ustadmobile.core.db.dao.CourseBlockPictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseGroupMemberDao
import com.ustadmobile.core.db.dao.CourseGroupMemberDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseGroupSetDao
import com.ustadmobile.core.db.dao.CourseGroupSetDao_DoorWrapper
import com.ustadmobile.core.db.dao.CoursePermissionDao
import com.ustadmobile.core.db.dao.CoursePermissionDao_DoorWrapper
import com.ustadmobile.core.db.dao.CoursePictureDao
import com.ustadmobile.core.db.dao.CoursePictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.CourseTerminologyDao
import com.ustadmobile.core.db.dao.CourseTerminologyDao_DoorWrapper
import com.ustadmobile.core.db.dao.DeletedItemDao
import com.ustadmobile.core.db.dao.DiscussionPostDao
import com.ustadmobile.core.db.dao.DiscussionPostDao_DoorWrapper
import com.ustadmobile.core.db.dao.EnrolmentRequestDao
import com.ustadmobile.core.db.dao.EnrolmentRequestDao_DoorWrapper
import com.ustadmobile.core.db.dao.ErrorReportDao
import com.ustadmobile.core.db.dao.ErrorReportDao_DoorWrapper
import com.ustadmobile.core.db.dao.ExternalAppPermissionDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao
import com.ustadmobile.core.db.dao.HolidayCalendarDao_DoorWrapper
import com.ustadmobile.core.db.dao.HolidayDao
import com.ustadmobile.core.db.dao.HolidayDao_DoorWrapper
import com.ustadmobile.core.db.dao.LanguageDao
import com.ustadmobile.core.db.dao.LanguageDao_DoorWrapper
import com.ustadmobile.core.db.dao.LanguageVariantDao
import com.ustadmobile.core.db.dao.LanguageVariantDao_DoorWrapper
import com.ustadmobile.core.db.dao.LeavingReasonDao
import com.ustadmobile.core.db.dao.LeavingReasonDao_DoorWrapper
import com.ustadmobile.core.db.dao.LocallyAvailableContainerDao
import com.ustadmobile.core.db.dao.MessageDao
import com.ustadmobile.core.db.dao.MessageDao_DoorWrapper
import com.ustadmobile.core.db.dao.MessageReadDao
import com.ustadmobile.core.db.dao.MessageReadDao_DoorWrapper
import com.ustadmobile.core.db.dao.NetworkNodeDao
import com.ustadmobile.core.db.dao.OfflineItemDao
import com.ustadmobile.core.db.dao.OfflineItemDao_DoorWrapper
import com.ustadmobile.core.db.dao.OutgoingReplicationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao
import com.ustadmobile.core.db.dao.PeerReviewerAllocationDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonAuth2Dao
import com.ustadmobile.core.db.dao.PersonAuth2Dao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonAuthDao
import com.ustadmobile.core.db.dao.PersonDao
import com.ustadmobile.core.db.dao.PersonDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonGroupDao
import com.ustadmobile.core.db.dao.PersonGroupDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonGroupMemberDao
import com.ustadmobile.core.db.dao.PersonGroupMemberDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonParentJoinDao
import com.ustadmobile.core.db.dao.PersonParentJoinDao_DoorWrapper
import com.ustadmobile.core.db.dao.PersonPictureDao
import com.ustadmobile.core.db.dao.PersonPictureDao_DoorWrapper
import com.ustadmobile.core.db.dao.ReportDao
import com.ustadmobile.core.db.dao.ReportDao_DoorWrapper
import com.ustadmobile.core.db.dao.ScheduleDao
import com.ustadmobile.core.db.dao.ScheduleDao_DoorWrapper
import com.ustadmobile.core.db.dao.SchoolDao
import com.ustadmobile.core.db.dao.SchoolDao_DoorWrapper
import com.ustadmobile.core.db.dao.SchoolMemberDao
import com.ustadmobile.core.db.dao.SchoolMemberDao_DoorWrapper
import com.ustadmobile.core.db.dao.ScopedGrantDao
import com.ustadmobile.core.db.dao.ScopedGrantDao_DoorWrapper
import com.ustadmobile.core.db.dao.ScrapeQueueItemDao
import com.ustadmobile.core.db.dao.SiteDao
import com.ustadmobile.core.db.dao.SiteDao_DoorWrapper
import com.ustadmobile.core.db.dao.SiteTermsDao
import com.ustadmobile.core.db.dao.SiteTermsDao_DoorWrapper
import com.ustadmobile.core.db.dao.StateContentDao
import com.ustadmobile.core.db.dao.StateContentDao_DoorWrapper
import com.ustadmobile.core.db.dao.StateDao
import com.ustadmobile.core.db.dao.StateDao_DoorWrapper
import com.ustadmobile.core.db.dao.StatementDao
import com.ustadmobile.core.db.dao.StatementDao_DoorWrapper
import com.ustadmobile.core.db.dao.SyncNodeDao
import com.ustadmobile.core.db.dao.SystemPermissionDao
import com.ustadmobile.core.db.dao.SystemPermissionDao_DoorWrapper
import com.ustadmobile.core.db.dao.TransferJobDao
import com.ustadmobile.core.db.dao.TransferJobErrorDao
import com.ustadmobile.core.db.dao.TransferJobItemDao
import com.ustadmobile.core.db.dao.UserSessionDao
import com.ustadmobile.core.db.dao.UserSessionDao_DoorWrapper
import com.ustadmobile.core.db.dao.VerbDao
import com.ustadmobile.core.db.dao.VerbDao_DoorWrapper
import com.ustadmobile.core.db.dao.XLangMapEntryDao
import com.ustadmobile.core.db.dao.XLangMapEntryDao_DoorWrapper
import com.ustadmobile.core.db.dao.XObjectDao
import com.ustadmobile.core.db.dao.XObjectDao_DoorWrapper
import com.ustadmobile.door.DoorDatabaseWrapper
import com.ustadmobile.door.log.DoorLogger
import com.ustadmobile.door.message.DoorMessageCallback
import com.ustadmobile.door.nodeevent.NodeEventManagerCommon
import com.ustadmobile.door.nodeevent.NodeEventManagerJs
import com.ustadmobile.door.room.InvalidationTracker
import com.ustadmobile.door.room.RoomDatabase
import kotlin.Any
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.collections.List
import kotlin.reflect.KClass
import kotlinx.coroutines.Runnable

@Suppress("REDUNDANT_PROJECTION", "ClassName")
public class UmAppDatabase_DoorWrapper(
  private val _db: UmAppDatabase,
  override val nodeId: Long,
  private val messageCallback: DoorMessageCallback<UmAppDatabase>,
  logger: DoorLogger,
  dbName: String,
) : UmAppDatabase(), DoorDatabaseWrapper<UmAppDatabase> {
  override val dbVersion: Int
    get() = 171

  override val dbName: String
    get() = "DoorWrapper for [${_db.toString()}]"

  override val networkNodeDao: NetworkNodeDao
    get() = _db.networkNodeDao

  public val _PersonDao: PersonDao by lazy  {
        PersonDao_DoorWrapper(_db, _db.personDao)
      }


  override val personDao: PersonDao
    get() = _PersonDao

  public val _ClazzDao: ClazzDao by lazy  {
        ClazzDao_DoorWrapper(_db, _db.clazzDao)
      }


  override val clazzDao: ClazzDao
    get() = _ClazzDao

  public val _CourseBlockDao: CourseBlockDao by lazy  {
        CourseBlockDao_DoorWrapper(_db, _db.courseBlockDao)
      }


  override val courseBlockDao: CourseBlockDao
    get() = _CourseBlockDao

  public val _CourseTerminologyDao: CourseTerminologyDao by lazy  {
        CourseTerminologyDao_DoorWrapper(_db, _db.courseTerminologyDao)
      }


  override val courseTerminologyDao: CourseTerminologyDao
    get() = _CourseTerminologyDao

  public val _CourseGroupSetDao: CourseGroupSetDao by lazy  {
        CourseGroupSetDao_DoorWrapper(_db, _db.courseGroupSetDao)
      }


  override val courseGroupSetDao: CourseGroupSetDao
    get() = _CourseGroupSetDao

  public val _CourseGroupMemberDao: CourseGroupMemberDao by lazy  {
        CourseGroupMemberDao_DoorWrapper(_db, _db.courseGroupMemberDao)
      }


  override val courseGroupMemberDao: CourseGroupMemberDao
    get() = _CourseGroupMemberDao

  public val _ClazzEnrolmentDao: ClazzEnrolmentDao by lazy  {
        ClazzEnrolmentDao_DoorWrapper(_db, _db.clazzEnrolmentDao)
      }


  override val clazzEnrolmentDao: ClazzEnrolmentDao
    get() = _ClazzEnrolmentDao

  public val _LeavingReasonDao: LeavingReasonDao by lazy  {
        LeavingReasonDao_DoorWrapper(_db, _db.leavingReasonDao)
      }


  override val leavingReasonDao: LeavingReasonDao
    get() = _LeavingReasonDao

  public val _ContentEntryDao: ContentEntryDao by lazy  {
        ContentEntryDao_DoorWrapper(_db, _db.contentEntryDao)
      }


  override val contentEntryDao: ContentEntryDao
    get() = _ContentEntryDao

  public val _ContentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao by lazy  {
        ContentEntryContentCategoryJoinDao_DoorWrapper(_db, _db.contentEntryContentCategoryJoinDao)
      }


  override val contentEntryContentCategoryJoinDao: ContentEntryContentCategoryJoinDao
    get() = _ContentEntryContentCategoryJoinDao

  public val _ContentEntryParentChildJoinDao: ContentEntryParentChildJoinDao by lazy  {
        ContentEntryParentChildJoinDao_DoorWrapper(_db, _db.contentEntryParentChildJoinDao)
      }


  override val contentEntryParentChildJoinDao: ContentEntryParentChildJoinDao
    get() = _ContentEntryParentChildJoinDao

  public val _ContentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao by lazy  {
        ContentEntryRelatedEntryJoinDao_DoorWrapper(_db, _db.contentEntryRelatedEntryJoinDao)
      }


  override val contentEntryRelatedEntryJoinDao: ContentEntryRelatedEntryJoinDao
    get() = _ContentEntryRelatedEntryJoinDao

  public val _ContentCategorySchemaDao: ContentCategorySchemaDao by lazy  {
        ContentCategorySchemaDao_DoorWrapper(_db, _db.contentCategorySchemaDao)
      }


  override val contentCategorySchemaDao: ContentCategorySchemaDao
    get() = _ContentCategorySchemaDao

  public val _ContentCategoryDao: ContentCategoryDao by lazy  {
        ContentCategoryDao_DoorWrapper(_db, _db.contentCategoryDao)
      }


  override val contentCategoryDao: ContentCategoryDao
    get() = _ContentCategoryDao

  public val _LanguageDao: LanguageDao by lazy  {
        LanguageDao_DoorWrapper(_db, _db.languageDao)
      }


  override val languageDao: LanguageDao
    get() = _LanguageDao

  public val _LanguageVariantDao: LanguageVariantDao by lazy  {
        LanguageVariantDao_DoorWrapper(_db, _db.languageVariantDao)
      }


  override val languageVariantDao: LanguageVariantDao
    get() = _LanguageVariantDao

  override val scrapeQueueItemDao: ScrapeQueueItemDao
    get() = _db.scrapeQueueItemDao

  override val personAuthDao: PersonAuthDao
    get() = _db.personAuthDao

  public val _PersonGroupDao: PersonGroupDao by lazy  {
        PersonGroupDao_DoorWrapper(_db, _db.personGroupDao)
      }


  override val personGroupDao: PersonGroupDao
    get() = _PersonGroupDao

  public val _PersonGroupMemberDao: PersonGroupMemberDao by lazy  {
        PersonGroupMemberDao_DoorWrapper(_db, _db.personGroupMemberDao)
      }


  override val personGroupMemberDao: PersonGroupMemberDao
    get() = _PersonGroupMemberDao

  public val _PersonPictureDao: PersonPictureDao by lazy  {
        PersonPictureDao_DoorWrapper(_db, _db.personPictureDao)
      }


  override val personPictureDao: PersonPictureDao
    get() = _PersonPictureDao

  override val connectivityStatusDao: ConnectivityStatusDao
    get() = _db.connectivityStatusDao

  public val _ContainerDao: ContainerDao by lazy  {
        ContainerDao_DoorWrapper(_db, _db.containerDao)
      }


  override val containerDao: ContainerDao
    get() = _ContainerDao

  override val containerEntryDao: ContainerEntryDao
    get() = _db.containerEntryDao

  override val containerEntryFileDao: ContainerEntryFileDao
    get() = _db.containerEntryFileDao

  override val containerETagDao: ContainerETagDao
    get() = _db.containerETagDao

  public val _VerbDao: VerbDao by lazy  {
        VerbDao_DoorWrapper(_db, _db.verbDao)
      }


  override val verbDao: VerbDao
    get() = _VerbDao

  public val _XObjectDao: XObjectDao by lazy  {
        XObjectDao_DoorWrapper(_db, _db.xObjectDao)
      }


  override val xObjectDao: XObjectDao
    get() = _XObjectDao

  public val _ReportDao: ReportDao by lazy  {
        ReportDao_DoorWrapper(_db, _db.reportDao)
      }


  override val reportDao: ReportDao
    get() = _ReportDao

  override val containerImportJobDao: ContainerImportJobDao
    get() = _db.containerImportJobDao

  public val _StatementDao: StatementDao by lazy  {
        StatementDao_DoorWrapper(_db, _db.statementDao)
      }


  override val statementDao: StatementDao
    get() = _StatementDao

  public val _ContextXObjectStatementJoinDao: ContextXObjectStatementJoinDao by lazy  {
        ContextXObjectStatementJoinDao_DoorWrapper(_db, _db.contextXObjectStatementJoinDao)
      }


  override val contextXObjectStatementJoinDao: ContextXObjectStatementJoinDao
    get() = _ContextXObjectStatementJoinDao

  public val _StateDao: StateDao by lazy  {
        StateDao_DoorWrapper(_db, _db.stateDao)
      }


  override val stateDao: StateDao
    get() = _StateDao

  public val _StateContentDao: StateContentDao by lazy  {
        StateContentDao_DoorWrapper(_db, _db.stateContentDao)
      }


  override val stateContentDao: StateContentDao
    get() = _StateContentDao

  public val _AgentDao: AgentDao by lazy  {
        AgentDao_DoorWrapper(_db, _db.agentDao)
      }


  override val agentDao: AgentDao
    get() = _AgentDao

  public val _ClazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao by lazy  {
        ClazzLogAttendanceRecordDao_DoorWrapper(_db, _db.clazzLogAttendanceRecordDao)
      }


  override val clazzLogAttendanceRecordDao: ClazzLogAttendanceRecordDao
    get() = _ClazzLogAttendanceRecordDao

  public val _ClazzLogDao: ClazzLogDao by lazy  {
        ClazzLogDao_DoorWrapper(_db, _db.clazzLogDao)
      }


  override val clazzLogDao: ClazzLogDao
    get() = _ClazzLogDao

  public val _ScheduleDao: ScheduleDao by lazy  {
        ScheduleDao_DoorWrapper(_db, _db.scheduleDao)
      }


  override val scheduleDao: ScheduleDao
    get() = _ScheduleDao

  public val _HolidayCalendarDao: HolidayCalendarDao by lazy  {
        HolidayCalendarDao_DoorWrapper(_db, _db.holidayCalendarDao)
      }


  override val holidayCalendarDao: HolidayCalendarDao
    get() = _HolidayCalendarDao

  public val _HolidayDao: HolidayDao by lazy  {
        HolidayDao_DoorWrapper(_db, _db.holidayDao)
      }


  override val holidayDao: HolidayDao
    get() = _HolidayDao

  public val _SchoolDao: SchoolDao by lazy  {
        SchoolDao_DoorWrapper(_db, _db.schoolDao)
      }


  override val schoolDao: SchoolDao
    get() = _SchoolDao

  public val _XLangMapEntryDao: XLangMapEntryDao by lazy  {
        XLangMapEntryDao_DoorWrapper(_db, _db.xLangMapEntryDao)
      }


  override val xLangMapEntryDao: XLangMapEntryDao
    get() = _XLangMapEntryDao

  override val locallyAvailableContainerDao: LocallyAvailableContainerDao
    get() = _db.locallyAvailableContainerDao

  public val _SchoolMemberDao: SchoolMemberDao by lazy  {
        SchoolMemberDao_DoorWrapper(_db, _db.schoolMemberDao)
      }


  override val schoolMemberDao: SchoolMemberDao
    get() = _SchoolMemberDao

  public val _ClazzAssignmentDao: ClazzAssignmentDao by lazy  {
        ClazzAssignmentDao_DoorWrapper(_db, _db.clazzAssignmentDao)
      }


  override val clazzAssignmentDao: ClazzAssignmentDao
    get() = _ClazzAssignmentDao

  public val _CourseAssignmentSubmissionDao: CourseAssignmentSubmissionDao by lazy  {
        CourseAssignmentSubmissionDao_DoorWrapper(_db, _db.courseAssignmentSubmissionDao)
      }


  override val courseAssignmentSubmissionDao: CourseAssignmentSubmissionDao
    get() = _CourseAssignmentSubmissionDao

  public val _CourseAssignmentSubmissionFileDao: CourseAssignmentSubmissionFileDao by lazy  {
        CourseAssignmentSubmissionFileDao_DoorWrapper(_db, _db.courseAssignmentSubmissionFileDao)
      }


  override val courseAssignmentSubmissionFileDao: CourseAssignmentSubmissionFileDao
    get() = _CourseAssignmentSubmissionFileDao

  public val _CourseAssignmentMarkDao: CourseAssignmentMarkDao by lazy  {
        CourseAssignmentMarkDao_DoorWrapper(_db, _db.courseAssignmentMarkDao)
      }


  override val courseAssignmentMarkDao: CourseAssignmentMarkDao
    get() = _CourseAssignmentMarkDao

  public val _CommentsDao: CommentsDao by lazy  {
        CommentsDao_DoorWrapper(_db, _db.commentsDao)
      }


  override val commentsDao: CommentsDao
    get() = _CommentsDao

  override val syncNodeDao: SyncNodeDao
    get() = _db.syncNodeDao

  public val _SiteDao: SiteDao by lazy  {
        SiteDao_DoorWrapper(_db, _db.siteDao)
      }


  override val siteDao: SiteDao
    get() = _SiteDao

  public val _SiteTermsDao: SiteTermsDao by lazy  {
        SiteTermsDao_DoorWrapper(_db, _db.siteTermsDao)
      }


  override val siteTermsDao: SiteTermsDao
    get() = _SiteTermsDao

  public val _PersonParentJoinDao: PersonParentJoinDao by lazy  {
        PersonParentJoinDao_DoorWrapper(_db, _db.personParentJoinDao)
      }


  override val personParentJoinDao: PersonParentJoinDao
    get() = _PersonParentJoinDao

  public val _ScopedGrantDao: ScopedGrantDao by lazy  {
        ScopedGrantDao_DoorWrapper(_db, _db.scopedGrantDao)
      }


  override val scopedGrantDao: ScopedGrantDao
    get() = _ScopedGrantDao

  public val _ErrorReportDao: ErrorReportDao by lazy  {
        ErrorReportDao_DoorWrapper(_db, _db.errorReportDao)
      }


  override val errorReportDao: ErrorReportDao
    get() = _ErrorReportDao

  public val _PersonAuth2Dao: PersonAuth2Dao by lazy  {
        PersonAuth2Dao_DoorWrapper(_db, _db.personAuth2Dao)
      }


  override val personAuth2Dao: PersonAuth2Dao
    get() = _PersonAuth2Dao

  public val _UserSessionDao: UserSessionDao by lazy  {
        UserSessionDao_DoorWrapper(_db, _db.userSessionDao)
      }


  override val userSessionDao: UserSessionDao
    get() = _UserSessionDao

  override val contentEntryImportJobDao: ContentEntryImportJobDao
    get() = _db.contentEntryImportJobDao

  public val _ContentJobDao: ContentJobDao by lazy  {
        ContentJobDao_DoorWrapper(_db, _db.contentJobDao)
      }


  override val contentJobDao: ContentJobDao
    get() = _ContentJobDao

  public val _CoursePictureDao: CoursePictureDao by lazy  {
        CoursePictureDao_DoorWrapper(_db, _db.coursePictureDao)
      }


  override val coursePictureDao: CoursePictureDao
    get() = _CoursePictureDao

  public val _ContentEntryPictureDao: ContentEntryPictureDao by lazy  {
        ContentEntryPictureDao_DoorWrapper(_db, _db.contentEntryPictureDao)
      }


  override val contentEntryPictureDao: ContentEntryPictureDao
    get() = _ContentEntryPictureDao

  public val _ChatDao: ChatDao by lazy  {
        ChatDao_DoorWrapper(_db, _db.chatDao)
      }


  override val chatDao: ChatDao
    get() = _ChatDao

  public val _ChatMemberDao: ChatMemberDao by lazy  {
        ChatMemberDao_DoorWrapper(_db, _db.chatMemberDao)
      }


  override val chatMemberDao: ChatMemberDao
    get() = _ChatMemberDao

  public val _MessageDao: MessageDao by lazy  {
        MessageDao_DoorWrapper(_db, _db.messageDao)
      }


  override val messageDao: MessageDao
    get() = _MessageDao

  public val _MessageReadDao: MessageReadDao by lazy  {
        MessageReadDao_DoorWrapper(_db, _db.messageReadDao)
      }


  override val messageReadDao: MessageReadDao
    get() = _MessageReadDao

  public val _PeerReviewerAllocationDao: PeerReviewerAllocationDao by lazy  {
        PeerReviewerAllocationDao_DoorWrapper(_db, _db.peerReviewerAllocationDao)
      }


  override val peerReviewerAllocationDao: PeerReviewerAllocationDao
    get() = _PeerReviewerAllocationDao

  public val _DiscussionPostDao: DiscussionPostDao by lazy  {
        DiscussionPostDao_DoorWrapper(_db, _db.discussionPostDao)
      }


  override val discussionPostDao: DiscussionPostDao
    get() = _DiscussionPostDao

  override val externalAppPermissionDao: ExternalAppPermissionDao
    get() = _db.externalAppPermissionDao

  public val _ContentEntryVersionDao: ContentEntryVersionDao by lazy  {
        ContentEntryVersionDao_DoorWrapper(_db, _db.contentEntryVersionDao)
      }


  override val contentEntryVersionDao: ContentEntryVersionDao
    get() = _ContentEntryVersionDao

  override val outgoingReplicationDao: OutgoingReplicationDao
    get() = _db.outgoingReplicationDao

  override val transferJobDao: TransferJobDao
    get() = _db.transferJobDao

  override val transferJobItemDao: TransferJobItemDao
    get() = _db.transferJobItemDao

  override val cacheLockJoinDao: CacheLockJoinDao
    get() = _db.cacheLockJoinDao

  public val _OfflineItemDao: OfflineItemDao by lazy  {
        OfflineItemDao_DoorWrapper(_db, _db.offlineItemDao)
      }


  override val offlineItemDao: OfflineItemDao
    get() = _OfflineItemDao

  override val deletedItemDao: DeletedItemDao
    get() = _db.deletedItemDao

  public val _EnrolmentRequestDao: EnrolmentRequestDao by lazy  {
        EnrolmentRequestDao_DoorWrapper(_db, _db.enrolmentRequestDao)
      }


  override val enrolmentRequestDao: EnrolmentRequestDao
    get() = _EnrolmentRequestDao

  public val _CoursePermissionDao: CoursePermissionDao by lazy  {
        CoursePermissionDao_DoorWrapper(_db, _db.coursePermissionDao)
      }


  override val coursePermissionDao: CoursePermissionDao
    get() = _CoursePermissionDao

  public val _SystemPermissionDao: SystemPermissionDao by lazy  {
        SystemPermissionDao_DoorWrapper(_db, _db.systemPermissionDao)
      }


  override val systemPermissionDao: SystemPermissionDao
    get() = _SystemPermissionDao

  public val _CourseBlockPictureDao: CourseBlockPictureDao by lazy  {
        CourseBlockPictureDao_DoorWrapper(_db, _db.courseBlockPictureDao)
      }


  override val courseBlockPictureDao: CourseBlockPictureDao
    get() = _CourseBlockPictureDao

  public val _ContentEntryPicture2Dao: ContentEntryPicture2Dao by lazy  {
        ContentEntryPicture2Dao_DoorWrapper(_db, _db.contentEntryPicture2Dao)
      }


  override val contentEntryPicture2Dao: ContentEntryPicture2Dao
    get() = _ContentEntryPicture2Dao

  override val transferJobErrorDao: TransferJobErrorDao
    get() = _db.transferJobErrorDao

  override val realDatabase: RoomDatabase
    get() = _db

  override val nodeEventManager: NodeEventManagerCommon<UmAppDatabase> = NodeEventManagerJs(_db,
      messageCallback, logger, dbName)


  override val invalidationTracker: InvalidationTracker
    get() = _db.invalidationTracker

  override fun createAllTables(): List<String> = _db.createAllTables()

  override suspend fun clearAllTablesAsync() {
    _db.clearAllTablesAsync()
  }

  override fun clearAllTables() {
    _db.clearAllTables()
  }

  override fun runInTransaction(runnable: Runnable) {
    _db.runInTransaction(runnable)
  }

  @Suppress("UNCHECKED_CAST")
  override fun <T : Any> getDaoByClass(daoClass: KClass<T>): T = when(daoClass) {
    NetworkNodeDao::class -> networkNodeDao as T
    PersonDao::class -> personDao as T
    ClazzDao::class -> clazzDao as T
    CourseBlockDao::class -> courseBlockDao as T
    CourseTerminologyDao::class -> courseTerminologyDao as T
    CourseGroupSetDao::class -> courseGroupSetDao as T
    CourseGroupMemberDao::class -> courseGroupMemberDao as T
    ClazzEnrolmentDao::class -> clazzEnrolmentDao as T
    LeavingReasonDao::class -> leavingReasonDao as T
    ContentEntryDao::class -> contentEntryDao as T
    ContentEntryContentCategoryJoinDao::class -> contentEntryContentCategoryJoinDao as T
    ContentEntryParentChildJoinDao::class -> contentEntryParentChildJoinDao as T
    ContentEntryRelatedEntryJoinDao::class -> contentEntryRelatedEntryJoinDao as T
    ContentCategorySchemaDao::class -> contentCategorySchemaDao as T
    ContentCategoryDao::class -> contentCategoryDao as T
    LanguageDao::class -> languageDao as T
    LanguageVariantDao::class -> languageVariantDao as T
    ScrapeQueueItemDao::class -> scrapeQueueItemDao as T
    PersonAuthDao::class -> personAuthDao as T
    PersonGroupDao::class -> personGroupDao as T
    PersonGroupMemberDao::class -> personGroupMemberDao as T
    PersonPictureDao::class -> personPictureDao as T
    ConnectivityStatusDao::class -> connectivityStatusDao as T
    ContainerDao::class -> containerDao as T
    ContainerEntryDao::class -> containerEntryDao as T
    ContainerEntryFileDao::class -> containerEntryFileDao as T
    ContainerETagDao::class -> containerETagDao as T
    VerbDao::class -> verbDao as T
    XObjectDao::class -> xObjectDao as T
    ReportDao::class -> reportDao as T
    ContainerImportJobDao::class -> containerImportJobDao as T
    StatementDao::class -> statementDao as T
    ContextXObjectStatementJoinDao::class -> contextXObjectStatementJoinDao as T
    StateDao::class -> stateDao as T
    StateContentDao::class -> stateContentDao as T
    AgentDao::class -> agentDao as T
    ClazzLogAttendanceRecordDao::class -> clazzLogAttendanceRecordDao as T
    ClazzLogDao::class -> clazzLogDao as T
    ScheduleDao::class -> scheduleDao as T
    HolidayCalendarDao::class -> holidayCalendarDao as T
    HolidayDao::class -> holidayDao as T
    SchoolDao::class -> schoolDao as T
    XLangMapEntryDao::class -> xLangMapEntryDao as T
    LocallyAvailableContainerDao::class -> locallyAvailableContainerDao as T
    SchoolMemberDao::class -> schoolMemberDao as T
    ClazzAssignmentDao::class -> clazzAssignmentDao as T
    CourseAssignmentSubmissionDao::class -> courseAssignmentSubmissionDao as T
    CourseAssignmentSubmissionFileDao::class -> courseAssignmentSubmissionFileDao as T
    CourseAssignmentMarkDao::class -> courseAssignmentMarkDao as T
    CommentsDao::class -> commentsDao as T
    SyncNodeDao::class -> syncNodeDao as T
    SiteDao::class -> siteDao as T
    SiteTermsDao::class -> siteTermsDao as T
    PersonParentJoinDao::class -> personParentJoinDao as T
    ScopedGrantDao::class -> scopedGrantDao as T
    ErrorReportDao::class -> errorReportDao as T
    PersonAuth2Dao::class -> personAuth2Dao as T
    UserSessionDao::class -> userSessionDao as T
    ContentEntryImportJobDao::class -> contentEntryImportJobDao as T
    ContentJobDao::class -> contentJobDao as T
    CoursePictureDao::class -> coursePictureDao as T
    ContentEntryPictureDao::class -> contentEntryPictureDao as T
    ChatDao::class -> chatDao as T
    ChatMemberDao::class -> chatMemberDao as T
    MessageDao::class -> messageDao as T
    MessageReadDao::class -> messageReadDao as T
    PeerReviewerAllocationDao::class -> peerReviewerAllocationDao as T
    DiscussionPostDao::class -> discussionPostDao as T
    ExternalAppPermissionDao::class -> externalAppPermissionDao as T
    ContentEntryVersionDao::class -> contentEntryVersionDao as T
    OutgoingReplicationDao::class -> outgoingReplicationDao as T
    TransferJobDao::class -> transferJobDao as T
    TransferJobItemDao::class -> transferJobItemDao as T
    CacheLockJoinDao::class -> cacheLockJoinDao as T
    OfflineItemDao::class -> offlineItemDao as T
    DeletedItemDao::class -> deletedItemDao as T
    EnrolmentRequestDao::class -> enrolmentRequestDao as T
    CoursePermissionDao::class -> coursePermissionDao as T
    SystemPermissionDao::class -> systemPermissionDao as T
    CourseBlockPictureDao::class -> courseBlockPictureDao as T
    ContentEntryPicture2Dao::class -> contentEntryPicture2Dao as T
    TransferJobErrorDao::class -> transferJobErrorDao as T
    else -> throw IllegalArgumentException("No such DAO on this DB")
  }

  override fun close() {
    _db.close()
  }
}
